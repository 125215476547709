import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button ,Modal,CloseButton } from "react-bootstrap"
import { AssistantItem } from "../../../../components";
import { AvisItem } from "../../../../components";
import { getListWatcher } from "../../../../actions/user";
import { useHistory } from "react-router";
import { BASE_URL_PROD } from "../../../../components/config/keys";
import { surpls,epr } from "../../../../assets/images";
import { useTranslation } from 'react-i18next';
 

const Page = () => {
    const autre_services_data = JSON.parse(localStorage.getItem('autre_services_data'));
    console.log('autre_services_data');
    console.log(autre_services_data);
    const dispatch = useDispatch();
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const [genre, setGenre] = useState(autre_services_data.genre);
    const [genreText, setGenreText] = useState(autre_services_data.genreText);
    const [qte, setQte] = useState(autre_services_data.qte);
    const [genreError, setGenreError] = useState(0);

    useEffect(() => {

    }, [])
  
    const handleGoBack = () => {
      history.push('/coiffure');
    };

    const handleGoTo = () => {
      setGenreError(0)
      if(genre == '' || genre == undefined){
        setGenreError(1)
      }else{
        autre_services_data.genre = genre
        autre_services_data.genreText = genreText
        autre_services_data.qte = qte
        localStorage.setItem('autre_services_data', JSON.stringify(autre_services_data))
        history.push('/garde-enfants/service');
      }
    };
    
    const handleGenre = element => {
      setGenreError(0)
      setGenre(element);
      setQte(1)
      if(element == 1) setGenreText("Garde d'enfant(s)")
      if(element == 2) setGenreText('Garde périscolaire')
    };
    const handelePlus = () => {
      const qte_new = qte+1
      setQte(qte_new)
    };
    const handeleMoins = () => {
      if(qte > 1){
        const qte_new = qte-1
        setQte(qte_new)
      }
    };
    return (
      <div className="garde_animaux gardeenfants2" >
        <section className="gray-bg big-padding " id="besoin1">
          <div className="container small-container ">
            <div className='registerform'>
              <div className='row '>
                <div className='col-md-8'><p onClick={()=>handleGoBack()} style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>&lt;	&lt;	Retour</p></div>
                <div className='col-md-8'>
                  <div className="section-title"><h2><span>Quel type de garde ? </span></h2>
                  </div>
                  <div className='custom-form col-md-4' style={{ paddingLeft:"0" }}>
                    <div onClick={() => handleGenre(1)} className={genre == 1 ? "boxShadow coiffurebox selected":"boxShadow coiffurebox"}>
                      <img src="/images/services/image enfants garde.png"/>
                      <p>Garde d'enfant(s)</p>
                    </div>
                  </div>
                  <div className='custom-form col-md-4' style={{ paddingLeft:"0" }}>
                    <div onClick={() => handleGenre(2)} className={genre == 2 ? "boxShadow coiffurebox selected":"boxShadow coiffurebox"}>
                        <img src="/images/services/image périscolaire.png"/>
                        <p>Garde périscolaire</p>
                    </div>
                  </div>


                  {
                    genreError == 1 ?
                    <div className='col-md-9' style={{ paddingLeft:"0", paddingTop:'30px', clear:'both' }}>
                      <div className="alert alert-danger" role="alert">
                        Veuillez choisir le genre !
                      </div>
                    </div>
                    :
                    ""
                  }
                  <div className='col-md-9' style={{ paddingLeft:"0", paddingTop:'30px', clear:'both' }}>
                    <div style={{textAlign:'left',marginBottom:'30px'}}>
                      <a onClick={()=>handleGoTo()} class="btn btnform w200 "> Continuer </a>
                    </div>
                  </div>
                </div>    
                <div className='col-md-4'>
                    <div className="header1 boxShadow">Recapitulatif</div>
                    <div className="body1 boxShadow">
                      <p style={{ margin:'0px', paddingLeft:'10px' }}>1 intervention garde enfants</p>
                      <hr style={{ margin:'10px' }}></hr>
                      {
                        genreText !== '' ?
                        <ul>
                          <li>{genreText}</li>
                        </ul>
                        :
                        ''
                      }
                    </div>
                </div>  
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Page;