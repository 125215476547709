import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { Dashbord,AssistantItem, DashbordHeader} from "../../../components";
import { EditMission,getMissionById } from "../../../actions/pages";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router";
const Page = () => {

    const dispatch = useDispatch();
    const { slug } = useParams()
    const { user } = useSelector(state => state.user)
    const { mission } = useSelector(state => state.pages)
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        dispatch(EditMission(formDataObj,user.id,slug,user.emailToken,'update','user'));
    
    }
     useEffect(() => {
       
      
        if(langset == false){
            dispatch(getMissionById(slug,user.id,user.emailToken));
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])

    
 
    return (
        <div className="myccount editprofile " >
           <DashbordHeader src={'u'} />
     

            <section className="gray-bg main-dashboard-sec" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <Dashbord />
                                 
                            </div>

                    
                         <div className='col-md-9'>

                             <div className='tab' id=''>
                                 <div className="dashboard-title   fl-wrap">
                                    <h3 className='htri1'>{t('Détail Mission')} - Ref #{mission.ref} </h3>
                                    <br/><br/>


                                    <div className='row'>

                                       <div className='col-md-8'>
                                          
                                                                <div className="profile-edit-container fl-wrap block_box">
                                    <div className="custom-form">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <label style={{fontWeight: 'bold',color: '#4c4c4c'}}>{t('Catégorie')}</label>
                                                <Form.Control readOnly  style={{border: '0px',backgroundColor:'#FFF'}}  type="text" className=" fnbordernn" defaultValue={mission.label} />                                              
                                            </div>
                                            <div className="col-sm-6">
                                                <label style={{fontWeight: 'bold',color: '#4c4c4c'}}>{t('Ville')} </label>
                                                 <Form.Control readOnly type="text" style={{border: '0px',backgroundColor:'#FFF',paddingLeft: '0px'}} className="form-control fnbordernn" defaultValue={mission.ville} />                                              
                                            </div>
                                           
                                            <div className="col-sm-6">
                                                <label style={{fontWeight: 'bold',color: '#4c4c4c'}}>{t('Adresse')}</label>
                                                <Form.Control readOnly type="text" style={{border: '0px',backgroundColor:'#FFF',paddingLeft: '0px'}}  className="fnbordernn"  defaultValue={mission.adresse} />                                                
                                            </div>

                                            <div className="col-sm-6">
                                                <label style={{fontWeight: 'bold',color: '#4c4c4c'}}>{t('Prix')} (€)</label>
                                                <Form.Control readOnly type="text" style={{border: '0px',backgroundColor:'#FFF',paddingLeft: '0px'}} className='fnbordernn' defaultValue={mission.price} />                                                
                                            </div>

                                            <div className="col-sm-6">
                                                <label style={{fontWeight: 'bold',color: '#4c4c4c'}}>{t('Durée')}</label>
                                                <Form.Control readOnly type="text" style={{border: '0px',backgroundColor:'#FFF',paddingLeft: '0px'}} className='fnbordernn'  defaultValue={mission.duree} />                                                
                                            </div>
 

                                              <div className="col-sm-6">
                                                <label style={{fontWeight: 'bold',color: '#4c4c4c'}}> {t('Date')} </label>
                                                <Form.Control readOnly  className='fnbordernn' style={{border: '0px',backgroundColor:'#FFF',paddingLeft: '0px'}}  type="text" placeholder={t('Date souhaitée')+"*"} name="datepicker-here-time" defaultValue={mission.date_mission}/>                                                
                                            </div>


                                           


                                            <div className="col-sm-12">
                                                <label style={{fontWeight: 'bold',color: '#4c4c4c'}}> {t('Que souhaitez vous faire ? Décrivez ce que dois réaliser l’assistant...')} </label>
                                                <textarea  readOnly defaultValue={mission.description} style={{marginBottom:"20px",paddingLeft: '0px',backgroundColor: "#FFF",border:"0px"}} className='fnbordernn' name='description' cols="40" rows="2" >{mission.description}</textarea>                                               
                                            </div>
                                          
                                        </div>
                                      
                                    </div>
                                </div>


                                       </div>

                                       <div className='col-md-4 paiments'>




                                              <div className='fl-wrap lws_mobile tabs-act block_box vishidelem'>
                                                  {
                                                    mission.watcher && 

                                                <div className='rapelasst'>
                                                <div className='center p33' style={{width: "100%"}}>
                                                    <div className='ps889' style={{backgroundImage:`url(${mission.watcher.photo })`, margin: '0 auto'}}>

                                                     </div>
                                                 </div>
                                                 
                                                 <strong style={{textTransform:"capitalize"}}>{mission.watcher.prenom} <span>{mission.watcher.nom}</span>.  {mission.watcher.intern== 1 && <span className="verified-badge"><i className="fal fa-check"></i></span> }</strong>
                                                  <p style={{fontSize:"12px"}}>{mission.watcher.ville}</p>
                                                <div class="listing-rating card-popup-rainingvis" data-starrating2="4"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><div class="card-popup-rainingvis_bg"><span class="card-popup-rainingvis_bg_item"></span><span class="card-popup-rainingvis_bg_item"></span><span class="card-popup-rainingvis_bg_item"></span><span class="card-popup-rainingvis_bg_item"></span><span class="card-popup-rainingvis_bg_item"></span><div></div></div></div>
                                              </div>

                                                  }
                                             

                                              <div className='rapelasstbtm '>
 
 

                 
                                                  <div className='group'>

                                                  {
                                                    mission.missionwatcher &&

                                                     <label>
                                                    <i class="fas fa-euro-sign"></i>

                                                  {
                                              
                                                        mission.missionwatcher.price  > mission.price ?
                                                              <span className=""> <span className='libebaree'>€{mission.price}</span> <strong>€{mission.missionwatcher.price}</strong></span>
                                                         :

                                                            <span className=""> €{mission.price}</span>
       
                                              

                  
                                                      }
                                                       </label>
                                                  }
                                                      

                                                   
                                                    
                                                </div>
                                              </div>
                                         </div>


                                       </div>




                                    </div>
                                  
              


                    
                                 </div>
                              </div>
 

                              

                         </div>
                    </div>
            </section>


        </div>
    );
}

export default Page;