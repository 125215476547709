import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card,Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenuClient,DashbordHeaderClient } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"

import { AUTHENTICATE_USER, LOGOUT_USER } from "../../../constants/actions";
import { getAllCategories,getAllBienReservation,getUserBien } from "../../../actions/pages";
import { updateInfoUser, suspendCompte } from "../../../actions/user";
import { BASE_URL_PROD } from "../../../components/config/keys";
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';
import moment from 'moment'



const Page = () => {

   
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user)
  const { data } = useSelector(state => state.pages)
  const { t, i18n } = useTranslation();
  const currentlangStorage = localStorage.getItem("currentlang");
  const currentlang=i18n.language;
  const [langset, setLangset] = useState(false);
  const {  reservation_bien } = useSelector(state => state.pages)
  const { user_biens, user_all_biens } = useSelector(state => state.pages)
  const history = useHistory()
  const [startDate, setStartDate] = useState(new Date());
  const label = { inputProps: { 'aria-label': '' } };


   


useEffect(() => {
     dispatch(getUserBien({user_id : user.id, type : 1}));

}, [])


    const returnitems =  () => {
        return(

            <div className='col-md-3  position_relative'>

                 <div className="process-item position_relative" style={{cursor:'pointer'}} onClick={()=>history.push('/detail-bien/1869')}>
                        <div className="process-img" style={{backgroundImage:"url('/images/imo.png')", minHeight: '95px'}}>
                       
                        </div>
                       
                        <h4 className='basecolor title' ><strong style={{color:'#0E0E0E'}}>Appartement </strong>3 pièces 65 m²</h4>
                       
                        <h6 className='' style={{ textAlign:"left", fontSize:"11px", color:"gray",     display: 'flex',alignItems: 'center',justifyContent: 'space-between' }}>
                          <div><img src="/images/immo/icone-map.png" className="" style={{ width:"12px",    height: '16px',     marginTop: '0px' }} /> Paris 18ème</div>
                          <div className="priceoffre" style={{ textAlign:"left", fontSize:"18px" ,padding: '4px',    background: '#073460'}}><span style={{fontSize: '12px', color: '#FFF'}}>85 0000 € </span></div>
                        </h6>
                        
                      </div>

            </div>


          )
    }
     

 

    return (
        <div className="myccount  abonnement devis " >
            
                       <section className="gray-bg main-dashboard-sec dashboard myccount" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenuClient src='charges'/>
                                 
                            </div>


                         <div className='col-md-9 dashbordassitantcontent' >

                             <div className='tab' id=''>


                              <DashbordHeaderClient src={'w'} />

                             <div className='row' style={{marginTop:'30px'}}>

                               <div className='col-md-12'>

                                  <form>
                                    <div class="rowtitel">
                                       <img src="/images/chargesico.png" style={{width: '59px'}}/>
                                       <h3 class="htri1">Charges </h3>
                                    </div>

                            <div className='row mr778'>
                              
                              <div className='col-md-4'>
                                  <div className='flx889'>
                                    
                                   
                                  </div>
                              </div>
                              <div className='col-md-5'>
                                <div className='flx889'>
                                     <div className='actsr55 clrbl11'>Période</div>
                                     <span style={{paddingRight:'12px'}} className='clrbl11'>Du </span>
                                     <div className='actsearch flx889'>
                                       <input type="text" className='form-control inp778' placeholder="" />
                                       <div className='absposcal'><img src='/images/icones/iconecalendrier.png' /></div>
                                     </div>
                                     <span style={{paddingRight:'12px'}} className='clrbl11'>au </span>
                                     <div className='actsearch flx889'>
                                       <input type="text" className='form-control inp778' placeholder="" />
                                        <div className='absposcal'><img src='/images/icones/iconecalendrier.png' /></div>
                                     </div>
                                  </div>
                              </div>
                              <div className='col-md-3'>
                                  <button type="submit" className="btn float-btn color2-bg btn9876 btcustom " style={{fontSize: '12px',borderRadius: '5px',float: 'right'}}>Télécharger</button>
                              </div>

                           </div>


                                  <div className="custom-form">

                                  <table  id="datatable_valides" className="rowtabcustomer table table-separate  table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                                    <thead>
                                    <tr>
                                        <th>Charges</th>
                                        <th>Copropriété</th>
                                        <th>Budget (€)</th>
                                        <th>Appel (€)</th>
                                        <th>tantiémes (€)</th>
                                        <th>Quotes parts (€)</th>
                                     

                                    </tr>
                                    </thead>
                                    <tbody> 
                                   
                                    

                                    </tbody>


                                  </table>
                                </div>
                                <br/><br/>

                                    </form>   


                            
                                 <form className='biens'  style={{marginTop: '25px',    marginBottom: '0px',paddingBottom: '0px'}}>
                                   <div class="rowtitel">
                                       <h3 class="htri1" style={{    marginLeft: '0px'}}>Répartition des charges année<br/><div style={{    fontFamily: 'Montserrat-Medium'}}> 2023</div> </h3>
                                    </div>
                                    <div>

                                     <div className='row offresection'>
                                        
                                        <div className='col-md-3'>
                                            <div className='wie778 wie778c1'>
                                            <h6>Total des biens</h6>
                                            <strong>{user_biens?.length}</strong>

                                            </div>

                                        </div>
                                        <div className='col-md-3'>
                                            <div className='wie778 wie778c2'>
                                            <h6>Dépenses parties privatives</h6>
                                            <strong>0</strong>

                                            </div>

                                        </div>
                                        <div className='col-md-3'>
                                            <div className='wie778 wie778c3'>
                                            <h6>Dépenses parties communes</h6>
                                            <strong>0</strong>

                                            </div>

                                        </div>
                                        <div className='col-md-3'>
                                            <div className='wie778 wie778c4'>
                                            <h6>Dépenses parties copropriétaire</h6>
                                            <strong>0</strong>

                                            </div>

                                        </div>




                                     </div>


                                    </div>

                                 </form>

                               </div>
                  

                             </div>


                                 
                          </div>
 

                              

                         </div>
                    </div>
            </section>
     
        </div>
    );
}

export default Page;


