import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card,Modal} from "react-bootstrap";
//import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenuClient,DashbordHeaderClient } from "../../../components";
import { getListMissions,EditMission,getListValidateMissions,getListRealiseMissions,getListEncoursMissions,setMessages, getAllProfessionalMissionsCalendar } from "../../../actions/pages";
import { useTranslation } from 'react-i18next';
import DatePicker, { registerLocale } from "react-datepicker";
// import { registerLocale, setDefaultLocale } from  "react-datepicker";
// import DatePicker from 'sassy-datepicker';
import { momentLocalizer  } from 'react-big-calendar'
import moment from "moment";
import 'moment/locale/fr'
import fr from 'date-fns/locale/fr';
const localizer = momentLocalizer(moment)
registerLocale('fr', fr)
// setDefaultLocale('fr')
const Page = () => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const { user } = useSelector(state => state.user)
    const { missions,missionsV,missionsE,missionsR, all_prof_missions_calendar } = useSelector(state => state.pages)
    const [langset, setLangset] = useState(false);
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const [show, setShow] = useState(false);
     const [photo, setPhoto] = useState('');
    const [lname, setLname] = useState('');
    const [date, setDate] = useState(new Date());
    const [to, setTo] = useState('');
    const [locale, setLocal] = useState('fr');
    const handleClose = () => setShow(false);
    const handleSubmit = async (e) => {
        e.preventDefault();

        if(to){


        const formData = new FormData(e.target)
          const formDataObj = Object.fromEntries(formData.entries())
       
        dispatch(setMessages(formDataObj,user.id,to,'watcher','save',user.emailToken,t('Votre message est bien été envoyé..')));
        setShow(false)
        setPhoto('')
        setLname('')
        setTo('')

        }

        
      
    }

    const goto = (slug)=>{
       history.push(slug)
    }

    const onChange = newDate => {
    console.log(`New date selected - ${newDate.toString()}`);
    setDate(newDate);
  };

    const handleSetProfil =  (profil) => {
       

        setPhoto(profil.photo)
        setLname(profil.prenom)
        setTo(profil.id)
        setShow(true)
    }


      useEffect(() => {
        const params={
           id:user.id,
           type:'recus',
           profile:'watcher'
        }

        dispatch(getListMissions(params));
        const params2={
           id:user.id,
           type:'enattente',
           profile:'watcher'
        }
        dispatch(getListValidateMissions(params2));

        const params3={
           id:user.id,
           type:'encours',
           profile:'watcher'
        }
        dispatch(getListEncoursMissions(params3));


        const params4={
           id:user.id,
           type:'complete',
           profile:'watcher'
        }
        dispatch(getListRealiseMissions(params4));
        
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])
    
 
    return (
        <div className="myccount responses " >
            
           

            <section className="gray-bg main-dashboard-sec dashboard myccount" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenuClient src='profil'/>
                                 
                            </div>


                         <div className='col-md-9 dashbordassitantcontent' >

                             <div className='tab' id=''>


                              <DashbordHeaderClient src={'w'} />

                             <div className='row' style={{marginTop:'30px'}}>
                               <div className='col-md-8'>

                               <div className='row'>

<div className='col-md-12 w100'>
 <img src='/images/Dashboard_sect1.png' />
</div>

</div>
                                    <div className='row'>
                                       <div className='col-6 col-md-6  '>
                                             <div className='process-item' onClick={()=>goto('/assistants/portfeuille/')}>
                                                <img src='/images/icones/iconeCA.png' />
                                                <h3>Chiffre d'affaires</h3>

                                             </div>

                                             <div className='process-item'  onClick={()=>goto('/client/interventions/')}>
                                                <img src='/images/icones/iconefacturedevis.png' />
                                                <h3>Factures/Devis</h3>

                                             </div>
                                       </div>

                                       <div className='col-6 col-md-6'>
                                            <div className='process-item'>
                                                <img src='/images/icones/iconeavisclients.png' />
                                                <h3>Avis clients</h3>

                                             </div>
                                             <div className='process-item'>
                                                <img src='/images/icones/iconedossiercompta.png' />
                                                <h3>Comptabilité</h3>

                                             </div>

                                       </div>

                                        {/* <div className='col-6 col-md-4 '>
                                             <div className='process-item'>
                                                <img className='lrgimg' src='/images/icones/iconeconsultationvidéo.png' />
                                                <h3>Consultation vidéo</h3>

                                             </div>
                                       </div> */}

                                     </div>

                                     <div className='row'>

                                       <div className='col-md-12 w100'>
                                        <img src='/images/Dashboard_sect1.png' />
                                       </div>

                                     </div>

                                       <div className='row'>

                                       <div className='col-md-12 w100'>
                                        <img src='/images/Dashboard_sect2.png' />
                                       </div>

                                     </div>

                                    

                               </div>
                               <div className='col-md-4 '>
                                
                                <div className='datepickercnt'>

                                  <div className='f12'>
                                    <h5>Agenda</h5>
                                    <a href="javascipt:void(0)" ><img src='/images/icones/iconepluspetit.png' /></a>
                                  </div>
                                  <div style={{textAlign:'center'}}>
                                  <DatePicker 
                                    locale={locale}
                                    multiple
                                    //onChange={onChange} 
                                    selected={date} 
                                    events={all_prof_missions_calendar}
                                    selectsRange
                                    inline
                                  />
                                 </div>
                                </div>

                                 <div className='w100'>
                                      <img src='/images/interventionpr.png' />
                                 </div>
                                 

                                </div>

                             </div>


                                 
                              </div>
 

                              

                         </div>
                    </div>
            </section>
<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>

          
 
           

        </Modal.Body>
        <Modal.Footer>
          
         
        </Modal.Footer>
</Modal>

        </div>
    );
}

export default Page;