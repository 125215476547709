import React, { useEffect,useState } from "react";
import './styles.scss'; 
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
 
import { useParams } from "react-router";
import { apiClient } from "../../../actions/api"
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import { AvisItem } from "../../../components";

import { useHistory } from "react-router";
 



const Page = () => { 
    
    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)
     const history = useHistory()
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
 
    const { user } = useSelector(state => state.user)
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const { mission_infos } = useSelector(state => state.pages)

     var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
    
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };
   

   const handleGoTo = () => {
         
       
    }
 
    const handleLink = (slug) => {
        history.push(slug);
    }
   
    const handleSubmit = async (e) => {
     
    }

  const handlereturn = () => {
    
          history.push('/');
        
    }
 

 

 
 

     useEffect(() => {


   
        if(langset == false){
            setLangset(true)
        }
 
 

    }, [])
   
    return (
        <div className="besoin  " >
           <section className="gray-bg big-padding  besoin9 " id="besoin7">
            <div className="container small-container ">
             <div className='registerform'>

             
             <br/>



                <div className='row '>
                <div className='col-md-12'>

                 <div className="section-title"><h2  className='center'><span>Votre intervention est validée !</span></h2>
                 <p className='center w100  patk' >Veuillez transmettre ce code à l'artisan en cas de modification du prix indiqué : </p>
                  </div>

                  <br/>

                 
                </div>

                <div className='col-md-3'></div>

                <div className='col-md-6'>
                   <div className="section-title"><h2  className='center'><span>{mission_infos.ref}</span></h2>
                   
                     <br/><br/><br/><br/>
                    <p className='center w100 patk'  >Si l'intervention dépasse le délai prévu vous devez être informé par l'artisan. Vous devez lui communiquer un code qu'il validera pour confirmer le nouveau ticket majoré qui vaudra acceptation de votre part.</p>
                    <br/><br/>

                 
                  </div>

                     
                </div>
                <div className='col-md-3'></div>
                </div>

                 <div className='row '>
                  <div className='col-md-4'></div>
                  <div className='col-md-4'>
                           <button type="button" onClick={()=>handleLink(user.type==2 ? '/my-account/' : '/assistants/dashboard')} className="btn btnform w100 bgorange ">Accédez à mon espace personnel </button>
                      <br/><br/>
                    <a href="#" onClick={()=>handlereturn()} className="btn btnform w100 bgorange ">Retour à l'accueil </a>

                  </div>
                  <div className='col-md-4'></div>

                 </div>






             </div>
             </div>


 
 
 

         <div className='sect3'>
              <div className="container small-container">
 
                      <div className='cadrbs1' style={{marginBottom: '60px'}}>

                                  <div className="process-wrap fl-wrap commentcafonctionne "><div className="default-desc margright mrgtop"><p>&nbsp;</p>
                                    <ul className="no-list-style row">



                                    <li className="col-md-4">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/Iconeartisan.png' />
                                    </div>

                                    <p className='basecolor'>Nos artisans sont certifiés et labellisés.</p>
                                    </div>
                                    </li>

                                    <li className="col-md-4">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/iconechrono.png' />
                                    </div>

                                    <p className='basecolor'>Suivez votre intervention dans votre espace dédié et activez les notifications pour rester informé du déroulement de l'intervention.</p>
                                    </div>
                                    </li>

                                    <li className="col-md-4">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/IconeIA.png' />
                                    </div>

                                    <p className='basecolor'>Bénéficier d'un abonnement qui vous permet de faire des économies dès la première intervention.</p>
                                    </div>
                                    </li>

                                    </ul>
                                    </div>
                             </div>

                      </div>
 
                  </div>
             
             </div>








           </section>

 
 

        </div>

    );
}

export default Page;

