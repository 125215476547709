import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button ,Modal,CloseButton } from "react-bootstrap"
import { AssistantItem } from "../../components";
import { AvisItem } from "../../components";
import { getAllCategories,getAllReviews,getWatchersHome } from "../../actions/pages";
import { getListWatcher } from "../../actions/user";
import { useHistory } from "react-router";
import { BASE_URL_PROD } from "../../components/config/keys";
import { surpls,epr } from "../../assets/images";
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import { getBiens } from "../../actions/pages";
import { apiClient } from "../../actions/api";
import { HiLocationMarker } from 'react-icons/hi';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment'


const Page = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const [showMore, setShowMore] = useState(0);
    const [active, setactive] = useState(2);
    const [typeDates, setTypeDates] = useState(2);
    const [valueDates, setValueDates] = useState('');
    const [dateday, setdateday] = useState('');
    const [selectDates, setSelectDates] = useState(0);
    const [selectVoyageurs, setSelectVoyageurs] = useState(0);
    const [typelogement, settypelogement] = useState(1);
        const [typepropriete, settypepropriete] = useState(1);
    const [valueVoyageur, setValueVoyageur] = useState('');
    const [datedepart, setdatedepart] = useState('');
    const [datearrive, setdatearrive] = useState('');
    const [show,setShow]=useState(false);
    const [disestimat,setdisestimat]=useState(false);
    const [showError,setShowError]=useState(false);
    const [showErrorSelect,setShowErrorSelect]=useState(false);
    const { user } = useSelector(state => state.user)

    const [message_error,setErroMessage]=useState(false);

    const [type_bien, setTypeBien] = useState('');
    const [surface, setSurface] = useState('');
    const [prix_max, setPrixMax] = useState('');
    const [adress, setAdress] = useState('');

    const [adultes, setadultes] = useState(0);
    const [enfants, setenfants] = useState(0);
    const [bebes, setbebes] = useState(0);
    const [animeaux, setanimeaux] = useState(0);

    const handleClosemodal = () => setShow(false);
    const handleClosemodalSelect = () => setShow(false);
    const handleClosemodalError = () => setShow(false);
    
    const { last_biens } = useSelector(state => state.pages)

    useEffect(() => {
      dispatch(getBiens({limit:2}));
    }, [])

    const [state, setState] = useState([
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 1),
        key: 'selection'
      }
    ]);

    const updatedates = (item) => {  
       setState(item)
       setValueDates(moment(new Date(item[0].startDate)).format("DD MMM") +'-'+moment(new Date(item[0].endDate)).format("DD MMM") )
       setdatedepart(item[0].startDate)
       setdatearrive(item[0].endDate)
    }
  
    const shownbvoyageur = () => {    
       setSelectDates(0)
       setSelectVoyageurs(!selectVoyageurs)
    } 

    const showdates = () => {
       setSelectDates(!selectDates)
       setSelectVoyageurs(0)
    } 

 const estimation = (e) => {

  if(!user?.id){

    alert('Veuillez vous connecter à votre espace client !')
    return false
  }
  
  if(type_bien && type_bien && adress && surface && prix_max ){
    
     const membreData = {
        user_id: user.id,
        type_bien:type_bien,
        adress:adress,
        surface:surface,
        prix_max:prix_max
    }

    return apiClient().post(`/estimations`, membreData)
          .then((res) =>
           { 

               setdisestimat(true)

         })
   
  }
  else{
      alert('Veuillez indiquer les informations suivantes :  type de bien, surface, budget max.. ')
      return false
  }





        
} 


    
    const setElementactive = valeur =>{
      setactive(valeur)
      setTypeBien('')
      setSurface('')
      setPrixMax('')
      setAdress('')
    }
    const handleChange = (e) => {
      if(e.target.name == 'type_bien')
        setTypeBien(e.target.value)
      else if(e.target.name == 'surface')
        setSurface(e.target.value)
      else if(e.target.name == 'prix_max')
        setPrixMax(e.target.value)
      else if(e.target.name == 'adress')
        setAdress(e.target.value)
    }

    const goToList = () => {
      
 let search={}
      if(active==1){
        //louer
         search = {'active':active, 'type_bien':type_bien, 'surface':surface, 'prix_max':prix_max, 'adress':adress,'valueDates':valueDates,'adultes':adultes,'enfants':enfants,'bebes':bebes,'animeaux':animeaux,'datedepart':datedepart,'datearrive':datearrive}
      }
      else{

        //achat

          search = {'active':active, 'type_bien':type_bien, 'surface':surface, 'prix_max':prix_max, 'adress':adress}

      }

  

      localStorage.setItem('search_data', JSON.stringify(search))

      if(active == 1 && valueDates == ''){
        alert("Veuillez choisir vos dates !")
        return false;
      }
      if(active == 1 && adultes == 0){
        alert("Veuillez indiquer le nombre de voyageurs !")
        return false;
      }

      history.push('/liste-biens')
    }

    const returncontentvente = () => {
         return (

          <>

                        <div className='col-md-4'>
                          <div style={{position:'relative'}}>
                            <span className='san88'><HiLocationMarker size={25} /></span>
                            <input className='adress markerinput' type="text" placeholder="Dans quelle ville ? quartier ?" name="adress" value={adress} onChange={handleChange}/>
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <select className='' name="type_bien" value={type_bien} onChange={handleChange}>
                            <option>Type de bien</option>
                            <option value="1">Maison</option>
                            <option value="2">Pavillon</option>
                            <option value="3">Gîte</option>
                            <option value="4">Appartement</option>
                            <option value="5">Appartement en résidence</option>
                            <option value="6">Loft</option>
                            <option value="7">Résidence de tourisme</option>
                          </select>
                        </div>
                        <div className='col-md-3'>
                          <input className='adress intp9987' name="surface" value={surface} placeholder="Surface max en m²" type="text" onChange={handleChange}/>
                        </div>
                        <div className='col-md-2'>
                         <div style={{position:'relative'}}>
                          <input className='adress' type="text" placeholder="Budget max" name="prix_max" value={prix_max} onChange={handleChange}/>
                          <span className='san99'><i   aria-hidden="true" style={{    fontStyle: 'normal', fontSize: '18px'}}>€</i></span>
                        </div>
                        </div>
                        <div className="col-md-12 mb-2">
                          {
                            showMore === 0 ?
                            <div className='fser778'>
                              <img onClick={() => setShowMoreValue(1)} src="/images/immo/icone-plus.png" style={{ width:'20px', cursor:'pointer', marginRight:'10px' }} /> <span onClick={() => setShowMoreValue(1)} style={{  cursor:'pointer' }}>de critères</span>
                            </div>
                            :
                            <div className='fser778'>
                              <img onClick={() => setShowMoreValue(0)} src="/images/immo/icone-refresh.png" style={{ width:'15px', cursor:'pointer', marginRight:'10px' }} /> <span onClick={() => setShowMoreValue(0)} style={{  cursor:'pointer' }}>de critères</span>
                            </div>
                          }
                          
                        </div>

          </>


          )
    };


    const returncontentlocation = () => {
         return (

          <>

                  <div className='col-md-4'>
                        <div style={{position:'relative'}}>
                        <span className='san88'><HiLocationMarker size={25} /></span>
                          <input className='adress markerinput' type="text" placeholder="Dans quelle ville ? quartier ?"  name="adress" value={adress} onChange={handleChange}/>
                        </div>
                        </div>
                        <div className='col-md-3'>
                          <select className='' name="type_bien" value={type_bien} onChange={handleChange}>
                            <option>Type de bien</option>
                            <option value="1">Maison</option>
                            <option value="2">Pavillon</option>
                            <option value="3">Gîte</option>
                            <option value="4">Appartement</option>
                            <option value="5">Appartement en résidence</option>
                            <option value="6">Loft</option>
                            <option value="7">Résidence de tourisme</option>
                            <option value="8">Chambre privée</option>
                            <option value="9">Logement de vacances</option>
                          </select>
                        </div>
                        <div className='col-md-2' style={{position:'relative'}}>
                           <div className='putselectform' onClick={()=>showdates()}>
                            <span>

                            {
                              valueDates=='' ?
                              'Dates'
                              :
                              <div style={{display: 'flex',alignItems: 'center'}}>

                                {valueDates}
                                {
                                   dateday &&
                                   <div>({dateday}j)</div>
                                }

                              </div>

                            }

                            </span>



                           
                           </div>


                            {
                               selectDates==1 &&

                               <div className='DateRangePicker'>

                               <div className='tt566'>

                                   <div className='ttbg77'>
                                     <span onClick={()=>setTypeDates(1)} className={typeDates==1 && 'active'} >Dates fixes</span>
                                     <span onClick={()=>setTypeDates(2)} className={typeDates==2 && 'active'}>Dates flexibles</span>

                                   </div>
                                   {
                                     typeDates===2 &&

                                  <div className='ttbg78'>
                                     <span onClick={()=>setdateday(1)} className={dateday==1 && 'active'} ><b><div>+</div><div>-</div></b>1 jour</span>
                                     <span onClick={()=>setdateday(2)} className={dateday==2 && 'active'} ><b><div>+</div><div>-</div></b>2 jour</span>
                                     <span onClick={()=>setdateday(3)} className={dateday==3 && 'active'} ><b><div>+</div><div>-</div></b>3 jour</span>
                                     <span onClick={()=>setdateday(7)} className={dateday==7 && 'active'} ><b><div>+</div><div>-</div></b>7 jour</span>
                                     

                                   </div>
                                   }


                                 


                               </div>


                                 <DateRangePicker
                                    onChange={item => updatedates([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={state}
                                    direction="horizontal"

                                  />



                               </div>

                            }

                        </div>
                        <div className='col-md-3'>
                         <div style={{position:'relative'}}>
                         
                         <div className='' style={{position:'relative'}}>
                           <div className='putselectform' onClick={()=>shownbvoyageur()}>
                            <span>
                              
                            {
                              valueVoyageur=='' ?
                              'Voyageurs'
                              :
                              <div style={{display: 'flex',alignItems: 'center'}}>

                                {valueVoyageur}
                            

                              </div>

                            }
                             </span>
                            </div>

                            {
                              selectVoyageurs==1 &&
                              <div className='selectvoyageurs'>
                              <ul>
                               <li>
                                <span><b>Adultes</b>(13 ans et +)</span>
                                <div>

                                  <div className="ingrouped">
                                
                                      <div class="input-group">
                                    <div class="input-group-prepend">
                                      <button class="btn btn-outline-primary" type="button" onClick={()=>setadultes(adultes > 0 ? adultes - 1 : 0)}   >-</button>
                                    </div>
                                    <input type="text" class="form-control" value={adultes}    />
                                    <div class="input-group-prepend">
                                      <button class="btn btn-outline-primary" type="button" onClick={()=>setadultes(adultes+1)} >+</button>
                                    </div>
                                  </div>
                                  </div>



                                </div>
                               </li>
                                     <li>
                                <span><b>Enfants</b>(2 à 12 ans)</span>
                                <div>

                                  <div className="ingrouped">
                                
                                      <div class="input-group">
                                    <div class="input-group-prepend">
                                      <button class="btn btn-outline-primary" type="button" onClick={()=>setenfants(enfants > 0 ? enfants - 1 : 0)}   >-</button>
                                    </div>
                                    <input type="text" class="form-control" value={enfants}    />
                                    <div class="input-group-prepend">
                                      <button class="btn btn-outline-primary" type="button" onClick={()=>setenfants(enfants+1)} >+</button>
                                    </div>
                                  </div>
                                  </div>



                                </div>
                               </li>
                                     <li>
                                <span><b>Bébé</b>(- 2 ans)</span>
                                <div>

                                  <div className="ingrouped">
                                
                                      <div class="input-group">
                                    <div class="input-group-prepend">
                                      <button class="btn btn-outline-primary" type="button" onClick={()=>setbebes(bebes > 0 ? bebes - 1 : 0)}   >-</button>
                                    </div>
                                    <input type="text" class="form-control" value={bebes}    />
                                    <div class="input-group-prepend">
                                      <button class="btn btn-outline-primary" type="button"  onClick={()=>setbebes(bebes+1)}>+</button>
                                    </div>
                                  </div>
                                  </div>



                                </div>
                               </li>
                                <li style={{borderBottom:'none'}}>
                                <span><b>Animaux</b></span>
                                <div>

                                  <div className="ingrouped">
                                
                                      <div class="input-group">
                                    <div class="input-group-prepend">
                                      <button class="btn btn-outline-primary" type="button"  onClick={()=>setanimeaux( animeaux > 0 ? animeaux-1 : 0)}  >-</button>
                                    </div>
                                    <input type="text" class="form-control" value={animeaux}    />
                                    <div class="input-group-prepend">
                                      <button class="btn btn-outline-primary" type="button" onClick={()=>setanimeaux(animeaux+1)} >+</button>
                                    </div>
                                  </div>
                                  </div>



                                </div>
                               </li>
                               


                              </ul>




                              </div>
                            }
                          </div>
                        </div>
                        </div>
                        <div className="col-md-12 mb-2">
                          {
                            showMore === 0 ?
                            <div className='fser778'>
                              <img onClick={() => setShowMoreValue(1)} src="/images/immo/icone-plus.png" style={{ width:'20px', cursor:'pointer', marginRight:'10px' }} /> <span onClick={() => setShowMoreValue(1)} style={{  cursor:'pointer' }}>de critères</span>
                            </div>
                            :
                            <div className='fser778'>
                              <img onClick={() => setShowMoreValue(0)} src="/images/immo/icone-refresh.png" style={{ width:'15px', cursor:'pointer', marginRight:'10px' }} /> <span onClick={() => setShowMoreValue(0)} style={{  cursor:'pointer' }}>de critères</span>
                            </div>
                          }
                          
                        </div>

          </>


          )
    };


    const returncontentestimer =() =>{ 
       return (


                 <>

                    <div className='col-md-4'>
                        <div style={{position:'relative'}}>
                        <span className='san88'><HiLocationMarker size={25} /></span>
                          <input className='adress markerinput' type="text" placeholder="Dans quelle ville ? quartier ?" name="adress" value={adress} onChange={handleChange}/>
                        </div>
                        </div>
                        <div className='col-md-3'>
                          <select className=''  name="type_bien" value={type_bien} onChange={handleChange}>
                            <option>Type de bien</option>
                            <option value="1">Appartement</option>
                            <option value="2">Maison</option>
                            <option value="3">Professionnel</option>
                          </select>
                        </div>
                        <div className='col-md-3'>
                          <input className='adress intp9987' name="surface" value={surface} placeholder="Surface max en m²" type="text" onChange={handleChange}/>
                        </div>
                        <div className='col-md-2'>
                         <div style={{position:'relative'}}>
                          <input className='adress' type="text" placeholder="Budget max" name="prix_max" value={prix_max} onChange={handleChange}/>
                          <span className='san99'><i   aria-hidden="true" style={{    fontStyle: 'normal', fontSize: '18px'}}>€</i></span>
                        </div>
                        </div>

              

          </>


        )
    }

    const setShowMoreValue = valeur => {
      setShowMore(valeur);
    };


    const gotoaddannocen = (e) =>{

      e.preventDefault();

      if(!user?.id){
       
       alert('Veuillez vous connecter à votre espace client !')
       return false 


      }
      else{
        if(user?.id && user.type==2 ){
           history.push('/client/ajouter-bien-location/')
        }
        else{

          alert('Veuillez vous créer un compte particulier !')
          return false 

        }
      }
  

    }


    return (
      <div id="homwraped" className="biens bienshome">
        <div className="content">
          <section className="hero-section hero-section-mobile"   data-scrollax-parent="true" style={{paddingBottom:"0px"}}>
            <div className="bg-tabs-wrap">
              <div className="bg-parallax-wrap" data-scrollax="properties: { translateY: '200px' }"></div>
            </div>
            <div className="container small-container center homflat">
              <div className='row ' style={{ position:'relative' }}>
                <div className='col-md-12'>
                  <div className="intro-item fl-wrap">
                    <div >
                      <h1>Trouvez le bien de vos rêves !</h1>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className="intro-item fl-wrap row intro99887" style={{margin: '0px'}}>
                    <div class="cadre col-5">
                      <span onClick={()=>setElementactive(1)} className={active==1 && 'active'} >Louer</span>
                      <span onClick={()=>setElementactive(2)} className={active==2 && 'active'}>Acheter</span>
                      <span onClick={()=>setElementactive(3)} className={active==3 && 'active'}>Estimer</span>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className="">
                    <div class="cadre1" style={{ position:'relative' }}>
                      <div className='row form' style={{paddingBottom: '0px'}}>

                      {active==1 && returncontentlocation()}
                      {active==2 && returncontentvente()}
                      {active==3 && returncontentestimer()}
         
                          {
                            showMore === 0 ?
                              active === 3 ?
                                <div className="col-md-12 estim998" style={{ position:'absolute', bottom:'-25px',     left: '0px' }}><a className="btn btnform w200 button_search" href='javascript:void(0)' onClick={(e)=>setShow(true)}> Estimer </a></div>
                                :
                                <div className="col-md-12" style={{ position:'absolute', bottom:'-25px',     left: '0px' }}><a className="btn btnform w200 button_search" href='javascript:void(0)' onClick={(e)=>goToList()}> Rechercher </a></div>

                            
                            :
                            ''
                          }
                      </div>
                    </div>
                  </div>
                </div>
                {
                  showMore  === 1 ? 
                  <div className='col-md-12 row' style={{ position: 'relative' }}>
                    <div className='col-md-12' style={{ marginTop: '-30px', position:'absolute', zIndex:"9" }}>
                      <div className="">
                        <div class="cadre1 boxShadow cadrefiltre887" style={{ width:"70%" }}>
                          <div className='row form'>
                            <div className="col-md-12 mb-2">
                                <div className="row">
                                    <div className="col-md-12">
                                      <h5 className="basecolor mt-3 mb-3" style={{ textAlign:"left", fontSize:"13px" }}>Prix</h5>
                                      <div className='col-md-3 ml-0 pl-0'>
                                        <div style={{position:'relative'}}>
                                        <input className='adress' type="text" placeholder="Prix minimum"/>
                                        <span className='san99'><i   aria-hidden="true" style={{    fontStyle: 'normal', fontSize: '18px'}}>€</i></span>
                                        </div>
                                      </div>
                                      <div className='col-md-3 pl-0'>
                                        <div style={{position:'relative'}}>
                                           <input className='adress' type="text" placeholder="Prix maximum"/>
                                           <span className='san99'><i   aria-hidden="true" style={{    fontStyle: 'normal', fontSize: '18px'}}>€</i></span>
                                         </div>
                                      </div>
                                      <div className="col-md-12 p-0"><hr></hr></div>
                                    </div>
                                    <div className="col-md-12">
                                      <h5 className="basecolor mt-3 mb-3" style={{ textAlign:"left", fontSize:"13px" }}>Type de logement</h5>
                                      <div className={typelogement==1 ? 'col-md-3 ml-0 type_b pl-0 active'  :'col-md-3 ml-0 type_b pl-0'}  onClick={()=> settypelogement(1)}>Logement entière</div>
                                      <div className={typelogement== 2 ? 'col-md-3 ml-0 type_b active' : 'col-md-3 ml-0 type_b'} onClick={()=> settypelogement(2)}>Chambre Privée</div>
                                      <div className={typelogement==3 ? 'col-md-3 ml-0 type_b active' : 'col-md-3 ml-0 type_b'} onClick={()=> settypelogement(3)} >Chambre Partagée</div>
                                      <div className="col-md-12 p-0"><hr></hr></div>
                                    </div>
                                    <div className="col-md-12">
                                      <h5 className="basecolor mt-3 mb-3" style={{ textAlign:"left", fontSize:"13px" }}>Type de propriété</h5>
                                      <div className={typepropriete== 1 ? 'col-md-3 ml-0 type_b pl-0 active' : 'col-md-3 ml-0 type_b pl-0'} onClick={()=> settypepropriete(1)}>Maison</div>
                                      <div className={typepropriete== 2 ? 'col-md-3 ml-0 type_b active' : 'col-md-3 ml-0 type_b'} onClick={()=> settypepropriete(2)} >Appartement</div>
                                      <div className={typepropriete== 3 ? 'col-md-3 ml-0 type_b active' : 'col-md-3 ml-0 type_b'} onClick={()=> settypepropriete(3)} >Chambre d'Hôtes</div>
                                      <div className="col-md-12 p-0"><hr></hr></div>
                                      <div style={{    marginBottom: '12px'}}></div>
                                      <div className='col-md-12 p-0 flexcol'>
                                         <a href='javascript:void(0)' style={{color:'#073460'}}> Réinitialiser </a>
                                         <a href='javascript:void(0)' onClick={(e)=>goToList()} style={{width: '160px'}} class="btn btnform w200 button_search"> Afficher les biens </a>

                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  :
                  ""
                }
                <br></br>
                <div className="col-md-12" style={{ margin:'50px 0px 30px 0px' }}><a className="btn btnform w200 deposer-annonce" onClick={(e)=>gotoaddannocen(e)}> + Déposer une annonce </a></div>
              </div>
            </div>
          </section>
          <section className='pd889' id='section-formules'>
            <div className="container small-container">
              <div className="">
                <br/>
                <h5 style={{ color:'gray' }}><span>Les derniers biens publiés</span></h5>
              </div>
              <div className="process-wrap fl-wrap commentcafonctionne ">
                <div className="default-desc margright mrgtop"><p>&nbsp;</p>
                  <ul className="no-list-style row offresection">
                  {
                    last_biens != undefined ?
                    last_biens.map((element, index) => (
                      <li className="col-6 col-md-4 position_relative" style={{cursor:'pointer'}} onClick={()=>history.push('/detail-bien/'+element.id)}>
                      <div className="process-item position_relative">
                        <div className="process-img" style={{backgroundImage: element.img != '' ? "url("+BASE_URL_PROD+'/admin/public//img/biens/'+element.img+")" : "url('/images/imo.png')"}}></div>
                        <h4 className='basecolor type-bien tag'>{element?.type_bien== 1 ? 'Location Saisonnière' : 'Vente'}</h4>
                        <h4 className='basecolor title' >{element.logement}</h4>
                        <img src="/images/immo/favori.png" className="img-favoris"/>
                        <h6 className='' style={{ textAlign:"left", fontSize:"11px", color:"gray" }}>
                          <img src="/images/immo/icone-map.png" className="" style={{ width:"12px" }} /> {element?.address}
                        </h6>
                        <div className="priceoffre" style={{ textAlign:"left", fontSize:"18px" }}><span>{element.price} € </span>par nuit</div>
                      </div>
                    </li>
                    ))
                    :
                    ""
                  }
                  </ul>
                </div>
              </div>
              <br/>                           
            </div>
          </section>
        </div>

<Modal show={showError} onHide={handleClosemodalError} className='modelhermixtarif' size="" aria-labelledby="contained-modal-title-vcenter" centered>
  <Modal.Header>
    <h1 className='colorbase'>Recherche</h1>
    <CloseButton variant="dark" onClick={handleClosemodalError} />
  </Modal.Header>
  <Modal.Body>
      {message_error}
  </Modal.Body>
</Modal>

        <Modal show={show} onHide={handleClosemodal} className='modelhermixtarif'   size=""
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header   >
             <h1 className='colorbase'>Estimer mon bien</h1>
          <CloseButton variant="dark" onClick={handleClosemodal} />
        </Modal.Header>
        <Modal.Body>

      {/* <Modal show={showErrorSelect} onHide={handleClosemodalSelect} className='modelhermixtarif' aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header   >
             <h1 className='colorbase'>Estimer mon bien</h1>
          <CloseButton variant="dark" onClick={handleClosemodalSelect} />
        </Modal.Header>
      <Modal.Body> */}

   <br/>

   {
    disestimat == true ? 
 <div>
       <p>Votre demande d'estimation a été prise en compte.</p>
       <p>Un conseiller vous contactera <strong>dans les plus brefs délais.</strong></p>

</div>
   :

   <div>

       <br/>
   <p>Comment souhaitez-vous être contacté ?</p>
   <br/>
   <div className='ff8898'>

                                  <div className="form-check">
                                        <input name="" type="checkbox" className="form-check-input ch667" value="1" style={{paddingLeft: '0px !important'}}/>
                                        <label title="" className="form-check-label">Par téléphone</label>
                                     </div>
                                      <div className="form-check">
                                        <input name="" type="checkbox" className="form-check-input ch667" value="2" style={{paddingLeft: '0px !important'}}/>
                                        <label title="" className="form-check-label">Par E-mail</label>
                                     </div>

                           


   </div>
          <br/>
                                    <div className='center'> <a className="btn btnform w200 button_search" href='javascript:void(0)' onClick={(e)=>estimation()}> Envoyer </a></div>
                                     <br/>


   </div>

   }

  

 
 

        </Modal.Body>
   
      </Modal>

      </div>
    );
}

export default Page;