import React, { useEffect,useState } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { AssistantItem } from "../../components";
import { useParams } from "react-router";
import { apiClient } from "../../actions/api"
import ModalVideo from 'react-modal-video'
import { useTranslation } from 'react-i18next';
import {getContentPage } from "../../actions/pages";

const Page = () => {
    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)
    const dispatch = useDispatch();
     const { t, i18n } = useTranslation();
    const [langset, setLangset] = useState(false);
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const [isOpen, setOpen] = useState(false)
   const { contents } = useSelector(state => state.pages)
   
 
    const handleSubmit = (e) => {
        e.preventDefault();
         window.location.href='/'
    }

     const handleGoTo = (e) => {
        e.preventDefault();
         
    }

      useEffect(() => {
         dispatch(getContentPage({},'Comment ça fontionne'));

        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 
    }, [])
   
    return (
        <div className="commentcafonctionne" >

          <ModalVideo  channel='vimeo'  autoplay={"true"} videoId="677234268"   isOpen={isOpen} onClose={() => setOpen(false)} />
          <section data-scrollax-parent="true" id="sec1">
                        <div className="container">
                        <br/>



                            <div className="row">
                               <div className="col-md-2"></div>
                               <div className="col-md-8">
                                  <div className="video">
                                     <img src="/images/macbook.png" alt=""/>
                                         <div onClick={()=> setOpen(true)} className='landing-img'>
                                           <img  src="/images/play.png" />
                                         </div>
                                         
                                        <div className="landing-video">
                                            <img className='' src="/images/howitsworckbg.png" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2"></div>
                            </div>


                <div className='row'>
                    <div className='col-md-12 center home ' style={{paddingTop:'35px'}}> 
                          <button onClick={handleSubmit} className="header-assistant-button green-bg">{t('Que souhaitez vous Vérifier ?')}</button>
                    </div>
                </div>
<br/> <br/> <br/>
 
                               
<div class="section-title"><h2> {t('Comment ça marche')}</h2><div class="section-subtitle"> {t('Comment ça marche')}</div><span class="section-separator"></span></div>
 

                            <div className="process-wrap fl-wrap">
                            {
                            contents.contenu &&
                                <div className="default-desc margright mrgtop" dangerouslySetInnerHTML={{__html: currentlang=='fr' ? contents.contenu.fr : contents.contenu.en }}></div>
                            } 
                                <div className="process-end"><i className="fal fa-check"></i></div>
                            </div>
                        </div>
                    </section>


 

 
 
             
        </div>
    );
}

export default Page;