import { AUTHENTICATE_USER,SET_PROFESSIONNAL_INFOS,SET_USER,LOGOUT_USER,SET_LIST_WATCHERS } from "../constants/actions";

const INITIAL_STATE = {
    user: {},
    watchersList: [],
   

};

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AUTHENTICATE_USER :
            return {...state,user:action.payload}
        case SET_USER :
            return {...state,user:{...state.user,...action.payload}}
        case LOGOUT_USER :
            return {...state,user:INITIAL_STATE.user}
        case SET_LIST_WATCHERS :
            return {...state,watchersList:action.payload} 
        case SET_PROFESSIONNAL_INFOS :
            return {...state,profInfos:action.payload}
        
          
        default:
            return state;
    }
}
export default reducer;