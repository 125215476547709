import React,{ useState,useEffect } from "react";
import ReactDOM from 'react-dom';
import $, { Event, event } from 'jquery';
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenu,DashbordHeader } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"
import { getProfessionelClients, getElements, getDevisNumber, saveDevis } from "../../../actions/pages";
// import { updateInfoUser } from "../../../actions/user";
import Switch from '@material-ui/core/Switch';


const Page = () => {
    const dispatch = useDispatch();
    const today = new Date().toLocaleDateString()
    const { user } = useSelector(state => state.user)
    const { data, interventions } = useSelector(state => state.pages)
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categ, setCateg] = useState('');
    const [rib, setRib] = useState('');
    const [countN, setCountNumber] = useState([{'name':'1', 'intervention':[]}]);
    const [devisNumber, setDevisNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [elements_metiers, setElementMetiers] = useState('');
    const [trTable, setTrTable] = useState([1]);
    const [customers, setCustomers] = useState([]);
    const [customer_id, setCustomerId] = useState('');
    const [nombre, setNombre] = useState([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]);

    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const label = { inputProps: { 'aria-label': '' } };

     const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
    
        
    
    }

    const removeRow = index => {
      const list = [...countN];
      list.splice(index,1)
      setCountNumber(list)
    }

    const saveData = async (e) => {
      e.preventDefault();

      if(!customer_id){
        alert('Veuiller sélectionner un client !')
        return false
      }
      const dataInfos = {
        customer_id:customer_id,
        professional:user.id,
        data:countN,
      };
    
      dispatch(saveDevis(dataInfos)).then((res) => {
        history.push("/assistants/devis")
      })
    }

    const handleActive = (s, y) => {
      dispatch(getElements(2, s.target.value)).then((res) => {
        const list = [...countN];
        list[y].serviceId = s.target.value
        list[y].intervention = res.data
        setCountNumber(list)
      })
    }
    const handleChangeIntervention = (s, y) => {

      const list = [...countN];
      list[y].interventionId = s.target.value
      setCountNumber(list)
    }
    const handleChangeQte = (s, y) => {
      const list = [...countN];
      list[y].qte = s.target.value
      setCountNumber(list)
    }

       const handleChangeDate = (s, y) => {

      const list = [...countN];
      list[y].date = s.target.value
   
      setCountNumber(list)
    }
    const handleChangePU = (s, y) => {
      const list = [...countN];
      list[y].price = s.target.value
      setCountNumber(list)
    }

    const pushCountNumber =() => {
      setCountNumber([...countN,{'name':'1', 'intervention':[]}])
    }
    
    const prependFromTable =(element) => {
      console.log(element);
      $(element).parent().remove()
    }
      const goto = (slug) => {
         history.push(slug)
     }

     const selectClient = (client) => {
      setCustomerId(client)
     }
    useEffect(() => { 
      dispatch(getProfessionelClients(user.id)).then((res) => {
          setCustomers(res.data);
      })
      dispatch(getDevisNumber(user.id)).then((res) => {
          setDevisNumber(res);
      })
      dispatch(getElements(1, user.type_logement)).then((res) => {
          setElementMetiers(res);
      })
    }, [])

  
    return (
        <div className="myccount editprofil editservices " >
            
                <section className="gray-bg main-dashboard-sec dashboard myccount abonnement devis " id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenu src='profil'/>
                                 
                            </div>


                         <div className='col-md-9 dashbordassitantcontent' >

                             <div className='tab' id=''>


                              <DashbordHeader src={'w'} />

                             <div className='row' style={{marginTop:'30px'}}>
                               <div className='col-md-12'>

                     
                               <div className='rowtiteldevis flx889'>
                                  Devis n°{devisNumber}
                                      <div>
                                      <strong>Date {today}</strong>
                                      {/* <span style={{fontWeight: 'normal'}}> - expire le 04/04/2022</span> */}
                                </div>
                               
                               </div>
                         <form  onSubmit={handleSubmit} className='noraduis' >
                               
                              

                          <div className="profile-edit-container fl-wrap block_box">

                           <div className='row mr778'>

                             <div className='col-md-4'>
                                     <label className='clrbl21' >Prestation</label>
                              </div>
                              
                              <div className='col-md-8'>
                                  <div className='flx889' style={{    justifyContent: 'flex-start'}}>
                                    <label className='clrbl11' style={{marginRight:'10px'}}>Code d'intervention</label>
                                     <div className='actsearch flx889'>
                                       <input type="text" className='form-control clrbl11' placeholder="ex:TRI887656" />
                                       <button type="submit" className="btn float-btn color2-bg btn9876 btcustom lileft"> Rechercher</button>
                                     </div>
                                  </div>
                              </div>
                
                            

                           </div>
                                    <div className="">

                                  <table  id="datatable_valides" className="dstb table table-separate  table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                                    <thead>
                                    <tr>
                                        <th>Service</th>
                                        <th>Intervention</th>
                                        <th>Quantité</th>
                                        <th>Prix unitaire HT</th>
                                        <th>Tarif TTC</th>
                                        <th>Durée</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody id="tableServices">
                                    
                                      {
                                        countN.map((x,y) => (
                                        <tr key={y}>
                                        <td>
                                          <select className="form-control ff56" style={{    width: 150}}  onChange={(e) => handleActive(e, y)}>
                                            <option value=''>Service</option>
                                            {
                                                elements_metiers.data != undefined && elements_metiers.data.length > 0 ?
                                                elements_metiers.data.map((item) => (
                                                  <option value={item.id} >{item.label}</option>
                                              ))
                                              :
                                              ""
                                            }
                                          </select>
                                        </td>
                                        <td>
                                          <select className="form-control ff56" style={{    width: 150}}  onChange={(k) => handleChangeIntervention(k, y)}>
                                            <option></option>
                                            {
                                                x.intervention != undefined && x.intervention.length > 0 ?
                                                x.intervention.map((item) => (
                                                  <option value={item.id} >{item.label}</option>
                                              ))
                                              :
                                              ""
                                            }
                                          </select>
                                        </td>
                                        <td className='rowflex1 ' style={{width: '80px'}} >
                                          <div className='item' >
                                           <label><input placeholder="" onChange={(k) => handleChangeQte(k, y)} name="" type="number" min="1" className="form-control" required=""/></label>
                                          </div>
                                        </td>
                                        <td className='rowflex1 ' style={{width: '80px'}} >
                                          <div className='item' >
                                           <label><input placeholder="" onChange={(k) => handleChangePU(k, y)} name="" type="number" min="0" className="form-control" required=""/> <span className="euro">€</span> </label>
                                          </div>
                                        </td>
                                         <td className='rowflex1 ' style={{width: '80px'}} >
                                          <div className='item'>
                                           <label><input placeholder=""  value={x.price*x.qte} type="number" min="0" className="form-control" required=""/> <span className="euro">€</span> </label>
                                          </div>
                                        </td>

                                        <td className='rowflex1 ' style={{width: '80px'}} >
                                          <div className='item'>
                                           <label><input placeholder="" onChange={(k) => handleChangeDate(k, y)} type="date"className="form-control" required=""/>  </label>
                                          </div>
                                        </td>

                                       
                                        <td>
                                         <div className='flx889 flmi90990' style={{    justifyContent: 'flex-end'}}>
                                          {
                                            y == 0 ?
                                            <a onClick={pushCountNumber}><img src='/images/icones/iconeajouteruneligne.png' /></a>                                           
                                            :
                                            <a className="rmv_tr" onClick={() => removeRow(y)} style={{marginRight:'10px'}} ><img src='/images/icones/iconesupprimeruneligne.png' /></a>                                          
                                          }
                                         </div>

                                        </td>
                                        </tr>
                                        ))
                                      }


                                            

                                    </tbody>

                                </table>

                                      <div className='bigTitle'>
                                      <div className='row '>
                                        <div className='col-md-6'>

                                            <div className='flx889 '>
                              
                                                      <div  className='item2'>
                                                            <label className='clrbl21' >Coordonnées du client</label>

                                                       </div>

                                                        
                                         </div>
         
                                        </div>
                                        <div className='col-md-6'>
                                           <div style={{textAlign:'right', display:'none'}}>
                                                  <a href='#'  ><img style={{    width: '123px'}} src='/images/nouveauclient.png' /></a>
                                           </div>
                                        </div>
                                      </div>

                                    </div>

                                  {
                                    customers.length == 0 &&
                                    <div className='alert alert-warning'>Aucun client n'est trouvé !</div>
                                  }
                                  <div className='listclient'>
                                    {
                                      customers.map((x,y) => (
                                    <div className='flx889' style={{background:customer_id == x.customer_id ? '#ccc' :'none', paddingLeft:'10px', paddingRight:'10px'}}>
                                     <div className='item'>
                                       <img style={{    width: 44}} src='/images/icones/iconeprofilproblocabonnementmenu.png' />
                                       <span>{x.nom+' '+x.prenom}</span>
                                     </div>
                                      <div className='item'>
                                       <a onClick={() => selectClient(x.customer_id)}> <img className='bnb' src='/images/btnselect.png' /></a>
                                     </div>
                                    </div>  
                                    ))
                                    }

                                  </div>

                                  <div style={{textAlign:'right', marginTop:'20px'}} >
                                     <a onClick={saveData}> <img src='/images/savebtn.png' style={{width: '186px'}} /></a>
                                  </div>


 
                                      
                                </div>
                            </div>

                            <div id="trElement" style={{display:"none"}}>
<tr>
                                     <td>
                                     <select className="form-control">
                                               
                                               <option value=''>Service</option>
                                               {
                                                   elements_metiers.data != undefined && elements_metiers.data.length > 0 ?
                                                   elements_metiers.data.map((item) => (
                                                     <option value={item.id}>{item.label}</option>
                                                 ))
                                                 :
                                                 ""
                                               }
                                             </select>
                                     </td>
                                        <td><select className="form-control"><option></option></select></td>
                                        <td className='rowflex1 ' style={{width: '80px'}} >
                                          <div className='item' >
                                           <label><input placeholder="" name="" type="number" min="1" className="form-control" required=""/></label>
                                          </div>
                                        </td>
                                     <td className='rowflex1 ' style={{width: '130px'}} >
                                       <div className='item' >
                                        <label><input placeholder="" name="" type="number" min="0" className="form-control" required=""/> <span className="euro">€</span> </label>
                                       </div>
                                     </td>
                                      <td className='rowflex1 ' style={{width: '130px'}} >
                                       <div className='item'>
                                        <label><input placeholder="" name="" type="number" min="0" className="form-control" required=""/> <span className="euro">€</span> </label>
                                       </div>
                                     </td>
                                    
                                     <td>
                                      <div className='flx889 flmi90990' style={{    justifyContent: 'flex-end'}}>
                                          <a className="rmv_tr" onClick={() => this.removeRow()} style={{marginRight:'10px'}} ><img src='/images/icones/iconesupprimeruneligne.png' /></a>
                                         {/* <a className="add_tr" onClick={() => this.appendToTable()} ><img src='/images/icones/iconeajouteruneligne.png' />dd</a> */}
                                      </div>

                                     </td>
                                     </tr>

</div>


                        </form>

 

                                    

                               </div>
                  

                             </div>


                                 
                          </div>
 

                              

                         </div>
                    </div>
            </section>
        </div>
    );
}

export default Page;