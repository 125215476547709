import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { AssistantItem } from "../../components";
import { AvisItem } from "../../components";
import { getAllCategories,getAllReviews,getWatchersHome } from "../../actions/pages";
import { getListWatcher } from "../../actions/user";
import { useHistory } from "react-router";
import { BASE_URL_PROD } from "../../components/config/keys";
import { surpls,epr } from "../../assets/images";
import { useTranslation } from 'react-i18next';
import {getText} from "../../actions/pages";
import { useParams } from "react-router";
 

const Page = () => {
  const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const { data,reviews,watchers } = useSelector(state => state.pages)
    const [titre, setTitre] = useState('')
    const [texte, setTexte] = useState('')

    useEffect(() => {
      
      dispatch(getText(id)).then((res) => {
        setTitre(res.titre1)
        setTexte(res.text1)
          console.log(res);
    })

    }, [])
 
 

    return (
        <div id="homwraped" className="home">
                <div className="content">
                    <section className='pd889'>
                        <div className="container small-container">
                            <div className="section-title">
                                <h2><span>{titre}</span></h2>
                           <br/> <br/> <br/>
                                <p className='p889' dangerouslySetInnerHTML={{__html: texte }}></p>
                            </div>
                                   



                        </div>
                    </section>
                </div>
        </div>
    );
}

export default Page;