import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import { BASE_URL_PROD } from "../../../components/config/keys";
import { surpls,epr } from "../../../assets/images";
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import { HiLocationMarker } from 'react-icons/hi';
import GoogleMapReact from 'google-map-react';
import { useParams } from "react-router";
import moment from 'moment'


import { getBienById } from "../../../actions/pages";

const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { id } = useParams()
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const [slides, setslides] = useState([]);
    const history = useHistory()
    const [bien_info, setBienInfo] = useState({});
    const [equipements, setEquipement] = useState([]);


    const search_data = JSON.parse(localStorage.getItem('search_data'));
    const [type_active, setTypeActive] = useState(search_data.active);
    const [type_logement, setTypeLogement] = useState(search_data.type_bien);
    const [adress, setAdress] = useState(search_data.adress);
    const [valueDates, setvalueDates] = useState(search_data.valueDates);
    const [datedepart, setdatedepart] = useState(search_data.datedepart);
    const [datearrive, setdatearrive] = useState(search_data.datearrive);
    const [voyageurs, setVoyageur] = useState(search_data.adultes +  search_data.enfants + search_data.bebes + search_data.animeaux);
    const [nbdays, setnbdayes] = useState(0);

    const [total, setTotal] = useState(0);
const [request, setrequest] = useState(false);

    const defaultProps = {
    center: {
      lat: 48.8588548,
      lng: 2.347035
    },
    zoom: 11
  };
  var settingsSlide =  {
    customPaging: function(i) {
        return (
          <a style={{display:'none'}}>
            <img src={`${i + 1}`} />
          </a>
        );
      },
    
    dotsClass: "slick-dots slick-thumb",
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };

    const handleSubmit = async (e) => {
        e.preventDefault();
 

    }

    const goToReservation = () => {
      localStorage.setItem('detail_bient', JSON.stringify(bien_info))
      if(user.id == undefined)
        history.push('/biens-connexion')
      else
        history.push('/reservation-bien/'+bien_info.id)
    }
    
    const goBack = () => {
      history.push('/liste-biens')
    }


    useEffect(() => {

   
       if(voyageurs==0){
         setVoyageur(1)
       }
      
      let nbbd=nbdays



       if(bien_info?.type_bien== 1){
          
           setTotal( (nbbd * bien_info.price) + bien_info.frais_menage + bien_info.frais_service + bien_info.taxe_sejour)

      }
      else{
         setTotal( (nbbd * bien_info.price))
      }



    }, [bien_info])

  useEffect(() => {


    if(!request){
       let nbbd=nbdays
            dispatch(getBienById(id)).then((res) => {
        setBienInfo(res.data)
        setslides(res.data.images)
        setEquipement(res.data.equipements)

        // calculate total
        

           if(res.data.type_bien== 1){
         if(datearrive && datedepart ){

          setnbdayes( Math.abs(moment(new Date(datedepart)).diff(moment(new Date(datearrive)), 'days')))
          nbbd=Math.abs(moment(new Date(datedepart)).diff(moment(new Date(datearrive)), 'days'))
         }
         else{
          
          let today = moment(new Date())
         
          let tomorow = moment(new Date(today)).add(1, 'days')
     
          setdatearrive(today)
          setdatedepart(tomorow)
          setnbdayes(Math.abs( moment(new Date(tomorow)).diff(moment(new Date(today)), 'days')))
          nbbd=Math.abs(moment(new Date(today)).diff(moment(new Date(tomorow)), 'days'))

         }

      }

      if(res.data.type_bien== 1){
          
           setTotal( (nbbd * res.data.price) + res.data.frais_menage + res.data.frais_service + res.data.taxe_sejour)

      }
      else{
         setTotal( (nbbd * res.data.price))
      }



      })

      setrequest(true)
    }

   



    }) 



  const returnLocationsaisoniere =  () => {
        return(

            <div >
             <h1>{bien_info.logement} {bien_info.city}</h1>
             {
              bien_info.type_bien == 1 ?
              <h2 style={{display:'none'}}>3 voyageurs - 1 chambre - 3 lits - 1 salle de bain</h2>
              :
              ""
             }
             <p>
              {bien_info.description}
             </p>
             <hr/>
             <h3>Equipements</h3>
             <p>
              <ul>
                {
                  equipements.map((eq,ind) => (
                    <li className={eq.class}>{eq.label}</li>
                  ))
                }
              </ul>
              
             </p>
             <br/>
             {/* <a href='javascript:void(0)' className='btndall'>Afficher tous les équipements</a> */}

                  

            </div>


          )
    }
    
   const returnBlockreservation =  () => {
    return(
        <div className='blocreservation' >
        <h6><strong>{bien_info.price} € </strong></h6>
         <div className='cdr990'>
           <div className='tjst6'><span><strong>Total</strong></span><strong>{bien_info.price} €</strong></div>
         </div>

         <a class="btn btnform w200 button_search" href="javascript:void(0)" onClick={()=> goToReservation()} > Réserver </a>
         <a style={{display:'none'}} class="btn btnform w200 button_search" href="javascript:void(0)" onClick={()=> goBack()} > Retour </a>
         <a class="btn btnform w200 button_search" href="javascript:void(0)" onClick={()=> goBack()} > Modification </a>
          
        </div>


      )
}
   const returnBlockreservationLocation =  () => {
        return(

            <div className='blocreservation' >
            {
              bien_info.type_bien == 1 ?
              <h6><strong>{bien_info.price} € </strong>par nuit</h6>
              :
             <h6><strong>{bien_info.price} € </strong></h6>
            }
            {
              bien_info.type_bien == 1 ?
              <div className='cdr990'>
                <div><span>Arrivée</span><b>{moment(new Date(datearrive)).format("DD MMM Y")}</b></div>
                <div><span>Départ</span><b>{moment(new Date(datedepart)).format("DD MMM Y")}</b></div>
              </div>
              :
             ''
            }

             <div className='cdr990'>
               <div><span>Nb de voyageurs</span><b>{voyageurs}</b></div>
             </div>
             <div className='cdr990'>
               <div className='tjst6'><span>{bien_info.price} € x {nbdays} nuit{nbdays > 1 && 's'}</span><b>{parseInt(nbdays) * bien_info.price } €</b></div>
               {
                bien_info.frais_menage > 0 ?
                <div className='tjst6'><span>Frais de ménage</span><b>{bien_info.frais_menage} €</b></div>
                :
                ''
               }
               {
                bien_info.frais_service > 0 ?
                <div className='tjst6'><span>Frais de service</span><b>{bien_info.frais_service} €</b></div>
                :
                ''
               }
               {
                bien_info.taxe_sejour > 0 ?
                <div className='tjst6'><span>Taxes de séjour</span><b>{bien_info.taxe_sejour} €</b></div>
                :
                ''
               }
               <hr/>
               <div className='tjst6'><span><strong>Total</strong></span><strong>{total} €</strong></div>
                
             </div>

             <a class="btn btnform w200 button_search" href="javascript:void(0)" onClick={()=> goToReservation()} > Réserver </a>
               <a class="btn btnform w200 button_search" href="javascript:void(0)" onClick={()=> goBack()} > Modification </a>
            </div>


          )
    }
    return (
      <div id="homwraped" className="biens">
        <div className="content">

        <section id='biens-detail-slide'>
        <div className="container small-container">
          <div className='row'>
            <div className='col-md-12'>

               <Slider {...settingsSlide}>
                           {slides.map((item) => (
                            <div className="ps-banner--market-4"   >
                                  <div className="ps-banner_img" style={{backgroundImage:`url(${item})`}} >
                                  </div>
                               </div>
                            ))}
                           
              </Slider>

            </div>

          </div>

        </div>

        </section>

        <section id='biens-detail'>
        <div className="container small-container">
          <div className='row'>
            <div className='col-md-8'>
                {returnLocationsaisoniere()}
            </div>

            <div className='col-md-4'>
{
  bien_info.type_bien == 2 ?
  returnBlockreservation()
  :
  returnBlockreservationLocation()
}

            </div>

          </div>

        </div>


            
           
        </section>

        <section id="detail-maps">

         <div className="container small-container">
          <div className='row'>
            <div className='col-md-12'>

                  <div style={{ height: 200, width: '100%',    overflow: 'hidden',borderRadius: '14px' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyBTF7Lt3-kiG9UrWnl1R6SVsAev-1Y6aNc" }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                  >

                   <AnyReactComponent
                      lat={bien_info.lat}
                      lng={bien_info.lng}
                      text=""
                    />
                
                  </GoogleMapReact>
                </div>

            </div>
          </div>
         </div>

      

        </section>

        

        </div>
      </div>
    );
}
const AnyReactComponent = ({ text }) => <div><img style={{width:25}} src='/images/marker2.png' /></div>;
export default Page;