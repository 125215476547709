import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button ,Modal,CloseButton } from "react-bootstrap"
import { AssistantItem } from "../../../../components";
import { AvisItem } from "../../../../components";
import { getListWatcher } from "../../../../actions/user";
import { useHistory } from "react-router";
import { BASE_URL_PROD } from "../../../../components/config/keys";
import { surpls,epr } from "../../../../assets/images";
import { useTranslation } from 'react-i18next';
 
import DatePicker, { registerLocale } from "react-datepicker";
import { momentLocalizer  } from 'react-big-calendar'
import moment from "moment";
import 'moment/locale/fr'
import fr from 'date-fns/locale/fr';
const localizer = momentLocalizer(moment)

registerLocale('fr', fr)
const Page = () => {
    const autre_services_data = JSON.parse(localStorage.getItem('autre_services_data'));
    const dispatch = useDispatch();
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const [besoin, setBesoin] = useState('');
    const [desc_besoin, setDescBesoin] = useState('');
    const [besoinText, setBesoinText] = useState('');
    const [locale, setLocal] = useState('fr');
    const [date, setDate] = useState(new Date());
    useEffect(() => {

    }, [])
  
    const handleGoBack = () => {
      history.push('/aide-domicile');
    };

    const handleGoTo = () => {
      autre_services_data.date = date
      localStorage.setItem('autre_services_data', JSON.stringify(autre_services_data))
      history.push('/informatique/date');
    };
    
    const handleBesoin = element => {
      var index = element.nativeEvent.target.selectedIndex;
      setBesoin(element.target.value);
      setBesoinText(element.nativeEvent.target[index].text);
    };
    const handleBesoinDesc = element => {
      setDescBesoin(element.target.value);
    };


    const changeDate = newDate => {
      setDate(newDate[0]);
    };
    return (
      <div className="informatique3" >
        <section className="gray-bg big-padding " id="besoin1">
          <div className="container small-container ">
            <div className='registerform'>
              <div className='row '>
                <div className='col-md-8'><p onClick={()=>handleGoBack()} style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>&lt;	&lt;	Retour</p></div>
                <div className='col-md-8'>
                  <div className="section-title"><h2><span>Quel jour vous convient le mieux ? </span></h2>
                  </div>
                  <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                    <div className='datepickercnt' style={{ textAlign:'center' }}>
                    <DatePicker 
                      locale={locale}
                      multiple
                      onChange={changeDate} 
                      selected={date} 
                      selectsRange
                      inline
                    />
                    </div>
                  </div>
                  <div className='col-md-9' style={{ paddingLeft:"0", marginTop:'30px', clear:'both' }}>
                    <div style={{textAlign:'left',marginBottom:'30px'}}>
                      <a onClick={()=>handleGoTo()} class="btn btnform w200 "> Continuer </a>
                    </div>
                  </div>
                </div>    
                <div className='col-md-4'>
                    <div className="header1 boxShadow">Recapitulatif</div>
                    <div className="body1 boxShadow">
                      <p style={{ margin:'0px', paddingLeft:'10px' }}>1 intervention informatique</p>
                      <hr style={{ margin:'10px' }}></hr>
                      <p style={{ margin:'0px', paddingLeft:'10px' }}><img style={{ width:'16px' }} src="/images/services/icone_detail.png"/> Détails de l'intervention</p>
                        <ul>
                          <li>{autre_services_data.besoinText}</li>
                        </ul>
                    </div>
                </div>   
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Page;