import React, { useState, useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button, Nav, Card, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap, BiShoppingBag, BiUserCircle, BiHistory } from 'react-icons/bi';
import { DashbordMenuClient, DashbordHeaderClient } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"

import { AUTHENTICATE_USER, LOGOUT_USER } from "../../../constants/actions";
import { saveBien } from "../../../actions/pages";
import { BASE_URL, BASE_URL_PROD } from "../../../components/config/keys";
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';
import GoogleMapReact from 'google-map-react';
import axios from "axios";

import { geocodeByAddress,getLatLng }  from 'react-google-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';

const Page = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user)
  const { data } = useSelector(state => state.pages)
  const { t, i18n } = useTranslation();
  const currentlangStorage = localStorage.getItem("currentlang");
  const currentlang = i18n.language;
  const [langset, setLangset] = useState(false);
  const history = useHistory()
  const [startDate, setStartDate] = useState(new Date());
  
  const [nb_chambres, setNbChambre] = useState(0);
  const [nb_salle_bain, setNbBain] = useState(0);
  const [nb_wc, setNbWc] = useState(0);
  const [nb_etages, setNbEtages] = useState(0);
  const [etage, setEtages] = useState(0);
  const [aproximite, setAproximite] = useState([{'label':'Bus', 'selected':0},{'label':'Métro', 'selected':0},{'label':'PER', 'selected':0},{'label':'Gar', 'selected':0},{'label':'Commerces', 'selected':0},{'label':'Accès plage', 'selected':0}]);
  const [animaux, setAnimaux] = useState(0);
  const [fumeur, setFumeur] = useState(0);
  const [image1, setImageName1] = useState("");
  const [image2, setImageName2] = useState("");
  const [image3, setImageName3] = useState("");
  const [image4, setImageName4] = useState("");
  const [image5, setImageName5] = useState("");
  const [image6, setImageName6] = useState("");
  const [equipements, setEquipement] = useState([
                                        {'class':'t1', 'label':'Accès PMR', 'selected':0},
                                        {'class':'t2', 'label':'Ascenseur', 'selected':0},
                                        {'class':'t3', 'label':'Gardien', 'selected':0},
                                        {'class':'t4', 'label':'Interphone', 'selected':0},
                                        {'class':'t5', 'label':'Digicode', 'selected':0},
                                        {'class':'t6', 'label':'Fibre optique', 'selected':0},
                                      ]);
                const [exterieurs, setExterieurs] = useState([
                                          {'class':'t21', 'label':'Parking gratuit', 'selected':0},
                                          {'class':'t22', 'label':'Terrasse', 'selected':0},
                                          {'class':'t23', 'label':'Balcon', 'selected':0},
                                          {'class':'t24', 'label':'Jardin', 'selected':0},
                                          {'class':'t25', 'label':'Piscine', 'selected':0},
                                        ]);

  const [address,setAddress]=useState("");
  const [city,setCity]=useState("");
  const [lat,setLat]=useState("");
  const [lng,setLng]=useState("");
  const [value, setValue] = useState(null);

  const label = { inputProps: { 'aria-label': '' } };

  const defaultProps = {
    center: {
      lat: 48.8733089,
      lng: -0.4833178
    },
    zoom: 5
  };

  const returnFormAddLocation = () => {
    return (
      <div className='col-md-3  position_relative'></div>
    )
  }

  const handleChangeAproximite = (element) => {
    const data = aproximite
    if(data[element].selected == 0)
      data[element].selected = 1
    else
      data[element].selected = 0
    setAproximite(data)
    console.log("aproximite");
    console.log(aproximite);
  }

  const handleChangeAnimaux = () => {
    if(animaux == 0)
      setAnimaux(1)
    else
      setAnimaux(0)
  }
  const handleChangeFumeur = () => {
    if(fumeur == 0)
      setFumeur(1)
    else
    setFumeur(0)
  }

  const handelePlus = (element) => {
    if(element == 1){
      const nb = nb_chambres+1
      setNbChambre(nb)
    }
    else if(element == 2){
      const nb = nb_salle_bain+1
      setNbBain(nb)
    }
    else if(element == 3){
      const nb = nb_wc+1
      setNbWc(nb)
    }
    else if(element == 4){
      const nb = etage+1
      setEtages(nb)
    }
    else if(element == 5){
      const nb = nb_etages+1
      setNbEtages(nb)
    }

    
    
  };
  const handeleMoins = (element) => {
    if(element == 1){
      if(nb_chambres > 0){
        const nb = nb_chambres-1
        setNbChambre(nb)
      }
    }
    else if(element == 2){
      if(nb_salle_bain > 0){
        const nb = nb_salle_bain-1
        setNbBain(nb)
      }
    }
    else if(element == 3){
      if(nb_wc > 0){
        const nb = nb_wc-1
        setNbWc(nb)
      }
    }
    else if(element == 4){
      if(etage > 0){
        const nb = etage-1
        setEtages(nb)
      }
    }
    else if(element == 5){
      if(nb_etages > 0){
        const nb = nb_etages-1
        setNbEtages(nb)
      }
    }
  };

  const handleSubmit = (e) => {
       
      e.preventDefault();
      const formData = new FormData(e.target)
      const formDataObj = Object.fromEntries(formData.entries())
      formDataObj.equipements = equipements
      formDataObj.exterieurs = exterieurs
      formDataObj.aproximite = aproximite
      formDataObj.animaux = animaux
      formDataObj.fumeur = fumeur
      formDataObj.user_id = user.id
      formDataObj.image1 = image1
      formDataObj.image2 = image2
      formDataObj.image3 = image3
      formDataObj.image4 = image4
      formDataObj.image5 = image5
      formDataObj.image6 = image6
      console.log('formDataObj');
      console.log(formDataObj);

      dispatch(saveBien(formDataObj)).then((res) => {
        history.push('/client/patrimoine/')
      })
  }

  const onChangeFile = (e) => {
		let files = e.target.files || e.dataTransfer.files;

		if (!files.length)
		return;

    if(files[0].type=='image/png' || files[0].type=='image/jpeg' || files[0].type=='image/jpg' || files[0].type=='image/gif' ){
      if(files[0].size <= (2*1000000)){
          createImage(files[0], e.target.name);
      }
      else{
          alert('Votre pièce-jointe ne doit  pas dépasser cette taille: 2 MO.');
      }
    }
    else{
        alert("Ce Format document n'est pas supporté ! Les formats acceptés sont (JPG,JPEG,PNG,GIF)");
    }
	}
  
	function createImage(file, tag_name) {
		// $('#divLoad').show()
		let reader = new FileReader();
		reader.onload = (e) => {
		  fileUpload(e.target.result, tag_name);
		};
		reader.readAsDataURL(file);
	}

  const handleChange = address => {
    setAddress(address);
  };
  
 const selectValuemap=(value)=>{
  
  setAddress(value);
  setValue(value);
   geocodeByAddress(value)
   .then(results => {
      const city = results[0].address_components.find(component => component.types.includes("locality")).long_name;
      setCity(city)
      getLatLng(results[0]).then(({ lat, lng }) =>{
          setLat(lat);
          setLng(lng);
       }
      )
   });
 }
 const onChangeaddress = (text) => {
    console.log("jjjjjjjjj",text)
 }

 const renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions,loading }) => (
  <div className="autocomplete-root">
    <input {...getInputProps({
        placeholder: 'Adresse ...',
        className: 'form-control',
    })} />
    <div className="autocomplete-dropdown-container my-2">
      {loading && <div>Loading...</div>}
      {suggestions.map(suggestion => (
      
        <div className=" mb-2  listitem" {...getSuggestionItemProps(suggestion)}>
          <span >{suggestion.description}</span>
        </div>
      ))}
    </div>
  </div>
);

	function fileUpload (values, tag_name){
			var self = this;
		    axios.post(BASE_URL+'/insertimage', {'file':values, 'type':'images_bien'}, { headers: {"Accept": "application/json",
	                "Content-Type": "application/json"}}).then((res) => {
                    if(tag_name == 'image1')
                      setImageName1(res.data.name)
                    else if(tag_name == 'image2')
                      setImageName2(res.data.name)
                    else if(tag_name == 'image3')
                      setImageName3(res.data.name)
                    else if(tag_name == 'image4')
                      setImageName4(res.data.name)
                    else if(tag_name == 'image5')
                      setImageName5(res.data.name)
                    else if(tag_name == 'image6')
                      setImageName6(res.data.name)
                // this.setState({
                // 	[tag_name]: res.data.name
                // })

                // $('#divLoad').hide()
			}).catch((e)=>{
		});
	}

  return (
    <div className="myccount editprofil abonnement " >
      <section className="gray-bg main-dashboard-sec dashboard myccount" id="sec1">
        <div className="container">
          <div className="col-md-3">
            <DashbordMenuClient src='patrimoine' />
          </div>
          <div className='col-md-9 dashbordassitantcontent' >
            <div className='tab' id=''>
              <DashbordHeaderClient src={'w'} />
              <div className='row' style={{ marginTop: '30px' }}>
                <div className='col-md-12'>
                  <form style={{ background: '#f6f6f8', boxShadow: 'none' }} className='custom-form' onSubmit={handleSubmit}>
                    <div class="rowtitel">
                      <img src="/images/addpatrimoine.png" style={{ width: '55px' }} />
                      {/* <h3 class="htri1">Patrimoine -  <span style={{ color: '#4EC5CA' }}>Ajouter un bien en location</span> </h3> */}
                      <h3 class="htri1">Patrimoine -  <span style={{ color: '#4EC5CA' }}>Ajouter un bien en vente</span> </h3>
                    </div>

                    <div className='section298 '>
                      <div className='section298frm row'>
                        <input type="hidden" name="city" value={city} />
                        <div className='col-md-7'>
                          <h4>Titre de l'annonce</h4>
                          <input placeholder="Ex. : Appartement T3 vue dégagée" name="title" type="text" class="form-control inptxt" required="" />
                        </div>
                        
                        <input type="hidden" className='form-control' name='type_bien' value="2"/>
                        <div className='col-md-3'>
                          <h4>Durée de l'annonce</h4>
                          <select className='form-control' name='duree'>
                            <option value="">Sélectionnez</option>
                            <option value="1">1 mois</option>
                            <option value="2">2 mois</option>
                            <option value="3">3 mois</option>
                            <option value="4">Pas de limite de temps</option>
                          </select>
                        </div>
                      </div>
                      <div className='section298frm row'>
                        <div className='col-md-12'> <h4>Type de logement</h4></div>
                        <div className='col-md-4'>
                          <select className='form-control' name='type_logement'>
                            <option value="">Sélectionnez</option>
                            <option value="1">Maison</option>
                            <option value="2">Pavillon</option>
                            <option value="3">Gîte</option>
                            <option value="4">Appartement</option>
                            <option value="5">Appartement en résidence</option>
                            <option value="6">Loft</option>
                            <option value="7">Résidence de tourisme</option>
                            <option value="8">Chambre privée</option>
                            <option value="9">Logement de vacances</option>
                          </select>
                        </div>
                        <div className='col-md-4'>
                          <select className='form-control' name='nb_pieces'>
                            <option value="">Nb de pièces</option>
                            <option value="1">1 pièce</option>
                            <option value="2">2 pièces</option>
                            <option value="3">3 pièces</option>
                            <option value="4">4 pièces</option>
                            <option value="5">5 pièces et +</option>
                          </select>
                        </div>
                        <div className='col-md-4'>
                          <input placeholder="surface m²" name="surface" type="text" class="form-control inptxt" required="" />
                        </div>
                      </div>
                      <div className='section298frm row'>
                        <div className='col-md-3'>
                          <h4>Prix de vente (HT)</h4>
                          <div className='posrela'><input placeholder="0" name="prix_nuit" type="number" min="0" class="form-control inptxt" required="" /><span>€</span></div>
                        </div>
                        <div className='col-md-1'></div>
                      </div>
                      <div className='section298frm row'>
                        <div className='col-md-12'>
                          <h4>Présentation du bien</h4>
                          <textarea name="description" style={{ border: '1px solid #e5e7f2', height: '135px' }} class="form-control" row='3' placeholder='Ex. : Situé à 400 mètres de la plage, magnifique gîte avec piscine...'></textarea>
                          <div className='xtm00'>4000 caractères max.</div>
                        </div>
                      </div>
                      <div className='section298frm row'>
                        <div className='col-md-12'>
                          <h4>Caractéristiques</h4>
                          <br />
                          <h7>GENERAL<span></span></h7>
                          <br />
                          <div className="col-md-2 ingrouped">
                            <h6>Nb de chambres</h6>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <button onClick={() => handeleMoins(1)} class="btn btn-outline-primary" type="button"   >-</button>
                              </div>
                              <input type="text" name="nb_chambres" class="form-control" value={nb_chambres} />
                              <div class="input-group-prepend">
                                <button onClick={() => handelePlus(1)} class="btn btn-outline-primary" type="button" >+</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 ingrouped">
                            <h6>Salle de bain</h6>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <button onClick={() => handeleMoins(2)} class="btn btn-outline-primary" type="button"  >-</button>
                              </div>
                              <input type="text" name="nb_salle_bain" class="form-control" value={nb_salle_bain} />
                              <div class="input-group-prepend">
                                <button onClick={() => handelePlus(2)} class="btn btn-outline-primary" type="button" >+</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 ingrouped">
                            <h6>WC</h6>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <button onClick={() => handeleMoins(3)} class="btn btn-outline-primary" type="button"  >-</button>
                              </div>
                              <input type="text" name="nb_wc" class="form-control" value={nb_wc} />
                              <div class="input-group-prepend">
                                <button onClick={() => handelePlus(3)} class="btn btn-outline-primary" type="button" >+</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 ingrouped">
                            <h6>Etage du Bien</h6>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <button onClick={() => handeleMoins(4)} class="btn btn-outline-primary" type="button"  >-</button>
                              </div>
                              <input type="text" name="etage" class="form-control" value={etage} />
                              <div class="input-group-prepend">
                                <button onClick={() => handelePlus(4)} class="btn btn-outline-primary" type="button" >+</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 ingrouped">
                            <h6>NB Etages</h6>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <button onClick={() => handeleMoins(5)} class="btn btn-outline-primary" type="button"  >-</button>
                              </div>
                              <input type="text" name="nb_etages" class="form-control" value={nb_etages} />
                              <div class="input-group-prepend">
                                <button onClick={() => handelePlus(5)} class="btn btn-outline-primary" type="button" >+</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 ingrouped"><h6>&nbsp;</h6><div class="input-group">&nbsp;</div></div>
                          <div className="col-md-2 ingrouped"><h6>&nbsp;</h6><div class="input-group">&nbsp;</div></div>
                          <div className="col-md-2 ingrouped">
                            <h6>Exposition séjour</h6>
                            <div class="input-group">
                              <select className='form-control' name='exposition_sejeur'>
                                <option value="">Sélectionnez</option>
                                <option value="1">Est</option>
                                <option value="2">Ouest</option>
                                <option value="3">Nord</option>
                                <option value="4">Sud</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-2 ingrouped">
                            <h6>Etat intérieur</h6>
                            <div class="input-group">
                              <select className='form-control' name='etat_interieur'>
                                <option value="">Sélectionnez</option>
                                <option value="1">Moyen</option>
                                <option value="2">Bon</option>
                                <option value="3">Très Bon</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-2 ingrouped">
                            <h6>Cuisine équipée</h6>
                            <div class="input-group">
                              <select className='form-control' name='cuisine_equipe'>
                                <option value="">Sélectionnez</option>
                                <option value="1">Oui</option>
                                <option value="2">Non</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-2 ingrouped">
                            <h6>Cave</h6>
                            <div class="input-group">
                              <select className='form-control' name='cave'>
                                <option value="">Sélectionnez</option>
                                <option value="1">Oui</option>
                                <option value="2">Non</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-2 ingrouped">
                            <h6>Balcon</h6>
                            <div class="input-group">
                              <select className='form-control' name='balcon'>
                                <option value="">Sélectionnez</option>
                                <option value="1">Oui</option>
                                <option value="2">Non</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-12'>
                          <br />
                          <h7>COPROPRIETE<span></span></h7>
                          <br />
                          <div className="row">
                          <div className="col-md-3 ingrouped">
                            <h6>Année de construction</h6>
                            <div class="input-group">
                              <select className='form-control' name='annee_construction'>
                                <option value="">Sélectionnez</option>
                                <option value="1900-1943">1900-1943</option>
                                <option value="1944-1958">1944-1958</option>
                                <option value="1959-1978">1959-1978</option>
                                <option value="1979-1998">1979-1998</option>
                                <option value="1999-2009">1999-2009</option>
                                <option value="Après 2009">Après 2009</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3 ingrouped">
                            <h6>Bien en copropriété</h6>
                            <div class="input-group">
                              <select className='form-control' name='bien_copropriete'>
                                <option value="">Sélectionnez</option>
                                <option value="1">Oui</option>
                                <option value="2">Non</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6 ingrouped">
                            <h6>Procédures diligentées c/ syndicat de copropriété</h6>
                            <div class="input-group">
                              <select className='form-control' name='syndicat'>
                                <option value="">Sélectionnez</option>
                                <option value="1">Oui</option>
                                <option value="2">Non</option>
                              </select>
                            </div>
                          </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-md-3 ">
                              <h6>Nb Lots Copropriété</h6>
                              <div class="input-group">
                                <input type="text" className='form-control inptxt' name='nb_ots_copropriete' />
                              </div>
                            </div>
                            <div className="col-md-3 ">
                              <h6>Charges copropriété</h6>
                              <div class="input-group">
                                <input type="text" className='form-control inptxt' name='charge_copropriete' />
                              </div>
                            </div>
                            <div className="col-md-3 ">
                              <h6>Montant charges annuelles</h6>
                              <div class="input-group">
                                <input type="text" className='form-control inptxt' name='montant_charges_annuelles' />
                              </div>
                            </div>
                            <div className="col-md-3 ">
                              <h6>Taxe foncière</h6>
                              <div class="input-group">
                                <input type="text" className='form-control inptxt' name='taxe_fonciere' />
                              </div>
                            </div>
                          </div>
                          
                          <div className="row">
                            <div className="col-md-3 ingrouped">
                              <h6>Type de chauffage</h6>
                              <div class="input-group">
                                <select className='form-control' name='type_chauffage'>
                                  <option value="">Sélectionnez</option>
                                  <option value="1">Collectif</option>
                                  <option value="2">Individuel</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-3 ingrouped">
                              <h6>Mecanisme chauffage</h6>
                              <div class="input-group">
                                <select className='form-control' name='mecanisme_chauffage'>
                                  <option value="">Sélectionnez</option>
                                  <option value="1">Gaz</option>
                                  <option value="2">Electrique</option>
                                  <option value="3">Chauffage Urbain</option>
                                  <option value="4">Fuel</option>
                                  <option value="5">Biomasse</option>
                                  <option value="6">Géothermie</option>
                                  <option value="7">Bois</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='section298frm row'>
                        <div className='col-md-12'>
                          <h7>Immeuble<span></span></h7>
                          <br />
                        </div>
                        <div className='col-md-12'>
                        <div className="row">
                            <div className="col-md-3 ingrouped">
                              <h6>Standing</h6>
                              <div class="input-group">
                                <select className='form-control' name='standing'>
                                  <option value="">Sélectionnez</option>
                                  <option value="1">Moyen</option>
                                  <option value="2">Bon</option>
                                  <option value="3">Très Bon</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-3 ingrouped">
                              <h6>Emplacement</h6>
                              <div class="input-group">
                                <select className='form-control' name='emplacement'>
                                  <option value="">Sélectionnez</option>
                                  <option value="1">Moyen</option>
                                  <option value="2">Bon</option>
                                  <option value="3">Très Bon</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-3 ingrouped">
                              <h6>Matériaux</h6>
                              <div class="input-group">
                                <select className='form-control' name='materiaux'>
                                  <option value="">Sélectionnez</option>
                                  <option value="1">Béton</option>
                                  <option value="2">Brique</option>
                                  <option value="3">Pierre</option>
                                  <option value="4">Autres</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <ul className='row'>
                            {
                              equipements.map((eqp, index) => (
                                <li className='col-md-4 isnonbg'><Switch {...label} size="small" color="secondary" /><span>{eqp.label}</span></li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                      <div class="section298frm row bgcap89">
                        <div class="col-md-12">
                          <h4>Bilan énergétique</h4>
                        </div>
                        <div className='col-md-12'>
                          <div className="row">
                            <div className="col-md-3 ingrouped">
                              <h6>Consommation énergétique</h6>
                              <div class="input-group">
                                <select className='form-control' name='consommation_energetique'>
                                  <option value="">Sélectionnez</option>
                                  <option value="1">50 A</option>
                                  <option value="2">51-90 1B</option>
                                  <option value="3">91-150 C</option>
                                  <option value="4">151-230 D</option>
                                  <option value="5">231-330 1E</option>
                                  <option value="6">331-450 F</option>
                                  <option value="7">> 450 1G</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-3 ingrouped">
                              <h6>Emission de gaz</h6>
                              <div class="input-group">
                                <select className='form-control' name='emission_gaz'>
                                  <option value="">Sélectionnez</option>
                                  <option value="1">5 1A</option>
                                  <option value="2">6-10 1B</option>
                                  <option value="3">11-20 1C</option>
                                  <option value="4">21-35 D</option>
                                  <option value="5">36-55 1E</option>
                                  <option value="6">56-80 1F</option>
                                  <option value="7">> 80 1G</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="section298frm row bgcap89">
                        <div class="col-md-12">
                          <h4>Photos</h4>
                        </div>
            {
              image1 != '' ? 
              <div className='col-6 col-md-2'><div className='img'  style={{background:"url("+BASE_URL_PROD+'/img/biens/'+image1+")", backgroundSize:"cover"}}></div></div>
              :
              <div className='col-6 col-md-2'><div className='img'><input style={{cursor:'pointer'}} className="inputFileInvis" name="image1" type="file" onChange={onChangeFile}/></div></div>
            }
            {
              image2 != '' ? 
              <div className='col-6 col-md-2'><div className='img'  style={{background:"url("+BASE_URL_PROD+'/img/biens/'+image2+")", backgroundSize:"cover"}}></div></div>
              : 
              <div className='col-6 col-md-2'><div className='img'><input style={{cursor:'pointer'}} className="inputFileInvis" name="image2" type="file" onChange={onChangeFile}/></div></div>
            }
            {
              image3 != '' ? 
              <div className='col-6 col-md-2'><div className='img'  style={{background:"url("+BASE_URL_PROD+'/img/biens/'+image3+")", backgroundSize:"cover"}}></div></div>
              : 
              <div className='col-6 col-md-2'><div className='img'><input style={{cursor:'pointer'}} className="inputFileInvis" name="image3" type="file" onChange={onChangeFile}/></div></div>
            }
            {
              image4 != '' ? 
              <div className='col-6 col-md-2'><div className='img'  style={{background:"url("+BASE_URL_PROD+'/img/biens/'+image4+")", backgroundSize:"cover"}}></div></div>
              : 
              <div className='col-6 col-md-2'><div className='img'><input style={{cursor:'pointer'}} className="inputFileInvis" name="image4" type="file" onChange={onChangeFile}/></div></div>
            }
            {
              image5 != '' ? 
              <div className='col-6 col-md-2'><div className='img'  style={{background:"url("+BASE_URL_PROD+'/img/biens/'+image5+")", backgroundSize:"cover"}}></div></div>
              : 
              <div className='col-6 col-md-2'><div className='img'><input style={{cursor:'pointer'}} className="inputFileInvis" name="image5" type="file" onChange={onChangeFile}/></div></div>
            }
            {
              image6 != '' ? 
              <div className='col-6 col-md-2'><div className='img'  style={{background:"url("+BASE_URL_PROD+'/img/biens/'+image6+")", backgroundSize:"cover"}}></div></div>
              : 
              <div className='col-6 col-md-2'><div className='img'><input style={{cursor:'pointer'}} className="inputFileInvis" name="image6" type="file" onChange={onChangeFile}/></div></div>
            }
                      </div>
                      <div class="section298frm row">
                        <div class="col-md-12">
                          <h4>Localisation du bien</h4><br />
                          <h6>Où se situe votre bien ?</h6>
                        </div>
                        <div className='col-12 col-md-12'>
                          <div style={{ height: 200, width: '100%', overflow: 'hidden', borderRadius: '14px', position: 'relative' }}>
                            <div className='google_search'>
                              <div className='flx998'>
                                {/* <input placeholder="Renseignez votre adresse ou votre ville" name="" type="text" class="form-control inptxt" required="" /> */}
                                {/* <button class="btn float-btn color2-bg btn9876 btcustom " ><i className='fa fa-search'></i></button> */}

                                <Form.Group as={Col} >
                                  <Form.Label>Adresse</Form.Label>
                                  <Form.Control defaultValue={address} type="hidden" placeholder="Votre adresse" name="address" />
                                  <Form.Control defaultValue={lat} type="hidden" placeholder="Votre adresse" name="lat" />
                                  <Form.Control defaultValue={lng} type="hidden" placeholder="Votre adresse" name="lng" />
                                  <PlacesAutocomplete value={address} onChange={handleChange}   onSelect={selectValuemap}>
                                    {renderFunc}
                                  </PlacesAutocomplete>
                                </Form.Group>

                              </div>
                            </div>
                            <GoogleMapReact
                              bootstrapURLKeys={{ key: "AIzaSyBTF7Lt3-kiG9UrWnl1R6SVsAev-1Y6aNc" }}
                              defaultCenter={defaultProps.center}
                              defaultZoom={defaultProps.zoom}
                            >
                              <AnyReactComponent
                                lat={lat}
                                lng={lng}
                                text=""
                              />
                            </GoogleMapReact>
                          </div>
                        </div>
                      </div>
                      <div class="section298frm row">
                        <div class="col-md-12">
                          <h4>A proximité</h4><br />
                        </div>
                        <div className='col-12 col-md-12'>
                          <ul className='row'>
                            {
                              aproximite.map((p, index) =>(
                                <li className='col-md-4 isnonbg'><Switch {...label} size="small" color="secondary" onChange={() => handleChangeAproximite(index)} /> <span>{p.label}</span></li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div>
                            <button type="submit" class="btn float-btn color2-bg btn9876 btcustom " style={{ borderRadius: '13px' }}>Publier l'annonce</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
const AnyReactComponent = ({ text }) => <div></div>;
export default Page;