import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenuClient,DashbordHeaderClient } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"
import { getAllCategories } from "../../../actions/pages";
import { updateInfoUser } from "../../../actions/user";
import Switch from '@material-ui/core/Switch';

const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { data } = useSelector(state => state.pages)
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categ, setCateg] = useState('');
    const [rib, setRib] = useState('');
    const [loading, setLoading] = useState(false);

    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const label = { inputProps: { 'aria-label': '' } };

     const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
    
        
    
    }

      const goto = (slug) => {
         history.push(slug)
     }
    useEffect(() => {
    
      

    }, [])

    
 
   
 

 

    return (
        <div className="myccount editprofil portfeuile " >
            
                <section className="gray-bg main-dashboard-sec dashboard myccount abonnement devis " id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenuClient src='profil'/>
                                 
                            </div>


                         <div className='col-md-9 dashbordassitantcontent' >

                             <div className='tab' id=''>


                              <DashbordHeaderClient src={'w'} />

                             <div className='row' style={{marginTop:'30px'}}>
                               <div className='col-md-12'>

                                <div className='flx998 mtgt6 flx998mob '>
                                 <div className='item bg1'>
                                   <p>Total des revenus</p>
                                   <strong>2 548,00 €</strong>
                                 </div>
                                 <div className='item bg2'>
                                   <p>Paiements à venir</p>
                                   <strong>940,00 €</strong>
                                 </div>
                                 <div className='item bg3'>
                                   <p>Nb de devis émis</p>
                                   <strong>15</strong>
                                 </div>
                                 <div className='item bg4'>
                                   <p>Nb de factures émises</p>
                                   <strong>12</strong>
                                 </div>
                                 <div className='item bg5'>
                                   <p>Missions en cours</p>
                                   <strong>20</strong>
                                 </div>
                                               
                                </div>


                            

                             
                         <form  onSubmit={handleSubmit} className='' >
                               
                              

                          <div className="profile-edit-container fl-wrap block_box">

          
                                    <div className="row sepa66">
                                     <div className='col-md-7'>

                                        <div className='flx999'>
                                           <img src='/images/icones/sarlbu.png' />
                                           <div>
                                             <h6>SARL Eric Brisso Plomberie</h6>
                                             <p>18 rue Saint Fiacre</p>
                                             <p>78000 Versailles</p>
                                             <p>Siret :  999 999 999 9999</p>

                                           </div>

                                        </div>

                                     </div>

                                      <div className='col-md-5'>
                                              <div className='flx999' style={{justifyContent: 'flex-end',paddingTop: '19px'}}>
                                                 <p>Revenus disponibles</p>
                                                 <div className='bg99'><strong>1 608,00 €</strong></div>
                                              </div>
                                     </div>
                                    </div>


                                  <div className="row">

                                  <div className='col-md-12'>

                                    <h4>

                                     Vous pouvez demander le retrait de vos revenus (par virement bancaire sous 24h).

                                    </h4>

                                     <div className='row998'>

                                     <p>

                                     <input type="radio"  />
                                     <label>Faire une demande de retrait total</label>

                                    </p>
                                     <p>

                                     <input type="radio"   />
                                     <label>Faire une demande de retrait partiel</label>

                                    </p>
                                     <p>

                                     <input type="radio"   />
                                     <label>Recharger mon compte (0 € commission)</label>

                                    </p>


                                    <table>
                                     <thead>

                                       <tr>
                                        <th>Code banque</th>
                                        <th>Code guichet</th>
                                        <th>Numéro de compte</th>
                                        <th>Clé RIB</th>

                                       </tr>

                                     </thead>
                                     <tbody>
                                     <tr>
                                       <td>-----</td>
                                       <td>-----</td>
                                       <td>-----</td>
                                       <td>-----</td>
                                      </tr>
                                     </tbody>
                                   


                                    </table>

                                    <div>
                                       <button type="submit" className="btn float-btn color2-bg btn9876 btcustom bt77 " style={{fontSize: '12px',borderRadius: '5px'}}> Envoyer</button>

                                    </div>

                                     </div>


                                  </div>




                                  </div>





                            </div>



                        </form>


                         <form  onSubmit={handleSubmit} className='' >
                               
                              

                          <div className="profile-edit-container fl-wrap block_box">

                          <h4>
                           RIB
                          </h4>
                          <br/>

                          <div className='row'>
                           <div className='col-md-6'>

                           <div className='abonnement'>
                              <div class="flx881  "><div className='flx999'> <p class="clrbl9" style={{ marginBottom:'4px',   color: '#5e7b97', fontSize: '13px'}}>Nom de la banque </p></div><input placeholder="Nom de la banque" name="" type="text" class="form-control" required="" value=""/></div>

                          </div>
                          <div className='download'>
                           <div className='item1'>Document(s) téléchargé(s)</div>
                           <div className='item'>
                              <div><img src='/images/icones/file2256.png' /> <span>RIB</span></div>
                              <div><img src='/images/icones/iconefermercroixpetit.png' /></div>

                           </div>

                          </div>

                           </div>
                             <div className='col-md-6'>

                              <div className='login'>
                                  <div className='download' style={{    textAlign: 'center',minHeight: '96px',paddingTop:'29px',marginBottom:'0px'}}>
                                                  
                                                     <input name="kbis" type="file"   required />
                                                      «Glisser-déposer votre logo dans cette zone ou<br/> <span style={{color:'#2971b9'}}>cliquez ici pour sélectionner une photo</span>». 
                                                    </div>

                              </div>
                              <button type="submit" className="btn float-btn color2-bg btn9876 btcustom bt77 " style={{fontSize: '12px',borderRadius: '5px',width:'100%'}}> Mettre à jour</button>

                           </div>

                          </div>



                       





                          </div>

                          </form>

 

                                    

                               </div>
                  

                             </div>


                                 
                          </div>
 

                              

                         </div>
                    </div>
            </section>

        </div>
    );
}

export default Page;