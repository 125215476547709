import React, { useEffect,useState } from "react";
import './styles.scss'; 
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
 
import { useParams } from "react-router";
import { apiClient } from "../../../actions/api"
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import { AvisItem } from "../../../components";
import { useHistory } from "react-router";
import { BASE_URL } from "../../../components/config/keys";
import { SET_MISSION_INFOS } from "../../../constants/actions"

import {
        Elements,
        CardElement,
        useStripe,
        useElements,
      } from "@stripe/react-stripe-js";
      import { loadStripe } from "@stripe/stripe-js";


      import {checkCoupon } from "../../../actions/pages";

const Page = () => { 
    const stripe = loadStripe("pk_test_51LSGF1G7VfW1y640IKD9C5oLmKjNJD3WdB2jwYNOh0vqBOCdFtTCL58QrWONgbrOPA6ImuX0E2uYpk1aQJrWgQib00Ok5knJ1L");
    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)
    const [coupon, setCoupon] = useState("")
    const [couponSucces, setCouponSucces] = useState("OK")
    const [couponValue, setCouponValue] = useState(0)
    const [errorCoupon, setErrorCoupon] = useState(false);
     const history = useHistory()
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [price_intervention, setPrice] = useState(localStorage.getItem('min_price'))
    const [total, setTotal] = useState(localStorage.getItem('min_price'))
    const [promo_price, setPricePromo] = useState(0)
 
    const { user } = useSelector(state => state.user)
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);

     var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
    
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };
   

   const handleGoTo = () => {
         
       
    }
 
 
    const handleChangeCoupon = couponData => {
      setCoupon(couponData.target.value);
    };
   
    const handleAddCoupon =  () => {
      setCouponValue(0)
      setCouponSucces("OK")
      setErrorCoupon(false)
      if(coupon == '')
        setErrorCoupon(true)
      else
        dispatch(checkCoupon(coupon)).then((res) => {
          setCouponSucces(res.succes)
          setCouponValue(res.valeur)

          let promo_price_val = price_intervention*res.valeur/100
          setPricePromo(promo_price_val)

          let new_total = parseFloat(price_intervention)-parseFloat(promo_price_val)
          setTotal(new_total)
        }) 
    }

    const handleSubmit = async (e) => {
     
    }


 

         const savecommande=(payement_inten_id)=>{
          localStorage.setItem('total', total)
          localStorage.setItem('promo_price', promo_price)
          localStorage.removeItem('mission_data')
              const params = {
                payement_inten_id:payement_inten_id,
                user_id:user.id,
                data:localStorage
              };
              
              apiClient().post(`/mission/paiement`,params)
              .then((res) => {
                if(res.data.success==1){
                    dispatch({type:SET_MISSION_INFOS,payload:res.data.data})
                     setShow(true)
                     setTimeout(function(){
                        history.push('/votre-paiement-accepte/')
                     }, 2000);

                }
                else{
                  alert('Une erreur est survenue veuillez réessayer ultérieurement.')
                }
                
                 
              })
          
        }


 
 

     useEffect(() => {


   
        if(langset == false){
            setLangset(true)
        }
 
 

    }, [])
   
    return (
        <div className="besoin  " >
           <section className="gray-bg big-padding " id="besoin7">
            <div className="container small-container ">
             <div className='registerform'>

                <div className='row '>
                <div className='col-md-12'>
                   <div className='progressbare'>
                     <div className='veh' style={{ left: '95%'}}><img src='/images/Groupe6849.png'/></div>
                     <div className="progressvalue" style={{width:'100%'}}></div>
                     <div className='bat' ><img src='/images/Groupe6903.png'/></div>
                   </div>
                </div>
                </div>



                <div className='row '>
                <div className='col-md-7'>

                 <div className="section-title"><h2 ><span>Réservez votre intervention</span></h2>
                      
                  </div>
                      <br/><br/>


                   <ul className='bs7'>

                     

                      <li style={{backgroundPosition: 'left 26px'}}>
                        Proximiti est conçu pour vous protéger tout au long d’une demande d'intervention. Ainsi, en effectuant votre paiement sur notre plateforme, <strong>nous sécurisons vos paiements et vos informations restent confidentielles.</strong>
                      </li>

                      <li>
                          <strong> Aucun prélèvement n'est effectué,</strong> il s'agit juste d'une emprunte bancaire, en cas d'annulation, vous pouvez décidez de convertir votre réservation pour d'autres services, un autre artisan ou un abonnement.
                      </li>

                      <li>
                           <strong>Vous pouvez annuler votre réservation jusqu'à 2h avant l'intervention.</strong> Après 2h nous convertissons votre réservation en jetons services valable sur n'importe quelle prestation. Sais t-on jamais !
                      </li>

                      <li>
                          <strong>Nous vous accompagnons en cas de litige</strong> mais le prestataire reste seul responsable de la prestation et non la plateforme. Toute réclamation devra uniquement concerner l'objet du présent ticket payé d'avance. Tout élément accessoire qui en serai la cause ou/et la conséquence ne sera pris en compte.
                      </li>

                      <li>
                       <strong>Nous ne stockons pas</strong> votre numéro de carte ni le code de sécurité.
                      </li>


                   </ul>

                </div>

                 <div className='col-md-5'>
                         
                         <div className="cadrerecap">
                            <div className='titlerecap'>Récapitulatif</div>
                            <div className='bodyrecap '>
                               <div className="ligne">
                                 <div className="col-md-9 ">1 intervention en plomberie  </div>
                                 <div className="col-md-3 tright"> {parseFloat(price_intervention).toFixed(2)} €</div>
                               </div>
                                {/* <div className="ligne">
                                 <div className="col-md-9">Frais de service  </div>
                                 <div className="col-md-3 tright"> 10,00 €</div>
                               </div> */}
                                    {
                                      couponValue > 0 ?
                                      <div className="ligne">
                                        <div className="col-md-9">Remise ({couponValue} %)</div>
                                        <div className="col-md-3 tright"> {parseFloat(promo_price).toFixed(2)} €</div>
                                      </div>
                                      :
                                      ''
                                    }
                               

                                <div className="ligne home" style={{padding:'0px',marginTop:'10px'}}>
                                  <div className='col-md-12'> <div className="form-group--nest">
                                    <input name='coupon'  onChange={handleChangeCoupon} className="form-control" type="text" placeholder="Code promo"/>
                                    <button className="ps-btn" onClick={handleAddCoupon}>OK</button>
                                    
                                  </div>
                                  {
                                    errorCoupon ?
                                    <div class="alert alert-danger col-md-12" style={{clear:'both', marginTop:'5px'}} role="alert">
                                      Veuillez saisir le code promo
                                    </div>
                                    :
                                    ''
                                  }
                                  {
                                    couponSucces == false ?
                                    <div class="alert alert-danger col-md-12" style={{clear:'both', marginTop:'5px'}} role="alert">
                                      Veuillez saisir le code promo
                                    </div>
                                    :
                                    ""
                                  }
                                </div>
                               </div>
                               <br/>
                                <div className="ligne">
                                 <div className="col-md-9 "><strong>TOTAL</strong>  </div>
                                 <div className="col-md-3 tright"> <strong>{parseFloat(total).toFixed(2)} €</strong></div>
                               </div>
                               <br/>

                            </div>

                         </div>


                         <br/>



                        <div className="cadrerecap">
                            
                            <div className='bodyrecap '>
                               <div className="ligne">
                                 <div className="col-md-12">
                                     <img src='/images/paiemenetsecurise.jpg' />
                                  </div>
                               
                               </div>
                                <div className="ligne paiments">
                                 <div className="col-md-12">
                                      <Elements stripe={stripe}>
                                        <CheckoutForm  savecommande={savecommande} user={user} total={parseFloat(total).toFixed(2)} history={history} BASE_URL={BASE_URL} />
                                     </Elements>
                                  </div>
                               
                               </div>
 

                            </div>

                         </div>




                </div>

                </div>



             </div>
             </div>


            <div className='sect2'>
              <div className="container small-container ">
                 <div className='row'>
                  <div className='col-md-12'>
                      <div className="section-title" style={{paddingBottom:'0px'}}>
                        <h5><span>Confiance et sécurité garanties :   Identité des artisans vérifiée et services assurés.</span></h5>
                      </div>
 
 
                  </div>
               </div>
              </div>
             </div>
 
 

         <div className='sect3'>
              <div className="container small-container ">
 
                      <div className='cadrbs1' style={{background:'#f6f6f6'}}>

                                  <div className="process-wrap fl-wrap commentcafonctionne "><div className="default-desc margright mrgtop"><p>&nbsp;</p>
                                    <ul className="no-list-style row">



                                    <li className="col-md-4">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/Iconeartisan.png' />
                                    </div>

                                    <p className='basecolor'>Nos artisans sont certifiés et labellisés.</p>
                                    </div>
                                    </li>

                                    <li className="col-md-4">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/iconechrono.png' />
                                    </div>

                                    <p className='basecolor'>Suivez votre intervention dans votre espace dédié et activez les notifications pour rester informé du déroulement de l'intervention.</p>
                                    </div>
                                    </li>

                                    <li className="col-md-4">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/IconeIA.png' />
                                    </div>

                                    <p className='basecolor'>Bénéficier d'un abonnement qui vous permet de faire des économies dès la première intervention.</p>
                                    </div>
                                    </li>

                                    </ul>
                                    </div>
                             </div>

                      </div>
 
                  </div>
             
             </div>








           </section>


                    <section className=' home gray-bg'>
                        <div className="container small-container">

                             <div className='bgreviews'>
                                 <div className='row'>

                                    <div className='col-md-4'>
                                     <h5 className='basecolor'>Ce que<br/> pensent nos<br/> clients</h5>
                                     <div className='notereview'>4,5/5</div>
                                     <div className="listing-rating-count-wrap"><div className="listing-rating card-popup-rainingvis" data-starrating2="5"><div><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></div><div className="card-popup-rainingvis_bg"><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><div></div></div></div><br/><div className="reviews-count"></div></div>
                                      <p>Sur la base de 585 avis</p>
 


                                    </div>

                                     <div className='col-md-8'>

                                          <Slider {...settings}>
                                          
                                             <AvisItem  />
                                              <AvisItem  />
                                               <AvisItem  />

                                          </Slider>
 


                                    </div>

                                 </div>
                             </div>

                        </div>
                </section>

        </div>

    );
}

export default Page;

const CheckoutForm = ({savecommande,total,user,history,BASE_URL}) => {
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    
    const stripe = useStripe();
    const elements = useElements();


     const getSetupClientSecret=()=>{

        const params = {
          total: total ,
          customer_id: user.id
        }
        return fetch(BASE_URL+'/payment/stripe', {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'post',
          body: JSON.stringify(params)
        }).then(response => response.json())
          .catch(error => {
            console.log("stripe_get_secret_setup_intents error", error)
          });
      } 


    const payMoney = async (e) => {
      e.preventDefault();
        
        // history.push('/votre-paiement-accepte/')
      // return false;
      
      if (!stripe || !elements) {
        return;
      }


      setPaymentLoading(true);
      
      const info = await getSetupClientSecret();
      const clientSecret = info.stripe.client_secret;
      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: user.prenom+ +user.nom,
          },
        },
      });
      setPaymentLoading(false);
      console.log(paymentResult);
      if (paymentResult.error) {
        alert(paymentResult.error.message);
      } else {
         if (paymentResult.paymentIntent.status == "requires_capture") {
             console.log(paymentResult.paymentIntent.id);
            savecommande(paymentResult.paymentIntent.id);
        }
      }
    };


 

    return (
      <div>
        <div
          style={{
            maxWidth: "500px",
            margin: "0 auto",
          }}
        >
          <form
            style={{
              display: "block",
              width: "100%",
            }}

            onSubmit = {payMoney}
           
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CardElement
                className="card"
                options={{
                  style: {
                    base: {
                      backgroundColor: "white"
                    } 
                  },
                }}
              />
              <button
                className="pay-button"
                disabled={isPaymentLoading}
              >
                {isPaymentLoading ? "Loading..." : "Payer "+total+" €"}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }