import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button ,Modal,CloseButton } from "react-bootstrap"
import { AssistantItem } from "../../../../components";
import { AvisItem } from "../../../../components";
import { getListWatcher } from "../../../../actions/user";
import { useHistory } from "react-router";
import { BASE_URL_PROD } from "../../../../components/config/keys";
import { surpls,epr } from "../../../../assets/images";
import { useTranslation } from 'react-i18next';
 

const Page = () => {
    const autre_services_data = JSON.parse(localStorage.getItem('autre_services_data'));
    const dispatch = useDispatch();
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const [genre, setGenre] = useState(autre_services_data.genre);
    const [genreText, setGenreText] = useState(autre_services_data.genreText);
    const [qte, setQte] = useState(autre_services_data.qte);
    const [categorie, setcCategorie] = useState(autre_services_data.categorie);
    const [categorieText, setcCategorieText] = useState(autre_services_data.categorieText);
    const [categorieError, setCategorieError] = useState(0);
    useEffect(() => {

    }, [])
  
    const handleGoBack = () => {
      history.push('/garde-animaux/pour-qui');
    };

    const handleGoTo = () => {
      setCategorieError(0)
      if(categorie == '' || categorie == undefined){
        setCategorieError(1)
      }else{
        autre_services_data.categorie = categorie
        localStorage.setItem('autre_services_data', JSON.stringify(autre_services_data))
        history.push('/garde-animaux/frequence');
      }
    };
    
    const handleCategorie = element => {
      setCategorieError(0)
      setcCategorie(element);

      if(element == 1) setcCategorieText('Coupe & Coiffage')
      if(element == 2) setcCategorieText('Coloration & Balayage')
      if(element == 3) setcCategorieText('Coiffure spéciale')
    };
    return (
      <div className="gardeanimaux3" >
        <section className="gray-bg big-padding " id="besoin1">
          <div className="container small-container ">
            <div className='registerform'>
              <div className='row '>
                <div className='col-md-8'><p onClick={()=>handleGoBack()} style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>&lt;	&lt;	Retour</p></div>
                <div className='col-md-8'>
                  <div className="section-title"><h2><span>Quel type de garde ? </span></h2>
                  </div>
                  <div className='custom-form col-md-4' style={{ paddingLeft:"0" }}>
                    <div onClick={() => handleCategorie(1)} className={categorie == 1 ? "boxShadow coiffurebox selected":"boxShadow coiffurebox"}>
                      <p>Garde <br/>à domicile</p>
                    </div>
                  </div>
                  <div className='custom-form col-md-4' style={{ paddingLeft:"0" }}>
                    <div onClick={() => handleCategorie(2)} className={categorie == 2 ? "boxShadow coiffurebox selected":"boxShadow coiffurebox"}>
                      <p>Garde <br/>chez le prestataire</p>
                    </div>
                  </div>
                  <div className='custom-form col-md-4' style={{ paddingLeft:"0" }}>
                    <div onClick={() => handleCategorie(3)} className={categorie == 3 ? "boxShadow coiffurebox selected":"boxShadow coiffurebox"}>
                      <p>Visites <br/>à domicile</p>
                    </div>
                  </div>
                  {
  categorieError == 1 ?
  <div className='col-md-9' style={{ paddingLeft:"0", paddingTop:'30px', clear:'both' }}>
    <div className="alert alert-danger" role="alert">
      Veuillez choisir une catégorie !
    </div>
  </div>
  :
  ""
}
                  <div className='col-md-9' style={{ paddingLeft:"0", paddingTop:'30px', clear:'both' }}>
                    <div style={{textAlign:'left',marginBottom:'30px'}}>
                      <a onClick={()=>handleGoTo()} class="btn btnform w200 "> Continuer </a>
                    </div>
                  </div>
                </div>    
                <div className='col-md-4'>
                    <div className="header1 boxShadow">Recapitulatif</div>
                    <div className="body1 boxShadow">
                      <p style={{ margin:'0px', paddingLeft:'10px' }}>1 intervention garde animaux</p>
                      <hr style={{ margin:'10px' }}></hr>
                      {
                        genreText != '' ?
                        <ul>
                          <li>{genreText}</li>
                          {
                            genre == 1 ?
                            <li>{qte} {qte > 1 ? 'chiens':'chien'}</li>
                            :
                            (
                              genre == 2 ?
                              <li>{qte} {qte > 1 ? 'chats':'chat'}</li>
                              :
                              (
                                genre == 3 ?
                                <li>{qte} {qte > 1 ? 'promonades':'promonade'}</li>
                                :
                                ''
                              )
                            )
                          }
                        </ul>
                        :
                        ''
                      }
                    </div>
                </div>  
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Page;