import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card,Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenuClient,DashbordHeaderClient } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"
import { getAllCategories, getAllMissions, getAllMissionsv2, getAllBienReservation,saveMessage, getMessagesClient, setAsReaded,getCountUnreadMsg } from "../../../actions/pages";
import { updateInfoUser } from "../../../actions/user";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { BASE_URL } from "../../../components/config/keys";
import moment from 'moment'


const Page = () => {
    const stripe = loadStripe("pk_test_51LSGF1G7VfW1y640IKD9C5oLmKjNJD3WdB2jwYNOh0vqBOCdFtTCL58QrWONgbrOPA6ImuX0E2uYpk1aQJrWgQib00Ok5knJ1L");
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { data } = useSelector(state => state.pages)
    const { all_missions, all_messages, all_sent_messages, reservation_bien } = useSelector(state => state.pages)
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const [status, setStatus] = useState('');
    const [categories, setCategories] = useState([]);
    const [categ, setCateg] = useState('');
    const [rib, setRib] = useState('');
    const [interventionId, setIntervention] = useState();
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [intervention_error, setInterventionError] = useState(false);
    const [message_error, setMessageError] = useState(false);
    const [succesSave, setSuccesSave] = useState(-1);
    const [messageType, setMessageType] = useState(2);
    const [showdetailmission,setshowdetailmission] = useState(false);
    const [showdetailreservation,setshowdetailreservation] = useState(false);
    const [detailmission, setdetailmission] = useState({});
    const [detailreservation, setdetailreservation] = useState({});

    const [show, setShow] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [showpaiement, setshowpaiement] = useState(false);


    
    const [detailTitle, setDetailTitle] = useState('');
    const [detailBody, setDetailBody] = useState('');

    const handleClose = () => setShow(false);
    const handleCloseDetail = () => setShowDetail(false);
    const handleClosePaiement = () => setshowpaiement(false);

    const [tab, setTab] = useState(1);

    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
     const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        let go=true;

   


        if(go){
           dispatch(updateInfoUser(formDataObj,user.id,'watcher'));
        }
        
    
    }

    const showMessageDetail = (ind) => {
      setShowDetail(true)
      setDetailTitle('')
      setDetailBody('')
      if(messageType == 2){
        setDetailTitle(all_messages[ind].ref)
        setDetailBody(all_messages[ind].msg)

        if(all_messages[ind].status == 0){
          dispatch(setAsReaded(all_messages[ind].id)).then(() => {
            dispatch(getCountUnreadMsg(user.id, 'customer'))
            dispatch(getMessagesClient(user.id, 2,'customer'))
          })
        }
      }else{
        setDetailTitle(all_sent_messages[ind].ref)
        setDetailBody(all_sent_messages[ind].msg)
      }
    }

const changeIntervention = (e) => {

  setIntervention(e.target.value);
}
const changeMessage = (e) => {

  setMessage(e.target.value);
}

const changeTypeMessage = (valeur) => {
  setMessageType(valeur)
}
     const settab1= (e) => {
        e.preventDefault();
        
        setTab(1)
        setshowdetailmission(false)
        setshowdetailreservation(false)
        dispatch(getMessagesClient(user.id, 2,'customer'))
        dispatch(getMessagesClient(user.id, 1,'customer'))
    }

    const showNewMsg = () => {
      setShow(true)
    }

    const settab2= (e) => {
        e.preventDefault();
        
        setTab(2)
        setshowdetailmission(false)
        setshowdetailreservation(false)
        dispatch(getAllMissionsv2(user.id, status, 'customer'))
    }

    const settab3= (e) => {
        e.preventDefault();
        
        setTab(3)
        setshowdetailmission(false)
        setshowdetailreservation(false)
        dispatch(getAllBienReservation(user.id, status, 'customer'))
    }

    const sendMessage = () => {
      setInterventionError(false)
      setMessageError(false)
      if(interventionId == '' || interventionId == 0 || interventionId == undefined)
        setInterventionError(true)
      else if(message == '' || message == undefined)
        setMessageError(true)
      else{
          dispatch(saveMessage(user.id, interventionId, message, 2)).then((res) => {

            if(res.success){
              
            window.location.reload()
              setSuccesSave(1)
              dispatch(getMessagesClient(user.id, 1,'customer'))
            }
            else
              setSuccesSave(0)           
        })
      }
    }

    const changeStatus= (e) => {
      setStatus(e.target.value);
      dispatch(getAllMissionsv2(user.id, e.target.value,'customer'))
  }
    useEffect(() => {
      dispatch(getAllMissionsv2(user.id, status, 'customer'))        .then(() => {
        console.log('all_missions')
        console.log(all_missions)
   })
        dispatch(getMessagesClient(user.id, 2,'customer'))
        dispatch(getMessagesClient(user.id, 1,'customer'))
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])

    
     const handleSetCategorie = (categ) => {
            
            let newcategories=[...categories];
            var index = newcategories.indexOf(categ)
            if( index > -1){
               newcategories.splice(index, 1);
               setCategories(newcategories);
 
            }
            else{

               newcategories.push(categ);
               setCategories(newcategories);

            }            
    }

      const goToDetails = (id) => {
        history.push("/detail/mission/"+id)
      }

      const createPdf = (file) => {
      
      setLoading(true)
      const data = new FormData();
      data.append('membreId', user.id);
      data.append('file', file);
      data.append('type', 'ribs');
      apiClient().post(`/insertimage`,data)
            .then((res) => {
               setRib(res.data.name)  
               setLoading(false)             
            })

  }

    const uploadPdf = (e) => {
        

    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
    return;

      if(files[0].type=='application/pdf'  ){
              if(files[0].size <= (2*1000000)){
                 createPdf(files[0]);
              }
              else{

                 alert(t('Votre pièce-jointe ne doit pas dépasser cette taille: 2 MO.'));
              }
      }
      else{
         alert(t("Ce format n'est pas supporté ! Le format accepté est (PDF)"));
      }

        const param = {
                id:user.id,
              //files_img:this.state.files,
            }
    }


  const payerIntervention=(payement_inten_id)=>{
          const params = {
            payement_inten_id:payement_inten_id,
            user_id:user.id,
            id_mission:detailmission?.id,
            total:detailmission?.price,
            
          };
          
          apiClient().post(`/client/payerintervention`,params)
          .then((res) => {
            if(res.data.success == 1){
                 setshowpaiement(false)
                 setshowdetailmission(false)
                 dispatch(getAllMissionsv2(user.id, status, 'customer'))

            }
            else{
              alert('Une erreur est survenue veuillez réessayer ultérieurement.')
            }
            
             
          })
      
    } 

   
 const updateticket =  (s) => {
      const data = {
        userId:user.id,
        missionId:detailmission?.id,
        type:s,
        price:detailmission?.professional?.price,
        comment:'',
        action:'customer',

    }
          apiClient().post(`/acceptMission`,data)
            .then((res) => {

              setshowdetailmission(false)
              dispatch(getAllMissionsv2(user.id, status, 'customer'))
                        
            })
  }



  const updateReservation =  (s) => {
    const data = {
      userId:user.id,
      reservationId:detailreservation?.res_id,
      type:s,
    }
        apiClient().post(`/acceptReservation`,data)
          .then((res) => {
            setshowdetailreservation(false)
            dispatch(getAllBienReservation(user.id, status, 'customer'))
                    
          })  
}

   const setdatamission = (detail) =>{
      console.log(detail)
       setdetailmission(detail)
       setshowdetailmission(true)
  }

  const setDataReservation = (detail) =>{
      console.log(detail)
      setdetailreservation(detail)
      setshowdetailreservation(true)
  }



    const displaydetailmission = () =>{

       return (


        <div className='detailmission09'>

        <h3>Ticket travaux </h3>

        <div className='crd98776 row'>
           <div className='col-md-6'>

           <div className='trcrd0'>

               <div className='trcrd1'>
                <label><span><i className="fal fa-calendar"></i> </span> {detailmission?.date_mission}</label>
                <label><span><i className="fal fa-clock-o "></i> </span> {detailmission?.duree == 1 ? 'Matin':(detailmission?.duree == 2 ? 'Après-midi':'Soir')}</label>
               </div>

               <div className='trcrd2'>
                  <label>N° de l'intervention</label>
                  <label>{detailmission?.ref}</label>
               </div>
                <div className='trcrd2'>
                  <label>Intervention</label>
                  <label>{detailmission?.label} {detailmission?.demande} </label>
               </div>

               <div className='trcrd2'>
                  <label>Tarif</label>
                  <label>{detailmission?.price} € </label>
               </div>

               {
                detailmission?.status== 4 &&

                    <div className='trcrd2' style={{borderTop:'1px solid #ccc'}}>

                     <label><strong>Nouveau tarif</strong></label>
                     <label><strong>{detailmission?.professional?.price}€</strong></label>
                     
                   </div>
               }

              




           </div>

            


           </div>

        </div>

        <div className='row'>
        <div className='col-md-12'>

          {
             detailmission?.status == 0 &&

           
                                                           <div className='flx881 ' >
                                                                 <button onClick={()=>updateticket(3)} style={{background:'#384F95', marginRight:10}} className="btn float-btn color2-bg btn98996 w100">Annuler le ticket</button>
                                                           </div>
            


          }

          {

            detailmission?.status== 4 &&

            
                                                           <div className='flx881 ' >
                                                                 <button onClick={()=>updateticket(3)} style={{background:'#384F95', marginRight:10}} className="btn float-btn color2-bg btn98996 w100">Annuler le ticket</button>
                                                                 <button onClick={()=>updateticket(1)}  style={{background:'#E99F28'}} className="btn float-btn color2-bg btn9886 w100">Accepter le nouveau tarif</button>
                                  
                                                           </div>
          


          }

           {

            (detailmission?.status== 1 && detailmission?.status_paiement== 1 ) &&

           
                                                           <div className='flx881 ' >
                                                                  
                                                                 <button onClick={()=>setshowpaiement(true)}  style={{background:'#E99F28'}} className="btn float-btn color2-bg btn9886 w100">Payer l'intervention</button>
                                  
                                                           </div>
          


          }

          {

            (detailmission?.status== 1 && detailmission?.status_paiement== 2 ) &&

           
                                                           <div className='flx881 ' >
                                                                  
                                                                 <button onClick={()=>updateticket(6)}  style={{background:'#E99F28'}} className="btn float-btn color2-bg btn9886 w100">Valider le paiement de l'intervention</button>
                                  
                                                           </div>
          


          }


            {

            detailmission?.status== 2 &&

           
                                                           <div className='flx881 ' >
                                                                  
                                                                 <a href={detailmission?.facture_client} download  style={{background:'#073460'}} className="btn float-btn color2-bg btn9886 w100">Télécharger la facture <img src="/images/icones/iconetélécharger.png" style={{width: 18, marginLeft:20}} /></a>
                                  
                                                           </div>
          


          }
 

        </div>


        </div>





        </div>




        )
    }


    const displaydetailreservation = () =>{

      return (
       <div className='detailmission09'>
      <p>Vous trouverez ci-dessous le récapitulatif de la demande de {detailreservation.type_bie ? 'location':''}.</p>
       <h3>{detailreservation.logement+' '+detailreservation.city}</h3>
       {
        detailreservation.type_bien == 1 ?
         <label><span><i className="fal fa-calendar"></i> </span> {moment(new Date(detailreservation?.datearrive)).format("DD MMM Y") } - {moment(new Date(detailreservation?.datedepart)).format("DD MMM Y") }</label>
        :
        <label><span><i className="fal fa-calendar"></i> </span> {detailreservation?.date_res}</label>
       }
        {
          detailreservation.type_bien == 1 ?
          <label><span><i className="fal fa-clock-o "></i> </span> {detailreservation?.voyageurs} Voyageurs </label>
          :
          ""
        }
       <div className='crd98776 row mt-3'>
          <div className='col-md-4 p-0'  style={{backgroundImage:`url(${detailreservation.img})`, backgroundSize: '100% 100%', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px'}}>      
          </div>
          <div className='col-md-6'  style={{borderTopRightRadius: '10px', borderBottomRightRadius: '10px', background:'#fff', padding: '15px !important'}}>
            <div className=''>
                <div className=''>
                </div>
                <div className='trcrd2 col-12'>
                {
                  detailreservation.type_bien == 1 ?
                  <h6><strong>{detailreservation.price} € </strong>par nuit</h6>
                  :
                  <h6><strong>{detailreservation.price} € </strong></h6>
                }
                  <label>{detailreservation?.ref}</label>
                </div>
                <div className='trcrd2 col-12'>
                <div className='cdr990 col-12'>
                {
                  detailreservation.type_bien == 1 ?
                  <div className='tjst6'><span>{detailreservation.price} € x {detailreservation?.nbnuit} nuits</span><b style={{ float:'right' }}>{detailreservation?.nbnuit * detailreservation.price } €</b></div>
                  :
                  <div className='tjst6'><span>{detailreservation.price} €</span></div>
                }
               {
                detailreservation.frais_menage > 0 ?
                <div className='tjst6'><span>Frais de ménage</span><b style={{ float:'right' }}>{detailreservation.frais_menage} €</b></div>
                :
                ''
               }
               {
                detailreservation.frais_service > 0 ?
                <div className='tjst6'><span>Frais de service</span><b style={{ float:'right' }}>{detailreservation.frais_service} €</b></div>
                :
                ''
               }
               {
                detailreservation.taxe_sejour > 0 ?
                <div className='tjst6'><span>Taxes de séjour</span><b style={{ float:'right' }}>{detailreservation.taxe_sejour} €</b></div>
                :
                ''
               }
               <hr/>
               <div className='tjst6'><span><strong>Total</strong></span><strong style={{ float:'right' }}>{detailreservation.total} €</strong></div>
                
             </div>
                </div>
    
            </div>        
          </div>
       </div>
       <div className='row'>
       <div className='col-md-12'>
        
         {
            detailreservation?.res_status == 0 && user.id == detailreservation?.client_id &&
            <div className='flx881 ' >
                  <button onClick={()=>updateReservation(3)} style={{background:'#384F95', marginRight:10}} className="btn float-btn color2-bg btn98996 w100">Annuler la réservation</button>
            </div>
         }
         {
            detailreservation?.res_status == 0 && user.id == detailreservation?.user_id &&
            <div className='flx881 ' >
                  <button onClick={()=>updateReservation(2)} style={{background:'#384F95', marginRight:10}} className="btn float-btn color2-bg btn98996 w100">Refuser la réservation</button>
                  <button onClick={()=>updateReservation(1)} style={{background:'#384F95', marginRight:10}} className="btn float-btn color2-bg btn98996 w100">Accepté la réservation</button>
            </div>
         }
        {/* {
           detailreservation?.res_status== 2 &&
            <div className='flx881 ' >
                  <a href={detailreservation?.facture_client} download  style={{background:'#073460'}} className="btn float-btn color2-bg btn9886 w100">Télécharger la facture <img src="/images/icones/iconetélécharger.png" style={{width: 18, marginLeft:20}} /></a>
            </div>
        } */}
       </div>
       </div>
       </div>
       )
   }

    return (
        <div className="myccount editprofil messages  " >
            
                       <section className="gray-bg main-dashboard-sec dashboard myccount history" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenuClient src='profil'/>
                                 
                            </div>


                         <div className='col-md-9 dashbordassitantcontent' >

                             <div className='tab' id=''>


                              <DashbordHeaderClient src={'w'} />


                            <div className='ffform'>

                           

                             <div className='row ' style={{marginTop:'30px'}}>
                               <div className='col-md-3' style={{paddingLeft:'0px'}}>
                               <div>

                                  <div className='rowtitel' style={{    marginLeft: '10px'}}>
                                  
                                  <img src='/images/icones/iconemessagerie.png' />
                                  <h3 className='htri1'>Messages </h3>

                                  </div>
                                  <div className='btn11'>
                                          <a href='javascript:void(0)' onClick={showNewMsg} ><img src="/images/icones/iconeplusrajouter.png"/>Nouveau message</a>
                                  </div>

                               

                                  <div className='msgset1'>
                                     
                                      <ul>

                                       <li style={{cursor:'pointer'}} className={messageType == 2 ? 'active':''} onClick={() => changeTypeMessage(2)}>
                                         <div className='img'><img src='/images/icones/open-inbox.png' /></div>
                                         <div className='txt'> Boîte de réception</div>
                                         <span className='nmb'>({all_messages != undefined ? all_messages.length : 0})</span>
 
                                       </li>

                                       <li style={{cursor:'pointer'}} className={messageType == 1 ? 'active':''} onClick={() => changeTypeMessage(1)}>
                                         <div className='img'><img src='/images/icones/envoyer-1.png' /></div>
                                         <div className='txt'> Messages envoyés</div>
                                         <span className='nmb'>({all_sent_messages != undefined ? all_sent_messages.length : 0})</span>
 
                                       </li>

                                        <li className=''>
                                         <div className='img'><img src='/images/icones/trash-alt.png' /></div>
                                         <div className='txt'>Messages supprimés</div>
                                         <span className='nmb'>(0)</span>
 
                                       </li>

                                         <li className=''>
                                         <div className='img'><img src='/images/icones/iconebrouillon.png' /></div>
                                         <div className='txt'> Brouillons</div>
                                         <span className='nmb'>(0)</span>
 
                                       </li>
                                          <li className=''>
                                         <div className='img'><img src='/images/icones/md-archive.png' /></div>
                                         <div className='txt'> Messages archivés</div>
                                         <span className='nmb'>(0)</span>
 
                                       </li>


                                     </ul>



                                  </div>

                                 


                               </div>
                                

                             
                                 
                      

 

                                    

                               </div>
                               <div className='col-md-9'>


                                 <div className='sect2 reservationbien'>

                                   <a style={{cursor:'pointer'}} onClick={settab1} href="javascript:void(0)" className={tab==1 && 'active'} >Messages</a>
                                   <a style={{cursor:'pointer'}}  onClick={settab2} className={tab==2 && 'active'} href="javascript:void(0)" >Tickets</a>
                                   <a style={{cursor:'pointer'}}  onClick={settab3} className={tab==3 && 'active'} href="javascript:void(0)" >Réservations Biens</a>

                                </div>

                                           <div className='flx889'>
                                          <div className="filter-tags ft-list" style={{    marginLeft: '9px'}}> 
                                                                 
                                                                {/* <input id="check-a2" type="checkbox" name="check"  />
                                                                <label for="check-a2">Tout sélectionner</label> */}
                                                          
                                           </div>

                                           <div style={{    display: 'flex'}}>
                                             {
                                                tab==2 &&
                                                <select name="type" onChange={changeStatus} required="" className="selectform bgselect">
                                                    <option value="">Statut</option>
                                                    <option value="0">Nouveau</option>
                                                    <option value="4">En attente</option>
                                                    <option value="1">En Cours</option>
                                                    <option value="2">Terminé</option>
                                                    <option value="3">Annulé</option>
                                                </select>


                                             }

                                             <select name="type" required="" className="selectform bgselect"><option value="">Trier par</option></select>


                                           </div>
                                </div>

                            


                             {

                                 tab==1 ? 


                              <div className='contentmessages'>

                               <ul>
                                 {
                                  messageType == 1 ?
                                  (
                                   all_sent_messages != undefined && all_sent_messages.length > 0 ?
                                   (
                                    all_sent_messages.map((msg, index) => (
                                  <li>
                                    {/* <div className='f1'> <div className="filter-tags ft-list"> 
                                                    <input id="check-a2" type="checkbox" name="check"  /></div>
                                   </div> */}
                                    <div className='f2'> <strong>Ticket {msg.ref} </strong>- {msg.msg}...</div>
                                    <div className='f3'>{msg.date}</div>
                                    {/* <div className='f4'><a href='javascript:void(0)'><img src='/images/icones/pointsmenudépliant.png' /></a></div> */}
                                    <div className='f4'><div className='statut st1' onClick={() => showMessageDetail(index)} style={{cursor:'pointer', textAlign:"center"}}>Voir</div></div>

                                  </li>
                                     ))
                                   )
                                   :
                                     <li>
                                       <h3 style={{width:'100%', textAlign:"center"}}>Aucun Message</h3>
                                    </li>
                                  )
                                  :
                                  (
                                    all_messages != undefined && all_messages.length > 0?
                                    (
                                      all_messages.map((msg, index) => (
                                   <li>
                                     {/* <div className='f1'> <div className="filter-tags ft-list"> 
                                                     <input id="check-a2" type="checkbox" name="check"  /></div>
                                    </div> */}
                                     <div className='f2'> <strong>Ticket {msg.ref} </strong>- {msg.msg}...</div>
                                     <div className='f3'>{msg.date}</div>
                                    <div className='f3'></div>
                                    <div className='f4'><div className='statut st2' style={{textAlign:"center"}}>{msg.status == 0 ? 'Non lu':'lu'}</div></div>
                                    <div className='f4'><div className='statut st1' onClick={() => showMessageDetail(index)} style={{cursor:'pointer', textAlign:"center"}}>Voir</div></div>

                                   </li>
                                      ))
                                    )
                                    :
                                      <li>
                                        <h3 style={{width:'100%', textAlign:"center"}}>Aucun Message</h3>
                                     </li>
                                  )
                                    } 
                                                   

                                 </ul>

                               </div>

                               :

                                (
                                  tab == 2 ?
                                  <div className='contentmessages'>

                                  {
                                      
                                      showdetailmission == false ?  
   
                                       <ul className='missionscnt'>
                                     {
                                       all_missions != undefined && all_missions.length > 0 ?
                                       (
                                         all_missions.map((mission) => (
                                           <li onClick={() => setdatamission(mission)}>
                                               
                                                {/* <div className='f1'> <div className="filter-tags ft-list"> 
                                                                <input id="check-a2" type="checkbox" name="check"  /></div>
                                                </div> */}
                                                
                                                <div className='f2' style={{    width: '73%'}}> <strong>Ticket intervention {mission.ref}</strong>  - {mission.label}</div>
                                               
                                                {
                                                 mission.status == 4 &&
                                                   <div className='f4' ><div className='statut st2' style={{background:'#e99f28'}}>En attente</div></div>
   
                                                 }
   
                                               {
                                                 mission.status == 0 ?
                                                   <div className='f4'><div className='statut st2'>Nouveau</div></div>
                                                 :
                                                 (
                                                   mission.status == 1 ?
                                                   <div className='f4'><div className='statut st1'>En cours</div></div>
                                                   :
                                                   (
                                                     mission.status == 2 ?
                                                     <div className='f4'><div className='statut st4'>Terminé</div></div>
                                                     :
                                                     (
                                                       mission.status == 3 ?
                                                       <div className='f4'><div className='statut st3'>Annulé</div></div>
                                                       :
                                                       ""
                                                     )
                                                   )
                                                 )
                                               }
                                                <div className='f3'>{mission.date_mission}</div>
                                                
                                               
                                            </li>
                                         ))
                                       )
                                       :
                                         <li>
                                           <h3 style={{width:'100%', textAlign:"center"}}>Aucune mission trouvée</h3>
                                        </li>
                                     }
   
                                    </ul>
   
   
                                      :
   
   
                                      displaydetailmission()
   
   
   
   
                                  }
   
                                 
   
   
                                  </div>
                                  :
                                  <div className='contentmessages'>

                                  {
                                      
                                      showdetailreservation == false ?  
   
                                       <ul className='missionscnt'>
                                     {
                                       reservation_bien != undefined && reservation_bien.length > 0 ?
                                       (
                                        reservation_bien.map((reservation) => (
                                           <li onClick={() => setDataReservation(reservation)}>
                                                                                      
                                                <div className='f2' style={{    width: '73%'}}> <strong>Réservation {reservation.logement}</strong></div>
                                               
                                                {
                                                 reservation.res_status == 0 ?
                                                   <div className='f4'><div className='statut st2'>Nouveau</div></div>
                                                 :
                                                 (
                                                  reservation.res_status == 1 ?
                                                   <div className='f4'><div className='statut st1'>Accepté</div></div>
                                                   :
                                                   (
                                                    reservation.res_status == 2 ?
                                                     <div className='f4'><div className='statut st4'>Refusé</div></div>
                                                     :
                                                     (
                                                      reservation.res_status == 3 ?
                                                       <div className='f4'><div className='statut st3'>Annulé</div></div>
                                                       :
                                                       ""
                                                     )
                                                   )
                                                 )
                                               }
                                                <div className='f3'>{reservation.date_res}</div>
                                                
                                               
                                            </li>
                                         ))
                                       )
                                       :
                                         <li>
                                           <h3 style={{width:'100%', textAlign:"center"}}>Aucune réservation trouvée</h3>
                                        </li>
                                     }
   
                                    </ul>
   
   
                                      :
     
                                      displaydetailreservation()
  
                                  }
   
                                 
   
   
                                  </div>
                                )




                              }

                                

                              





                                
                            

                           
                               
                                 

                                </div>

                             </div>
                           </div>

                                 
                              </div>
 

                              

                         </div>
                    </div>
            </section>

            <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                        Nouveau Message
                  </Modal.Header>
                  <Modal.Body>


                  <select name="type" onChange={changeIntervention} required="" className="selectform bgselect">
                      <option value="">Choisir Intervention</option>
                      {
                                    all_missions != undefined && all_missions.length > 0 ?
                                    (
                                      all_missions.map((mission) => (
                                        <option value={mission.id}>{mission.nom+' '+mission.prenom} ({mission.ref} {mission.label})</option>
                                      ))
                                  )
                                  :
                                  ''
                      }
                  </select>
                  
                  {
                      intervention_error ?
                      <div class="alert alert-danger custom-form col-md-12" style={{clear:'both'}} role="alert">
                        Veuillez choisir une mission
                      </div>
                      :
                      ''
                  }
                  <textarea  className="form-control" placeholder="Message" onChange={changeMessage}></textarea>
                    <br/>
                  {
                      message_error ?
                      <div class="alert alert-danger custom-form col-md-12" style={{clear:'both'}} role="alert">
                        Veuillez saisir votre message
                      </div>
                      :
                      ''
                  }
                  {
                    succesSave == 0 ?
                    <div class="alert alert-danger col-md-12" style={{clear:'both', marginTop:'5px'}} role="alert">
                        Un problème est survenu lors de l'enregistrement !
                    </div>
                    :
                    ""
                  }
                  {
                    succesSave == 1 ?
                    <div class="alert alert-success col-md-12" style={{clear:'both', marginTop:'5px'}} role="alert">
                      Message envoyé avec succès !
                    </div>
                    :
                    ""
                  }
                  </Modal.Body>
                  <Modal.Footer>
                    
                    <Button variant="success" onClick={sendMessage}>
                               Envoyer
                    </Button>
                  </Modal.Footer>
          </Modal>
            <Modal show={showDetail} onHide={handleCloseDetail}>
                  <Modal.Header closeButton>
                        Ticket {detailTitle}
                  </Modal.Header>
                  <Modal.Body>
                  {detailBody}
                  </Modal.Body>
          </Modal>


          <Modal show={showpaiement} onHide={handleClosePaiement}>
                  <Modal.Header closeButton>
                       Paiement
                  </Modal.Header>
                  <Modal.Body>

                  <div className="cadrerecap">
                            
                            <div className='bodyrecap '>
                              <div className="ligne">
                                <div className="col-md-12">
                                    <img src='/images/paiemenetsecurise.jpg' />
                                  </div>
                              
                              </div>
                                <div className="ligne paiments">
                                <div className="col-md-12">
                        <Elements stripe={stripe}>
                            <CheckoutForm BASE_URL={BASE_URL} payerIntervention={payerIntervention} user={user} total={detailmission?.price} history={history}  />
                        </Elements>
                                  </div>
                              
                              </div>


                            </div>

                        </div>
                  </Modal.Body>
          </Modal>



        </div>
    );
}

export default Page;


const CheckoutForm = ({payerIntervention,total,user,history,BASE_URL}) => {
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  
  const stripe = useStripe();
  const elements = useElements();


   const getSetupClientSecret=()=>{

      const params = {
        total: total ,
        customer_id: user.id
      }
      return fetch(BASE_URL+'/payment/stripe', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(params)
      }).then(response => response.json())
        .catch(error => {
          console.log("stripe_get_secret_setup_intents error", error)
        });
    } 


  const payMoney = async (e) => {
    e.preventDefault();
      
      // history.push('/votre-paiement-accepte/')
    // return false;
    
    if (!stripe || !elements) {
      return;
    }


    setPaymentLoading(true);
    
    const info = await getSetupClientSecret();
    const clientSecret = info.stripe.client_secret;
    const paymentResult = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: user.prenom+ +user.nom,
        },
      },
    });
    setPaymentLoading(false);
    console.log(paymentResult);
    if (paymentResult.error) {
      alert(paymentResult.error.message);
    } else {
       if (paymentResult.paymentIntent.status == "requires_capture") {
           console.log(paymentResult.paymentIntent.id);
          payerIntervention(paymentResult.paymentIntent.id);
      }
    }
  };




  return (
    <div>
      <div
        style={{
          maxWidth: "500px",
          margin: "0 auto",
        }}
      >
        <form
          style={{
            display: "block",
            width: "100%",
          }}

          onSubmit = {payMoney}
         
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardElement
              className="card"
              options={{
                style: {
                  base: {
                    backgroundColor: "white",
                    width: "100%",
                    
                  } 
                },
              }}
            />
            <button
              className="pay-button"
              disabled={isPaymentLoading}
            >
              {isPaymentLoading ? "Loading..." : "Payer "+total+" €"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}