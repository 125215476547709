import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card,Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import ReactStars from "react-rating-stars-component";

import { useDispatch, useSelector } from "react-redux";
import { updateInfoUser } from "../../actions/user";
import { getListMissions,EditMission,getListValidateMissions,getListRealiseMissions,setMessages,setRate } from "../../actions/pages";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { Dashbord,DashbordHeader } from "../../components";
import { useTranslation } from 'react-i18next';



const Page = () => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const { user } = useSelector(state => state.user)
    const { missions,missionsV,missionsR } = useSelector(state => state.pages)
    const [langset, setLangset] = useState(false);
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const [show, setShow] = useState(false);
    const [showR, setShowR] = useState(false);
     const [photo, setPhoto] = useState('');
    const [lname, setLname] = useState('');
    const [to, setTo] = useState('');
    const [note, setNote] = useState(0);
    const [missionid, setMissionid] = useState('');
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);

    const handleClose = () => setShow(false);
    const handleCloseR = () => setShowR(false);
    const handleSubmit = async (e) => {
        e.preventDefault();

        if(to){


        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
       
        dispatch(setMessages(formDataObj,user.id,to,'user','save',user.emailToke,t('Votre message est bien été envoyé..')));
        setShow(false)
        setPhoto('')
        setLname('')
        setTo('')

        }
        
    }

const ratingChanged = (newRating) => {
 
  setRating(newRating)
};



        const handleSubmitRate = async (e) => {
        e.preventDefault();

        if(to){


        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
       
        dispatch(setRate(formDataObj,user.id,to,missionid,rating,'user','save',user.emailToken,t('Votre évaluation est bien été enregistrée..')));
        setShowR(false)
        setPhoto('')
        setLname('')
        setMissionid('')
        setTo('')

        }
        
    }

    const handleRemove =  (id) => {
        
        dispatch(EditMission({},user.id,id,user.emailToken,'remove','user'));
        
    }


     const handleUpdateState =  (id) => {
        
        dispatch(EditMission({},user.id,id,user.emailToken,'updatestatemak','user'));
        
    }

     const handleSetProfil =  (profil) => {
       

        setPhoto(profil.photo)
        setLname(profil.prenom)
        setTo(profil.id)
        setShow(true)
    }

     const handleSetReview =  (profil,Missionid) => {
       

        setPhoto(profil.photo)
        setLname(profil.prenom)
        setTo(profil.id)
        setMissionid(Missionid)
        setShowR(true)
    }



     useEffect(() => {
        // const params={
        //    id:user.id,
        //    type:'encours',
        //    profile:'user'
        // }

        // dispatch(getListMissions(params));
        // const params2={
        //    id:user.id,
        //    type:'valide',
        //    profile:'user'
        // }
        // dispatch(getListValidateMissions(params2));

        // const params3={
        //    id:user.id,
        //    type:'realise',
        //    profile:'user'
        // }
        // dispatch(getListRealiseMissions(params3));
        
        // if(langset == false){
        //     if(!currentlangStorage || currentlangStorage=='null'){
        //      localStorage.setItem("currentlang",currentlang)
        //     }
        //     else{
        //          i18n.changeLanguage(localStorage.getItem("currentlang"))
        //     }
        //     setLangset(true)
        // }

    }, [])
    
 
    return (
        <div className="myccount" >
            <DashbordHeader src={'u'} />

            <section className="gray-bg main-dashboard-sec" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                               
                                  <Dashbord src='profile' />
                            </div>


                         <div className='col-md-9'>

                             <div className='tab' id=''>
                                 <div class="dashboard-title   fl-wrap">
                                    <h3 className='htri1'>{t('Mes demandes en cours')}</h3>
                                     <table  id="datatable_encours" className="table table-separate datatable-client table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                                    <thead>
                                    <tr>
                                        <th>{t('Ref')}#</th>
                                        <th>{t('Ville')}</th>
                                        <th>{t('Adresse')}</th>
                                        <th>{t('Date')}</th>
                                        <th>{t('Action')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {missions.map((item,index) => {
                                      
                                       return (
                                      <tr>
                                      <td>{item.ref}</td>
                                      <td>{item.ville}</td>
                                      <td>{item.adresse}</td>
                                      <td>{item.date_mission}</td>
                                      <td>
                                          
                                       <div className="list-contr">
                                                
                                                <a href={"/edit-mission/"+item.id} className="color-bg tolt center" data-microtip-position="left" data-tooltip={t('Modifier')}><i class="fal fa-edit"></i></a>
                                                <a href="javascript:void(0)" onClick={()=>handleRemove(item.id)} className="red-bg tolt center" data-microtip-position="left" data-tooltip={t('Supprimer')}><i class="fal fa-trash"></i></a>
                                                <a href={"/dashboard/watchers/"+item.id} className="green-bg tolt center" data-microtip-position="left" data-tooltip={t('Réponses')}>{item.nbwatchers} </a>
                                        </div>


                                      </td>
                                      </tr>
                                        );
                            
                                     })}

                                    
                                    
                  
                                
                                    
                                    </tbody>
                            </table>

                            <br/>

                             <h3 className='htri2'>{t('Mes demandes validées')}</h3>
                                     <table  id="datatable_valides" className="table table-separate datatable-client table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                                    <thead>
                                    <tr>
                                        <th>{t('Ref')}#</th>
                                        <th>{t('Ville')}</th>
                                        <th>{t('Assistant')}</th>
                                        <th>{t('Date')}</th>
                                        <th>{t('Action')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                             
     

                                      {missionsV.map((item,index) => {
                                      
                                       return (
                                      <tr>
                                      <td>{item.ref}</td>
                                      <td>{item.ville}</td>
                                      <td><img src={item.watcher && item.watcher.photo} className='prfimg' /> {item.watcher && item.watcher.prenom} {item.watcher && item.watcher.nom}</td>
                                      <td>{item.date_mission}</td>
                                      <td>
                                     
                                      <div className="list-contr">
                                                
                                                <a href={"/account/detail/mission/"+item.id}  className="color-bg tolt center" data-microtip-position="left" data-tooltip={t('Détail')}><i class="fal fa-eye"></i></a>
                                                <a href="javascript:void(0)" onClick={()=>handleSetProfil(item.watcher)}  className="red-bg tolt center" data-microtip-position="left" data-tooltip={t('Messages')}><i class="fal fa-comments-alt"></i></a>
                                                <a href="javascript:void(0)" onClick={()=>handleUpdateState(item.id)}  className="green-bg tolt center" data-microtip-position="left" data-tooltip={t('Marquer comme realisée')}><i class="fal fa-check"></i></a>
                                        </div>


                                      </td>
                                      </tr>
                                        );
                            
                                     })}
                                      
                                    </tbody>
                            </table>
<br/>
                             <h3 className='htri3'>{t('Mes missions realisées')}</h3>
                                     <table  id="datatable_realises" className="table table-separate datatable-client table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                                    <thead>
                                    <tr>
                                        <th>{t('Ref')}#</th>
                                        <th>{t('Ville')}</th>
                                        <th>{t('Assistant')}</th>
                                        <th>{t('Date')}</th>
                                        <th>{t('Action')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                       {missionsR.map((item,index) => {
                                      
                                       return (
                                      <tr>
                                      <td>{item.ref}</td>
                                      <td>{item.ville}</td>
                                      <td><img src={item.watcher && item.watcher.photo} className='prfimg' /> {item.watcher && item.watcher.prenom} {item.watcher && item.watcher.nom}</td>
                                      <td>{item.date_mission}</td>
                                      <td>
                                     
                                      <div className="list-contr">
                                                
                                                <a href={"/account/detail/mission/"+item.id}  className="color-bg tolt center" data-microtip-position="left" data-tooltip={t('Détail')}><i class="fal fa-eye"></i></a>
                                                <a href="javascript:void(0)" onClick={()=>handleSetProfil(item.watcher)} className="red-bg tolt center" data-microtip-position="left" data-tooltip={t('Messages')}><i class="fal fa-comments-alt"></i></a>
                                                <a href="javascript:void(0)" onClick={()=>handleSetReview(item.watcher,item.id)} className="green-bg tolt center" data-microtip-position="left" data-tooltip={t('Noter la mission')}><i class="fal fa-star"></i></a>
                                        </div>


                                      </td>
                                      </tr>
                                        );
                            
                                     })}
                             
 
                                      
                                    </tbody>
                            </table>


                                 </div>
                             </div>

                            

                              

                         </div>
                    </div>
            </section>
<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>

          <div className='center'>
             <img src={photo} className='msgprofile' />
             
             <div>{lname}</div>
          </div>

          <br/>

          <form onSubmit={handleSubmit}>

                 <div >
                                        <div className="row">
                                            <div className="col-sm-12">

                                                 
                                                <textarea style={{height: "100px"}} cols="40" rows="2" placeholder={t('Votre message')+'...'} name='message' className='form-control' required></textarea>                                             
                                            </div>
                                        </div>
                                 <div className="row">
                                            <div className="col-sm-12">
                                            
                                                 <button type="submit" className="btn float-btn color2-bg btn9876 "> {t('Envoyer')} <i className="fas fa-caret-right"></i></button>
                                            </div>
                               </div>     
                   </div>

          </form>
         
           

        </Modal.Body>
        <Modal.Footer>
          
         
        </Modal.Footer>
</Modal>


<Modal show={showR} onHide={handleCloseR}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>

          <div className='center'>
             <img src={photo} className='msgprofile' />
             
             <div>{lname}</div>
          </div>

          <br/>

          <form onSubmit={handleSubmitRate}>

                 <div >
                                        <div className="row">
                                            <div className="col-sm-12">


                                            <div>




                                            </div>

  <div className='rate'>
                                            <ReactStars
    count={5}
    onChange={ratingChanged}
    size={24}
    isHalf={true}
    emptyIcon={<i className="far fa-star"></i>}
    halfIcon={<i className="fa fa-star-half-alt"></i>}
    fullIcon={<i className="fa fa-star"></i>}
    activeColor="#ffd700"
  />

  </div>




                                                 
                                                <textarea style={{height: "100px"}} cols="40" rows="2" placeholder={t('Votre commentaire')+'...'} name='message' className='form-control' required></textarea>                                             
                                            </div>
                                        </div>
                                 <div className="row">
                                            <div className="col-sm-12">
                                            
                                                 <button type="submit" className="btn float-btn color2-bg btn9876 "> {t('Noter le watcher')} <i className="fas fa-caret-right"></i></button>
                                            </div>
                               </div>     
                   </div>

          </form>
         
           

        </Modal.Body>
        <Modal.Footer>
          
         
        </Modal.Footer>
</Modal>

        </div>
    );
}

export default Page;