import React, { useEffect, useState } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap"
import { useParams } from "react-router";
import { useHistory } from "react-router";
import { BASE_URL, BASE_URL_PROD } from "../../../components/config/keys";
import axios from "axios";



const Page = () => {
  const { keyword: gottenKeyword } = useParams();
  const [keyword, setkeyword] = useState(gottenKeyword)
  const history = useHistory()
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const { user } = useSelector(state => state.user)
  const [langset, setLangset] = useState(false);

  const [image1, setImageName1] = useState("");
  const [image2, setImageName2] = useState("");
  const [image3, setImageName3] = useState("");

  const [video1, setVideoName1] = useState("");
  const [video2, setVideoName2] = useState("");
  const [video3, setVideoName3] = useState("");


  const handleGoTo = () => {
    localStorage.setItem('image1', image1)
    localStorage.setItem('image2', image2)
    localStorage.setItem('image3', image3)

    localStorage.setItem('video1', video1)
    localStorage.setItem('video2', video2)
    localStorage.setItem('video3', video3)
    history.push('/recherche-artisan#');
  }

  const handlereturn = () => {
    history.push('/quel-est-votre-besoin-2/'+localStorage.getItem('service_id'));
  }

  const onChangeFile = (e) => {
		let files = e.target.files || e.dataTransfer.files;

		if (!files.length)
		return;

    if(files[0].type=='image/png' || files[0].type=='image/jpeg' || files[0].type=='image/jpg' || files[0].type=='image/gif' ){
      if(files[0].size <= (2*1000000)){
          createImage(files[0], e.target.name);
      }
      else{
          alert('Votre pièce-jointe ne doit  pas dépasser cette taille: 2 MO.');
      }
    }
    else{
        alert("Ce Format document n'est pas supporté ! Les formats acceptés sont (JPG,JPEG,PNG,GIF)");
    }
	}

	function createImage(file, tag_name) {
		// $('#divLoad').show()
		let reader = new FileReader();
		reader.onload = (e) => {
		  fileUpload(e.target.result, tag_name);
		};
		reader.readAsDataURL(file);
	}

	function fileUpload (values, tag_name){
			var self = this;
		    axios.post(BASE_URL+'/insertimage', {'file':values, 'type':'images_fuites'}, { headers: {"Accept": "application/json",
	                "Content-Type": "application/json"}}).then((res) => {
                    if(tag_name == 'image1')
                      setImageName1(res.data.name)
                    else if(tag_name == 'image2')
                      setImageName2(res.data.name)
                    else if(tag_name == 'image3')
                      setImageName3(res.data.name)
                // this.setState({
                // 	[tag_name]: res.data.name
                // })

                // $('#divLoad').hide()
			}).catch((e)=>{
		});
	}
  const onChangeFileVideo = (e) => {
		let files = e.target.files || e.dataTransfer.files;

		if (!files.length)
		return;
    if(files[0].type=='video/x-ms-wmv' || files[0].type=='video/mp4' ){
      if(files[0].size <= (30*1000000)){
          createVideo(files[0], e.target.name);
      }
      else{
          alert('Votre pièce-jointe ne doit  pas dépasser cette taille: 30 MO.');
      }
    }
    else{
        alert("Ce Format document n'est pas supporté ! Les formats acceptés sont (JPG,JPEG,PNG,GIF)");
    }
	}
	function createVideo(file, tag_name) {
		// $('#divLoad').show()
		let reader = new FileReader();
		reader.onload = (e) => {
		  fileVideo(e.target.result, tag_name);
		};
		reader.readAsDataURL(file);
	}
	function fileVideo(values, tag_name){
			var self = this;
		    axios.post(BASE_URL+'/insertimage', {'file':values, 'type':'videos_fuites'}, { headers: {"Accept": "application/json",
	                "Content-Type": "application/json"}}).then((res) => {
                    if(tag_name == 'video1')
                      setVideoName1(res.data.name)
                    else if(tag_name == 'video2')
                      setVideoName2(res.data.name)
                    else if(tag_name == 'video3')
                      setVideoName3(res.data.name)
                // this.setState({
                // 	[tag_name]: res.data.name
                // })

                // $('#divLoad').hide()
			}).catch((e)=>{
		});
	}

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    const formDataObj = Object.fromEntries(formData.entries())

  }


  const componentClicked = () => {


  }





  useEffect(() => {

    if (langset == false) {
      setLangset(true)
    }



  }, [])

  return (
    <div className="besoin  " >
      <section className="gray-bg big-padding " id="besoin3">

        <div className="container small-container">
          <form className='registerform'>

            <div className='row '>
              <div className='col-md-12'>
                <div className='progressbare'>
                  <div className='veh' style={{ left: '55%' }}><img src='/images/Groupe6849.png' /></div>
                  <div className="progressvalue" style={{ width: '60%' }}></div>
                  <div className='bat' ><img src='/images/Groupe6903.png' /></div>
                </div>
              </div>
            </div>


            <div className='row '>
              <div className='col-md-12'>



                <div >


                  <div className="section-title"><h2><span>Ajoutez des photos ou envoyez des vidéos <b>(facultatif)</b>. </span></h2></div>

                  <div className='row'>
                    <div className='col-md-8'>
                      <ul className='photocadre'>
                        {
                          image1 != '' ? 
                          <li className='imgbg' style={{backgroundImage:"url("+BASE_URL_PROD+'/admin/public/img/images_fuites/'+image1+")", backgroundSize:"contain",backgroundPosition:'center'}}><input className="inputFileInvis" name="image1" type="file" onChange={onChangeFile}/></li>
                          :
                          <li className='imgbg' style={{cursor:'pointer'}}><input style={{cursor:'pointer'}} className="inputFileInvis" name="image1" type="file" onChange={onChangeFile}/></li>  
                        }
                        {
                          image2 != '' ? 
                          <li className='imgbg' style={{backgroundImage:"url("+BASE_URL_PROD+'/admin/public/img/images_fuites/'+image2+")", backgroundSize:"contain",backgroundPosition:'center'}}><input className="inputFileInvis" name="image2" type="file" onChange={onChangeFile}/></li>
                          :
                          <li className='imgbg' style={{cursor:'pointer'}}><input style={{cursor:'pointer'}} className="inputFileInvis" name="image2" type="file" onChange={onChangeFile}/></li>  
                        }
                        {
                          image3 != '' ? 
                          <li className='imgbg' style={{backgroundImage:"url("+BASE_URL_PROD+'/admin/public/img/images_fuites/'+image3+")", backgroundSize:"contain",backgroundPosition:'center'}}><input className="inputFileInvis" name="image3" type="file" onChange={onChangeFile}/></li>
                          :
                          <li className='imgbg' style={{cursor:'pointer'}}><input style={{cursor:'pointer'}} className="inputFileInvis" name="image3" type="file" onChange={onChangeFile}/></li>  
                        }
                      </ul>

                      <ul className='photocadre'>
                        <li className='videobg' style={{cursor:'pointer'}}><input style={{cursor:'pointer'}} className="inputFileInvis" name="video1" type="file" onChange={onChangeFileVideo}/>
                        {video1 != '' ?"Vidéo ajoutée" : ''}
                        </li>
                        <li className='videobg' style={{cursor:'pointer'}}><input style={{cursor:'pointer'}} className="inputFileInvis" name="video2" type="file" onChange={onChangeFileVideo}/>
                        {video2 != '' ?"Vidéo ajoutée" : ''}</li>
                        <li className='videobg' style={{cursor:'pointer'}}><input style={{cursor:'pointer'}} className="inputFileInvis" name="video3" type="file" onChange={onChangeFileVideo}/>
                        {video3 != '' ?"Vidéo ajoutée" : ''}</li>
                      </ul>
                    </div>

                    <div className='col-md-4'>
                      <img style={{ width: '238px' }} src='/images/icones/simulateerpng.png' />
                    </div>


                  </div>



                  <div className='mrg78'></div>





                  <br />




                  <div style={{ textAlign: 'left', marginBottom: '30px', color: '#73777b' }}>
                    <a href="#" onClick={() => handleGoTo()} className="btn btnform w550 "> Valider </a>
                  </div>

                  <br /><br />

                  <div style={{ textAlign: 'left', marginBottom: '30px', color: '#73777b' }}>
                    <a href='#' onClick={() => handlereturn()} className="btn btnform w200 btngreen"> {'<<'} Etape précédente </a>
                  </div>

                </div>

                <div className='col-md-3 center'>

                </div>



              </div>



            </div>

          </form>


        </div>




        <div className='sect2' style={{ background: "#e9f5f6", padding: '0px' }}>
          <div className="container  small-container">
            <div className="process-wrap fl-wrap commentcafonctionne "><div className="default-desc margright mrgtop"><p>&nbsp;</p>
              <ul className="no-list-style row bsy77">



                <li className="col-md-4">
                  <div className="process-item">
                    <div className="process-img">
                      <img src='/images/iconedashboard.png' />
                    </div>

                    <p className='basecolor'>Suivez votre intervention, vos factures et devis dans votre espace dédié.</p>
                  </div>
                </li>

                <li className="col-md-4">
                  <div className="process-item">
                    <div className="process-img">
                      <img src='/images/iconescanner.png' />
                    </div>

                    <p className='basecolor'>Scannez le QR code de l'artisan ou demandez lui le code d'intervention pour vous assurez d'être en face du bon artisan de Proximiti.</p>
                  </div>
                </li>

                <li className="col-md-4">
                  <div className="process-item">
                    <div className="process-img">
                      <img src='/images/iconecarte.png' />
                    </div>

                    <p className='basecolor'>Payez vos intervention selon votre budget en 2x ou 3x.</p>
                  </div>
                </li>

              </ul>
            </div>
            </div>
          </div>
        </div>
        <br /><br /> <br /><br /><br />
        <div className='sect3'>
          <div className="container small-container ">

            <div className='row'>
              <div className='col-md-12'>
                <div className="section-title"><h5 style={{ color: "#073460" }}><span>Une image vaut mille mots !</span></h5>
                  <br />
                  <p className="" style={{ maxWidth: '100%', fontWeight: 'normal', fontSize: '13px' }}>Des photos ou des vidéos apporteront plus de précisions à l'artisan. Elles pourront faciliter son diagnostic avant intervention. Un gain de temps pour tous !</p></div>

              </div>
            </div>

            <br /> <br />
            <div className='cadrbs1'>

              <div className="process-wrap fl-wrap commentcafonctionne "><div className="default-desc margright mrgtop"><p>&nbsp;</p>
                <ul className="no-list-style row">



                  <li className="col-md-4">
                    <div className="process-item">
                      <div className="process-img">
                        <img src='/images/Iconeartisan.png' />
                      </div>

                      <p className='basecolor'>Nos artisans sont certifiés et labellisés.</p>
                    </div>
                  </li>

                  <li className="col-md-4">
                    <div className="process-item">
                      <div className="process-img">
                        <img src='/images/iconechrono.png' />
                      </div>

                      <p className='basecolor'>Traitement rapide des demandes.</p>
                    </div>
                  </li>

                  <li className="col-md-4">
                    <div className="process-item">
                      <div className="process-img">
                        <img src='/images/IconeIA.png' />
                      </div>

                      <p className='basecolor'>Un algorithme de matching client-dépanneur en fonction de la proximité et de l’incident.</p>
                    </div>
                  </li>

                </ul>
              </div>
              </div>

            </div>

          </div>

        </div>



      </section>

    </div>
  );
}

export default Page;