import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button ,Modal,CloseButton } from "react-bootstrap"
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";

const Page = () => {
    useEffect(() => {
      localStorage.setItem('autre_services_data', '')
    }, [])
  
    return (
      <div className="autresservicesucces" >
        <section className="gray-bg big-padding " id="">
          <div className="container small-container ">
            <div className='registerform'>
              <div className='row '>
                <div className='col-md-12' style={{ textAlign:"center" }}>
                      <img src="/images/6376884.png" style={{ width:'150px' }} />
                      <br/>
                      <h3 style={{ color:"green" }}>Vous avez réserver votre intervention avec succès.</h3>
                </div>  
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Page;