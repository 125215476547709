import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card,Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenu,DashbordHeader } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"
import { getAllCategories, getAllMissions,getAllMissionsv2, saveMessage, getMessages, setAsReaded,getCountUnreadMsg } from "../../../actions/pages";
import { updateInfoUser } from "../../../actions/user";

const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { data } = useSelector(state => state.pages)
    const { all_missions, all_messages, all_sent_messages } = useSelector(state => state.pages)
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const [status, setStatus] = useState('');
    const [categories, setCategories] = useState([]);
    const [categ, setCateg] = useState('');
    const [rib, setRib] = useState('');
    const [interventionId, setIntervention] = useState();
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [intervention_error, setInterventionError] = useState(false);
    const [message_error, setMessageError] = useState(false);
    const [succesSave, setSuccesSave] = useState(-1);
    const [messageType, setMessageType] = useState(2);


    const [show, setShow] = useState(false);
    const [showDetail, setShowDetail] = useState(false);

    
    const [detailTitle, setDetailTitle] = useState('');
    const [detailBody, setDetailBody] = useState('');

    const handleClose = () => setShow(false);
    const handleCloseDetail = () => setShowDetail(false);

    const [tab, setTab] = useState(1);

    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
     const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        let go=true;

   


        if(go){
           dispatch(updateInfoUser(formDataObj,user.id,'watcher'));
        }
        
    
    }

    const showMessageDetail = (ind) => {
      setShowDetail(true)
      setDetailTitle('')
      setDetailBody('')
      if(messageType == 2){
        setDetailTitle(all_messages[ind].ref)
        setDetailBody(all_messages[ind].msg)

        if(all_messages[ind].status == 0){
          dispatch(setAsReaded(all_messages[ind].id)).then(() => {
            dispatch(getCountUnreadMsg(user.id))
            dispatch(getMessages(user.id, 2))
          })
        }
      }else{
        setDetailTitle(all_sent_messages[ind].ref)
        setDetailBody(all_sent_messages[ind].msg)
      }
    }

const changeIntervention = (e) => {

  setIntervention(e.target.value);
}
const changeMessage = (e) => {

  setMessage(e.target.value);
}

const changeTypeMessage = (valeur) => {
  setMessageType(valeur)
}
     const settab1= (e) => {
        e.preventDefault();

           dispatch(getMessages(user.id, 2))
        dispatch(getMessages(user.id, 1))
        setTab(1)
    }

    const showNewMsg = () => {
      setShow(true)
    }

    const settab2= (e) => {
        e.preventDefault();
        dispatch(getAllMissionsv2(user.id, status))
        setTab(2)
    }

    const sendMessage = () => {
      setInterventionError(false)
      setMessageError(false)
      if(interventionId == '' || interventionId == 0 || interventionId == undefined)
        setInterventionError(true)
      if(message == '' || message == undefined)
        setMessageError(true)
      dispatch(saveMessage(user.id, interventionId, message, 1)).then((res) => {

          if(res.success){
            window.location.reload()
            setSuccesSave(1)
            dispatch(getMessages(user.id, 1))
          }
          else
            setSuccesSave(0)           
      })
    }

    const changeStatus= (e) => {
      setStatus(e.target.value);
      dispatch(getAllMissionsv2(user.id, e.target.value))
  }
    useEffect(() => {
        dispatch(getAllMissionsv2(user.id, status))
        dispatch(getMessages(user.id, 2))
        dispatch(getMessages(user.id, 1))
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])

    
     const handleSetCategorie = (categ) => {
            
            let newcategories=[...categories];
            var index = newcategories.indexOf(categ)
            if( index > -1){
               newcategories.splice(index, 1);
               setCategories(newcategories);
 
            }
            else{

               newcategories.push(categ);
               setCategories(newcategories);

            }            
    }

      const goToDetails = (id) => {
        history.push("/detail/mission/pro/"+id)
      }

      const createPdf = (file) => {
      
      setLoading(true)
      const data = new FormData();
      data.append('membreId', user.id);
      data.append('file', file);
      data.append('type', 'ribs');
      apiClient().post(`/insertimage`,data)
            .then((res) => {
               setRib(res.data.name)  
               setLoading(false)             
            })

  }

    const uploadPdf = (e) => {
        

    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
    return;

      if(files[0].type=='application/pdf'  ){
              if(files[0].size <= (2*1000000)){
                 createPdf(files[0]);
              }
              else{

                 alert(t('Votre pièce-jointe ne doit pas dépasser cette taille: 2 MO.'));
              }
      }
      else{
         alert(t("Ce format n'est pas supporté ! Le format accepté est (PDF)"));
      }

        const param = {
                id:user.id,
              //files_img:this.state.files,
            }
    }

    return (
        <div className="myccount editprofil messages  " >
            
                       <section className="gray-bg main-dashboard-sec dashboard myccount history" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenu src='profil'/>
                                 
                            </div>


                         <div className='col-md-9 dashbordassitantcontent' >

                             <div className='tab' id=''>


                              <DashbordHeader src={'w'} />


                            <div className='ffform'>

                           

                             <div className='row ' style={{marginTop:'30px'}}>
                               <div className='col-md-3' style={{paddingLeft:'0px'}}>
                               <div>

                                  <div className='rowtitel' style={{    marginLeft: '10px'}}>
                                  
                                  <img src='/images/icones/iconemessagerie.png' />
                                  <h3 className='htri1'>Messages </h3>

                                  </div>
                                  <div className='btn11'>
                                          <a href='javascript:void(0)' onClick={showNewMsg} ><img src="/images/icones/iconeplusrajouter.png"/>Nouveau message</a>
                                  </div>

                               

                                  <div className='msgset1'>
                                     
                                      <ul>

                                       <li style={{cursor:'pointer'}} className={messageType == 2 ? 'active':''} onClick={() => changeTypeMessage(2)}>
                                         <div className='img'><img src='/images/icones/open-inbox.png' /></div>
                                         <div className='txt'> Boîte de réception</div>
                                         <span className='nmb'>({all_messages != undefined ? all_messages.length : 0})</span>
 
                                       </li>

                                       <li style={{cursor:'pointer'}} className={messageType == 1 ? 'active':''} onClick={() => changeTypeMessage(1)}>
                                         <div className='img'><img src='/images/icones/envoyer-1.png' /></div>
                                         <div className='txt'> Messages envoyés</div>
                                         <span className='nmb'>({all_sent_messages != undefined ? all_sent_messages.length : 0})</span>
 
                                       </li>

                                        <li className=''>
                                         <div className='img'><img src='/images/icones/trash-alt.png' /></div>
                                         <div className='txt'>Messages supprimés</div>
                                         <span className='nmb'>(0)</span>
 
                                       </li>

                                         <li className=''>
                                         <div className='img'><img src='/images/icones/iconebrouillon.png' /></div>
                                         <div className='txt'> Brouillons</div>
                                         <span className='nmb'>(0)</span>
 
                                       </li>
                                          <li className=''>
                                         <div className='img'><img src='/images/icones/md-archive.png' /></div>
                                         <div className='txt'> Messages archivés</div>
                                         <span className='nmb'>(0)</span>
 
                                       </li>


                                     </ul>



                                  </div>

                                 


                               </div>
                                

                             
                                 
                      

 

                                    

                               </div>
                               <div className='col-md-9'>


                                <div className='sect2'>

                                   <a style={{cursor:'pointer'}} onClick={settab1} href="javascript:void(0)" className={tab==1 && 'active'} >Messages</a>
                                   <a style={{cursor:'pointer'}}  onClick={settab2} className={tab==2 && 'active'} href="javascript:void(0)" >Missions</a>

                                </div>

                                <div className='flx889'>
                                          <div className="filter-tags ft-list" style={{    marginLeft: '9px'}}> 
                                                                 
                                                                {/* <input id="check-a2" type="checkbox" name="check"  />
                                                                <label for="check-a2">Tout sélectionner</label> */}
                                                          
                                           </div>

                                           <div style={{    display: 'flex'}}>
                                             {
                                                tab==2 &&
                                                <select name="type" onChange={changeStatus} required="" className="selectform bgselect">
                                                    <option value="">Statut</option>
                                                    <option value="0">Nouveau</option>
                                                    <option value="4">En attente</option>
                                                    <option value="1">En Cours</option>
                                                    <option value="2">Terminé</option>
                                                    <option value="3">Annulé</option>
                                                </select>


                                             }

                                             <select name="type" required="" className="selectform bgselect"><option value="">Trier par</option></select>


                                           </div>
                                </div>

                                

                               <div className='contentmessages'>

                               {

                                 tab==1 ? 

                                 <ul>
                                 {
                                  messageType == 1 ?
                                  (
                                   all_sent_messages != undefined && all_sent_messages.length > 0 ?
                                   (
                                    all_sent_messages.map((msg, index) => (
                                  <li>
                                    {/* <div className='f1'> <div className="filter-tags ft-list"> 
                                                    <input id="check-a2" type="checkbox" name="check"  /></div>
                                   </div> */}
                                    <div className='f2'> <strong>Ticket {msg.ref} </strong>- {msg.msg}...</div>
                                    <div className='f3'>{msg.date}</div>
                                    {/* <div className='f4'><a href='javascript:void(0)'><img src='/images/icones/pointsmenudépliant.png' /></a></div> */}
                                    <div className='f4'><div className='statut st1' onClick={() => showMessageDetail(index)} style={{cursor:'pointer', textAlign:"center"}}>Voir</div></div>

                                  </li>
                                     ))
                                   )
                                   :
                                     <li>
                                       <h3 style={{width:'100%', textAlign:"center"}}>Aucun Message</h3>
                                    </li>
                                  )
                                  :
                                  (
                                    all_messages != undefined && all_messages.length > 0?
                                    (
                                      all_messages.map((msg, index) => (
                                   <li>
                                     {/* <div className='f1'> <div className="filter-tags ft-list"> 
                                                     <input id="check-a2" type="checkbox" name="check"  /></div>
                                    </div> */}
                                     <div className='f2' style={{width:'60%'}}> <strong>Ticket {msg.ref} </strong>- {msg.msg}...</div>
                                     <div className='f3' style={{width:'10%'}}>{msg.date}</div>
                                    <div className='f3'></div>
                                    <div className='f4' style={{width:'15%'}}><div className='statut st2' style={{textAlign:"center"}}>{msg.status == 0 ? 'Non lu':'lu'}</div></div>
                                    <div className='f4' style={{width:'15%'}}><div className='statut st1' onClick={() => showMessageDetail(index)} style={{cursor:'pointer', textAlign:"center"}}>Voir</div></div>

                                   </li>
                                      ))
                                    )
                                    :
                                      <li>
                                        <h3 style={{width:'100%', textAlign:"center"}}>Aucun Message</h3>
                                     </li>
                                  )
                                    } 
                                                   

                                 </ul>

                                 :

                                 <ul className='missionscnt'>
                                  {
                                    all_missions != undefined && all_missions.length > 0 ?
                                    (
                                      all_missions.map((mission) => (
                                        <li onClick={() => goToDetails(mission.id)}>
                                            
                                             {/* <div className='f1'> <div className="filter-tags ft-list"> 
                                                             <input id="check-a2" type="checkbox" name="check"  /></div>
                                             </div> */}

                                              {
                                              mission.status == 4 &&
                                                <div className='f4' ><div className='statut st2' style={{background:'#e99f28'}}>En attente</div></div>

                                              }

                                             
                                             <div className='f2' style={{    width: '73%'}}> <strong>Ticket intervention {mission.ref}</strong>  - {mission.label}</div>
                                            
                                            {
                                              mission.status == 0 ?
                                                <div className='f4'><div className='statut st2'>Nouveau</div></div>
                                              :
                                              (
                                                mission.status == 1 ?
                                                <div className='f4'><div className='statut st1'>En cours</div></div>
                                                :
                                                (
                                                  mission.status == 2 ?
                                                  <div className='f4'><div className='statut st4'>Terminé</div></div>
                                                  :
                                                  (
                                                    mission.status == 3 ?
                                                    <div className='f4'><div className='statut st3'>Annulé</div></div>
                                                    :
                                                    ""
                                                  )
                                                )
                                              )
                                            }
                                             <div className='f3'>{mission.date_mission}</div>
                                             
                                            
                                         </li>
                                      ))
                                    )
                                    :
                                      <li>
                                        <h3 style={{width:'100%', textAlign:"center"}}>Aucune mission trouvée</h3>
                                     </li>
                                  }

                                 </ul>
                               }

                                 
 


                               </div>
                                
                            

                           
                               
                                 

                                </div>

                             </div>
                           </div>

                                 
                              </div>
 

                              

                         </div>
                    </div>
            </section>

            <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                        Nouveau Message
                  </Modal.Header>
                  <Modal.Body>


                  <select name="type" onChange={changeIntervention} required="" className="selectform bgselect">
                      <option value="">Choisir Intervention</option>
                      {
                                    all_missions != undefined && all_missions.length > 0 ?
                                    (
                                      all_missions.map((mission) => (
                                        mission.status > 0 ?
                                        <option value={mission.id}>{mission.nom+' '+mission.prenom} ({mission.ref} {mission.label})</option>
                                        :
                                        ''
                                      ))
                                  )
                                  :
                                  ''
                      }
                  </select>
                  
                  {
                      intervention_error ?
                      <div class="alert alert-danger custom-form col-md-12" style={{clear:'both'}} role="alert">
                        Veuillez choisir une mission
                      </div>
                      :
                      ''
                  }
                  <textarea  className="form-control" placeholder="Message" onChange={changeMessage}></textarea>
                    <br/>
                  {
                      message_error ?
                      <div class="alert alert-danger custom-form col-md-12" style={{clear:'both'}} role="alert">
                        Veuillez saisir votre message
                      </div>
                      :
                      ''
                  }
                  {
                    succesSave == 0 ?
                    <div class="alert alert-danger col-md-12" style={{clear:'both', marginTop:'5px'}} role="alert">
                        Un problème est survenu lors de l'enregistrement !
                    </div>
                    :
                    ""
                  }
                  {
                    succesSave == 1 ?
                    <div class="alert alert-success col-md-12" style={{clear:'both', marginTop:'5px'}} role="alert">
                      Message envoyé avec succès !
                    </div>
                    :
                    ""
                  }
                  </Modal.Body>
                  <Modal.Footer>
                    
                    <Button variant="success" onClick={sendMessage}>
                               Envoyer
                    </Button>
                  </Modal.Footer>
          </Modal>
            <Modal show={showDetail} onHide={handleCloseDetail}>
                  <Modal.Header closeButton>
                        Ticket {detailTitle}
                  </Modal.Header>
                  <Modal.Body>
                  {detailBody}
                  </Modal.Body>
          </Modal>
        </div>
    );
}

export default Page;