import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button ,Modal,CloseButton } from "react-bootstrap"
import { AssistantItem } from "../../../../components";
import { AvisItem } from "../../../../components";
import { getListWatcher } from "../../../../actions/user";
import { useHistory } from "react-router";
import { BASE_URL_PROD } from "../../../../components/config/keys";
import { surpls,epr } from "../../../../assets/images";
import { useTranslation } from 'react-i18next';
 

const Page = () => {
  const autre_services_data = JSON.parse(localStorage.getItem('autre_services_data'));
    const dispatch = useDispatch();
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const [frequence, setFrequence] = useState(autre_services_data.frequence);
    const [formattedDate, setFormatedDate] = useState('');
    const [frequenceError, setFrequenceError] = useState(0);

    useEffect(() => {
      const dateObj = new Date(autre_services_data.date);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const formattedDate = dateObj.toLocaleDateString('fr-FR', options);
      setFormatedDate(formattedDate)
    }, [])
  
    const handleGoBack = () => {
      history.push('/coiffure/date');
    };

    const handleGoTo = () => {
      setFrequenceError(0)
      if(frequence == '' || frequence == undefined){
        setFrequenceError(1)
      }else{
        autre_services_data.formattedDate = formattedDate
        autre_services_data.frequence = frequence
        localStorage.setItem('autre_services_data', JSON.stringify(autre_services_data))
        history.push('/coiffure/heure');
      }
    };
    
    const handleFrequence = element => {
      setFrequence(element);
    };
    return (
      <div className="coiffure5" >
        <section className="gray-bg big-padding " id="besoin1">
          <div className="container small-container ">
            <div className='registerform'>
              <div className='row '>
                <div className='col-md-8'><p onClick={()=>handleGoBack()} style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>&lt;	&lt;	Retour</p></div>
                <div className='col-md-8'>
                  <div className="section-title"><h2><span>Fréquence des rendez-vous coiffure ? </span></h2>
                  </div>
                  <div onClick={() => handleFrequence(1)} className={frequence === 1 ? 'custom-form col-md-4 boxShadow blocfrequence blocfrequenceActive' : 'custom-form col-md-4 boxShadow blocfrequence'}><p className="p1">1 seule fois</p><p>&nbsp;</p></div>
                  <div onClick={() => handleFrequence(2)} className={frequence === 2 ? 'custom-form col-md-4 boxShadow blocfrequence blocfrequenceActive' : 'custom-form col-md-4 boxShadow blocfrequence'}><p className="p1">1 fois</p><p className="p2">par mois</p></div>
                  <div onClick={() => handleFrequence(3)} className={frequence === 3 ? 'custom-form col-md-4 boxShadow blocfrequence blocfrequenceActive' : 'custom-form col-md-4 boxShadow blocfrequence'}><p className="p1">2 fois</p><p className="p2">par mois</p></div>

                  {
                    frequenceError == 1 ?
                    <div className='col-md-9' style={{ paddingLeft:"0", paddingTop:'30px', clear:'both' }}>
                      <div className="alert alert-danger" role="alert">
                        Veuillez choisir une fréquence
                      </div>
                    </div>
                    :
                    ""
                  }
                  <div className='col-md-9' style={{ paddingLeft:"0", marginTop:'30px' , clear:'both'}}>
                    <div style={{textAlign:'left',marginBottom:'30px'}}>
                      <a onClick={()=>handleGoTo()} class="btn btnform w200 "> Continuer </a>
                    </div>
                  </div>
                </div>    
                <div className='col-md-4'>
                    <div className="header1 boxShadow">Recapitulatif</div>
                    <div className="body1 boxShadow">
                      <p style={{ margin:'0px', paddingLeft:'10px' }}>1 intervention coiffure</p>
                      <hr style={{ margin:'10px' }}></hr>
                      <p style={{ margin:'0px', paddingLeft:'10px' }}><img style={{ width:'16px' }} src="/images/services/icone_detail.png"/> Détails de l'intervention</p>
                      {
                        autre_services_data.genreText != '' ?
                        <ul>
                          <li>{autre_services_data.genreText}</li>
                          <li>{autre_services_data.qte} {autre_services_data.qte > 1 ? 'personnes':'personne'}</li>
                          <li>{autre_services_data.categorieText}</li>
                        </ul>
                        :
                        ''
                      }
                      <hr style={{ margin:'10px' }}></hr>
                      <p style={{ margin:'0px', paddingLeft:'10px' }}><img style={{ width:'16px' }} src="/images/services/icone_frequence.png"/> Rendez-vous</p>
                      <ul style={{ marginBottom:'0px' }}>
                      <li>{formattedDate}  </li>
                      </ul>            
                      {
                        frequence != '' ?
                        <ul>
                          {
                            frequence === 1 ?
                            <li>Fréquence : 1 seule fois</li>
                            :
                            (
                              frequence === 2 ?
                              <li>Fréquence : 1 fois par mois</li>
                              :
                              (
                                frequence === 3 ?
                                <li>Fréquence : 2 fois par mois</li>
                                :
                                ''
                              )
                            )
                          }
                        </ul>
                        :
                        ''
                      }
                    </div>
                </div>  
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Page;