import React, { useEffect,useState } from "react";
import './styles.scss'; 
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { AssistantItem } from "../../components";
import { useParams } from "react-router";
import { apiClient } from "../../actions/api"
import Slider from '@material-ui/core/Slider';
import { useTranslation } from 'react-i18next';
import { saveMission } from "../../actions/pages";
const Page = () => {
    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [price, setPrice] =  useState(10);
    const [minprice, setMinprice] =  useState(10);
    const { user,watchersList } = useSelector(state => state.user)
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);

    const categoryitem = localStorage.getItem("categoryitem");
    const villeitem = localStorage.getItem("villeitem");


    

    // Changing State when volume increases/decreases
    const rangeSelector = (event, newValue) => {
      setPrice(newValue);
      
    };


    const handlerConnect = (e) => {
     e.preventDefault();

     const formData = new FormData(e.target)
     const formDataObj = Object.fromEntries(formData.entries())
     apiClient().post(`/save/newsletter`,formDataObj)
            .then((res) => {
               if(res.data.success==true){
                   window.location.href='/'
               }
                      
      })

      
    }
 
    const handleSubmit = (e) => {
        e.preventDefault();
       
         let go=true;

         if(!categoryitem || !villeitem ){
            go=false;
            window.location.href='/';
          }

          if(price < minprice ){
              alert(t('Le prix minimum imposé est : ')+minprice+'€');
              return false;
          }


            const formData = new FormData(e.target)
            const formDataObj = Object.fromEntries(formData.entries())

          if(user.id){
           
            dispatch(saveMission(formDataObj,price,categoryitem,villeitem,user.id,user.emailToken,currentlang));
            setShow(true)
          }
          else{
          
              // store data in session
              localStorage.setItem("formDataObj_mission",JSON.stringify(formDataObj))
              localStorage.setItem("price_mission",price) 
              localStorage.setItem("categoryitem_mission",categoryitem) 
              localStorage.setItem("villeitem_mission",villeitem) 
         
               window.location.href='/login'

              //alert(t('Veuillez vous connecter à votre espace client !'));
            
          }

        

    }

     const handleGoTo = (e) => {
        e.preventDefault();
        window.location.href='/mon-compte'
    }

     useEffect(() => {
        if(langset == false){

          apiClient().get(`/get/price`,{})
            .then((res) => {
               if(res.data.success==true){
              
                 setPrice(Number(res.data.data))
                 setMinprice(Number(res.data.data)) 
               }
                      
            })


            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 
 

    }, [])
   
    return (
        <div className="listing" >
           <section className="gray-bg small-padding no-top-padding-sec" id="sec1">
                <div className="container">
                    <div className="fl-wrap">
                        <div className="row">

                         <div className="col-md-4">
                                        <div className="fl-wrap lws_mobile tabs-act block_box vishidelem visiblelist">
                                            <div className="filter-sidebar-header fl-wrap" id="filters-column">
                                                <ul className="tabs-menu fl-wrap no-list-style">
                                                    <li className="current"><a href="#filters-search"> <i className="fal fa-check"></i>{t('Finalisez votre recherche ci-dessous')} </a></li>
                                                    <li className="" style={{display:"none"}}><a href="#category-search"> <i className="fal fa-sliders-h"></i> Filters</a></li>
                                                </ul>
                                            </div>
                                            <div className="scrl-content filter-sidebar    fs-viscon">
                                                                       
                                                <div className="tabs-container fl-wrap">
                                                    
                                                    <div className="tab">
                                                        <div id="filters-search" className="tab-content  first-tab " >
                                                            

                                                    <form  onSubmit={handleSubmit}  className="add-comment custom-form">
                                                    <fieldset>
                                                        <label><i className="far fa-bookmark"></i> </label>
                                                        <input required type="text" name="adresse" placeholder={t('Adresse ou Nom du lieu') + "*"} />
                                                        <div className="clearfix"></div>
                                                          <textarea style={{marginBottom:"20px"}} required name='description' cols="40" rows="2" placeholder={t('Que souhaitez vous faire ? Décrivez ce que dois réaliser l’assistant...')}></textarea>
                                                    
                                                        
                                                        <div className='blockbg55'>
                                                           <i className="fal fa-calendar"></i>
                                                           <div className='x778'>{t('Choisissez une durée approximative')}*</div>
                                                           <div class="mb-3 row"><div class="col"><div class="form-check"><input required name="duree" type="radio" class="form-check-input" value="15mn"/><label title="" class="form-check-label"> 15mn</label></div></div><div class="col"><div class="form-check"><input required name="duree" type="radio" class="form-check-input" value="30mn"/><label title="" class="form-check-label">30mn</label></div></div></div>
                                                           <div class="mb-3 row"><div class="col"><div class="form-check"><input required name="duree" type="radio" class="form-check-input" value="45mn"/><label title="" class="form-check-label">45mn</label></div></div><div class="col"><div class="form-check"><input  required name="duree" type="radio" class="form-check-input" value="1h"/><label title="" class="form-check-label">1h</label></div></div></div>

                                                        </div>

                                                        <div className="listsearch-input-item clact date-container2">
                                                            <label><i className="fal fa-calendar"></i></label>
                                                            <input required type="text" placeholder={t('Date souhaitée')+"*"} autocomplete="off" name="datepicker-here-time" />
                                                            <span className="clear-singleinput"><i className="fal fa-times"></i></span>
                                                        <div className="daterangepicker ltr single opensright"><div className="ranges"></div><div className="drp-calendar left single" style={{display: "block"}}><div className="calendar-table"></div><div className="calendar-time"></div></div><div className="drp-calendar right" style={{display: "none"}}><div className="calendar-table"></div><div className="calendar-time"></div></div><div className="drp-buttons"><span className="drp-selected"></span><button className="cancelBtn btn btn-sm btn-default" type="button">Clear</button><button className="applyBtn btn btn-sm btn-primary" disabled="disabled" type="button">{t('Appliquer')}</button> </div></div></div>
                                                            <div className='blockbg55'>
                                                           <i className="fal fa-euro-sign"></i>
                                                           <div >{t('Prix minimum')}* {" "}({price}€)</div>
                                                             <Slider value={price} onChange={rangeSelector} valueLabelDisplay="auto" min={10} step={1} max={200} valueLabelFormat={value => <div>{value}€</div>} />
 
                                                        </div>

                      

                                                      
                                                    </fieldset>

                                                      <div className="listsearch-input-item">
                                                        {
                                                          watchersList.length>=1 &&
                                                          <button type="submit"  className="toggle-mission-btn tsb_act "  ><i className="fal fa-clock"></i> <span>{t('Envoyer ma demande')}</span></button>

                                                        }
                                                                
                                                      </div>
                                                        

                                                    
                                                </form>                                    
                                                            
                                                        </div>
                                                    </div>
                                                   
                                                    <div className="tab">
                                                        <div id="category-search" className="tab-content disnone"  >
                                                                   <div className="listsearch-input-item">
                                                                <span className="iconn-dec"><i className="far fa-bookmark"></i></span>
                                                                <input type="text" placeholder="What are you looking for ?" value="" />
                                                            </div>
                                                           
                                                           <div className="listsearch-input-item">
                                                                <select data-placeholder="Categories" className="chosen-select no-search-select" style={{display:"none"}}  >
                                                                    <option>All Categories</option>
                                                                    <option>Shops</option>
                                                                    <option>Hotels</option>
                                                                    <option>Restaurants</option>
                                                                    <option>Fitness</option>
                                                                    <option>Events</option>
                                                                </select><div className="nice-select chosen-select no-search-select" tabindex="0"><span className="current">All Categories</span><div className="nice-select-search-box"><input type="text" className="nice-select-search" placeholder="Search..."/></div><ul className="list"><li data-value="All Categories" className="option selected focus">All Categories</li><li data-value="Shops" className="option">Shops</li><li data-value="Hotels" className="option">Hotels</li><li data-value="Restaurants" className="option">Restaurants</li><li data-value="Fitness" className="option">Fitness</li><li data-value="Events" className="option">Events</li></ul></div>
                                                            </div>
                                                            
                                                            
                                                            
                                                            <div className="listsearch-input-item location autocomplete-container">
                                                                <span className="iconn-dec"><i className="far fa-map-marker"></i></span>
                                                                <input type="text" placeholder="Where to look?" className="autocomplete-input pac-target-input" id="autocompleteid3" value="" autocomplete="off" />
                                                                <a href="#"><i className="fal fa-location"></i></a>
                                                            </div>
                                                           
                                                          
                                                          
                                                           
                                                            
                                                            
                                                           
                                                            <div className="listsearch-input-item">
                                                                <button className="header-search-button color-bg" onclick="window.location.href='listing.html'"><i className="far fa-search"></i><span>Search</span></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                     
                                                </div>
                                                                        
                                            </div>
                                        </div>
                                         
                                    </div>


                            <div className="col-md-8">
                                <div className="list-main-wrap-header fl-wrap block_box no-vis-shadow">
                                            <div className="list-main-wrap-title">
                                                <h2 className='mrg556'> { watchersList.length && t('Bonne nouvelle')} <span>{watchersList.length > 0 && watchersList.length }</span>  { watchersList.length >1 ? t('Watchers sont inscrits dans cette ville') : t('Watcher est inscrit dans cette ville')} - {villeitem} </h2>
                                            </div>
                                </div>


                                <div className="grid-item-holder gallery-items fl-wrap" style={{marginTop:'20px'}}>
                               
                                    <div className="listing-item row">

                                    {
                                      watchersList.length <=0 &&

                                      <div>
                                           <div className='alert alert-warning'>{t('Nous sommes en cours d’installation dans cette ville renseignez votre email nous vous préviendrons.')}</div>
                                             

                                             <div>

                                             <form className='custom-form' onSubmit={handlerConnect}>
                                                

                                                <input required type="email" name="email" placeholder={t('Email') + "*"} />
                                                <input type='hidden' name="ville" value={villeitem}  />

                                                <button type="submit" class="w-100 color-btn-bg upcase  btn btn-success" style={{background: "#085eab"}}>{t('Envoyer')}</button>
                                            </form>


                                             </div>

                                      </div>

                                      
                                    }

                                     {watchersList.map((item,index) => {
                                       let newindex=index+1;
                                       return (
                                             <AssistantItem key={index} ln={currentlang} item={item} src={'l'} />
                                        );
                            
                                     })}
                                         
                                         

                                    </div>
                                

                                </div>

                                
                            </div>

                           

         
                          </div>
                    </div>
                </div>
           </section>

<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>

          <div className='center'>
            <img src='/images/send.png' />
          </div>

          <br/><br/>
            <div className='center'><h4 className='center'>{t('Félicitations')} </h4></div>
           <br/>
          <div className='center'>
             {t('Nous avons envoyé votre demande aux assistants selectionés, nous vous informerons de leur disponibilité pour votre mission.')}
          </div>

        </Modal.Body>
        <Modal.Footer>
          
          <Button variant="success" onClick={handleGoTo}>
            {t('Dashboard')} 
          </Button>
        </Modal.Footer>
</Modal>
             
        </div>
    );
}

export default Page;