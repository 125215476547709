import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button ,Modal,CloseButton } from "react-bootstrap"
import { AssistantItem } from "../../../../components";
import { AvisItem } from "../../../../components";
import { getListWatcher } from "../../../../actions/user";
import { useHistory } from "react-router";
import { BASE_URL_PROD } from "../../../../components/config/keys";
import { surpls,epr } from "../../../../assets/images";
import { useTranslation } from 'react-i18next';
 
import { getBesoinDemenagement } from "../../../../actions/pages";

const Page = () => {
    
    const autre_services_data = JSON.parse(localStorage.getItem('autre_services_data'));
    const dispatch = useDispatch();
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const [besoin, setBesoin] = useState(autre_services_data.besoin);
    const [desc_besoin, setDescBesoin] = useState(autre_services_data.desc_besoin);
    const [besoinText, setBesoinText] = useState(autre_services_data.besoinText);
    const [avecCamion, setAvecCamion] = useState(autre_services_data.avecCamion);
    const [surface, setSurface] = useState(autre_services_data.surface);
    const [besoinPrice, setBesoinPrice] = useState(autre_services_data.besoinPrice);
    const [surfaceText, setSurfaceText] = useState(autre_services_data.surfaceText);
    const [besoinList, setBesoinList] = useState([]);
    
    const [besoinError, setShowBesoinError] = useState(0);
    const [camionError, setShowCamionError] = useState(0);
    const [surfaceError, setShowSurfaceError] = useState(0);

    // const [autre_services_data, setItems] = useState([]);
    useEffect(() => {
      dispatch(getBesoinDemenagement()).then((res) => {
          setBesoinList(res.data);
      })
    }, [])
  
    const handleGoBack = () => {
      history.push('/demenagement');
    };

    const handleGoTo = () => {
      setShowBesoinError(0)
      setShowSurfaceError(0)
      setShowCamionError(0)

      if(besoin == '' || besoin == undefined){
        setShowBesoinError(1)
      }else if(surface == '' || surface == undefined){
        setShowSurfaceError(1)
      }else if(avecCamion == '' || avecCamion == undefined){
        setShowCamionError(1)
      }else{
        autre_services_data.besoin = besoin
        autre_services_data.desc_besoin = desc_besoin
        autre_services_data.besoinText = besoinText
        autre_services_data.avecCamion = avecCamion
        autre_services_data.surface = surface
        autre_services_data.surfaceText = surfaceText
        autre_services_data.besoinPrice = besoinPrice
        localStorage.setItem('autre_services_data', JSON.stringify(autre_services_data))
        history.push('/demenagement-meubles/date');
      }
    };
    
    const handleBesoin = element => {
      setShowBesoinError(0)
      var index = element.nativeEvent.target.selectedIndex;
      setBesoinPrice(element.nativeEvent.target[index].dataset.price);
      setBesoin(element.target.value);
      setBesoinText(element.nativeEvent.target[index].text);
    };
    const handleaAvecCamion = element => {
      setShowCamionError(0)
      setAvecCamion(element);
    };
    const handleSurface = element => {
      setShowSurfaceError(0)
      setSurface(element);
      var surfaceText = ''
      if(element == 1)
        surfaceText = '- de 10 m²'
      else if(element == 2)
        surfaceText = '10-15 m²'
      else if(element == 3)
        surfaceText = '20-30 m²'
      else if(element == 4)
        surfaceText = '30-40 m²'
      else if(element == 5)
        surfaceText = '40-50 m²'
      else if(element == 6)
        surfaceText = '50-60 m²'
      else if(element == 7)
        surfaceText = '60-70 m²'
      else if(element == 8)
        surfaceText = '+ de 80 m²'

      setSurfaceText(surfaceText)
    };
    return (
      <div className="demenagement2" >
        <section className="gray-bg big-padding " id="besoin1">
          <div className="container small-container ">
            <div className='registerform'>
              <div className='row '>
                <div className='col-md-8'><p onClick={()=>handleGoBack()} style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>&lt;	&lt;	Retour</p></div>
                <div className='col-md-8'>
                  <div className="section-title"><h2><span>Quelle est votre besoin ? </span></h2>
                  </div>
                  <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                  <select required id='besoin' name='besoin' value={besoin}  onChange={handleBesoin} >
                      <option value=""></option>
                      {
                        besoinList.length > 0 ?
                        besoinList.map(item => (
                          <option value={item.id} data-price={item.price}>{item.besoin}</option>
                        ))
                        :
                        ''
                      }
                    </select>
                  </div>

                  {
                    besoinError == 1 ?
                    <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                      <div class="alert alert-danger" role="alert">
                        Veuillez choisir votre besoin !
                      </div>
                    </div>
                    :
                    ""
                  }
                  {
                    besoinText != '' ?
                    <div className='custom-form col-md-10' style={{ paddingLeft:"0", marginBottom:'20px' }}>
                      <p style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>Quelle est la surface à déménager ?</p>
                      <div onClick={() => handleSurface(1)} className={surface === 1 ? 'custom-form col-md-3 boxShadow blocNiveau blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocNiveau'}><p className="p1">- de 10 m²</p></div>
                      <div onClick={() => handleSurface(2)} className={surface === 2 ? 'custom-form col-md-3 boxShadow blocNiveau blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocNiveau'}><p className="p1">10-15 m²</p></div>
                      <div onClick={() => handleSurface(3)} className={surface === 3 ? 'custom-form col-md-3 boxShadow blocNiveau blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocNiveau'}><p className="p1">20-30 m²</p></div>
                      <div onClick={() => handleSurface(4)} className={surface === 4 ? 'custom-form col-md-3 boxShadow blocNiveau blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocNiveau'}><p className="p1">30-40 m²</p></div>
                      <div onClick={() => handleSurface(5)} className={surface === 5 ? 'custom-form col-md-3 boxShadow blocNiveau blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocNiveau'}><p className="p1">40-50 m²</p></div>
                      <div onClick={() => handleSurface(6)} className={surface === 6 ? 'custom-form col-md-3 boxShadow blocNiveau blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocNiveau'}><p className="p1">50-60 m²</p></div>
                      <div onClick={() => handleSurface(7)} className={surface === 7 ? 'custom-form col-md-3 boxShadow blocNiveau blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocNiveau'}><p className="p1">60-70 m²</p></div>
                      <div onClick={() => handleSurface(8)} className={surface === 8 ? 'custom-form col-md-3 boxShadow blocNiveau blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocNiveau'}><p className="p1">+ de 80 m²</p></div>
                    </div>
                    :
                    ''
                  }
                  {
                    surfaceError == 1 ?
                    <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                      <div class="alert alert-danger" role="alert">
                        Veuillez choisir une surface !
                      </div>
                    </div>
                    :
                    ""
                  }
                  {
                    besoinText != '' ?
                    <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                      <p style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>Intervention réaliser avec le camion du prestataire ?</p>
                      <div onClick={() => handleaAvecCamion(1)} className={avecCamion === 1 ? 'custom-form col-md-3 boxShadow blocNiveau blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocNiveau'}><p className="p1">Oui</p></div>
                      <div onClick={() => handleaAvecCamion(2)} className={avecCamion === 2 ? 'custom-form col-md-3 boxShadow blocNiveau blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocNiveau'}><p className="p1">Non</p></div>
                    </div>
                    :
                    ''
                  }
                  {
                      camionError == 1 ?
                      <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                        <div class="alert alert-danger" role="alert">
                          Veuillez indiquer votre choix !
                        </div>
                      </div>
                      :
                      ""
                    }
                  <div className='col-md-9' style={{ paddingLeft:"0", paddingTop:'30px', clear:'both' }}>
                    <div style={{textAlign:'left',marginBottom:'30px'}}>
                      <a onClick={()=>handleGoTo()} class="btn btnform w200 "> Continuer </a>
                    </div>
                  </div>
                </div>    
                <div className='col-md-4'>
                    <div className="header1 boxShadow">Recapitulatif</div>
                    <div className="body1 boxShadow">
                      <p style={{ margin:'0px', paddingLeft:'10px' }}>1 intervention déménagement meubles electro</p>
                      <hr style={{ margin:'10px' }}></hr>
                      {
                        besoinText != '' ?
                        <ul>
                          <li>{besoinText}</li>
                        </ul>
                        :
                        ''
                      }
                    </div>
                </div>  
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Page;