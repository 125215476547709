import React, { useEffect,useState } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { AssistantItem } from "../../components";
import { useParams } from "react-router";
import { apiClient } from "../../actions/api"
import { useTranslation } from 'react-i18next';

const Page = () => {
    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { t, i18n } = useTranslation();
    const [langset, setLangset] = useState(false);
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [socials, setSocials] = useState({});
   
 
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())

        return apiClient().post(`/contact/send`, formData)
          .then((res) =>
           { 
             alert(t('Votre message a bien été envoyé.'))
            //  window.location.reload();

         })
         
    }

     const handleGoTo = (e) => {
        e.preventDefault();
         
    }
     useEffect(() => {
        
         return apiClient().get(`/getSocialMedia`, {})
          .then((res) =>
           { 

            setSocials(res.data.data)

         })
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 
    }, [])
   
    return (
        <div className="contact" >


           <section className="parallax-section single-par" data-scrollax-parent="true">
                        <div className="bg par-elem " data-bg="images/contact-investisseurs-header.png" data-scrollax="properties: { translateY: '30%' }" style={{backgroundImage: "url(images/960594F1-409A-455A-94C5-DECC65C71D06.png)"}}></div>
                        <div className="overlay op7"></div>
                        <div className="container">
                            <div className="section-title center-align big-title">
                                <h2><span>{t('Nous contacter')}</span></h2>
                                <span className="section-separator"></span>
                                 
                            </div>
                        </div>
                        <div className="header-sec-link">
                            <a href="javascript:void(0)" className="custom-scroll-link"> </a> 
                        </div>
           </section>




           <section className="small-padding" id="sec1">
                <div className="container small-container">

                    <div className="about-wrap">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ab_text-title fl-wrap">
                                            <h3>{t('Entrer en contact')}</h3>
                                            <span className="section-separator fl-sec-sep"></span>
                                        </div>
                                                                             
                                        <div className="box-widget-item fl-wrap block_box">
                                            <div className="box-widget">
                                                <div className="box-widget-content bwc-nopad">
                                                    <div className="list-author-widget-contacts list-item-widget-contacts bwc-padside">
                                                        <ul className="no-list-style">
                                                            <li><span><i className="fal fa-map-marker"></i> {t('Adresse')}:</span> <a href="javascrip:void(O)" className="custom-scroll-link">37 rue Maurice de Tastes, 37100 Tours, France</a></li>
                                                            <li><span><i className="fal fa-phone"></i> {t('Téléphone')}:</span> <a href="javascrip:void(O)">09 70 99 84 45</a></li>
                                                            <li><span><i className="fal fa-envelope"></i> {t('Email')}:</span> <a href="javascrip:void(O)">support@proximitipro.fr<br/></a></li>
                                                        </ul>
                                                    </div>
                                                 
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <div className="box-widget-item fl-wrap" style={{marginTop:"20px"}}>
                                            
                                        </div>
                                                                                   
                                    </div>
                                    <div className="col-md-8">
                                        <div className="ab_text">
                                            <div className="ab_text-title fl-wrap">
                                                <h3>{t('Écrivez-nous')}</h3>
                                                <span className="section-separator fl-sec-sep"></span>
                                            </div>
                                          
                                            <div id="contact-form">
                                                <div id="message"></div>
                                                <form onSubmit={handleSubmit}    className="custom-form" action="" name="contactform" id="contactform">
                                                    <fieldset>
                                                        <label><i className="fal fa-user"></i></label>
                                                        <input type="text" name="name" id="name" placeholder={t('Votre nom complet') + "*"} required />
                                                        <div className="clearfix"></div>
                                                        <label><i className="fal fa-envelope"></i>  </label>
                                                        <input type="email" name="email" id="email" placeholder={t('Email') + "*"} />
                                                        <textarea name="comments" id="comments" cols="40" rows="3" placeholder={t('Votre Message') + "*"}></textarea>
                                                    </fieldset>
                                                    <button type="submit" onClick="return false;" className="btn float-btn color2-bg btn9876 " id="submit">{t('Envoyer')}<i className="fal fa-paper-plane"></i></button>
                                                </form>
                                            </div>
                                             
                                        </div>
                                    </div>
                                </div>
                            </div>
                     
                </div>
           </section>

 
             
        </div>
    );
}

export default Page;