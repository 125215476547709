import React, { useEffect,useState } from "react";
import './styles.scss'; 
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { AssistantItem } from "../../../components";
import { useParams } from "react-router";
import { apiClient } from "../../../actions/api"
import Slider from '@material-ui/core/Slider';
import { useTranslation } from 'react-i18next';
import { registerUserAndReturn,loginUser,loginUserFacebook,loginUserMission } from "../../../actions/user";
import { saveAutreService } from "../../../actions/pages";
import { useHistory } from "react-router";
import FacebookLogin from 'react-facebook-login';


const Page = () => { 
    const detail_bient = JSON.parse(localStorage.getItem('detail_bient'));
    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)
    const dispatch = useDispatch();
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
 
    const { user } = useSelector(state => state.user)
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const [tab, setTab] = useState(1);
    const [tabins, setTabins] = useState(1);
    const [tokenmob, settokenmob] = useState(localStorage.getItem('typepush'));
 
   
   
   const handleGoTo = (slug) => {
         history.push(slug)
    }

 

    const settab1= (e) => {
        e.preventDefault();
        
        setTab(1)
    }

    const settab2= (e) => {
        e.preventDefault();
        
        setTab(2)
    }
 
    const settabins1= (e) => {
        e.preventDefault();
        
        setTabins(1)
    }

    const settabins2= (e) => {
        e.preventDefault();
        
        setTabins(2)
    }
 
   
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        dispatch(registerUserAndReturn(formDataObj,'customer',currentlang)).then((res) => {
          history.push('/reservation-bien/'+detail_bient.id)
        })
    }


      const handlerconnexion = async (e) =>{
          e.preventDefault();
          const formData = new FormData(e.target)
          const formDataObj = Object.fromEntries(formData.entries())
          dispatch(loginUser(formDataObj,'AutreServiceIntervention',currentlang)).then((res) => {
            if(res){
              history.push('/reservation-bien/'+detail_bient.id)
            }
          })
      }


    const componentClicked =  () => {
        
       
    }




     const responseFacebook = (response) => {

    const membreData = {
        email: response.email,
    }

    return apiClient().post(`/check/user`, membreData)
          .then((res) =>
           { 

            if(res.data.success==true){
               
                 //dispatch(loginUserFacebook(res))
            }
            else{

                 const params = {
                  email: response.email,
                  fname: response.first_name,
                  lname: response.last_name,
                  tel:'',
                  password:'EY778UEIEU76567'
                 }

                  //dispatch(registerUserAndReturn(params,'user',currentlang));

            }

         })

 
       
} 
 

     useEffect(() => {
        if(langset == false){
              setLangset(true)
        }
 

    }, [])
   
    return (
        <div className="login " id='besoin6' >
           <section className="gray-bg small-padding no-top-padding-sec" id="sec1">
                <div className="container small-container">
                    <div className="fl-wrap">

                         <div className="besoin  " style={{marginTop:'100px'}} >
               <div className='row '>
                <div className='col-md-12'>
                </div>
                </div>
         </div>
                        <div className="row">


            <div className="">
              
                <div className="main-register-holder tabs-act">
                    <div className="main-register fl-wrap  ">
                     
                  
                        <ul className="tabs-menu fl-wrap no-list-style">
                            <li className={tab==1 && "current"}><a onClick={settab1} >Connexion</a></li>
                            <li className={tab==2 && "current"}><a onClick={settab2} > Inscription</a></li>
                        </ul>
                                              
                        <div className="tabs-container">
                            <div className="tab" >
                                
                                <div  className={tab==1 ? "tab-content first-tab1" :"tab-content"}>
                                    <div className="custom-form">
                                        <form  name="registerform" onSubmit={handlerconnexion}  >
                                           
                                            <input name="email" type="email" required placeholder="E-mail"/>
                                           
                                           <div className='displypass'> 
                                           <input name="password" type="password" required  placeholder="Mot de passe"  / >
                                           <a href='javascript:void(0)'><img src='/images/icones/oeil.png' /></a>
                                           </div>
                                              <div className="lost_password">
                                            <a  onClick={()=>handleGoTo('/password/user')}>Mot de passe oublié ?</a>
                                        </div>
                                            <button type="submit" style={{fontSize:'16px'}}  className='btn float-btn color2-bg pdpad'  > Se connecter </button>
                                            <div className="clearfix"></div>
                                          
                                        </form>
<br/>

{
  tokenmob ?
  <div></div>
  :

    <div>

    <div className="log-separator fl-wrap center"><span>OU</span></div>
                            <div className="soc-log fl-wrap" style={{    paddingTop: '0px'}}>
                                 
                                
                               <FacebookLogin
                                        appId="756894289090891"
                                        autoLoad={false}
                                        fields="first_name,last_name,email"
                                        textButton="S'identifier avec Facebook"
                                        onClick={componentClicked}
                                        callback={responseFacebook} />

                                      <div>

                                       <button  className="btn float-btn color2-bg  googleconnect" >
                                          S'identifier avec google
                                       </button>


                                      </div>


                            </div>

    </div>

}
                          
                                      
                                    </div>
                                </div>
                                
                                <div className="tab">
                                    <div  className={tab==2 ? "tab-content first-tab1" :"tab-content"}>
                                        <div className="custom-form">
{/* 
                                          <div className='actionename'>
                                            <ul>
                                             <li><a onClick={settabins1} className={tabins==1 ?  "btnins7 active mrright": "btnins7 mrright"} href='javascropt:void(0)'>Particulier</a></li>
                                             <li><a onClick={settabins2} className={tabins==2  ? "btnins7 active mrright": "btnins7 mrright"} href='javascropt:void(0)'>Professionnel</a></li>

                                            </ul>

                                          </div>
                                          <br/> */}

                                          {
                                             tabins==1 ?

                                               <form  onSubmit={handleSubmit}  name="registerform" className="main-register-form" id="main-register-form2" style={{marginTop:'30px'}}>
                                                
                                                <div className="row">
                                                  <div className='col-md-6'>
                                                    <input name="lname" type="text"    required placeholder="Nom" />
                                                  </div>
                                                   <div className='col-md-6'>
                                                     <input name="fname" type="text"   required placeholder='Prénom' />
                                
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className='col-md-12'>
                                                     <input name="adresse" type="text"  placeholder='Adresse'  required />
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className='col-md-5'>
                                                    <input name="codepostal" type="text"    required placeholder="Code postal" />
                                                  </div>
                                                   <div className='col-md-7'>
                                                     <input name="ville" type="text"   required placeholder='Ville' />
                                
                                                  </div>
                                                </div>

                                                 <div className="row">
                                                  <div className='col-md-12'>
                                                        <input name="email" type="email" placeholder='E-mail'  required/>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className='col-md-12'>
                                                         <div className='displypass'> 
                                                       <input name="password" type="password" required  placeholder="Mot de passe"  / >
                                                       <a href='javascript:void(0)'><img src='/images/icones/oeil.png' /></a>
                                                       </div>
                                                  </div>
                                                </div>
                                                
                                                <div className="row">
                                                  <div className='col-md-12'>
                                                         <select name='type_logement' className='selectform bgselect' >
                                                           <option value=''>Type de logement</option>
                                                           <option value='1'>Appartement</option>
                                                           <option value='2'>Maison</option>
                                                           <option value='3'>Bureau</option>
                                                           <option value='4'>Local Commercial</option>

                                                         </select>
                                                  </div>
                                                </div>

                                                  <div className="row">
                                                  <div className='col-md-6'>
                                                    <input name="digicode" type="text"  placeholder="Digicode" />
                                                  </div>
                                                   <div className='col-md-6'>
                                                     <input name="interphone" type="text" placeholder='Interphone' />
                                
                                                  </div>
                                                </div>
 

                                             
                                              
                                                <div className="filter-tags ft-list"> 
                                                    <input id="check-a2" type="checkbox" name="check" required  style={{    marginTop: '5px'}}/>
                                                    <label for="check-a2">J’accepte les Conditions Générales de Proximiti et je reconnais avoir été informé(e) et consentir au traitement de mes données personnelles détaillées dans la Politique de confidentialité de Proximiti. *</label>
                                                </div>
                                                <div className="clearfix"></div>
                                               
                                                <div className="clearfix"></div>
                                                <button type="submit"      className="btn float-btn color2-bg pdpad">S'inscrire </button>
                                            </form>

                                             :

                                              <form  onSubmit={handleSubmit}  name="registerform" className="main-register-form" id="main-register-form2" style={{marginTop:'30px'}}>
                                                
                                                
                                                 <div className="row">
                                                  <div className='col-md-12'>
                                                         <select name='type' className='selectform bgselect' >
                                                           <option value=''>Secteur d'activité</option>

                                                         </select>
                                                  </div>
                                                </div>

                                                  <div className="row">
                                                  <div className='col-md-12'>
                                                         <select name='type' required className='selectform bgselect' >
                                                           <option value=''>Type d'entreprise</option>
                                                           <option value='1'>SARL</option>
                                                <option value='4'>SASU</option>
                                                <option value='5'>Micro entreprise</option>
                                                <option value='6'>SAS</option>
                                                <option value='7'>Agence immobilière</option>
                                                <option value='8'>Indépendant</option>
                                                <option value='9'>Auto entrepreneur</option>
                                                <option value='2'>Individuelle</option>
                                                <option value='10'>SCI</option>
                                                <option value='11'>investisseur immobilier</option>
                                                <option value='3'>Anonyme</option>
                                                         </select>
                                                  </div>
                                                </div>

                                                <div className="row">
                                                  <div className='col-md-12'>
                                                         <select name='type' required className='selectform bgselect' >
                                                           <option value=''>Nombre d'employés</option>

                                                         </select>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className='col-md-12'>
                                                     <input name="societe" type="text"  placeholder='Nom de la Société'  required />
                                                  </div>
                                                </div>
                                                 <div className="row">
                                                  <div className='col-md-12'>
                                                     <input name="adresse" type="text"  placeholder='Adresse professionnelle'  required />
                                                  </div>
                                                </div>
                                                
                                    
                                                <div className="row">
                                                  <div className='col-md-5'>
                                                    <input name="codepostal" type="text"    required placeholder="Code postal" />
                                                  </div>
                                                   <div className='col-md-7'>
                                                     <input name="ville" type="text"   required placeholder='Ville' />
                                
                                                  </div>
                                                </div>


                                                <div className="row">
                                                  <div className='col-md-12'>
                                                     <input name="siret" type="text"  placeholder='Siret'  required />
                                                  </div>
                                                </div>

                                                <div className="row">
                                                  <div className='col-md-12'>
                                                   <div className='download'>
                                                      Téléchargez votre Kbis
                                                     <input name="kbis" type="file"   required />
                                                     <img src='/images/icones/Iconfeatherdownload.png' />
                                                    </div>
                                                  </div>
                                                </div>
                                                 <div className="row">
                                                  <div className='col-md-12'>
                                                   <div className='download'>
                                                       Téléchargez votre Attestation d'assurance
                                                     <input name="kbis" type="file"   required />
                                                     <img src='/images/icones/Iconfeatherdownload.png' />
                                                    </div>
                                                  </div>
                                                </div>

                                                 <div className="row">
                                                  <div className='col-md-12'>
                                                        <input name="assurance" type="text" placeholder="Nom de l'assurance"  required/>
                                                  </div>
                                                </div>

                                                 <div className="row">
                                                  <div className='col-md-12'>
                                                   <div className='download' style={{    textAlign: 'center',minHeight: '58px'}}>
                                                  
                                                     <input name="kbis" type="file"   required />
                                                      «Glisser-déposer votre logo dans cette zone ou<br/> <span style={{color:'#2971b9'}}>cliquez ici pour sélectionner une photo</span>». 
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="row">
                                                  <div className='col-md-12'>
                                                        <input name="profession" type="text" placeholder='Votre Profession'  required/>
                                                  </div>
                                                </div>

                                                  <div className="row">
                                                  <div className='col-md-12'>
                                                        <input name="nom" type="text" placeholder='Votre Nom'  required/>
                                                  </div>
                                                </div>

                                                  <div className="row">
                                                  <div className='col-md-12'>
                                                        <input name="prenom" type="text" placeholder='Votre Prénom'  required/>
                                                  </div>
                                                </div>

                                                  <div className="row">
                                                  <div className='col-md-12'>
                                                        <input name="mobile" type="text" placeholder='N° de mobile'  required/>
                                                  </div>
                                                </div>

                                                 <div className="row">
                                                  <div className='col-md-12'>
                                                        <input name="email" type="email" placeholder='E-mail'  required/>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className='col-md-12'>
                                                         <div className='displypass'> 
                                                       <input name="password" type="password" required  placeholder="Mot de passe"  / >
                                                       <a href='javascript:void(0)'><img src='/images/icones/oeil.png' /></a>
                                                       </div>
                                                  </div>
                                                </div>

                                                <div className="row">
                                                  <div className='col-md-12'>
                                                   <div className='download' style={{    textAlign: 'center',minHeight: '58px'}}>
                                                  
                                                     <input name="kbis" type="file"   required />
                                                      «Glisser-déposer votre photo dans cette zone ou<br/> <span style={{color:'#2971b9'}}>cliquez ici pour sélectionner une photo</span>». 
                                                    </div>
                                                  </div>
                                                </div>
                                                
                                               
                                               <div className="filter-tags ft-list"> 
                                                    <input id="check-a2" type="checkbox" name="check"  style={{    marginTop: '5px'}}/>
                                                    <label for="check-a2">J'accepte de recevoir les offres de Proximiti.</label>
                                                </div>

                                                <div className="clearfix"></div>

                                               <div className="filter-tags ft-list"> 
                                                    <input id="check-a2" type="checkbox" name="check" required  style={{    marginTop: '5px'}}/>
                                                    <label for="check-a2">Je m'engage à indiquer mes disponibilités et à les respecter.</label>
                                                </div>

                                                  <div className="clearfix"></div>



 
                                              
                                                <div className="filter-tags ft-list"> 
                                                    <input id="check-a2" type="checkbox" name="check" required  style={{    marginTop: '5px'}}/>
                                                    <label for="check-a2">J’accepte les Conditions Générales de Proximiti et je reconnais avoir été informé(e) et consentir au traitement de mes données personnelles détaillées dans la Politique de confidentialité de Proximiti. *</label>
                                                </div>
                                                <div className="filter-tags ft-list"> 
                                                  <label for="check-a4">
                                                    Votre attestation d'assurance RC pro et/ou décennale sera vérifiée et vous sera demandée deux fois au cours de l'année
                                                  </label>
                                                </div>
                                                <div className="clearfix"></div>
                                               
                                                <div className="clearfix"></div>
                                                <button type="submit"      className="btn float-btn color2-bg pdpad">S'inscrire </button>
                                            </form>



                                          }

                                           
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            
                   
                            <div className="wave-bg">
                                <div className='wave -one'></div>
                                <div className='wave -two'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


                        </div>

                     </div>
                 </div>

           </section>

 
             
        </div>
    );
}

export default Page;