import React, { useEffect,useState } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { AssistantItem } from "../../components";
import { useParams } from "react-router";
import { apiClient } from "../../actions/api"
import { useTranslation } from 'react-i18next';
import {getFaq } from "../../actions/pages";
const Page = () => {
    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [langset, setLangset] = useState(false);
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const { contents } = useSelector(state => state.pages)
   
 
    const handleSubmit = (e) => {
        e.preventDefault();
        setShow(true)
    }

     const handleGoTo = (e) => {
        e.preventDefault();
         
    }

        useEffect(() => {
        
         dispatch(getFaq());
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 
    }, [])
   
    return (
        <div className="faq" >


           <section className="parallax-section single-par" data-scrollax-parent="true">
                        <div className="bg par-elem " data-bg="images/faq346.jpg" data-scrollax="properties: { translateY: '30%' }" style={{backgroundImage: "url(images/faq346.jpg)"}}></div>
                        <div className="overlay op7"></div>
                        <div className="container">
                            <div className="section-title center-align big-title">
                                <h2><span>FAQ</span></h2>
                                <span className="section-separator"></span>
                                 
                            </div>
                        </div>
                        <div className="header-sec-link">
                            <a href="javascript:void(0)" className="custom-scroll-link"> </a> 
                        </div>
           </section>




           <section className="small-padding" >
                <div className="container">

                    <div className="about-wrap">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="ab_text-title fl-wrap">
                                            <h3>{t('FOIRE AUX QUESTIONS')}</h3>
                                            <span className="section-separator fl-sec-sep"></span>
                                        </div>

                                        <div className="faq-section fl-wrap" id="fq1">
                                             
                                            <div className="accordion">

                                            

                                            {contents.map((item,index) => {
                                      
                                                   return (
                                                       <div key={index}>

                                                         <a className="toggle" href="javascript:void(0)">{currentlang=='fr' ? item.question.fr : item.question.en }<span></span></a>
                                                        <div className="accordion-inner " style={{display: "none"}}>
                                                       
                                                            <p dangerouslySetInnerHTML={{__html: currentlang=='fr' ? item.reponse.fr : item.reponse.en }}></p>
                                                        </div>
                                                       </div>
                                                    );
                                        
                                                 })}

                                                
                                              
                                            </div>
                                                                                          
                                        </div>


                                        <div className="faq-links fl-wrap">
                                            
         
                                            <div className="section-title">
                                                <h2>{t("Encore besoin d'aide ?")}</h2>
                                                
                                                <span className="section-separator"></span>
                                                
                                            </div>
                                            
                                            <div className="post-nav-wrap fl-wrap">
                                                <a className="post-nav post-nav-prev" href="javascript:void(0)"><span className="post-nav-img"><img src="/images/callmywatcher.png" alt=""/></span><span className="post-nav-text"><strong>Appelez-nous</strong> <br/>0033 6 30 64 24 14</span></a> 
                                                <a className="post-nav post-nav-next" href="javascript:void(0)"><span className="post-nav-img"><img src="/images/callmywatcher.png" alt=""/></span><span className="post-nav-text"><strong>Écrivez-nous</strong><br/>support@proximitipro.fr</span></a> 
                                            </div>
                                            
                                        </div>

                                    </div>
                                          
                                 </div>
                   </div>
                                   
                </div>
           </section>

 
             
        </div>
    );
}

export default Page;