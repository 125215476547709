import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button ,Modal,CloseButton } from "react-bootstrap"
import { AssistantItem } from "../../../../components";
import { AvisItem } from "../../../../components";
import { getListWatcher } from "../../../../actions/user";
import { useHistory } from "react-router";
import { BASE_URL_PROD } from "../../../../components/config/keys";
import { surpls,epr } from "../../../../assets/images";
import { useTranslation } from 'react-i18next';
 
import { saveAutreService } from "../../../../actions/pages";

const Page = () => {
    const autre_services_data = JSON.parse(localStorage.getItem('autre_services_data'));
    const dispatch = useDispatch();
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const [frequence, setFrequence] = useState(autre_services_data.frequence);
    const [besoinText, setBesoinText] = useState(autre_services_data.besoinText);
    const [besoinPrice, setBesoinPrice] = useState(autre_services_data.besoinPrice);
    const [date, setDate] = useState([{ day: '', name: '' }]);
    const [dateName, setDateName] = useState([]);
    const [heure, setHeure] = useState('');
    const [heureDebut, setHeureDebut] = useState('');
    const [heureFin, setHeureFin] = useState('');
    const [mustConnect, setMustConnect] = useState(false);
    const [errorDate, setErrorDate] = useState([]);
    const [errorDateSave, setErrorDateSave] = useState(0);
    const [errorHeure, setErrorHeure] = useState(0);
    const [typeHeure, setTypeGeure] = useState(0);

    const { user } = useSelector(state => state.user)
    useEffect(() => {
      console.log('autre_services_data');
      console.log(frequence);
    }, [])
  
    const handleGoBack = () => {
      history.push('/garde-animaux/frequence');
    };

    const handleGoTo = () => {
      setErrorDateSave(0)
      setErrorHeure(0)
      if(date[0].day == ''){
        setErrorDateSave(1)
      }else if(heureDebut == '' || heureDebut == undefined){
        setErrorHeure(1)
      }else if(heureFin == '' || heureFin == undefined){
        setErrorHeure(1)
      }else{
        autre_services_data.date = date
        autre_services_data.heureDebut = heureDebut
        autre_services_data.heureFin = heureFin
        localStorage.setItem('autre_services_data', JSON.stringify(autre_services_data))
  
        if(user.id != undefined && user.id > 0 && user.type == 2){
          history.push("/autre-service-paiement")
          // dispatch(saveAutreService(user.id, autre_services_data)).then((res) => {
          //   history.push("/autre-service-succes")
          // })
        }else{
          history.push('/connexion');
        }
      }
    };
    
    const handleDate = element => {
      setErrorDateSave(0)
      var name = ''
      if(element == 1) name = 'Lundi'
      if(element == 2) name = 'Mardi'
      if(element == 3) name = 'Mercredi'
      if(element == 4) name = 'Jeudi'
      if(element == 5) name = 'Vendredi'
      if(element == 6) name = 'Samedi'
      if(element == 7) name = 'Dimanche'

      if(frequence == 3){
        if(date[0].day == ''){
          dateName.push(element)
          date[0].day = element
          date[0].name = name

          var ar2 = [];
          ar2.push(element)
          setDate(date)
          setDateName(ar2)
        }else{
          var isFound = false
          var ar1 = [];
          var ar2 = [];
          for (let index = 0; index < date.length; index++) {
            const elem = date[index];
            if(elem.day === element){
              isFound = true;
            }else{
              ar2.push(elem.day)
              ar1.push(elem)
            }
          }
          if(!isFound){
            ar1.push({ day: element, name: name })
            ar2.push(element)
          }
          setDate(ar1)
          setDateName(ar2)
        }
      }else if(frequence == 1 || frequence == 2){
        date[0].day = element
        date[0].name = name

        var ar2 = [];
        ar2.push(element)
        setDate(date)
        setDateName(ar2)
      }else if(frequence == 4){
        if(date.length < 2){
          var ar2 = [];
          if(date[0].day == ''){
            date[0].day = element
            date[0].name = name
            ar2.push(element)
          }else{
            for (let index = 0; index < date.length; index++) {
              const elem = date[index];
              ar2.push(elem.day)
            }
            const isFound = date.some(elem => {
              return elem.day === element;
            });
            if(isFound){
              var array = [...date];
              setDate(date.filter(a => a.day !== element))
            }
            else{
              date.push({ day: element, name: name })
              ar2.push(element)
            }
          }

          setDateName(ar2)
          /*setDate([...date, { day: element, name: name }]);*/
        }else{
          setErrorDate(1)
        }
      }
    };
    const handleHeure = element => {
      setErrorHeure(0)
      setErrorDate(0)
      setTypeGeure(0)
      if(typeHeure == 1)
        setHeureDebut(element);
      else
        setHeureFin(element);
    };
    
    const changeHeure = element => {
      setTypeGeure(element)
    };

    return (
      <div className="coursStandard4" >
        <section className="gray-bg big-padding " id="besoin1">
          <div className="container small-container ">
            <div className='registerform'>
              <div className='row '>
                <div className='col-md-8'><p onClick={()=>handleGoBack()} style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>&lt;	&lt;	Retour</p></div>
                <div className='col-md-8'>
                  <div className="section-title">
                    <h2><span>Quand aura lieu le cours ? </span></h2>
                  </div>
                  <p style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>Sélectionnez le jour</p>
                  <div className="row">
                    <div onClick={() => handleDate(1)} className={dateName.includes(1) === true ? 'custom-form col-md-3 boxShadow blocfrequence blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocfrequence'}><p className="p1">Lundi</p></div>
                    <div onClick={() => handleDate(2)} className={dateName.includes(2) === true ? 'custom-form col-md-3 boxShadow blocfrequence blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocfrequence'}><p className="p1">Mardi</p></div>
                    <div onClick={() => handleDate(3)} className={dateName.includes(3) === true ? 'custom-form col-md-3 boxShadow blocfrequence blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocfrequence'}><p className="p1">Mercredi</p></div>
                    <div onClick={() => handleDate(4)} className={dateName.includes(4) === true ? 'custom-form col-md-3 boxShadow blocfrequence blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocfrequence'}><p className="p1">Jeudi</p></div>
                    <div onClick={() => handleDate(5)} className={dateName.includes(5) === true ? 'custom-form col-md-3 boxShadow blocfrequence blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocfrequence'}><p className="p1">Vendredi</p></div>
                    <div onClick={() => handleDate(6)} className={dateName.includes(6) === true ? 'custom-form col-md-3 boxShadow blocfrequence blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocfrequence'}><p className="p1">Samedi</p></div>
                    <div onClick={() => handleDate(7)} className={dateName.includes(7) === true ? 'custom-form col-md-3 boxShadow blocfrequence blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocfrequence'}><p className="p1">Dimanche</p></div>
                    {
                      errorDate == 1 ?
                      <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                        <div class="alert alert-danger" role="alert">
                          Veuillez changer la fréquence pour choisir plus!
                        </div>
                      </div>
                      :
                      ""
                    }

                {
                    errorDateSave == 1 ?
                    <div className='custom-form col-md-9' style={{ paddingLeft:"0" }}>
                      <div class="alert alert-danger" role="alert">
                        Veuillez choisir une date !
                      </div>
                    </div>
                    :
                    ""
                }
                  </div>
                  <p style={{ color: "rgb(7, 52, 96)", cursor:'pointer', marginTop:'20px' }}>Quelle est l'heure de début et de fin ?</p>
                  <div className="row" style={{ background:"transparent", borderRadius:'5px', paddingTop:'10px' }}>
                      <div className='col-md-2'><div className='herueSelected heure'><input onBlur={() => changeHeure(1)} onClick={() => changeHeure(1)} value={heureDebut} readOnly type="text" placeholder="--:--" style={{width:'100%', border:'0'}}/></div></div>
                      <div className='col-md-2'><div className='herueSelected heure'><input onBlur={() => changeHeure(2)} onClick={() => changeHeure(2)} value={heureFin} readOnly type="text" placeholder="--:--" style={{width:'100%', border:'0'}}/></div></div>
                  </div>
                  {
                    typeHeure != 0 ?
                    <p style={{ color: "rgb(7, 52, 96)", cursor:'pointer', marginTop:'20px' }}>Sélectionnez l'heure</p>
                    :
                    ""
                  }
                  {
                    typeHeure != 0 ?
                  <div className="row boxShadow" style={{ background:"#fff", borderRadius:'5px', paddingTop:'10px' }}>
                      <div className='col-md-2'><div onClick={() => handleHeure('7:00')} className={heure == '7:00' ? 'herueSelected heure' : 'heure'}>7:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('7:30')} className={heure == '7:30' ? 'herueSelected heure' : 'heure'}>7:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('8:00')} className={heure == '8:00' ? 'herueSelected heure' : 'heure'}>8:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('8:30')} className={heure == '8:30' ? 'herueSelected heure' : 'heure'}>8:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('9:00')} className={heure == '9:00' ? 'herueSelected heure' : 'heure'}>9:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('9:30')} className={heure == '9:30' ? 'herueSelected heure' : 'heure'}>9:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('10:00')} className={heure == '10:00' ? 'herueSelected heure' : 'heure'}>10:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('10:30')} className={heure == '10:30' ? 'herueSelected heure' : 'heure'}>10:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('11:00')} className={heure == '11:00' ? 'herueSelected heure' : 'heure'}>11:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('11:30')} className={heure == '11:30' ? 'herueSelected heure' : 'heure'}>11:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('12:00')} className={heure == '12:00' ? 'herueSelected heure' : 'heure'}>12:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('12:30')} className={heure == '12:30' ? 'herueSelected heure' : 'heure'}>12:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('13:00')} className={heure == '13:00' ? 'herueSelected heure' : 'heure'}>13:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('13:30')} className={heure == '13:30' ? 'herueSelected heure' : 'heure'}>13:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('14:00')} className={heure == '14:00' ? 'herueSelected heure' : 'heure'}>14:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('14:30')} className={heure == '14:30' ? 'herueSelected heure' : 'heure'}>14:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('15:00')} className={heure == '15:00' ? 'herueSelected heure' : 'heure'}>15:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('15:30')} className={heure == '15:30' ? 'herueSelected heure' : 'heure'}>15:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('16:00')} className={heure == '16:00' ? 'herueSelected heure' : 'heure'}>16:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('16:30')} className={heure == '16:30' ? 'herueSelected heure' : 'heure'}>16:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('17:00')} className={heure == '17:00' ? 'herueSelected heure' : 'heure'}>17:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('17:30')} className={heure == '17:30' ? 'herueSelected heure' : 'heure'}>17:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('18:00')} className={heure == '18:00' ? 'herueSelected heure' : 'heure'}>18:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('18:30')} className={heure == '18:30' ? 'herueSelected heure' : 'heure'}>18:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('19:00')} className={heure == '19:00' ? 'herueSelected heure' : 'heure'}>19:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('19:30')} className={heure == '19:30' ? 'herueSelected heure' : 'heure'}>19:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('20:00')} className={heure == '20:00' ? 'herueSelected heure' : 'heure'}>20:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('20:30')} className={heure == '20:30' ? 'herueSelected heure' : 'heure'}>20:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('21:00')} className={heure == '21:00' ? 'herueSelected heure' : 'heure'}>21:00</div></div>
                  </div>
                    :
                    ""
                  }

                  {
                    errorHeure == 1 ?
                    <div className='col-md-9' style={{ paddingLeft:"0", marginTop:'30px', clear:'both' }}>
                      <div class="alert alert-danger" role="alert">
                        Veuillez choisir une heure !
                      </div>
                    </div>
                    :
                    ""
                }
                  <div className='col-md-9' style={{ paddingLeft:"0", marginTop:'30px', clear:'both' }}>
                    <div style={{textAlign:'left',marginBottom:'30px'}}>
                      <a onClick={()=>handleGoTo()} class="btn btnform w200 "> Continuer </a>
                    </div>
                  </div>
                </div>    
                <div className='col-md-4'>
                    <div className="header1 boxShadow">Recapitulatif</div>
                    <div className="body1 boxShadow">
                      <p style={{ margin:'0px', paddingLeft:'10px' }}>1 intervention garde animaux</p>
                      <hr style={{ margin:'10px' }}></hr>
                      <p style={{ margin:'0px', paddingLeft:'10px' }}><img style={{ width:'16px' }} src="/images/services/icone_detail.png"/> Détails de l'intervention</p>
                      {
                        autre_services_data.genreText != '' ?
                        <ul>
                          <li>{autre_services_data.genreText}</li>
                          {
                            autre_services_data.genre == 1 ?
                            <li>{autre_services_data.qte} {autre_services_data.qte > 1 ? 'chiens':'chien'}</li>
                            :
                            (
                              autre_services_data.genre == 2 ?
                              <li>{autre_services_data.qte} {autre_services_data.qte > 1 ? 'chats':'chat'}</li>
                              :
                              (
                                autre_services_data.genre == 3 ?
                                <li>{autre_services_data.qte} {autre_services_data.qte > 1 ? 'promonades':'promonade'}</li>
                                :
                                ''
                              )
                            )
                          }
                        </ul>
                        :
                        ''
                      }
                      <hr style={{ margin:'10px' }}></hr>
                      <p style={{ margin:'0px', paddingLeft:'10px' }}><img style={{ width:'16px' }} src="/images/services/icone_frequence.png"/> Rendez-vous</p>
                      {
                        frequence != '' ?
                        <ul>
                          {
                            frequence === 1 ?
                            <li>1 seule fois</li>
                            :
                            (
                              frequence === 2 ?
                              <li>1 fois par semaine</li>
                              :
                              (
                                frequence === 3 ?
                                <li>Plusieurs fois par semaine</li>
                                :
                                (
                                  frequence === 4 ?
                                  <li>2 fois par mois</li>
                                  :
                                  ''
                                )
                              )
                            )
                          }

{
                            date[0].day != '' ?
                            <li>
                            {
                            date.map(d=>(
                              <span key="{d.day}">{d.name}, </span>
                            ))
                            }
                            </li>
                            :
                            ''
                          }
                          {
                            heureDebut != '' ?
                            <li>
                              {heureDebut}
                              {
                                heureFin != ''?
                                ' - '+heureFin
                                :
                                ''
                              }
                            </li>
                            :
                            ''
                          }
                        </ul>
                        :
                        ''
                      }
                    </div>
                </div>  
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Page;