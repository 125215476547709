import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card,Modal} from "react-bootstrap";
//import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenu,DashbordHeader } from "../../../components";
import { getListMissions,EditMission,getListValidateMissions,getListRealiseMissions,getListEncoursMissions,setMessages, getAllProfessionalMissionsCalendar } from "../../../actions/pages";
import { useTranslation } from 'react-i18next';
import Kalend, { CalendarView } from 'kalend' // import component
import 'kalend/dist/styles/index.css'; // import styles
import moment from "moment";
import 'moment/locale/fr';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';


const Page = () => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const { user } = useSelector(state => state.user)
    const { missions,missionsV,missionsE,missionsR, all_prof_missions_calendar } = useSelector(state => state.pages)
    const [langset, setLangset] = useState(false);
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const [show, setShow] = useState(false);
     const [photo, setPhoto] = useState('');
    const [lname, setLname] = useState('');
    const [date, setDate] = useState(new Date());
    const [to, setTo] = useState('');
    const handleClose = () => setShow(false);
    const handleSubmit = async (e) => {
        e.preventDefault();

     

        
      
    }

    const onChange = newDate => {
    
    setDate(newDate);
  };

  
    const handleDateClick = (arg) => { // bind with an arrow function
        console.log(arg.event.id)
       
         history.push("/detail/mission/pro/"+arg.event.id)
    }


      useEffect(() => {
        dispatch(getAllProfessionalMissionsCalendar(user.id))
        
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])
    const events = [
                {
                    id: 1,
                    startAt: '2022-04-06T18:00:00.000Z',
                    endAt: '2022-04-05T19:00:00.000Z',
                    timezoneStartAt: 'Europe/Berlin', // optional
                    summary: 'test',
                    color: 'blue',
                    calendarID: 'work'
                },
                // {
                //     id: 2,
                //     startAt: '2021-11-21T18:00:00.000Z',
                //     endAt: '2021-11-21T19:00:00.000Z',
                //     timezoneStartAt: 'Europe/Berlin', // optional
                //     summary: 'test',
                //     color: 'blue',
                // }
        ]
 
    return (
        <div className="myccount responses agenda " >
            
           

            <section className="gray-bg main-dashboard-sec dashboard myccount" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenu src='agenda'/>
                                 
                            </div>


                         <div className='col-md-9 dashbordassitantcontent' style={{    paddingBottom: '24px'}} >

                             <div className='tab' id=''>


                              <DashbordHeader src={'w'} />

                            <div className='row' style={{marginTop:'30px'}}>
                               <div className='col-md-12'>

       <div id="contentevnetdatesco" >
                       <div className='datepickercnt' >
                        <div className='f13'>
                                    <div><span className='cl1'></span>Urgent</div>
                                    <div><span className='cl2'></span>Travaux</div>
                                    <div><span className='cl3'></span>Devis</div>
                                  </div>
                        </div>

        </div>


        <FullCalendar
 
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        weekends={true}
        initialView="dayGridMonth"
        headerToolbar={{
        start: 'title,prev,next',
        center: 'customButtons',   
        end: 'dayGridMonth,timeGridWeek' 
        }}
        locale={'fr'}
        events={all_prof_missions_calendar}
        // eventClick={handleDateClick}
        eventClick={event => handleDateClick(event)}
      />


                               </div>
                        

                              </div>

                         </div>
                    </div>

                



                  </div>

            </section>
<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>

          
 
           

        </Modal.Body>
        <Modal.Footer>
          
         
        </Modal.Footer>
</Modal>

        </div>
    );
}

export default Page;