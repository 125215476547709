import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenu,DashbordHeader } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"
import { getAllCategories, getMetier } from "../../../actions/pages";
import { updateInfoUser, getProfessionnalInfos } from "../../../actions/user";
import { BASE_URL, BASE_URL_PROD } from "../../../components/config/keys";
import axios from "axios";
import { geocodeByAddress,getLatLng }  from 'react-google-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';

const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { data } = useSelector(state => state.pages)
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categ, setCateg] = useState('');
    const [rib, setRib] = useState('');
    const [loading, setLoading] = useState(false);

    
    const [address,setAddress]=useState("");
    const [city,setCity]=useState("");
    const [value, setValue] = useState(null);
   


    const { metiers } = useSelector(state => state.pages)
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
     const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        let go=true;

   


        if(go){
           dispatch(updateInfoUser(formDataObj,user.id,'professionnal'));
        }
        
    
    }

    
    const [metier, setSecteurActivite] = useState(user.type_logement);
    const [type_entreprise, setTypeEntreprise] = useState(user.type_entreprise);
    const [societe, setSocieteName] = useState(user.societe_name);
    const [nb_employes, setNbEmploye] = useState(user.nb_employes);
    const [adresse, setAdresse] = useState(user.adresse);
    const [codepostal, setZipCode] = useState(user.zipcode);
    const [ville, setVille] = useState(user.ville);
    const [email, setEmail] = useState(user.email);
    const [mobile, setPhone] = useState(user.telephone);
    const [siret, setSiret] = useState(user.siret);
    const [img_kbis_val, setImageKbis] = useState(user.kbis);
    const [img_assurance_val, setImageAssurance] = useState(user.assurance);
    const [password, setPassword] = useState('');
    const [codenaf, setCodeNaf] = useState(user.codenaf);
    const [idursaf, setIdUrsaf] = useState(user.idursaf);
    const [lat,setLat]=useState(user.lat ? user.lat : '' );
    const [lng,setLng]=useState(user.lng ? user.lng : '');

    const onChangeData = (e) => {
      if(e.target.name == 'metier')
        setSecteurActivite(e.target.value)
      else if(e.target.name == 'type_entreprise')
        setTypeEntreprise(e.target.value)
      else if(e.target.name == 'nb_employes')
        setNbEmploye(e.target.value)
      else if(e.target.name == 'adresse')
        setAdresse(e.target.value)
      else if(e.target.name == 'codepostal')
        setZipCode(e.target.value)
      else if(e.target.name == 'ville')
        setVille(e.target.value)
      else if(e.target.name == 'email')
        setEmail(e.target.value)
      else if(e.target.name == 'mobile')
        setPhone(e.target.value)
      else if(e.target.name == 'siret')
        setSiret(e.target.value)
      else if(e.target.name == 'codenaf')
        setCodeNaf(e.target.value)
      else if(e.target.name == 'idursaf')
        setIdUrsaf(e.target.value)
    }

    useEffect(() => {      
        
      dispatch(getMetier());
        if(langset == false){
             

            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])

    
     const handleSetCategorie = (categ) => {
            
            let newcategories=[...categories];
            var index = newcategories.indexOf(categ)
            if( index > -1){
               newcategories.splice(index, 1);
               setCategories(newcategories);
 
            }
            else{

               newcategories.push(categ);
               setCategories(newcategories);

            }            
    }


      const createPdf = (file) => {
      
      setLoading(true)
      const data = new FormData();
      data.append('membreId', user.id);
      data.append('file', file);
      data.append('type', 'ribs');
      apiClient().post(`/insertimage`,data)
            .then((res) => {
               setRib(res.data.name)  
               setLoading(false)             
            })

  }

  const onChangeFile = (e) => {
		let files = e.target.files || e.dataTransfer.files;

		if (!files.length)
		return;

    if(files[0].type=='image/png' || files[0].type=='image/jpeg' || files[0].type=='image/jpg' || files[0].type=='image/gif' || files[0].type=='application/pdf'){
      if(files[0].size <= (5*1000000)){
          let type_file = ''
          if(files[0].type=='application/pdf')
            type_file = 'pdf'
          else
            type_file = "jpg"
          createImage(files[0], e.target.name, type_file);
      }
      else{
          alert('Votre pièce-jointe ne doit  pas dépasser cette taille: 5 MO.');
      }
    }
    else{
        alert("Ce Format document n'est pas supporté ! Les formats acceptés sont (PDF,JPG,JPEG,PNG,GIF)");
    }
	}

	function createImage(file, tag_name, type_file) {
		// $('#divLoad').show()
		let reader = new FileReader();
		reader.onload = (e) => {
		  fileUpload(e.target.result, tag_name, type_file);
		};
		reader.readAsDataURL(file);
	}

	function fileUpload (values, tag_name, type_file){
			var self = this;
		    axios.post(BASE_URL+'/insertimage', {'file':values, 'type':'profilUpdate', 'type_file':type_file, 'user_id':user.id}, { headers: {"Accept": "application/json",
	                "Content-Type": "application/json"}}).then((res) => {
                    if(tag_name == 'img_kbis')
                      setImageKbis(res.data.name)
                      else if(tag_name == 'img_assurance')
                      setImageAssurance(res.data.name)
                    // else if(tag_name == 'img_profil')
                    //   setImageProfil(res.data.name)
                    // else if(tag_name == 'img_logo')
                    //   setImageLogo(res.data.name)
                // $('#divLoad').hide()
			}).catch((e)=>{
		});
	}


    const uploadPdf = (e) => {
        

    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
    return;

      if(files[0].type=='application/pdf'  ){
              if(files[0].size <= (2*1000000)){
                 createPdf(files[0]);
              }
              else{

                 alert(t('Votre pièce-jointe ne doit pas dépasser cette taille: 2 MO.'));
              }
      }
      else{
         alert(t("Ce format n'est pas supporté ! Le format accepté est (PDF)"));
      }

        const param = {
                id:user.id,
              //files_img:this.state.files,
            }
    }



       const handleChange = address => {
  
        setAddress(address);
        setAdresse(address)
      };
     const selectValuemap=(value)=>{

     
        setAddress(value);
        setValue(value);
        setAdresse(value)
        
        geocodeByAddress(value)
         .then(results => getLatLng(results[0]))
         .then(({ lat, lng }) =>{

            setLat(lat);
            setLng(lng);
         }
         );

     }

  const renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions,loading }) => (
        
        <div className="autocomplete-container autocomplete-root">
                                                   
             
                 <input required name="adresse" {...getInputProps({
                          placeholder: 'Adresse professionnelle',
                          className: '',
                      })} />
                 

        <div className="autocomplete-dropdown-container my-2 ">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion,index) => (
            
              <div key={index}  className="listitem" {...getSuggestionItemProps(suggestion)}>
                <span >{suggestion.description}</span>
              </div>
            ))}
          </div>
        </div>

      
      );




    return (
        <div className="myccount editprofil " >
            
                       <section className="gray-bg main-dashboard-sec dashboard myccount" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenu src='profil'/>
                                 
                            </div>


                         <div className='col-md-9 dashbordassitantcontent' >

                             <div className='tab' id=''>


                              <DashbordHeader src={'w'} />

                             <div className='row' style={{marginTop:'30px'}}>
                               <div className='col-md-8'>

                                 
                      

                                   

                              <form  onSubmit={handleSubmit} >

                               <div className='rowtitel'>
                                  
                                  <img src='/images/icones/iconeentreprise.png' />
                                  <h3 className='htri1'>Votre entreprise </h3>

                               </div>

                          

                              

                              <input type="hidden" name="img_kbis_val" value={img_kbis_val} />
                          <div className="profile-edit-container fl-wrap block_box">
                                    <div className="custom-form">

                                    <div className="row mrgbtm">
                                            <div className="col-sm-6">
                                            <label>Secteur d'activité</label>
                                              <select name='metier' value={metier} onChange={e => onChangeData(e)} className="form-control" >
                                                <option value=''>Secteur d'activité</option>
                                                {
                                                metiers != undefined && metiers != '' ?
                                                metiers.data.map((item) => (
                                                  <option value={item.id}>{item.label}</option>
                                                ))
                                                :
                                                ""
                                                }
                                              </select>
 
                                            </div>
                                             <div className="col-sm-6">
                                             <label>Type d'entreprise</label>
                                              <select name='type_entreprise' value={type_entreprise} onChange={e => onChangeData(e)}  className="form-control" >
                                                
                                                <option value=''>Type d'entreprise</option>
                                                <option value='1'>SARL</option>
                                                <option value='4'>SASU</option>
                                                <option value='5'>Micro entreprise</option>
                                                <option value='6'>SAS</option>
                                                <option value='7'>Agence immobilière</option>
                                                <option value='8'>Indépendant</option>
                                                <option value='9'>Auto entrepreneur</option>
                                                <option value='2'>Individuelle</option>
                                                <option value='10'>SCI</option>
                                                <option value='11'>investisseur immobilier</option>
                                                <option value='3'>Anonyme</option>
                                              </select>
                                            </div>
                  
                                    </div>


                                        <div className="row mrgbtm">
                                            <div className="col-sm-6">
                                              
                                                <label>Nom de la société </label>
                                                <input placeholder="" name="societe" type="text" className="form-control"  onChange={e => onChangeData(e)} value={societe} required/>                                              
                                            </div>
                                             <div className="col-sm-6">
                                              
                                                <label>Nombre d'employés </label>
                                                <input placeholder="" name="nb_employes" type="text" className="form-control"  onChange={e => onChangeData(e)} value={nb_employes} required/>                                              
                                            </div>
                                         </div>
                                      <div className="row mrgbtm">
                                            <div className="col-sm-6">
                                              
                                                <label>Adresse professionnelle  </label>
                                                 
                                                <input type='hidden' name='lat' value={lat} />
                                                <input type='hidden' name='lng' value={lng} />
                                                     
                                                        <PlacesAutocomplete  value={adresse} onChange={handleChange}   onSelect={selectValuemap} searchOptions={{ types: ['address'], componentRestrictions: {country: "fr"} }}>
                                                            {renderFunc}
                                                        </PlacesAutocomplete>


                                            </div>
                                             <div className="col-sm-6">
                                              
                                                <label>Code postale </label>
                                                <input placeholder="" name="codepostal" type="text" className="form-control"  onChange={e => onChangeData(e)} value={codepostal} required/>                                              
                                            </div>
                                           
                                         </div>

                                          <div className="row mrgbtm">
                                           
                                             <div className="col-sm-6" style={{display:'none'}}>
                                              
                                                <label>Ville </label>
                                                <input placeholder="" name="ville" type="text" className="form-control"  onChange={e => onChangeData(e)} value={ville} required/>                                              
                                            </div>
                                         </div>

                                            <div className="row mrgbtm">
                                            <div className="col-sm-6">
                                              
                                                <label>E-mail professionnel </label>
                                                <input placeholder="" name="email" type="text" className="form-control"  onChange={e => onChangeData(e)} value={email} required/>                                              
                                            </div>
                                             <div className="col-sm-6">
                                              
                                                <label>Mot de passe </label>
                                                <input placeholder="" name="password" type="password" className="form-control"  onChange={e => onChangeData(e)} value={password}/>                                              
                                            </div>
                                             
                                         </div>

                                              <div className="row mrgbtm">
                                            <div className="col-sm-6">
                                              
                                                <label>Téléphone </label>
                                                <input placeholder="" name="telephone" type="text" className="form-control"  onChange={e => onChangeData(e)} value={mobile} required/>                                              
                                            </div>
                                             <div className="col-sm-6">
                                              
                                                <label>Siret </label>
                                                <input placeholder="" name="siret" type="text" className="form-control"  onChange={e => onChangeData(e)} value={siret} required/>                                              
                                            </div>
                                         </div>

                                        <div className="row mrgbtm">
                                            <div className="col-sm-6">
                                              
                                                <label>Code NAF </label>
                                                <input placeholder="" name="codenaf" type="text" className="form-control"  onChange={e => onChangeData(e)} value={codenaf}/>                                              
                                            </div>
                                             <div className="col-sm-6">
                                              
                                                <label>Identification Ursaff </label>
                                                <input placeholder="" name="idursaf" type="text" className="form-control"  onChange={e => onChangeData(e)} value={idursaf}/>                                              
                                            </div>
                                         </div>

                                              <input type="hidden" name="img_assurance_val" value={img_assurance_val} />

                                           <div className=" mrgbtm">
                                            <div className="col-sm-2" style={{width: '100%'}}>
                                              
                                                <label>Télécharger votre KBIS </label>
                                            <div className="custom-form" style={{cursor:'pointer'}}>
                                        <div className="row " style={{border:'1px solid #e5e7f2',paddingTop: '15px',paddingBottom: '9px'}}>

                                          <div className='col-md-6'>

                                            <label  for="imgprofil" className="">

                                             <input name="img_kbis" className="inputFileInvis1" type="file"  onChange={onChangeFile} />

                                           
                                              {
                                                (img_kbis_val !='' &&  img_kbis_val !=null) ? img_kbis_val 
                                                :
                                           
'cliquez ici pour sélectionner.' 
                                              }

                                                

                                            </label>
                                          </div>
                                          <div className='col-md-6'>

                                         

                                          { 
                                            loading &&  <img src='/images/loading.gif' />
                                          }
 
                                           
                                          </div>

                                        </div>

                                    </div>



                                                 
                                            </div>
                                              
                                         </div>

<br/>
                                <div className=" mrgbtm">
                                            <div className="col-sm-2" style={{width: '100%',marginTop:'20px'}}>
                                              
                                                <label>Téléchargez votre Attestation d'assurance </label>
                                            <div className="custom-form" style={{cursor:'pointer'}}>
                                        <div className="row " style={{border:'1px solid #e5e7f2',paddingTop: '15px',paddingBottom: '9px'}}>

                                          <div className='col-md-6'>

                                            <label  for="imgprofil" className="">

                                             <input name="img_assurance" className="inputFileInvis1" type="file"  onChange={onChangeFile} />

                                           
                                              {
                                                (img_assurance_val !='' &&  img_assurance_val !=null) ? img_assurance_val
                                                :
                                           
'cliquez ici pour sélectionner.' 
                                              }

                                                

                                            </label>
                                          </div>
                                          <div className='col-md-6'>

                                         

                                          { 
                                            loading &&  <img src='/images/loading.gif' />
                                          }
 
                                           
                                          </div>

                                        </div>

                                    </div>



                                                 
                                            </div>
                                              
                                         </div>



  <div className="row" style={{marginBottom:'10px'}}>
                                            <div className="col-sm-12">
                                                 <button type="submit" className="btn float-btn color2-bg btn9876 w100"> Mettre à jour</button>
                                            </div>
                                        </div>
 
                                      
                                    </div>
                                </div>



                  
 

                        

 
                             
                                      
                             








                                   </form>

                                    

                               </div>
                               <div className='col-md-4 '>
                                
                            

                                 <div className='w100'>
                                      <img src='/images/Dashboard_Mon-compte1.png' />
                                 </div>
                                 <div className='w100' style={{marginTop:'20px'}}>
                                      <img src='/images/Dashboard_Mon-compte2.png' />
                                 </div>
                                 

                                </div>

                             </div>


                                 
                              </div>
 

                              

                         </div>
                    </div>
            </section>

        </div>
    );
}

export default Page;