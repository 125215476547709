import React,{ useState, useEffect } from "react";
import './styles.scss';
import { Container, Navbar, Nav } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { logo,addtocart } from "../../assets/images";
import { LOGOUT_USER } from "../../constants/actions";
import { BiUser,BiLogOut} from 'react-icons/bi';
import { CURRENCY } from "../../components/config/keys";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";

const Component = ({back}) => {
    const { t, i18n } = useTranslation();
    const history = useHistory()
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { data:basket,total } = useSelector(state => state.basket)
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [value, setValue] = useState(false);
    const [scroll, setScroll] = useState(false);

    const queryParams = new URLSearchParams(window.location.search);

    const typepush = queryParams.get('typepush');
    const token = queryParams.get('token');


    useEffect(() => {
        
        if(!scroll){
            window.scrollTo(0,0)
            setScroll(true)
        }
        // dispatch({type:LOGOUT_USER});
         window.addEventListener("scroll", isSticky);
        return () => {
          window.removeEventListener("scroll", isSticky);
        };
 
    }, []);


       useEffect(() => {
        
         
       if(token){

          localStorage.setItem('token', token);
 
       }

        if(typepush){

        
          localStorage.setItem('typepush', typepush);
       }
 
    }, []);


    const isSticky = (e) => {
    const header = document.querySelector(".boxshadowheader");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
    };



    const handleLogout = () =>{
        dispatch({type:LOGOUT_USER});
    }

      const handleClick = () =>{
        setValue(!value)
    }

    const findAwatcher = () =>{
        window.location.href='/find-a-watcher/';
    }
    const handleLangChange = (ln) =>{
         if(ln=='fr'){
           i18n.changeLanguage('fr')
           localStorage.setItem("currentlang",'fr')
         }
         else{
          i18n.changeLanguage('en')
          localStorage.setItem("currentlang",'en')
         }
    }

    const handleLink = (slug) => {
        history.push(slug);
    }

    return (
   
                <header className="main-header boxshadowheader">
     <div className="container small-container center">
           
                
                <a href="#" onClick={()=>handleLink('/')}  className="logo-holder"><img src="/images/logo.png" alt=""/></a>
                 
             
                 
                  {
                 back==1 &&
                    <a style={{    top: 16, left: 19}} className="ps-goback" href="javascript:void(0)" onClick={() => history.goBack()}><img src="/images/goback.png" alt=""/> </a>
                  }
                
                <div onClick={()=>handleClick()} className={value==true ?"nav-button-wrap color-bg vismobmenu_btn" : "nav-button-wrap color-bg"  }>
                    <div className="nav-button">
                        <span></span><span></span><span></span>
                    </div>
                </div>
                
                <div className={value==true ? " main-menu vismobmenu" : "nav-holder main-menu" }>
                    <nav className={value==true ? " menusb sliding-menu" : "" }>
                        <ul className="no-list-style">
                            <li>
                                <a href='#section-services' onClick={()=>handleLink('/')}>Services</a>
                            </li>

                            <li >
                                <a onClick={()=>handleLink('/formules')}>Formules</a> 
                            </li>  
              
                             <li>
                             {user.id ?

                                <a onClick={()=>handleLink(user.type==2 ? '/client/interventions/' : '/assistants/dashboard' )} >Suivre votre ticket</a>
                                :
                                <a onClick={()=>handleLink('/login')} >Suivre votre ticket</a>

                            }
                            </li>

                             <li>
                               {user.id ?

                                     <a onClick={()=>handleLink(user.type==2 ? '/my-account/' : '/assistants/dashboard')} className=" avatar-img show-reg-form nav-link-resiter" ><i className="fal fa-user"></i>{t('Mon compte')}</a>
                                    :
                                     <a href='#' onClick={()=>handleLink('/login')} >Connexion/Inscription</a>

                                }
                            
                            </li>

                            <li>
                                    <a onClick={()=>handleLink('/contact')}  href="#" className='show-help-center '   rel="nofollow"><img className='hotline' src='/images/iconetel.png' /><div className='hotlinenumber'>09 70 99 84 45</div></a>
                            </li>
 
                        </ul>
                    </nav>
                </div>
                                     
  
</div>
                 
            </header>
            
    );
}

export default Component;