import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card,Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenuClient,DashbordHeaderClient } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"
import { getAllCategories, getAllMissions, saveMessage, getMessages, setAsReaded,getCountUnreadMsg } from "../../../actions/pages";
import { updateInfoUser } from "../../../actions/user";

const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { data } = useSelector(state => state.pages)
    const { all_missions, all_messages, all_sent_messages } = useSelector(state => state.pages)
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const [status, setStatus] = useState('');
    const [categories, setCategories] = useState([]);
    const [categ, setCateg] = useState('');
    const [rib, setRib] = useState('');
    const [interventionId, setIntervention] = useState();
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [intervention_error, setInterventionError] = useState(false);
    const [message_error, setMessageError] = useState(false);
    const [succesSave, setSuccesSave] = useState(-1);
    const [messageType, setMessageType] = useState(1);
    const [searchedKey, setSearched] = useState(localStorage.getItem('searchedTicket'));


    const [show, setShow] = useState(false);
    const [showDetail, setShowDetail] = useState(false);

    
    const [detailTitle, setDetailTitle] = useState('');
    const [detailBody, setDetailBody] = useState('');

    const handleClose = () => setShow(false);
    const handleCloseDetail = () => setShowDetail(false);

    const [tab, setTab] = useState(2);

    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
     const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        let go=true;

   


        if(go){
           dispatch(updateInfoUser(formDataObj,user.id,'watcher'));
        }
        
    
    }

    const showMessageDetail = (ind) => {
      setShowDetail(true)
      setDetailTitle('')
      setDetailBody('')
      if(messageType == 2){
        setDetailTitle(all_messages[ind].ref)
        setDetailBody(all_messages[ind].msg)

        if(all_messages[ind].status == 0){
          dispatch(setAsReaded(all_messages[ind].id)).then(() => {
            dispatch(getCountUnreadMsg(user.id))
            dispatch(getMessages(user.id, 2))
          })
        }
      }else{
        setDetailTitle(all_sent_messages[ind].ref)
        setDetailBody(all_sent_messages[ind].msg)
      }
    }

const changeIntervention = (e) => {

  setIntervention(e.target.value);
}
const changeMessage = (e) => {

  setMessage(e.target.value);
}

const changeTypeMessage = (valeur) => {
  setMessageType(valeur)
}
     const settab1= (e) => {
        e.preventDefault();
        
        setTab(1)
    }

    const showNewMsg = () => {
      setShow(true)
    }

    const settab2= (e) => {
        e.preventDefault();
        
        setTab(2)
    }

    const sendMessage = () => {
      setInterventionError(false)
      setMessageError(false)
      if(interventionId == '' || interventionId == 0 || interventionId == undefined)
        setInterventionError(true)
      if(message == '' || message == undefined)
        setMessageError(true)
      dispatch(saveMessage(user.id, interventionId, message, 1)).then((res) => {

          if(res.success){
            setSuccesSave(1)
            dispatch(getMessages(user.id, 1))
          }
          else
            setSuccesSave(0)           
      })
    }

  const onchangeSearch= (e) => {
  }
  const changeStatus= (e) => {
      setStatus(e.target.value);
      dispatch(getAllMissions(user.id, e.target.value, 'customer'))
  }
    useEffect(() => {

        dispatch(getAllMissions(user.id, status, 'customer', searchedKey))
        localStorage.removeItem('searchedTicket')
        dispatch(getMessages(user.id, 2))
        dispatch(getMessages(user.id, 1))
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])

    
     const handleSetCategorie = (categ) => {
            
            let newcategories=[...categories];
            var index = newcategories.indexOf(categ)
            if( index > -1){
               newcategories.splice(index, 1);
               setCategories(newcategories);
 
            }
            else{

               newcategories.push(categ);
               setCategories(newcategories);

            }            
    }

      const goToDetails = (id) => {
        history.push("/detail/mission/"+id)
      }

      const createPdf = (file) => {
      
      setLoading(true)
      const data = new FormData();
      data.append('membreId', user.id);
      data.append('file', file);
      data.append('type', 'ribs');
      apiClient().post(`/insertimage`,data)
            .then((res) => {
               setRib(res.data.name)  
               setLoading(false)             
            })

  }

    const uploadPdf = (e) => {
        

    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
    return;

      if(files[0].type=='application/pdf'  ){
              if(files[0].size <= (2*1000000)){
                 createPdf(files[0]);
              }
              else{

                 alert(t('Votre pièce-jointe ne doit pas dépasser cette taille: 2 MO.'));
              }
      }
      else{
         alert(t("Ce format n'est pas supporté ! Le format accepté est (PDF)"));
      }

        const param = {
                id:user.id,
              //files_img:this.state.files,
            }
    }

    return (
        <div className="myccount editprofil messages  " >
            
                       <section className="gray-bg main-dashboard-sec dashboard myccount history" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenuClient src='interventions'/>
                                 
                            </div>


                         <div className='col-md-9 dashbordassitantcontent' >

                             <div className='tab' id=''>


                              <DashbordHeaderClient src={'w'} />


                            <div className='ffform'>

                           

                             <div className='row custom-form ' style={{marginTop:'0px'}}>

                             <table  id="datatable_valides" className="table table-separate  table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                                    <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Client</th>
                                        <th>N Ticket</th>
                                        <th>Photos</th>
                                        <th>Détails</th>
                                        <th>Vidéo</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                    all_missions != undefined && all_missions.length > 0 ?
                                    (
                                      all_missions.map((mission) => (
                                      <tr>
                                        <td>{mission.date_mission}</td>
                                        <td>{user.nom+' '+user.prenom}</td>
                                        <td>{mission.ref}</td>
                                        <td>
                                          {
                                            mission.image1_url != '' ?
                                            <a href={mission.image1_url} target="_blank"><img  style={{width: '18px'}}  src={'/images/icones/iconetélécharger.png'} />&nbsp;&nbsp;</a>
                                            :
                                            ""  
                                          }
                                          {
                                            mission.image2_url != '' ?
                                            <a href={mission.image2_url} target="_blank"><img  style={{width: '18px'}}  src='/images/icones/iconetélécharger.png' />&nbsp;&nbsp;</a>
                                            :
                                            ""  
                                          }
                                          {
                                            mission.image3_url != '' ?
                                            <a href={mission.image3_url} target="_blank"><img  style={{width: '18px'}}  src='/images/icones/iconetélécharger.png' /></a>
                                            :
                                            ""  
                                          }
                                        </td>
                                        <td><a onClick={() => goToDetails(mission.id)} href='javascript:void(0)' ><img style={{width: '28px'}} src='/images/icones/iconetransformerfacture.png' /></a></td>
                                        <td>
                                          {
                                            mission.video1_url != '' ?
                                            <a href={mission.video1_url} target="_blank"><img  style={{width: '18px'}}  src={'/images/icones/iconetélécharger.png'} />&nbsp;&nbsp;</a>
                                            :
                                            ""  
                                          }
                                          {
                                            mission.video2_url != '' ?
                                            <a href={mission.video2_url} target="_blank"><img  style={{width: '18px'}}  src='/images/icones/iconetélécharger.png' />&nbsp;&nbsp;</a>
                                            :
                                            ""  
                                          }
                                          {
                                            mission.video3_url != '' ?
                                            <a href={mission.video3_url} target="_blank"><img  style={{width: '18px'}}  src='/images/icones/iconetélécharger.png' /></a>
                                            :
                                            ""  
                                          }

                                        </td>
                                      </tr>
                                    ))
                                  )
                                  :
                                  <tr>
                                    <td colSpan='6'>Aucune Intervention</td>
                                  </tr>
                                  }
                                    </tbody>

                                </table>

                             </div>


                           </div>

                           <img style={{width:"100%"}} src="/images/intervention_1.png"></img>
                                 
                              </div>
 

                              

                         </div>
                    </div>
            </section>

            <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                        Nouveau Message
                  </Modal.Header>
                  <Modal.Body>


                  <select name="type" onChange={changeIntervention} required="" className="selectform bgselect">
                      <option value="">Choisir Intervention</option>
                      {
                                    all_missions != undefined && all_missions.length > 0 ?
                                    (
                                      all_missions.map((mission) => (
                                        mission.status > 0 ?
                                        <option value={mission.id}>{mission.nom+' '+mission.prenom} ({mission.ref} {mission.label})</option>
                                        :
                                        ''
                                      ))
                                  )
                                  :
                                  ''
                      }
                  </select>
                  
                  {
                      intervention_error ?
                      <div class="alert alert-danger custom-form col-md-12" style={{clear:'both'}} role="alert">
                        Veuillez choisir une mission
                      </div>
                      :
                      ''
                  }
                  <textarea  className="form-control" placeholder="Message" onChange={changeMessage}></textarea>
                    <br/>
                  {
                      message_error ?
                      <div class="alert alert-danger custom-form col-md-12" style={{clear:'both'}} role="alert">
                        Veuillez saisir votre message
                      </div>
                      :
                      ''
                  }
                  {
                    succesSave == 0 ?
                    <div class="alert alert-danger col-md-12" style={{clear:'both', marginTop:'5px'}} role="alert">
                        Un problème est survenu lors de l'enregistrement !
                    </div>
                    :
                    ""
                  }
                  {
                    succesSave == 1 ?
                    <div class="alert alert-success col-md-12" style={{clear:'both', marginTop:'5px'}} role="alert">
                      Message envoyé avec succès !
                    </div>
                    :
                    ""
                  }
                  </Modal.Body>
                  <Modal.Footer>
                    
                    <Button variant="success" onClick={sendMessage}>
                               Envoyer
                    </Button>
                  </Modal.Footer>
          </Modal>
            <Modal show={showDetail} onHide={handleCloseDetail}>
                  <Modal.Header closeButton>
                        Ticket {detailTitle}
                  </Modal.Header>
                  <Modal.Body>
                  {detailBody}
                  </Modal.Body>
          </Modal>
        </div>
    );
}

export default Page;