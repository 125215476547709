import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenu,DashbordHeader } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"
import { getInterventions, getElements, savePrice, getProfPrices } from "../../../actions/pages";
import { updateInfoUser } from "../../../actions/user";
import Switch from '@material-ui/core/Switch';

const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { data } = useSelector(state => state.pages)
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categ, setCateg] = useState('');
    const [rib, setRib] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorSave, setErrorSave] = useState(false);
    const [addnewservice, setaddnewservice] = useState(false);
    const [newservice, setNewService] = useState('');
    const [elements_metiers, setElementMetiers] = useState('');

    
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const label = { inputProps: { 'aria-label': '' } };
    const { interventions, prof_elems_prices } = useSelector(state => state.pages)

     const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
    
        
    
    }

     const savenewservice = async (e) => {
        e.preventDefault();

     
       

    const membreData = {
        id: user.type_logement,
        element:newservice
    }

    return apiClient().post(`/professional/saveNewElement`, membreData)
          .then((res) =>
           { 

            if(res.data.succes==1){
                setaddnewservice(false)
                setNewService('')
                alert('Votre demande est en cours de traitement ! ')
            }
 

         })

    
        
    
    }


    const handleClickSavePrice = (id) => {
      setErrorSave(false)
      let element_id = id
      let min_price = document.getElementById('min_'+id).value
      let max_price = document.getElementById('max_'+id).value
      let isEnabled = document.getElementById('isEnabled_'+id).checked == true ? 1 : 0
      if((min_price == '' || max_price == '') && isEnabled == 1)
         setErrorSave(true)
      else
         dispatch(savePrice(user.id,element_id, min_price, max_price, isEnabled));
      // alert($('#min_'+id).val())
      // alert($('#max_'+id).val())
      // console.log(document.getElementById('min_'+id).value);
    }

    useEffect(() => {
      // dispatch(getInterventions(user.type_logement));

      dispatch(getProfPrices(user.id))
      
      dispatch(getElements(1, user.type_logement)).then((res) => {
         setElementMetiers(res);
      })
    }, [])

    
 
   
 

 

    return (
        <div className="myccount editprofil editservices " >
            
                       <section className="gray-bg main-dashboard-sec dashboard myccount" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenu src='tarification'/>
                                 
                            </div>


                         <div className='col-md-9 dashbordassitantcontent' >

                             <div className='tab' id=''>


                              <DashbordHeader src={'w'} />

                             <div className='row' style={{marginTop:'30px'}}>
                               <div className='col-md-12'>

                                 
                              <form  onSubmit={handleSubmit} >

                               <div className='rowtitel'>
                                  
                                  <img src='/images/icones/iconeservicesettarifs.png' />
                                  <h3 className='htri1'>Vos services et tarifs </h3>

                               </div>



                          

                              

                          <div className="profile-edit-container fl-wrap block_box">
                                    <div className="custom-form">
                                      <div className="row mrgbtm">
                                          {
                                             errorSave ?
                                             <div class="alert alert-danger col-md-12" style={{clear:'both', marginTop:'5px'}} role="alert">
                                                Veuillez remplir les prix des services actifs !
                                             </div>
                                                :
                                                ""
                                             }
                                            <div className="col-sm-8">
                                            {
                                                elements_metiers.data != undefined && elements_metiers.data.length > 0 ?
                                                elements_metiers.data.map((item) => (
                                                   <div className='rowflex'>
                                                       <div className='item labelswt'>
                                                          {
                                                            prof_elems_prices[item.id] && prof_elems_prices[item.id].status == 1 ?
                                                            <label> <Switch {...label} defaultChecked size="small" id={'isEnabled_'+item.id} color="secondary"/> {item.label}</label>
                                                            :
                                                            <label> <Switch {...label} size="small" color="secondary" id={'isEnabled_'+item.id} /> {item.label}</label>
                                                          }
                                                          
                                                       </div>
                                                       <div className='item'>
                                                          <label><strong>De</strong> <input defaultValue={prof_elems_prices[item.id] ? prof_elems_prices[item.id].min_price : ''} placeholder="" id={"min_"+item.id} type="text" className="form-control" /> <span className='euro'>€</span> </label>

                                                       </div>
                                                       <div className='item'>
                                                          <label><strong>à</strong> <input defaultValue={prof_elems_prices[item.id] ? prof_elems_prices[item.id].max_price : ''} placeholder="" id={"max_"+item.id} type="text" className="form-control" /> <span className='euro'>€</span> </label>
                                                       </div>
                                                        <div className='item'>
                                                            <button type="submit"  onClick={() => handleClickSavePrice(item.id)} className="btn float-btn color2-bg btn9876 btcustom"> Enregistrer</button>
                                                       </div>
                                                   </div>
                                                ))
                                                :
                                                ""
                                             }
                                            </div>
                                            
                                            <div className="col-sm-4">
                                               

                                               <div className='mainouvre'>

                                                <img src='/images/icones/iconenfsurdevisblocdroit.png' />

                                                 <p>

                                                    Notez que votre tarif comprend le déplacement, 1h de main d'œuvre et les petites fournitures.

                                                 </p>

                                               </div>
                                                
                                            </div>



                                           
                                         </div>

                           
 
                                      
                                    </div>
                                </div>



                                   </form>


                        <form  onSubmit={handleSubmit} >

                               <div className='rowtitel'>
                                  
                                 
                                  <h3 className='htri1'>Types d'intervention suggérés pour votre profession </h3>

                               </div>



                          

                              

                          <div className="profile-edit-container fl-wrap block_box">
                                    <div className="custom-form">

                                      <div className="row mrgbtm">
                                            <div className="col-sm-12">
                                             <div className='rowflex ' style={{    overflowY: 'auto',
    paddingBottom: '10px'}}>
                                             {
                                                elements_metiers.data != undefined && elements_metiers.data.length > 0 ?
                                                elements_metiers.data.map((item) => (
                                                   <div className='item itemtag '>
                                                      <label> {item.label} <span  style={{display:'none'}}><img src='/images/icones/iconefermercroixpetit.png' /></span></label>
                                                   </div>
                                                ))
                                                :
                                                ""
                                             }


                                             </div>
                                              
      
                                            </div>
                                         </div>



                                    <div className="row mrgbtm">
                                            <div className="col-sm-12">
                                               <a className='linkorgn' href='javascript:void(0)' onClick={()=>setaddnewservice(true)} >+ Ajoutez un autre service</a>
                                               <br/>
                                                {
                                                   addnewservice &&
                                                   <input placeholder="Ajouter un nouveau service" id="" type="text" class="form-control"  onChange={e => setNewService(e.target.value)} />
                                                }
                                               <br/>
                                               <br/>
                                                <button type="button" onClick={(e)=>savenewservice(e)} className="btn float-btn color2-bg btn9876 btcustom lileft"> Enregistrer</button>
                                             </div>
                                    </div>





                     
 
                                      
                                    </div>
                                </div>



                                   </form>

                                    

                               </div>
                  

                             </div>


                                 
                          </div>
 

                              

                         </div>
                    </div>
            </section>

        </div>
    );
}

export default Page;