import React, { useEffect,useState } from "react";
import './styles.scss'; 
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { AssistantItem } from "../../components";
import { useParams } from "react-router";
import { apiClient } from "../../actions/api"
import Slider from '@material-ui/core/Slider';
import { useTranslation } from 'react-i18next';
import { saveMission } from "../../actions/pages";
import { registerUser,loginUser,loginUserFacebook,loginUserMission,registerUserMission } from "../../actions/user";
import { useHistory } from "react-router";
import FacebookLogin from 'react-facebook-login';
import {getMetier, getAllCities } from "../../actions/pages";
import { BASE_URL, BASE_URL_PROD } from "../../components/config/keys";
import axios from "axios";
import { geocodeByAddress,getLatLng }  from 'react-google-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';


const Page = () => { 
    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)
    const dispatch = useDispatch();
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
 
    const { user } = useSelector(state => state.user)
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const [tab, setTab] = useState(1);
    const [tabins, setTabins] = useState(1);
  
    const { metiers, allCities } = useSelector(state => state.pages)
   
  const [img_kbis_val, setImageKbis] = useState("");
  const [img_assurance_val, setImageAssurance] = useState("");
  const [img_profil_val, setImageProfil] = useState("");
  const [img_logo_val, setImageLogo] = useState("");

  const [type_connex, setTypeConnex] = useState("customer");

  const [typeLog, setTypeLog] = useState(0);

  const [password1, showPassword1] = useState(false)
  const [password2, showPassword2] = useState(false)
  const [password3, showPassword3] = useState(false)

    const [codepostal, setCodePostal] = useState('');
    const [address,setAddress]=useState("");
    const [city,setCity]=useState("");
    const [value, setValue] = useState(null);
    const [lat,setLat]=useState("");
    const [lng,setLng]=useState("");

    const [tokenmob, settokenmob] = useState(localStorage.getItem('typepush'));


const chengeTypeConnex = (element) => {
  setTypeConnex(element)
}

  const changePasswordType = (element) => {
      if(element == 1){
        if(password1)
          showPassword1(false)
        else
          showPassword1(true)
      }
      else if(element == 2){
        if(password2)
          showPassword2(false)
        else
          showPassword2(true)
      }
      else if(element == 3){
        if(password3)
          showPassword3(false)
        else
          showPassword3(true)
      }
  }

  const showEtage = (e) => {
    setTypeLog(e.target.value)
  }

  const onChangeFile = (e) => {
		let files = e.target.files || e.dataTransfer.files;

		if (!files.length)
		return;

    if(files[0].type=='image/png' || files[0].type=='image/jpeg' || files[0].type=='image/jpg' || files[0].type=='image/gif' || files[0].type=='application/pdf'){
      if(files[0].size <= (20*1000000)){
          let type_file = ''
          if(files[0].type=='application/pdf')
            type_file = 'pdf'
          else
            type_file = "jpg"
          createImage(files[0], e.target.name, type_file);
      }
      else{
          alert('Votre pièce-jointe ne doit  pas dépasser cette taille: 20 MO.');
      }
    }
    else{
        alert("Ce Format document n'est pas supporté ! Les formats acceptés sont (PDF,JPG,JPEG,PNG,GIF)");
    }
	}

	function createImage(file, tag_name, type_file) {
		// $('#divLoad').show()
		let reader = new FileReader();
		reader.onload = (e) => {
		  fileUpload(e.target.result, tag_name, type_file);
		};
		reader.readAsDataURL(file);
	}

	function fileUpload (values, tag_name, type_file){
			var self = this;
		    axios.post(BASE_URL+'/insertimage', {'file':values, 'type':'profil', 'type_file':type_file}, { headers: {"Accept": "application/json",
	                "Content-Type": "application/json"}}).then((res) => {
                    if(tag_name == 'img_kbis')
                      setImageKbis(res.data.name)
                    else if(tag_name == 'img_assurance')
                      setImageAssurance(res.data.name)
                    else if(tag_name == 'img_profil')
                      setImageProfil(res.data.name)
                    else if(tag_name == 'img_logo')
                      setImageLogo(res.data.name)
                // this.setState({
                // 	[tag_name]: res.data.name
                // })

                // $('#divLoad').hide()
			}).catch((e)=>{
		});
	}


   const handleGoTo = (slug) => {
         history.push(slug)
    }

 

    const settab1= (e) => {
        e.preventDefault();
        
        setTab(1)
    }

    const settab2= (e) => {
        e.preventDefault();
        
        setTab(2)
    }
 
    const settabins1= (e) => {
        e.preventDefault();
        
        setTabins(1)
    }

    const settabins2= (e) => {
        e.preventDefault();
        
        setTabins(2)
    }
 
   
    const handleSubmit = async (e) => {
      e.preventDefault();
    
      const formData = new FormData(e.target)
      const formDataObj = Object.fromEntries(formData.entries())
      // console.log(formDataObj);
      dispatch(registerUser(formDataObj,'customerRegistration',currentlang)).then((res) => {
          if(res==true){
           history.push('/my-account')
         }

      })

  }

    const handleSubmitUser= async (e) => {
        e.preventDefault();
      
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        dispatch(registerUser(formDataObj,'user',currentlang)).then((res) => {
           if(res==true){
           history.push('/assistants/dashboard')
         }

      });
 
    }


    const handlerconnexion = async (e) =>{
         e.preventDefault();
         const formData = new FormData(e.target)
         const formDataObj = Object.fromEntries(formData.entries())
        //  history.push('/assistants/dashboard')
        dispatch(loginUser(formDataObj,type_connex,currentlang)).then((res) => {
          if(res==true){
             if(type_connex=='user'){
                history.push('/assistants/dashboard')
            }
            else{
                history.push('/my-account')
            }
          }
            
          
          

      });
       
    }


    const componentClicked =  () => {
        
       
    }




     const responseFacebook = (response) => {

    const membreData = {
        email: response.email,
    }

    return apiClient().post(`/check/user`, membreData)
          .then((res) =>
           { 

            if(res.data.success==true){
               
                 //dispatch(loginUserFacebook(res))
            }
            else{

                 const params = {
                  email: response.email,
                  fname: response.first_name,
                  lname: response.last_name,
                  tel:'',
                  password:'EY778UEIEU76567'
                 }

                  //dispatch(registerUser(params,'user',currentlang));

            }

         })

 
       
} 
 

     useEffect(() => {

      dispatch(getMetier());
      dispatch(getAllCities());

        if(langset == false){


     
            setLangset(true)
        }

      if(user?.id){
          history.push('/')
      }
 
 

    }, [user])


        const handleChange = address => {
  
        setAddress(address);
      };
     const selectValuemap=(value)=>{

     
        setAddress(value);
        setValue(value);
        geocodeByAddress(value).then(results => setCodePostal(results[0]['address_components'][6]['long_name'])  )
        geocodeByAddress(value)
         .then(results => getLatLng(results[0]))
         .then(({ lat, lng }) =>{

            setLat(lat);
            setLng(lng);
         }
         );

     }

  const renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions,loading }) => (
        
        <div className="autocomplete-container autocomplete-root">
                                                   
             
                 <input required name="adresse" {...getInputProps({
                          placeholder: 'Adresse professionnelle',
                          className: '',
                      })} />
                 

        <div className="autocomplete-dropdown-container my-2 autocomplete-dropdown-container2">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion,index) => (
            
              <div key={index}  className="listitem" {...getSuggestionItemProps(suggestion)}>
                <span >{suggestion.description}</span>
              </div>
            ))}
          </div>
        </div>

      
      );

   
    return (
        <div className="login " >
           <section className="gray-bg small-padding no-top-padding-sec" id="sec1">
                <div className="container">
                    <div className="fl-wrap">
                        <div className="row">


            <div className="">
              
                <div className="main-register-holder tabs-act">
                    <div className="main-register fl-wrap  ">
                     
                  
                        <ul className="tabs-menu fl-wrap no-list-style">
                            <li style={{cursor:'pointer'}} className={tab==1 && "current"}><a onClick={settab1} >Connexion</a></li>
                            <li style={{cursor:'pointer'}} className={tab==2 && "current"}><a onClick={settab2} > Inscription</a></li>
                        </ul>
                                              
                        <div className="tabs-container">
                            <div className="tab" >
                                <div  className={tab==1 ? "tab-content first-tab1" :"tab-content"}>

                                
                                <div className='actionename'>
                                    <ul>
                                      <li><a onClick={() => chengeTypeConnex('user')} className={type_connex=='user' ?  "btnins7 active mrright": "btnins7 mrright"} href='javascropt:void(0)'>Professionnel</a></li>
                                      <li><a onClick={() => chengeTypeConnex('customer')} className={type_connex=='customer'  ? "btnins7 active mrright": "btnins7 mrright"} href='javascropt:void(0)'>Particulier</a></li>

                                    </ul>

                                </div>
                                    <div className="custom-form" style={{marginTop: '30px'}}>
                                        <form  name="registerform" onSubmit={handlerconnexion}  >
                                           
                                            <input name="email" type="email" required placeholder="E-mail*"/>
                                           
                                           <div className='displypass'> 
                                           <input name="password" type={password1 ? 'text':'password'} required  placeholder="Mot de passe*"  / >
                                           <a href='javascript:void(0)' onClick={() => changePasswordType(1)}><img src='/images/icones/oeil.png' /></a>
                                           </div>
                                              <div className="lost_password">
                                            <a style={{cursor:'pointer'}} onClick={()=>handleGoTo('/password/'+type_connex)}>Mot de passe oublié ?</a>
                                        </div>
                                            <button type="submit" style={{fontSize:'16px'}}  className='btn float-btn color2-bg pdpad'  > Se connecter </button>
                                            <div className="clearfix"></div>
                                          
                                        </form>
<br/>

{
  tokenmob ?
  <div></div>
  :
  <div>

  <div className="log-separator fl-wrap center"><span>OU</span></div>
                            <div className="soc-log fl-wrap" style={{    paddingTop: '0px'}}>
                                 
                                
                               <FacebookLogin
                                        appId="756894289090891"
                                        autoLoad={false}
                                        fields="first_name,last_name,email"
                                        textButton="S'identifier avec Facebook"
                                        onClick={componentClicked}
                                        callback={responseFacebook} />

                                      <div>

                                       <button  className="btn float-btn color2-bg  googleconnect" >
                                          S'identifier avec google
                                       </button>


                                      </div>


                            </div>

  </div>

}
                            
                                      
                                    </div>
                                </div>
                                
                                <div className="tab">
                                    <div  className={tab==2 ? "tab-content first-tab1" :"tab-content"}>
                                        <div className="custom-form">

                                          <div className='actionename'>
                                            <ul>
                                             <li><a onClick={settabins1} className={tabins==1 ?  "btnins7 active mrright": "btnins7 mrright"} href='javascropt:void(0)'>Particulier</a></li>
                                             <li><a onClick={settabins2} className={tabins==2  ? "btnins7 active mrright": "btnins7 mrright"} href='javascropt:void(0)'>Professionnel</a></li>

                                            </ul>

                                          </div>
                                          <br/>

                                          {
                                             tabins==1 ?

                                               <form  onSubmit={handleSubmit}  name="registerform" className="main-register-form" id="main-register-form2" style={{marginTop:'30px'}}>
                                                
                                                <div className="row">
                                                  <div className='col-md-6'>
                                                    <input name="type" type="hidden" value="2"/>
                                                    <input name="lname" type="text"    required placeholder="Nom*" />
                                                  </div>
                                                   <div className='col-md-6'>
                                                     <input name="fname" type="text"   required placeholder='Prénom*' />
                                
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className='col-md-12'>
                                                     <input name="adresse" type="text"  placeholder='Adresse*'  required />
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className='col-md-5'>
                                                    <input name="codepostal" type="text"    required placeholder="Code posta*l" />
                                                  </div>
                                                   <div className='col-md-7'>
                                                     <input name="ville" type="text"   required placeholder='Ville*' />
                                
                                                  </div>
                                                </div>

                                                <div className="row">
                                                  <div className='col-md-12'>
                                                        <input name="tel" type="text" placeholder='N° de mobile*'  required/>
                                                  </div>
                                                </div>
                                                 <div className="row">
                                                  <div className='col-md-12'>
                                                        <input name="email" type="email" placeholder='E-mail*'  required/>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className='col-md-12'>
                                                         <div className='displypass'> 
                                                       <input name="password" type={password2 ? 'text':'password'} required  placeholder="Mot de passe*"  / >
                                                       <a href='javascript:void(0)' onClick={() => changePasswordType(2)}><img src='/images/icones/oeil.png' /></a>
                                                       </div>
                                                  </div>
                                                </div>
                                                
                                                <div className="row">
                                                  <div className='col-md-12'>
                                                         <select name='type_logement' onChange={showEtage} className='selectform bgselect' >
                                                           <option value=''>Type de logement</option>
                                                           <option value='1'>Appartement</option>
                                                           <option value='2'>Maison</option>
                                                           <option value='3'>Bureau</option>
                                                           <option value='4'>Local Commercial</option>
                                                         </select>
                                                  </div>
                                                </div>
                                                {
                                                  typeLog == 1 ? 
                                                  <div className="row">
                                                    <div className='col-md-12'>
                                                           <select name='etage' className='selectform bgselect' >
                                                             <option value=''>Etage</option>
                                                             <option value='1'>1</option>
                                                             <option value='2'>2</option>
                                                             <option value='3'>3</option>
                                                             <option value='4'>4</option>
                                                             <option value='5'>5</option>
                                                             <option value='6'>6</option>
                                                             <option value='7'>7</option>
                                                             <option value='8'>8</option>
                                                             <option value='9'>9</option>
                                                           </select>
                                                    </div>
                                                  </div>
                                                  :
                                                  ""
                                                }

                                                  <div className="row">
                                                  <div className='col-md-6'>
                                                    <input name="digicode" type="text"  placeholder="Digicode" />
                                                  </div>
                                                   <div className='col-md-6'>
                                                     <input name="interphone" type="text" placeholder='Interphone' />
                                
                                                  </div>
                                                </div>
 

                                             
                                              
                                                <div className="filter-tags ft-list"> 
                                                    <input id="check-a2" type="checkbox" name="check" required  style={{    marginTop: '5px'}}/>
                                                    <label for="check-a2">J’accepte les Conditions Générales de Proximiti et je reconnais avoir été informé(e) et consentir au traitement de mes données personnelles détaillées dans la Politique de confidentialité de Proximiti. *</label>
                                                </div>
                                                <div className="clearfix"></div>
                                               
                                                <div className="clearfix"></div>
                                                <button type="submit"      className="btn float-btn color2-bg pdpad">S'inscrire </button>
                                            </form>

                                             :

                                              <form  onSubmit={handleSubmitUser}  name="registerform" className="main-register-form" id="main-register-form2" style={{marginTop:'30px'}}>
                                                
                                                
                                              <input name="type" type="hidden" value="1"/>
                                              <input type="hidden" name="img_kbis_val" value={img_kbis_val} />
                                              <input type="hidden" name="img_assurance_val" value={img_assurance_val} />
                                              <input type="hidden" name="img_profil_val" value={img_profil_val} />
                                              <input type="hidden" name="img_logo_val" value={img_logo_val} />
                                                 <div className="row">
                                                  <div className='col-md-12'>
                                                         <select name='metier' className='selectform bgselect' >
                                                            <option value=''>Secteur d'activité</option>
                                                            {metiers?.data?.map((item) => (
                                                              <option value={item.id}>{item.label}</option>
                                                            ))}
                                                         </select>
                                                  </div>
                                                </div>

                                                  <div className="row">
                                                  <div className='col-md-12'>
                                                         <select name='type_entreprise' className='selectform bgselect' >
                                                           <option value=''>Type d'entreprise</option>
                                                <option value='1'>SARL</option>
                                                <option value='4'>SASU</option>
                                                <option value='5'>Micro entreprise</option>
                                                <option value='6'>SAS</option>
                                                <option value='7'>Agence immobilière</option>
                                                <option value='8'>Indépendant</option>
                                                <option value='9'>Auto entrepreneur</option>
                                                <option value='2'>Individuelle</option>
                                                <option value='10'>SCI</option>
                                                <option value='11'>investisseur immobilier</option>
                                                <option value='3'>Anonyme</option>
                                                         </select>
                                                  </div>
                                                </div>

                                                <div className="row">
                                                  <div className='col-md-12'>
                                                         <select name='nb_employes' className='selectform bgselect' >
                                                           <option value=''>Nombre d'employés</option>
                                                           <option value='1'>Moins de 5</option>
                                                           <option value='2'>Entre 5 et 20</option>
                                                           <option value='3'>Plus que 20</option>
                                                         </select>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className='col-md-12'>
                                                     <input name="societe" type="text"  placeholder='Nom de la Société *'  required />
                                                  </div>
                                                </div>

                                                       <div className="row">
                                                  <div className='col-md-5'>
                                                    <input name="codepostal" type="text"    required placeholder="Code postal *" />
                                                  </div>
                                                   <div className='col-md-7'>
                                                      <select name='ville' required className='selectform bgselect' >
                                                        <option value=''>Ville *</option>
                                                        
                                                        {
                                                          allCities != undefined && allCities != "" && allCities.length > 0 ?
                                                          allCities?.map((item, index) => (
                                                            <option value={item.id}>{item.ville}</option>
                                                          ))
                                                          :
                                                          ''
                                                        }
                                                      </select>
                                
                                                  </div>
                                                </div>

                                                 <div className="row">
                                                  <div className='col-md-12'>

                                                  <input type='hidden' name='lat' value={lat} />
                                                  <input type='hidden' name='lng' value={lng} />
                                                     
                                                        <PlacesAutocomplete  value={address} onChange={handleChange}   onSelect={selectValuemap} searchOptions={{ types: ['address'], componentRestrictions: {country: "fr"} }}>
                                                            {renderFunc}
                                                        </PlacesAutocomplete>
                                                  </div>
                                                </div>
                                                
                                    
                                         


                                                <div className="row">
                                                  <div className='col-md-12'>
                                                     <input name="siret" type="text"  placeholder='Siret'  />
                                                  </div>
                                                </div>

                                                <div className="row">
                                                  <div className='col-md-12'>
                                                     {
                                                      img_kbis_val != '' ?
                                                      <p style={{margin:"0",color:"green"}}>Image Téléchargée</p>
                                                      :
                                                      ''
                                                     }
                                                   <div className='download'>
                                                      Téléchargez votre Kbis
                                                     <input name="img_kbis" className="inputFileInvis1" type="file"  onChange={onChangeFile} />
                                                     <img src='/images/icones/Iconfeatherdownload.png' />
                                                    </div>
                                                  </div>
                                                </div>
                                                 <div className="row">
                                                  <div className='col-md-12'>
                                                     {
                                                      img_assurance_val != '' ?
                                                      <p style={{margin:"0",color:"green"}}>Image Téléchargée</p>
                                                      :
                                                      ''
                                                     }
                                                   <div className='download'>
                                                       Téléchargez votre Attestation d'assurance
                                                     <input name="img_assurance" className="inputFileInvis1" type="file" onChange={onChangeFile} />
                                                     <img src='/images/icones/Iconfeatherdownload.png' />
                                                    </div>
                                                  </div>
                                                </div>

                                                 <div className="row">
                                                  <div className='col-md-12'>
                                                        <input name="assurance" type="text" placeholder="Nom de l'assurance" />
                                                  </div>

                                                   <div className='col-md-12'>
                                                       <div>
                                                         <span style={{    fontSize: 11}}>Votre attestation d'assurance RC pro sera vérifiée et vous sera demandée deux fois au cours de l'année</span>
                                                       </div>
                                                  </div>
                                                </div>

                                                 <div className="row">
                                                  <div className='col-md-12'>
                                                     {
                                                      img_logo_val != '' ?
                                                      <p style={{margin:"0",color:"green"}}>Image Téléchargée</p>
                                                      :
                                                      ''
                                                     }
                                                   <div className='download' style={{    textAlign: 'center',minHeight: '58px'}}>
                                                  
                                                     <input name="img_logo" type="file"  className="inputFileInvis1"  onChange={onChangeFile} />
                                                      «Glisser-déposer votre logo dans cette zone ou<br/> <span style={{color:'#2971b9'}}>cliquez ici pour sélectionner une photo</span>». 
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="row">
                                                  <div className='col-md-12'>
                                                        <input name="profession" type="text" placeholder='Votre Profession *'  required/>
                                                  </div>
                                                </div>

                                                  <div className="row">
                                                  <div className='col-md-12'>
                                                        <input name="nom" type="text" placeholder='Votre Nom *'  required/>
                                                  </div>
                                                </div>

                                                  <div className="row">
                                                  <div className='col-md-12'>
                                                        <input name="prenom" type="text" placeholder='Votre Prénom *'  required/>
                                                  </div>
                                                </div>

                                                  <div className="row">
                                                  <div className='col-md-12'>
                                                        <input name="mobile" type="text" placeholder='N° de mobile *'  required/>
                                                  </div>
                                                </div>

                                                 <div className="row">
                                                  <div className='col-md-12'>
                                                        <input name="email" type="email" placeholder='E-mail *'  required/>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className='col-md-12'>
                                                         <div className='displypass'> 
                                                       <input name="password" type={password3 ? 'text':'password'} required  placeholder="Mot de passe *"  / >
                                                       <a href='javascript:void(0)' onClick={() => changePasswordType(3)}><img src='/images/icones/oeil.png' /></a>
                                                       </div>
                                                  </div>
                                                </div>

                                                <div className="row">
                                                  <div className='col-md-12'>
                                                     {
                                                      img_profil_val != '' ?
                                                      <p style={{margin:"0",color:"green"}}>Image Téléchargée</p>
                                                      :
                                                      ''
                                                     }
                                                   <div className='download' style={{    textAlign: 'center',minHeight: '58px'}}>
                                                  
                                                     <input name="img_profil" type="file"  className="inputFileInvis1"  onChange={onChangeFile} />
                                                      «Glisser-déposer votre photo dans cette zone ou<br/> <span style={{color:'#2971b9'}}>cliquez ici pour sélectionner une photo</span>». 
                                                    </div>
                                                  </div>
                                                </div>
                                                
                                               
                                               <div className="filter-tags ft-list"> 
                                                    <input id="check-a2" type="checkbox" name="check"  style={{    marginTop: '5px'}}/>
                                                    <label for="check-a2">J'accepte de recevoir les offres de Proximiti.</label>
                                                </div>

                                                <div className="clearfix"></div>

                                               <div className="filter-tags ft-list"> 
                                                    <input id="check-a3" type="checkbox" name="check"  style={{    marginTop: '5px'}}/>
                                                    <label for="check-a3">Je m'engage à indiquer mes disponibilités et à les respecter.</label>
                                                </div>

                                                  <div className="clearfix"></div>



 
                                              
                                                <div className="filter-tags ft-list"> 
                                                    <input id="check-a4" type="checkbox" name="check" required  style={{    marginTop: '5px'}}/>
                                                    <label for="check-a4">J’accepte les Conditions Générales de Proximiti et je reconnais avoir été informé(e) et consentir au traitement de mes données personnelles détaillées dans la Politique de confidentialité de Proximiti. *</label>
                                                </div>
                                                <div className="filter-tags ft-list"> 
                                                  <label for="check-a4">
                                                    Votre attestation d'assurance RC pro et/ou décennale sera vérifiée et vous sera demandée deux fois au cours de l'année
                                                  </label>
                                                </div>
                                                <div className="clearfix"></div>
                                               
                                                <div className="clearfix"></div>
                                                <button type="submit"      className="btn float-btn color2-bg pdpad">S'inscrire </button>
                                            </form>



                                          }

                                           
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            
                   
                            <div className="wave-bg">
                                <div className='wave -one'></div>
                                <div className='wave -two'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


                        </div>

                     </div>
                 </div>

           </section>

 
             
        </div>
    );
}

export default Page;