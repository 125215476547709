import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenu,DashbordHeader } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"
import { getDevisList } from "../../../actions/pages";
import { updateInfoUser } from "../../../actions/user";
import Switch from '@material-ui/core/Switch';
 import { DashbordMenuClient,DashbordHeaderClient } from "../../../components";
const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { data } = useSelector(state => state.pages)
    
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categ, setCateg] = useState('');
    const [rib, setRib] = useState('');
    const [loading, setLoading] = useState(false);

    const history = useHistory()
    const [list_formations, setFormations] = useState([]);
    const [searched, setSearched] = useState('');
    const label = { inputProps: { 'aria-label': '' } };

     const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
    
        
    
    }

     const goToDetails = (id) => {
        history.push("/detail/mission/pro/"+id)
      }


      const goto = (slug) => {
         history.push(slug)
     }
    useEffect(() => {
         

           apiClient().get(`/clients/videos`,{})
            .then((res) => {
              
                 setFormations(res.data.formations)
                 
            
                      
            })

      

    }, [])

    
 
   
 

 

    return (
        <div className="myccount editprofil editservices " >
            
                <section className="gray-bg main-dashboard-sec dashboard myccount abonnement devis " id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenuClient src='formations'/>
                                 
                            </div>


                         <div className='col-md-9 dashbordassitantcontent' >

                             <div className='tab' id=''>


                              <DashbordHeaderClient src={'w'} />

                             <div className='row' style={{marginTop:'30px'}}>
                               <div className='col-md-12'>

                      


                            

                               <div className='rowtiteldevis'>
                                 <span> Consultation Vidéo </span>

                               </div>
                         <form  onSubmit={handleSubmit} className='noraduis' >
                               
                              

                 
                            <div className="custom-form">

                                  <table  id="datatable_valides" className="table table-separate  table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                                    <thead>
                                    <tr>
                                        <th>Titre</th>
                                        <th>Description</th>
                                        
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                       {
                                          list_formations.map((element, index) => (
                                             <tr>
                                                <td>{element.titre}</td>
                                                <td> {element.description}</td>
                                               
                                             
                                                <td>
                                                   <div className='flx889 flmi90990'>
 
 
                                                      <a href={element?.lien} target='_blanck'  ><img src='/images/icones/iconeenvoyer.png' /></a>

                                                   </div>
                                                </td>
                                             </tr>
                                          ))
                                       }

                                    </tbody>

                                </table>
 
                                      
                                    </div>
                         



                        </form>

 

                                    

                               </div>
                  

                             </div>


                                 
                          </div>
 

                              

                         </div>
                    </div>
            </section>

        </div>
    );
}

export default Page;