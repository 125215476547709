import { SET_CATEGORIES,SET_REVIEWS,SET_MESSAGES,SET_CONTENT_PAGE,GET_WATCHER,SET_INVOICES,SET_RATE,SAVE_MISSION,SET_FILES,GET_RECLAMATIONS,MISSIONS_REALISE,MISSIONS_VALIDATE,MISSION_ENCOURS,GET_MISSION,WATCHER_MISSION,GET_DETAIL_MISSION,GET_PATENAIRES,LIST_MISSIONS,Edit_MISSION,GET_WATCHERS,GET_FAQ,SET_CURRENT_RESTAURENT,SET_LIST_ADDRESS,SET_LIST_COMMANDES,SET_CURRENT_COMMANDE,SET_HORAIRE_RESTAURENT,
        SET_METIERS, SET_Services,SET_CITIES,SET_ELEMENT_PRICES,SET_PARTNERS,SET_BLOGS,SET_COMMENTAIRES,ALL_MISSIONS,ALL_MESSAGES,ALL_SENT_MESSAGES,COUNT_UNREAD_MSG,ALL_PROF_MISSIONS,ALL_PROF_MISSIONS_CALENDAR, SET_MISSION_INFOS, SET_DATES, SET_INTERVENTIONS,SET_QUESTIONS,SET_RESPONSES,GET_LIST_RAPPORTS,GET_LIST_LOCATAIRES,GET_ASTREINTES,GET_RESERVATION_BIEN,GET_SEARCHED_BIEN,GET_LAST_BIEN,GET_USER_BIENS, GET_USER_ALL_BIENS
} from "../constants/actions";

const INITIAL_STATE = {
    data: [],
    reviews: [],
    rates:[],
    watchers: [],
    watchersbymission: [],
    contents: [],
    detailmission:{},
    partenaires: [],
    addresseslist: [],
    commandeslist: [],
    files: [],
    invoices:[],
    missions:[],
    missionsV:[],
    missionsR:[],
    missionsE:[],
    horaires: [],
    contacts: [],
    commandeinfo: {},
    mission: {},
    watcher: {},
     reclamations: [],
    currentRestau:{
        establishment:{},
        categories:[]
    },
    products:[
    ],
    metiers: [],
};

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_CATEGORIES :
            return {...state,data:action.payload}
    case SET_REVIEWS :
            return {...state,reviews:action.payload} 
    case GET_WATCHERS :
            return {...state,watchers:action.payload}
     case GET_WATCHER :
            return {...state,watcher:action.payload}            
    case SET_CONTENT_PAGE :
            return {...state,contents:action.payload}  
    case GET_PATENAIRES :
            return {...state,partenaires:action.payload}  
    case GET_FAQ :
            return {...state,contents:action.payload}  
     case LIST_MISSIONS :
            return {...state,missions:action.payload}
    case MISSIONS_VALIDATE :
            return {...state,missionsV:action.payload}  
     case MISSIONS_REALISE :
            return {...state,missionsR:action.payload}   
    case MISSION_ENCOURS :
            return {...state,missionsE:action.payload}                         
    case SAVE_MISSION :
            return {...state}
    case Edit_MISSION :
            return {...state} 
    case GET_MISSION :
            return {...state,mission:action.payload} 
    case GET_DETAIL_MISSION :
            return {...state,detailmission:action.payload}         
    case WATCHER_MISSION :
            return {...state,watchersbymission:action.payload}   
    case GET_USER_BIENS :
        return {...state,user_biens:action.payload}     
        case GET_USER_ALL_BIENS :
            return {...state,user_all_biens:action.payload}   
            case GET_LAST_BIEN :
                return {...state,last_biens:action.payload}   
        case GET_SEARCHED_BIEN :
                return {...state,searched_biens:action.payload} 
        case GET_RESERVATION_BIEN :
                return {...state,reservation_bien:action.payload}    
        case GET_ASTREINTES :
                return {...state,list_astreintes:action.payload}   
        case GET_LIST_LOCATAIRES :
                return {...state,list_locataires:action.payload}    
case GET_LIST_RAPPORTS :
        return {...state,list_rapports:action.payload}      

    case GET_RECLAMATIONS :
            return {...state,reclamations:action.payload}  

    case SET_MESSAGES :
            return {...state,contacts:action.payload}    
    case SET_FILES :
            return {...state,files:action.payload} 
    case SET_INVOICES :
            return {...state,invoices:action.payload}   
    case SET_RATE :
            return {...state,rates:action.payload}             
    case SET_CURRENT_RESTAURENT :
            return {...state,currentRestau:action.payload}
    case SET_LIST_ADDRESS :
          return {...state,addresseslist:action.payload}
    case SET_LIST_COMMANDES :
          return {...state,commandeslist:action.payload}
    case SET_CURRENT_COMMANDE :
          return {...state,commandeinfo:action.payload}
    case SET_HORAIRE_RESTAURENT :
            return {...state,horaires:action.payload}
        case SET_METIERS :
                return {...state,metiers:action.payload}
case SET_Services :
        return {...state,services:action.payload}
        case SET_DATES :
                return {...state,dates:action.payload}
        case SET_INTERVENTIONS :
                return {...state,interventions:action.payload}
        case SET_QUESTIONS :
                return {...state,questions:action.payload}
        case SET_RESPONSES :
                return {...state,responses:action.payload}  
        case SET_CITIES :
                return {...state,allCities:action.payload}
        case SET_BLOGS :
                return {...state,blogs:action.payload}
        case SET_COMMENTAIRES :
                return {...state,commentaires:action.payload}
        case SET_PARTNERS :
                return {...state,our_partners:action.payload}
        case SET_ELEMENT_PRICES :
                return {...state,prof_elems_prices:action.payload}
        case SET_MISSION_INFOS :
                return {...state,mission_infos:action.payload}
        case ALL_MISSIONS :
                return {...state,all_missions:action.payload}
        case ALL_PROF_MISSIONS :
                return {...state,all_prof_missions:action.payload}
        case ALL_PROF_MISSIONS_CALENDAR :
                return {...state,all_prof_missions_calendar:action.payload}
        case ALL_MESSAGES :
                return {...state,all_messages:action.payload}
        case ALL_SENT_MESSAGES :
                return {...state,all_sent_messages:action.payload}
        case COUNT_UNREAD_MSG :
                return {...state,count_unread_msg:action.payload}
        default:
            return state;
    }
}
export default reducer;