import React from "react";
import {
    BrowserRouter as Router,
    Switch,
} from "react-router-dom"; 
import { CustomRoute } from "../components";
import { 
    Login,
    Splashscreen,
    Register,
    ForgetPassword,
    Home,
    Besoin1,
    Besoin2,
    Besoin3,
    Besoin4,
    Besoin5,
    Besoin6,
    Besoin7,
    Besoin8,
    Besoin9,
    Formules,

    ContenuPage,

    ProfilAssistant,
    AgendaAssistant,
    EditProfilAssistant,
    MonProfilAssistant,
    EditServiceAssistant,
    Abonnement,
    devisAssistant,
    formationsAssistant,
    MessagesAssistant,
    FacturesAssistant,
    NouveaudevisAssistant,
    historiquesAssistant,
    portfeuilleAssistant,

    ProfilClient,
    Monprofil,
    formationsClient,
    AbonnementClient,
    ListAbonnementClient,
    ChargesClient,
    PatrimoineClient,
    AddPatrimoine,
    AddPatrimoineVente,
    ModifierPatrimoine,
    ModifierPatrimoineVente,
    Restreintes,
    Rapport,
    Addlocataire,
    AgendaClient,
    MessagesClient,
    DetailMissionClient,
    EtatLieuClient,
    InterventionsClient,
    EditProfilClient,
    EditServiceClient,
    devisClient,
    historiquesClient,
    portfeuilleClient,
    ContratBailClient,


    Restaurent,
    Listing,
    Assistantprofil,
    Assistantprofilselect,
    Devenirassistant,
    ConnexionAssistant,

 
    DetailMission,
    DetailProfilMission,
    Profile,
    Responses,
    Paiement,
    EditProfil,
    EditMission,
   
    Messages,
    Factures,
   
    ReclamationsAssistant,
    Reclamations,
    Contact,
    Commentcamarche,
    Apropos,
    Checkemail,
    Team,
    Talk,
    CheckToken,
    Blog,
    Blogdetail,


    Basket,
    Commandes,
    Commande,
    Historique,
    
    Aide,
    Cgv,
    Rgbd,
    Adresses,
    ThermalPrinter,

    Biens,ListeBiens,DetailBien,ReservationBien,

    AideDomicile,AideDomicileBesoin,AideDomicileFrequence,AideDomicileDate,
    Coiffure,CoiffurePourQui,CoiffureBesoin,CoiffureFrequence,CoiffureDate,CoiffureHeure,
    CoursStandard,CoursStandardBesoin,CoursStandardFrequence,CoursStandardDate,
    Demenagement1,Demenagement1Besoin,Demenagement1Heure,Demenagement1Date,
    Demenagement,DemenagementBesoin,DemenagementHeure,DemenagementDate,
    GardeAnimaux,GardeAnimauxPourQui,GardeAnimauxBesoin,GardeAnimauxFrequence,GardeAnimauxDate,GardeAnimauxHeure,
    GardeEnfants,GardeEnfantsPourQui,GardeEnfantsBesoin,GardeEnfantsFrequence,GardeEnfantsDate,GardeEnfantsHeure,
    Informatique,InformatiqueBesoin,InformatiqueFrequence,InformatiqueDate,
    Jardinage,JardinageBesoin,JardinageFrequence,JardinageDate,
    AutreServiceLogin,AutreSserviceSucces,AutreSservicePaiement,BienConnexion

 } from "../pages";

const MainNavigator =()=> {
    return (
        <Router>
            <Switch>
                <CustomRoute exact parts back={1} path="/biens" component={Biens} />
                <CustomRoute exact parts back={1} path="/liste-biens" component={ListeBiens} />
                <CustomRoute exact parts back={1} path="/detail-bien/:id" component={DetailBien} />
                <CustomRoute exact parts back={1} path="/reservation-bien/:id" component={ReservationBien} />
                <CustomRoute exact parts back={1} path="/biens-connexion" component={BienConnexion} />

                <CustomRoute exact parts back={1} path="/aide-domicile" component={AideDomicile} />
                <CustomRoute exact parts back={1} path="/aide-domicile/besoin" component={AideDomicileBesoin} />
                <CustomRoute exact parts back={1} path="/aide-domicile/frequence" component={AideDomicileFrequence} />
                <CustomRoute exact parts back={1} path="/aide-domicile/date" component={AideDomicileDate} />

                <CustomRoute exact parts back={1} path="/coiffure" component={Coiffure} />
                <CustomRoute exact parts back={1} path="/coiffure/pour-qui" component={CoiffurePourQui} />
                <CustomRoute exact parts back={1} path="/coiffure/service" component={CoiffureBesoin} />
                <CustomRoute exact parts back={1} path="/coiffure/date" component={CoiffureDate} />
                <CustomRoute exact parts back={1} path="/coiffure/frequence" component={CoiffureFrequence} />
                <CustomRoute exact parts back={1} path="/coiffure/heure" component={CoiffureHeure} />

                <CustomRoute exact parts back={1} path="/cours-standard" component={CoursStandard} />
                <CustomRoute exact parts back={1} path="/cours-standard/besoin" component={CoursStandardBesoin} />
                <CustomRoute exact parts back={1} path="/cours-standard/frequence" component={CoursStandardFrequence} />
                <CustomRoute exact parts back={1} path="/cours-standard/date" component={CoursStandardDate} />

                <CustomRoute exact parts back={1} path="/demenagement-meubles" component={Demenagement} />
                <CustomRoute exact parts back={1} path="/demenagement-meubles/besoin" component={DemenagementBesoin} />
                <CustomRoute exact parts back={1} path="/demenagement-meubles/date" component={DemenagementDate} />
                <CustomRoute exact parts back={1} path="/demenagement-meubles/heure" component={DemenagementHeure} />

                <CustomRoute exact parts back={1} path="/demenagement" component={Demenagement1} />
                <CustomRoute exact parts back={1} path="/demenagement/besoin" component={Demenagement1Besoin} />
                <CustomRoute exact parts back={1} path="/demenagement/date" component={Demenagement1Date} />
                <CustomRoute exact parts back={1} path="/demenagement/heure" component={Demenagement1Heure} />

                <CustomRoute exact parts back={1} path="/garde-animaux" component={GardeAnimaux} />
                <CustomRoute exact parts back={1} path="/garde-animaux/pour-qui" component={GardeAnimauxPourQui} />
                <CustomRoute exact parts back={1} path="/garde-animaux/service" component={GardeAnimauxBesoin} />
                <CustomRoute exact parts back={1} path="/garde-animaux/date" component={GardeAnimauxDate} />
                <CustomRoute exact parts back={1} path="/garde-animaux/frequence" component={GardeAnimauxFrequence} />
                <CustomRoute exact parts back={1} path="/garde-animaux/heure" component={GardeAnimauxHeure} />

                <CustomRoute exact parts back={1} path="/garde-enfants" component={GardeEnfants} />
                <CustomRoute exact parts back={1} path="/garde-enfants/pour-qui" component={GardeEnfantsPourQui} />
                <CustomRoute exact parts back={1} path="/garde-enfants/service" component={GardeEnfantsBesoin} />
                <CustomRoute exact parts back={1} path="/garde-enfants/date" component={GardeEnfantsDate} />
                <CustomRoute exact parts back={1} path="/garde-enfants/frequence" component={GardeEnfantsFrequence} />
                <CustomRoute exact parts back={1} path="/garde-enfants/heure" component={GardeEnfantsHeure} />

                <CustomRoute exact parts back={1} path="/informatique" component={Informatique} />
                <CustomRoute exact parts back={1} path="/informatique/besoin" component={InformatiqueBesoin} />
                <CustomRoute exact parts back={1} path="/informatique/frequence" component={InformatiqueFrequence} />
                <CustomRoute exact parts back={1} path="/informatique/date" component={InformatiqueDate} />

                <CustomRoute exact parts back={1} path="/jardinage" component={Jardinage} />
                <CustomRoute exact parts back={1} path="/jardinage/besoin" component={JardinageBesoin} />
                <CustomRoute exact parts back={1} path="/jardinage/frequence" component={JardinageFrequence} />
                <CustomRoute exact parts back={1} path="/jardinage/date" component={JardinageDate} />

                <CustomRoute exact parts back={1} path="/connexion" component={AutreServiceLogin} />
                <CustomRoute exact parts back={1}path="/autre-service-succes" component={AutreSserviceSucces} />
                <CustomRoute exact parts back={1} path="/autre-service-paiement" component={AutreSservicePaiement} />




                <CustomRoute exact parts path="/" component={Home} />
                <CustomRoute exact parts back={1} path="/formules" component={Formules} />
                <CustomRoute exact parts back={1} path="/quel-est-votre-besoin-1/:type?-:service_id?" component={Besoin1} />
                <CustomRoute exact parts back={1} path="/quel-est-votre-besoin-2/:metier_id" component={Besoin2} />
                <CustomRoute exact parts back={1}path="/ajouter-des-photos" component={Besoin3} />
                <CustomRoute exact parts back={1} path="/recherche-artisan" component={Besoin4} />
                <CustomRoute exact parts back={1} path="/prenez-rendez-vous-avec-un-artisan" component={Besoin5} />
                <CustomRoute exact parts back={1} path="/se-connecter" component={Besoin6} />
                <CustomRoute exact parts back={1}path="/reservez-votre-intervention" component={Besoin7} />
                <CustomRoute exact parts back={1} path="/votre-paiement-accepte" component={Besoin8} />
                <CustomRoute exact parts back={1} path="/validation-ticket" component={Besoin9} />


                <CustomRoute exact parts back={1} path="/detail/:id?/:text?" component={ContenuPage} />
                
                
                <CustomRoute   path="/assistants/dashboard" component={ProfilAssistant} />
                <CustomRoute   path="/assistants/agenda" component={AgendaAssistant} />
                <CustomRoute   path="/assistants/modifier-profile/" component={EditProfilAssistant} />
                <CustomRoute   path="/assistants/profile/" component={MonProfilAssistant} />
                <CustomRoute   path="/assistants/modifier-services/" component={EditServiceAssistant} />
                <CustomRoute   path="/assistants/abonnement/" component={Abonnement} />
                <CustomRoute   path="/assistants/devis/" component={devisAssistant} />
                <CustomRoute   path="/assistants/formations/" component={formationsAssistant} />
                <CustomRoute   path="/assistants/nouveau/devis/" component={NouveaudevisAssistant} />
                <CustomRoute   path="/assistants/historiques/" component={historiquesAssistant} />
                <CustomRoute    path="/assistants/messages" component={MessagesAssistant} />
                <CustomRoute   path="/assistants/factures/" component={FacturesAssistant} />
                <CustomRoute   path="/assistants/portfeuille/" component={portfeuilleAssistant} />

                
                <CustomRoute   path="/client/dashboard" component={ProfilClient} />
                <CustomRoute   path="/client/mon-profile" component={Monprofil} />
                <CustomRoute   path="/my-account" component={ProfilClient} />
                <CustomRoute   path="/client/agenda" component={AgendaClient} />
                <CustomRoute   path="/client/modifier-profile/" component={EditProfilClient} />
                <CustomRoute   path="/client/abonnement" component={AbonnementClient} />
                <CustomRoute   path="/client/changer-abonnement" component={ListAbonnementClient} />
                <CustomRoute   path="/client/patrimoine" component={PatrimoineClient} />
                <CustomRoute   path="/client/ajouter-bien-vente/" component={AddPatrimoineVente} />
                <CustomRoute   path="/client/ajouter-bien-location/" component={AddPatrimoine} />
                <CustomRoute   path="/client/modifier-bien-vente/:id" component={ModifierPatrimoineVente} />
                <CustomRoute   path="/client/modifier-bien-location/:id" component={ModifierPatrimoine} />
                <CustomRoute   path="/client/gestion-des-astreintes/" component={Restreintes} />
                <CustomRoute   path="/client/creer-rapport/" component={Rapport} />
                <CustomRoute   path="/client/ajouter-locataire/" component={Addlocataire} />
                <CustomRoute   path="/client/contrat-bail" component={ContratBailClient} />
                <CustomRoute   path="/client/charges" component={ChargesClient} />
                <CustomRoute   path="/client/etat-lieu" component={EtatLieuClient} />
                <CustomRoute   path="/client/interventions" component={InterventionsClient} />
                <CustomRoute   path="/client/modifier-services/" component={EditServiceClient} />
                <CustomRoute   path="/client/devis/" component={devisClient} />
                <CustomRoute   path="/client/historiques/" component={historiquesClient} />
                <CustomRoute    path="/client/messages" component={MessagesClient} />
                {/* <CustomRoute   path="/client/factures/" component={FacturesAssistant} /> */}
                <CustomRoute   path="/client/portfeuille/" component={portfeuilleClient} />
                <CustomRoute   path="/client/portfeuille/" component={portfeuilleClient} />
                <CustomRoute   path="/client/videos/" component={formationsClient} />



                <CustomRoute  parts path="/find-a-watcher" component={Listing} />
                <CustomRoute  parts path="/watcher/:slug" component={Assistantprofil} />
                <CustomRoute  parts path="/account/select/watcher/:slug/:slug1" component={Assistantprofilselect} />
                <CustomRoute  parts path="/become-a-watcher/" component={Devenirassistant} />
                <CustomRoute  parts path="/my-account/" component={Profile} />
                <CustomRoute  parts path="/dashboard/watchers/:slug" component={Responses} />
                <CustomRoute  parts path="/paiement/mission/:slug/:slug1" component={Paiement} />
                <CustomRoute  parts path="/watchers/login" component={ConnexionAssistant} />
                
                <CustomRoute  path="/detail/mission/pro/:slug" component={DetailMission} />
                <CustomRoute  parts path="/account/detail/mission/:slug" component={DetailProfilMission} />
                <CustomRoute  parts path="/modifier-profile/" component={EditProfil} />
                <CustomRoute  parts path="/edit-mission/:slug" component={EditMission} />
                
                <CustomRoute  parts path="/messages" component={Messages} />
               
                <CustomRoute  parts path="/factures/" component={Factures} />
                
                <CustomRoute  parts path="/assistants/reclamations/" component={ReclamationsAssistant} />
                <CustomRoute  parts path="/reclamations/" component={Reclamations} />
                <CustomRoute  parts path="/contact/" component={Contact} />
                <CustomRoute  parts path="/how-it-works/" component={Commentcamarche} />
                <CustomRoute  parts path="/about-us/" component={Apropos} />
                <CustomRoute  parts path="/talk-about-us" component={Talk} />
                <CustomRoute  parts path="/faq/" component={Aide} />
                <CustomRoute  parts path="/rgbd/" component={Rgbd} />
                <CustomRoute  parts path="/legal-notice/" component={Cgv} />
                <CustomRoute  parts path="/check-your-email/" component={Checkemail} />
                <CustomRoute  parts path="/watchers/token/validate/:slug" component={CheckToken} />
                <CustomRoute  parts path="/join-the-team/" component={Team} />
                <CustomRoute  parts path="/password/:slug" component={ForgetPassword}  />
                <CustomRoute  parts path="/blog/:slug" component={Blog}  />
                <CustomRoute  parts path="/details/:slug/:slug2" component={Blogdetail}  />
                <CustomRoute parts path="/login" component={Login}  />


                <CustomRoute parts  path="/splashscreen" component={Splashscreen}  />
                <CustomRoute parts path="/register" component={Register}  />
                <CustomRoute  path="/restaurents/:id" component={Restaurent} />
                <CustomRoute  protect path="/basket" component={Basket} />
                <CustomRoute  protect path="/commandes" component={Commandes} />
                <CustomRoute  protect path="/printer" component={ThermalPrinter} />
                
          
            </Switch>
        </Router>
    );
}

export default MainNavigator;