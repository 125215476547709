import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button ,Modal,CloseButton } from "react-bootstrap"
import { AssistantItem } from "../../../../components";
import { AvisItem } from "../../../../components";
import { getListWatcher } from "../../../../actions/user";
import { useHistory } from "react-router";
import { BASE_URL_PROD } from "../../../../components/config/keys";
import { surpls,epr } from "../../../../assets/images";
import { useTranslation } from 'react-i18next';

import { getBesoinAideDomicile } from "../../../../actions/pages";
 

const Page = () => {
    
    const autre_services_data = JSON.parse(localStorage.getItem('autre_services_data'));
    const dispatch = useDispatch();
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const [besoin, setBesoin] = useState(autre_services_data.besoin);
    const [desc_besoin, setDescBesoin] = useState(autre_services_data.desc_besoin);
    const [besoinText, setBesoinText] = useState(autre_services_data.besoinText);
    const [besoinList, setBesoinList] = useState([]);
    const [besoinError, setShowBesoinError] = useState(0);
    const [besoinPrice, setBesoinPrice] = useState(autre_services_data.besoinPrice);
    // const [autre_services_data, setItems] = useState([]);
    useEffect(() => {
      // setItems(JSON.parse(localStorage.getItem('autre_services_data')))
      dispatch(getBesoinAideDomicile()).then((res) => {
          setBesoinList(res.data);
      })
    }, [])
  
    const handleGoBack = () => {
      history.push('/aide-domicile');
    };

    const handleGoTo = () => {
      setShowBesoinError(0)
      if(besoin != '' && besoin != undefined){
        autre_services_data.besoin = besoin
        autre_services_data.desc_besoin = desc_besoin
        autre_services_data.besoinText = besoinText
        autre_services_data.besoinPrice = besoinPrice
        localStorage.setItem('autre_services_data', JSON.stringify(autre_services_data))
        history.push('/aide-domicile/frequence');
      }else{
        setShowBesoinError(1)
      }
    };
    
    const handleBesoin = element => {
      setShowBesoinError(0)
      var index = element.nativeEvent.target.selectedIndex;
      setBesoinPrice(element.nativeEvent.target[index].dataset.price);
      setBesoin(element.target.value);
      setBesoinText(element.nativeEvent.target[index].text);
    };
    const handleBesoinDesc = element => {
      setDescBesoin(element.target.value);
    };
    return (
      <div className="aideDomicile2" >
        <section className="gray-bg big-padding " id="besoin1">
          <div className="container small-container ">
            <div className='registerform'>
              <div className='row '>
                <div className='col-md-8'><p onClick={()=>handleGoBack()} style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>&lt;	&lt;	Retour</p></div>
                <div className='col-md-8'>
                  <div className="section-title"><h2><span>Quelle est votre besoin ? </span></h2>
                  </div>
                  <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                    <select required id='besoin' name='besoin' value={besoin}  onChange={handleBesoin} >
                      <option value=""></option>
                      {
                        besoinList.length > 0 ?
                        besoinList.map(item => (
                          <option value={item.id} data-price={item.price}>{item.besoin}</option>
                        ))
                        :
                        ''
                      }
                    </select>
                  </div>
                  {
                    besoinError == 1 ?
                    <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                      <div class="alert alert-danger" role="alert">
                        Veuillez choisir votre besoin !
                      </div>
                    </div>
                    :
                    ""
                  }
                  {
                    besoinText != '' ?
                    <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                      <p style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>Décrivez votre besoin (facultatif)</p>
                      <textarea required id='desc_besoin' name='desc_besoin' value={desc_besoin}  onChange={handleBesoinDesc} ></textarea>
                    </div>
                    :
                    ''
                  }
                  <div className='col-md-9' style={{ paddingLeft:"0", paddingTop:'30px', clear:'both' }}>
                    <div style={{textAlign:'left',marginBottom:'30px'}}>
                      <a onClick={()=>handleGoTo()} class="btn btnform w200 "> Continuer </a>
                    </div>
                  </div>
                </div>    
                <div className='col-md-4'>
                    <div className="header1 boxShadow">Recapitulatif</div>
                    <div className="body1 boxShadow">
                      <p style={{ margin:'0px', paddingLeft:'10px' }}>1 intervention aide à domicile</p>
                      {
                        besoinText != '' && besoinText != undefined ?
                        <hr style={{ margin:'10px' }}></hr>
                        :
                        ''
                      }
                      {
                        besoinText != '' && besoinText != undefined ?
                        <p style={{ margin:'0px', paddingLeft:'10px' }}>
                          <img style={{ width:'16px' }} src="/images/services/icone_detail.png"/> Détails de l'intervention
                        </p>
                        :
                        ''
                      }
                      {
                        besoinText != '' && besoinText != undefined ?
                        <ul>
                          <li>{besoinText}</li>
                        </ul>
                        :
                        ''
                      }
                    </div>
                </div>  
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Page;