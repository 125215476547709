import React, { useEffect,useState } from "react";
import './styles.scss'; 
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { useParams } from "react-router";
import { useHistory } from "react-router";
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import {getDates, getPartners } from "../../../actions/pages";
import moment from 'moment'

const Page = () => { 
    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)
    const history = useHistory()
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { user } = useSelector(state => state.user)
    const { dates, our_partners } = useSelector(state => state.pages)
    const [langset, setLangset] = useState(false);
    const [json, setJson] = useState([]);



 const handleGoTo = (periode, date) => {
    localStorage.setItem('periode', periode)
    localStorage.setItem('date', date)
    if(user.id != undefined && user.id > 0 && user.type == 2)
      history.push('/reservez-votre-intervention')
    else
      history.push('/se-connecter')
   // history.push('/se-connecter');
        
    }
 
 
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())

    }


    const componentClicked =  () => {
        
       
    }

  const selectedDay = val => {
    console.log(val);
  };
 
 

     useEffect(() => {
      dispatch(getDates())
      dispatch(getPartners())
        if(langset == false){
            setLangset(true)
        }
 

    }, [])
   

    var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 7,
    initialSlide:0,
     responsive: [
    
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 2,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 3,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };

       var settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
    
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 2,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 2,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };

    return (
        <div className="besoin  " >
           <section className="gray-bg big-padding " id="besoin5">

             <div className="container small-container ">
             <form className='registerform'>
               
               <div className='row '>
                <div className='col-md-12'>
                   <div className='progressbare'>
                     <div className='veh' style={{ left: '60%'}}><img src='/images/Groupe6849.png'/></div>
                     <div className="progressvalue" style={{width:'65%'}}></div>
                     <div className='bat' ><img src='/images/Groupe6903.png'/></div>
                   </div>
                </div>
                </div>

                   
                <div className='row '>
                <div className='col-md-9'>
                    
                     <div className="section-title"><h2 ><span>Prenez rendez-vous avec un artisan</span></h2>
                      <p className='parag'>Notre algorithme sélectionne un artisan disponible. </p>
                      <br/><br/>

                      <div className='htserv1'>
                          <span className='ht1'><img src='/images/iconetarifrdv.png'/></span>
                          <span className='ht2' >Tarif compris entre </span>
                          <span className='ht3' >{localStorage.getItem('min_price')} et {localStorage.getItem('max_price')} € </span>
                          <span className='ht4' >(Le prix comprend le déplacement, 1h de main d'œuvre et les petites fournitures.)</span>
                      </div>
                       <div className='htserv1'>
                          <span className='ht1'><img src='/images/iconehoraire.png'/></span>
                          <span className='ht2' >Nos horaires d'intervention : </span>
                          <span className='ht4' >matin </span>
                          <span className='ht2' > 8h-13h, </span>
                          <span className='ht4' >après-midi </span>
                          <span className='ht2' >13h-17h, </span>
                          <span className='ht4' >soirée </span>
                          <span className='ht2' >17h-5h </span>
                      </div>

                     </div>
                      
                </div>

                <div className='col-md-3'>
                   <div className='cardservicetel'>
                       <div>
                         <span><img src='/images/iconetéléphoner.png'/></span>
                          <span className='h4'>Je préfère être rappelé </span>
                         <p><strong>Vos préférences de rendez-vous</strong><br/> seront communiquées au dépanneur qui vous rappellera afin de fixer le rendez-vous.</p>
                       </div>
                   </div>
                </div>

               </div>



                <div className='row '>
                  <div className='col-md-12 bgplaning'>

                       <div className='calendar'>

                        {
                          
                          dates != undefined && dates.length > 0 ?
                        
                        <Slider {...settings}>
                                                
                                   {
                                   dates.map((item,index) => {
                                       let newindex=index+1;
                                       return (

                                            <div key={newindex} className='colcalendar'>
                                               <h5>{item?.name}</h5>
                                               <span className='dd'>{item.number}</span>
                                               <div className='actions'>
                                                  <div onClick={()=>handleGoTo(1, item.date)}>Matin</div>
                                                  <div onClick={()=>handleGoTo(2, item.date)}>Après-midi</div>
                                                  <div onClick={()=>handleGoTo(3, item.date)} className='active'>Soirée*</div>
                                               </div>
                                           </div>
                                           
                                            
                                      
                                        );
                            
                                     })
                                    }        


                        </Slider>
                          :
                          ""
                        }
                       </div>

                       <div className='logoplang'>

                       <h5>*Les interventions au-delà de 17h et le week-end sont majorés.</h5>
                         
                             
                                <ul>

                                <Slider {...settings2}>
                                {
                                  our_partners != undefined && our_partners != '' && our_partners.length > 0 ?
                                  our_partners.map((item, index) => (
                                  <li><img src={item.image} /></li>
                                  ))
                                  :
                                  ""
                                }
                                </Slider>


                                </ul>
                             

                       </div>




                  </div>



                </div>

             </form>
               

                <br/> <br/>
                      <div className='cadrbs1'>

                                  <div className="process-wrap fl-wrap commentcafonctionne "><div className="default-desc margright mrgtop"><p>&nbsp;</p>
                                    <ul className="no-list-style row">



                                    <li className="col-md-4">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/Iconeartisan.png' />
                                    </div>

                                    <p className='basecolor'>Nos artisans sont certifiés et labellisés.</p>
                                    </div>
                                    </li>

                                    <li className="col-md-4">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/iconechrono.png' />
                                    </div>

                                    <p className='basecolor'>Traitement rapide des demandes.</p>
                                    </div>
                                    </li>

                                    <li className="col-md-4">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/IconeIA.png' />
                                    </div>

                                    <p className='basecolor'>Un algorithme de matching client-dépanneur en fonction de la proximité et de l’incident.</p>
                                    </div>
                                    </li>

                                    </ul>
                                    </div>
                             </div>

                      </div>

          
             
             </div>



           </section>

        </div>
    );
}

export default Page;