import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card,Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenuClient,DashbordHeaderClient } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"

import { AUTHENTICATE_USER, LOGOUT_USER } from "../../../constants/actions";
import { getAllCategories } from "../../../actions/pages";
import { updateInfoUser, suspendCompte } from "../../../actions/user";


import { getUserBien } from "../../../actions/pages";

import { BASE_URL_PROD } from "../../../components/config/keys";
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';

 

const Page = () => {

   
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user)
  const { data } = useSelector(state => state.pages)
  const { t, i18n } = useTranslation();
  const currentlangStorage = localStorage.getItem("currentlang");
  const currentlang=i18n.language;
  const [langset, setLangset] = useState(false);
  const [type, setType] = useState(1);

  const { user_biens, user_all_biens } = useSelector(state => state.pages)

  const history = useHistory()
  const [startDate, setStartDate] = useState(new Date());
  const label = { inputProps: { 'aria-label': '' } };

  useEffect(() => {
    dispatch(getUserBien({user_id : user.id, type : type}));
  }, [])


  const changeType = (element) => {
    setType(element)
    dispatch(getUserBien({user_id : user.id, type : element}));
  }

    const returnitems =  (bien) => {
      console.log(bien)
        return(
              <div className='col-md-3  position_relative'>
                <div className="process-item position_relative" style={{cursor:'pointer'}} onClick={()=>history.push('/detail-bien/'+bien.id)}>
                  <div className="process-img" style={{backgroundImage:`url(https://proximitipro.fr/admin/public/img/biens/${bien?.images[0] })`, minHeight: '95px'}}></div>
                  <h4 className='basecolor title' ><strong style={{color:'#0E0E0E'}}>{bien.logement} </strong>{bien.nb_pieces} pièces {bien.surface} m²</h4>
                  <h6 className='' style={{ textAlign:"left", fontSize:"11px", color:"gray",     display: 'flex',alignItems: 'center',justifyContent: 'space-between' }}>
                    <div><img src="/images/immo/icone-map.png" className="" style={{ width:"12px",    height: '16px',     marginTop: '0px' }} /> {bien?.city}</div>
                    <div className="priceoffre" style={{ textAlign:"left", fontSize:"18px" ,padding: '4px',    background: '#073460'}}><span style={{fontSize: '12px', color: '#FFF'}}>{bien.price} € </span></div>
                  </h6>
                </div>
              </div>
          )
    }
     

 

    return (
        <div className="myccount editprofil abonnement " >
            
                       <section className="gray-bg main-dashboard-sec dashboard myccount" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenuClient src='patrimoine'/>
                                 
                            </div>


                         <div className='col-md-9 dashbordassitantcontent' >

                             <div className='tab' id=''>


                              <DashbordHeaderClient src={'w'} />

                             <div className='row' style={{marginTop:'30px'}}>

                               <div className='col-md-12'>

                                  <form>
                                    <div class="rowtitel">
                                       <img src="/images/patrimoine.png" style={{width: '59px'}}/>
                                       <h3 class="htri1">Patrimoine </h3>
                                    </div>

                                  <div className='flx889 '>
                      
                                              <div  className='item2 flx889bg'>
                                                <span style={{ cursor:'pointer' }} onClick={() => changeType(1)} className={type == 1 ? 'it1':'it2'} >Location </span>
                                                <span style={{ cursor:'pointer' }} onClick={() => changeType(2)} className={type == 2 ? 'it1':'it2'}  >Vente </span>
                                               </div>
 
                                    </div>


                                  <div className="custom-form">
                                  {
                                    type == 1 ?
                                    <table  id="datatable_valides" className="rowtabcustomer table table-separate  table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                                      <thead>
                                      <tr>
                                          <th>Entrée</th>
                                          <th>Type</th>
                                          <th>Adresse</th>
                                          <th>Ville</th>
                                          <th>Loyer (€)</th>
                                          <th>Option</th>
                                          <th></th>
                                      </tr>
                                      </thead>
                                      <tbody> 
                                        {
                                          user_biens != undefined ?
                                          user_biens.map((elem, index) => (
                                            <tr>
                                                <td>{elem.a_partir}</td>
                                                <td>{elem.logement}</td>
                                                <td>{elem.address}</td>
                                                <td>{elem.city}</td>
                                                <td>{elem.price}</td>
                                                <td>{elem.option == 1 ? 'Conciergerie':(elem.option == 2 ? 'Gestion':'')}</td>
                                                <td>
                                                <a onClick={()=>history.push('/client/modifier-bien-location/'+elem.id)} href="javascript:void(0)"><img src="/images/icones/iconemodifier.png" style={{ width:'17px' }} /></a>
                                                &nbsp;&nbsp;
                                                <a href="javascript:void(0)"><img src="/images/icones/iconepoubelle.png" style={{ width:'15px' }} /></a>
                                                </td>
                                            </tr>
                                          ))
                                          :
                                          ""
                                        }
                                      </tbody>
                                    </table>
                                    :
                                    <table  id="datatable_valides" className="rowtabcustomer table table-separate  table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                                      <thead>
                                      <tr>
                                          <th>Type</th>
                                          <th>Adresse</th>
                                          {/* <th>Code postal</th> */}
                                          <th>Ville</th>
                                          <th>Année d'achat</th>
                                          <th>Prix au m²</th>
                                          <th>Valeur (€)</th>
                                          <th></th>
                                      </tr>
                                      </thead>
                                      <tbody> 
                                        {
                                          user_biens != undefined ?
                                          user_biens.map((elem, index) => (
                                            <tr>
                                                <td>{elem.logement}</td>
                                                <td>{elem.address}</td>
                                                {/* <td>75018</td> */}
                                                <td>{elem.city}</td>
                                                <td>{elem.annee_construction}</td>
                                                <td>{elem.prix_m}</td>
                                                <td>{elem.price}</td>
                                                <td>
                                                <a onClick={()=>history.push('/client/modifier-bien-vente/'+elem.id)} href="javascript:void(0)"><img src="/images/icones/iconemodifier.png" style={{ width:'17px' }} /></a>
                                                &nbsp;&nbsp;
                                                <a href="javascript:void(0)"><img src="/images/icones/iconepoubelle.png" style={{ width:'15px' }} /></a>
                                                </td>
                                            </tr>
                                          ))
                                          :
                                          ""
                                        }
                                      </tbody>
                                    </table>
                                    
                                  }
                                </div>
                                <br/><br/>

                                    </form>   


                                <button  className="btn float-btn color2-bg btn9876 btcustom " onClick={()=>history.push(type == 1 ? '/client/ajouter-bien-location/':'/client/ajouter-bien-vente/')} style={{borderRadius:27, marginTop: '5px'}}>Ajouter un bien</button>
                                
                                 <form className='biens'  style={{marginTop: '82px',    marginBottom: '0px',paddingBottom: '0px'}}>
                                   <div class="rowtitel">
                                       <h3 class="htri1" style={{    marginLeft: '0px'}}>Annonces immobilières </h3>
                                    </div>
                                    <div>

                                     <div className='row offresection'>
                                      {
                                        user_all_biens != undefined ?
                                        user_all_biens.map((bien, ind) => (
                                         ind<3 &&
                                          returnitems(bien)
                                        ))
                                        :
                                        ""
                                      }
                                        <div className='col-md-3' style={{    display: 'flex',alignItems: 'center', cursor:'pointer'}}>
                                          <div onClick={()=>history.push('/biens')}>
                                            <span style={{paddingRight:10,    color: '#232323',fontSize: '12px'}}>Voir plus d'annonces </span>
                                            <i class="fa fa-arrow-right" aria-hidden="true"></i>

                                          </div>

                                        </div>

                                     </div>


                                    </div>

                                 </form>

                               </div>
                  

                             </div>


                                 
                          </div>
 

                              

                         </div>
                    </div>
            </section>
     
        </div>
    );
}

export default Page;


