import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button ,Modal,CloseButton } from "react-bootstrap"
import { AssistantItem } from "../../../../components";
import { AvisItem } from "../../../../components";
import { getListWatcher } from "../../../../actions/user";
import { useHistory } from "react-router";
import { BASE_URL_PROD } from "../../../../components/config/keys";
import { surpls,epr } from "../../../../assets/images";
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const Page = () => {
    const autre_services_data = JSON.parse(localStorage.getItem('autre_services_data'));
    const dispatch = useDispatch();
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const [genre, setGenre] = useState(autre_services_data.genre);
    const [genreText, setGenreText] = useState(autre_services_data.genreText);
    const [qte, setQte] = useState(autre_services_data.qte);
    const [categorie, setcCategorie] = useState(autre_services_data.categorie);
    const [categorieText, setcCategorieText] = useState(autre_services_data.categorieText);
    const [qteError, setQteError] = useState(0);

    const [ar_d, setArD] = useState([{id:'', type:'', date:'', age:''}]);
    useEffect(() => {

    }, [])
  
    const handleGoBack = () => {
      history.push('/garde-enfants/pour-qui');
    };

    const handleGoTo = () => {
      setQteError(0)
      if(qte == '' || qte == undefined){
        setQteError(1)
      }else{
        autre_services_data.ar_d = ar_d
        autre_services_data.qte = qte
        localStorage.setItem('autre_services_data', JSON.stringify(autre_services_data))
        history.push('/garde-enfants/frequence');
      }
    };
    
    const handleQte = element => {
      setQteError(0)
      setQte(element);

      const arr = []
      arr.push({id:0, type:'', date:'', age:''})
      for (let index = 1; index < element; index++) {
        arr.push({id:index, type:'', date:'', age:''})
      }
      setArD(arr)
    };

    const handleType = (index, event) => {
      ar_d[index].type = event.target.value
      setArD(ar_d)
    }
    const handleDate = (index, event) => {
      const age = moment().diff(moment(event.target.value), 'years');
      ar_d[index].date = event.target.value
      ar_d[index].age = age
      setArD(ar_d)
    }

    return (
      <div className="gardeenfants3" >
        <section className="gray-bg big-padding " id="besoin1">
          <div className="container small-container ">
            <div className='registerform'>
              <div className='row '>
                <div className='col-md-8'><p onClick={()=>handleGoBack()} style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>&lt;	&lt;	Retour</p></div>
                <div className='col-md-8'>
                  <div className="section-title">
                    <h2><span>Qui doit être gardé ? </span></h2>
                    <p>Combien d'enfants souhaitez-vous garder ?</p>
                  </div>
                  <div className='custom-form col-md-2' style={{ paddingLeft:"0" }}>
                    <div onClick={() => handleQte(1)} className={qte == 1 ? "boxShadow coiffurebox selected":"boxShadow coiffurebox"}>
                      <p>1</p>
                    </div>
                  </div>
                  <div className='custom-form col-md-2' style={{ paddingLeft:"0" }}>
                    <div onClick={() => handleQte(2)} className={qte == 2 ? "boxShadow coiffurebox selected":"boxShadow coiffurebox"}>
                      <p>2</p>
                    </div>
                  </div>
                  <div className='custom-form col-md-2' style={{ paddingLeft:"0" }}>
                    <div onClick={() => handleQte(3)} className={qte == 3 ? "boxShadow coiffurebox selected":"boxShadow coiffurebox"}>
                      <p>3</p>
                    </div>
                  </div>
                  <div className='custom-form col-md-2' style={{ paddingLeft:"0" }}>
                    <div onClick={() => handleQte(4)} className={qte == 4 ? "boxShadow coiffurebox selected":"boxShadow coiffurebox"}>
                      <p>4</p>
                    </div>
                  </div>
                  <div className='custom-form col-md-2' style={{ paddingLeft:"0" }}>
                    <div onClick={() => handleQte(5)} className={qte == 5 ? "boxShadow coiffurebox selected":"boxShadow coiffurebox"}>
                      <p>5</p>
                    </div>
                  </div>
                  <div className='custom-form col-md-2' style={{ paddingLeft:"0" }}>
                    <div onClick={() => handleQte(6)} className={qte  == 6 ? "boxShadow coiffurebox selected":"boxShadow coiffurebox"}>
                      <p>6</p>
                    </div>
                  </div>

                  {
                    qteError == 1 ?
                    <div className='col-md-9' style={{ paddingLeft:"0", paddingTop:'30px', clear:'both' }}>
                      <div className="alert alert-danger" role="alert">
                        Veuillez choisir la quantité !
                      </div>
                    </div>
                    :
                    ""
                  }
                  {
                    qte != '' && qte != undefined ?
                    <div className='custom-form col-md-12' style={{ paddingLeft:"0" }}>
                      <div className="section-title">
                        <h2><span>&nbsp;</span></h2>
                        <p>Qui ?</p>
                      </div>
                      {
                        ar_d.map((i, index) => (
                          <div className="row">
                            <div className="col-6">
                              <p style={{ marginBottom:"0px" }}>Fille ou garçon</p>
                              <select onChange={handleType.bind(null, index)}>
                                <option value="">Choisir</option>
                                <option value="1">Fille</option>
                                <option value="2">Garçon</option>
                              </select>
                            </div>
                            <div className="col-6">
                              <p style={{ marginBottom:"0px" }}>Date de naissance</p>
                              <input type='date' className="type_date" placeholder="" name='datenaissance[]' onChange={handleDate.bind(null, index)} />
                            </div>
                          </div>
                        ))
                      }
                    </div>
                    :
                    ""
                  }
                  <div className='col-md-9' style={{ paddingLeft:"0", paddingTop:'30px', clear:'both' }}>
                    <div style={{textAlign:'left',marginBottom:'30px'}}>
                      <a onClick={()=>handleGoTo()} class="btn btnform w200 "> Continuer </a>
                    </div>
                  </div>
                </div>    
                <div className='col-md-4'>
                    <div className="header1 boxShadow">Recapitulatif</div>
                    <div className="body1 boxShadow">
                      <p style={{ margin:'0px', paddingLeft:'10px' }}>1 intervention garde enfants</p>
                      <hr style={{ margin:'10px' }}></hr>
                      {
                        genreText != '' ?
                        <ul>
                          <li>{genreText}</li>
                          {
                            autre_services_data.genre == 1 ?
                            <li>{qte} {qte > 1 ? 'enfants':'enfant'}</li>
                            :
                            <li>{qte} {qte > 1 ? 'périscolaires':'périscolaire'}</li>
                          }
                          {
                            ar_d.map(ele =>(
                              ele.type != '' ?
                              <li>{ele.type == 1 ? 'Fille':'Garçon'} {ele.age != '' ? ' - '+ele.age+' ans':''}</li>
                              :
                              ''
                            ))
                          }
                        </ul>
                        :
                        ''
                      }
                    </div>
                </div>  
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Page;