import React, { useEffect,useState } from "react";
import './styles.scss'; 
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { useParams } from "react-router";
import { useHistory } from "react-router";

import { getPricesByElement } from "../../../actions/pages";
 

const Page = () => { 
    const { keyword:gottenKeyword } = useParams();
    const [keyword, setkeyword] = useState(gottenKeyword)
    const history = useHistory()
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
 
    const { user } = useSelector(state => state.user)
    const [langset, setLangset] = useState(false);

 
   

   const handleGoTo = () => {
         history.push('/recherche-artisan#');
        
    }
 
 
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())

    }


    const componentClicked =  () => {
        
       
    }


  const handleShow = () => {
       
        // history.push('/prenez-rendez-vous-avec-un-artisan#');

  };
 
 

     useEffect(() => {

        if(langset == false){
            setLangset(true)
             setTimeout(handleShow, 2000);
        }

        let codepostal=localStorage.getItem('codepostal');
        let lat=localStorage.getItem('lat');
        let lng=localStorage.getItem('lng');
        
        
        dispatch(getPricesByElement(localStorage.getItem('intervention_type'),lat,lng,codepostal)).then((res) => {

         
          if(res.succes == false)
            setShow(true)
          else{
            localStorage.setItem('min_price', res.data.min_price)
            localStorage.setItem('max_price', res.data.max_price)
            localStorage.setItem('artisantsids', res.artisants)
            setTimeout(() => {
              history.push('/prenez-rendez-vous-avec-un-artisan#');
            }, 300);
          }
       })

    }, [])
   
    return (
        <div className="besoin  " >
           <section className="gray-bg big-padding " id="besoin4">

             <div className="container small-container ">
             <form className='registerform'>
               
               <div className='row '>
                <div className='col-md-12'>
                   <div className='progressbare'>
                     <div className='veh' style={{ left: '60%'}}><img src='/images/Groupe6849.png'/></div>
                     <div className="progressvalue" style={{width:'65%'}}></div>
                     <div className='bat' ><img src='/images/Groupe6903.png'/></div>
                   </div>
                </div>
                </div>

                   
                <div className='row '>
                <div className='col-md-12'>
                    
      
                    {
                      show ?
                     <div className="section-title"><h2 className='center'><span>Aucun intervenant trouvé ... </span></h2></div>
                      
                      :
                      
                     <div className="section-title"><h2 className='center'><span>Nous recherchons un intervenant ... </span></h2></div>

                    }
 

                       <div className='mrg78'></div><br/>

                       {
                    show ?
                    '':
                       <div className='loading'>

                        <img src='/images/animation_500_l2pw5cf5.gif' />

                       </div>
                      }
 
 
                </div>

               

               </div>

             </form>
               

          
             
             </div>



           </section>

        </div>
    );
}

export default Page;