import React, { useEffect,useState,useRef } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { AssistantItem } from "../../components";
import { useParams } from "react-router";
import { apiClient } from "../../actions/api"
import { useTranslation } from 'react-i18next';
import { registerUser } from "../../actions/user";
import { getAllCategories,getContentPage } from "../../actions/pages";
import { geocodeByAddress,getLatLng }  from 'react-google-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';


const Page = () => {
    const { keyword:gottenKeyword } = useParams();
    const refVideo = useRef();
    const [keyword, setkeyword] = useState(gottenKeyword)
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categ, setCateg] = useState('');
    const [address,setAddress]=useState("");
    const [city,setCity]=useState("");
    const [value, setValue] = useState(null);
    const [lat,setLat]=useState("");
    const [lng,setLng]=useState("");


    const { data,contents } = useSelector(state => state.pages)
    
    let listCategories=[]



     useEffect(() => {

        dispatch(getAllCategories());
        dispatch(getContentPage({},'Devenir watcher'));
        setCategories(listCategories);
       
       
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 

    }, [])
 
    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        let go=true;



        if (formDataObj.password !== formDataObj.passwordconfirm) {
              
      go=false;
      if(currentlang=='fr'){
                alert("Les deux mots de passe ne correspondent pas !")
            }
            else{
                alert("Passwords do not match !")
            } 
            
        }

       if(categories.length <= 0){
            go=false;
            if(currentlang=='fr'){
                alert("Veuillez sélectionner au moins une catégorie !")
            }
            else{
                alert("Please select at least one category!")
            } 
        }

       if(!value || address!=value){
            go=false;
            if(currentlang=='fr'){
                alert("Veuillez choisir une ville !")
            }
            else{
                alert("Please choose a city!")
            } 
        }

        if(go){
           dispatch(registerUser(formDataObj,'watcher',currentlang));
        }

        
    }

    const handleSubmit2 = (e) => {
        e.preventDefault();
        window.location.href='/watchers/login'
        
    }

    const handleSetCategorie = (categ) => {
            
            let newcategories=[...categories];
            var index = newcategories.indexOf(categ)
            if( index > -1){
               newcategories.splice(index, 1);
               setCategories(newcategories);
 
            }
            else{

               newcategories.push(categ);
               setCategories(newcategories);
            }            
    }


     const handleChange = address => {
     
        setAddress(address);
      };
     const selectValuemap=(value)=>{
        
        setAddress(value);
        setValue(value);
        geocodeByAddress(value)
         .then(results => getLatLng(results[0]))
         .then(({ lat, lng }) =>{
            setLat(lat);
            setLng(lng);
         }
         );

     }


 const renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions,loading }) => (
        
        <div className="autocomplete-container autocomplete-root">
                                                   
             
                 <input required name="ville" {...getInputProps({
                          placeholder: t('Ville'),
                          className: 'form-control utocomplete-input',
                      })} />
                 

        <div className="autocomplete-dropdown-container my-2">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion,index) => (
            
              <div key={index}  className="listitem" {...getSuggestionItemProps(suggestion)}>
                <span >{suggestion.description}</span>
              </div>
            ))}
          </div>
        </div>

      
      );

    return (

        <div className="devenirassistant" >
             
               <section className="listing-hero-section hidden-section" data-scrollax-parent="true" id="sec1">
                        <div className="bg-parallax-wrap">
                           
                            <div className="media-container video-parallax">
                                
                                <div className="video-container wdth77"  >
                                    <video autoPlay={"true"} muted
          controls ={true} ref={refVideo}  >
                                        <source   src="https://mywatcher.fr/video/becomwatcher.mp4" type="video/mp4"   />
                                    </video>
                                </div>
                                
                                 
                            </div>
                        </div>
                        <div className="container">
                            <div className="list-single-header-item  fl-wrap">
                                <div className="row">
                                    <div className="col-md-9">
                                        
                                        <div className="geodir-category-location fl-wrap">  </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="list-single-header_bottom fl-wrap">
                                
                                
                                
                                
                                <div className="list-single-stats cws">
                                    <ul className="no-list-style">
                                        
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>



<section className="pd889"><div className="container big-container">
    {
                                 contents.titre &&
              <div className="section-title">
                <h2><span>  {currentlang=='fr' ? contents.titre.fr : contents.titre.en }</span></h2>
                <span className="section-separator"></span>
               
              </div>

            }
           {
                                            contents.contenu &&
                                            <p className="default-desc margright mrgtop center" dangerouslySetInnerHTML={{__html: currentlang=='fr' ? contents.contenu.fr : contents.contenu.en }}></p>
                }  

            </div>    
</section>

 <section className="gradient-bg hidden-section devenirassistant center" data-scrollax-parent="true">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="colomn-text fl-wrap">
                                        <div className="colomn-text-title">

                                            <img src="/images/hapyman.png"  className="imgavatar"/>
                                            <h3 className="center" >{t('Témoignage Loic')}</h3>
                                            <p className="center">{t('Témoignage Loic desc')}</p>
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        
                        
                        
        
                        <div className="circle-wrap" >
                            <div className="circle_bg-bal circle_bg-bal_versmall" data-scrollax="properties: { translateY: '-350px' }" ></div>
                        </div>
                    </section>

            <section className="pd889"><div className="container big-container">
         


           


                <div className='row'>

                      <div className='col-md-2'></div>
                      <div className='col-md-8'>

            <div className="text-center mx-auto my-5 col-lg-12">

           
            <Form className="mb-5"  onSubmit={handleSubmit} >
                           
                <div className="mb-3 row">
                  <div className="col">
                     <div className="form-check">
                       <input name="gender" type="radio" required className="form-check-input" value="M" />
                          <label title="" className="form-check-label">{t('Monsieur')}</label>
                    </div>
                </div>
                 <div className="col">
                   <div className="form-check">
                      <input name="gender" type="radio" className="form-check-input" value="F"/>
                        <label title="" className="form-check-label">{t('Madame')}</label>
                    </div>
                </div>
               </div>


            <div className="mb-3 row">
              <div className="col">
              <label className="form-label">{t('Prénom')}*</label>
                 <input placeholder="Robert" name="firstname" type="text" required className="form-control"/>
              </div>
              <div className="col">
              <label className="form-label">{t('Nom')}*</label>
                 <input placeholder="Aliox" name="lastname" type="text" required className="form-control"/>
              </div>
            </div>

             <div className="mb-3 row">
              <div className="col">
              <label className="form-label">{t('Ville')}*</label>

                  <PlacesAutocomplete  value={address} onChange={handleChange}   onSelect={selectValuemap} searchOptions={{ types: ['(cities)'] }}>
                      {renderFunc}
                  </PlacesAutocomplete>
                
              </div>
            </div>

            <div className="mb-3 row">
              <div className="col">
              <label className="form-label">{t('Téléphone')}*</label>
                 <input placeholder={t('Téléphone')} name="tel" type="text" required className="form-control"/>
              </div>
            </div>
             <div className="mb-3 row">
              <div className="col">
              <label className="form-label">{t('Décris toi brièvement (ce que tu aimes, tes passions, etc.) max 150 mots pour tes futurs contacts')}*</label>
                 <textarea   name="description"  className="form-control" row="8" required></textarea>
              </div>
            </div>

            <div className="mb-3 row">
              <div className="col">
              <label className="form-label"> {t('Votre tarification minimum')}*</label>
                 <input placeholder="30€/h" name="price" type="number" className="form-control" required/>
              </div>
            </div>

            <div className="mb-3 row">
              <div className="col">
              <label className="form-label">{t('Vous pouvez assister')}*</label>
                 <div className='row'>

                 {data.map((item,index) => {
                                       let newindex=index+1;
                                       return (
                                            
                                        
                                             <div key={index} className='col-md-3' >
                                                <div  className={'geodir_status_date  tain'+newindex}  onClick={()=>handleSetCategorie(item.id)}> {currentlang == 'fr' ? item.label : item.label_en } {categ} 
                                                 {
                                                   categories.includes(item.id) ? <i class="fal fa-check"></i> : null
                                                 }
                                                </div>
                                             </div>

                                        );
                            
                  })}
                   
                 </div>
              </div>
            </div>
 <br/>

            <div className="mb-3 row">
              <div className="col">
              <label className="form-label">{t('Email')}*</label>
                 <input placeholder="mail@domaine.com" name="email" type="email" required className="form-control"/>
              </div>
            </div>

             <div className="mb-3 row">
              <div className="col">
              <label className="form-label">{t('Mot de passe')}*</label>
                 <input placeholder="********" name="password" type="password" required className="form-control"/>
              </div>
            </div>


            <div className="mb-3 row">
              <div className="col">
              <label className="form-label">{t('Confirmer mot de passe')}*</label>
                 <input placeholder="*******" name="passwordconfirm"  required type="password" className="form-control"/>
              </div>
           
            </div>
            <input type='hidden' value={categories} name='categories'/>
                   
                            <Button
                                className="w-100 color-btn-bg " variant="success" type="submit">
                                {t("S'inscrir")}<i class="fal fa-bookmark"></i>
                            </Button>
            </Form>
                       
            
 
    </div>

    <div className="log-separator fl-wrap center"><span>{t('OU')}</span></div>
     
    <h5 className='center idn88'>{t('Si vous avez déjà un compte')}</h5>
    
    <div className='row'>
        <div className='col-md-3'></div>
        <div className='col-md-6'>
            <button  className="btn color2-bg url_btn float-btn bggreen99" onClick={handleSubmit2}  >{t('Identifiez-vous')}<i class="fal fa-user"></i></button>

        </div>
        <div className='col-md-3'></div>
    </div>
  



                      </div>
                       <div className='col-md-2'></div>


                      <div className='col-md-12'>
                        
                      </div>


                </div>


     

             </div>

             

         


            </section>
             
        </div>
    );
}

export default Page;