import React from "react";
import './styles.scss';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { IoMdTime } from 'react-icons/io';
import { BiCategoryAlt } from 'react-icons/bi';
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';

const Component = ({ src,item }) => {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const handleClick = (slug) => {

       
           window.location.href="/watcher/"+slug; 
       

       
    }
    const handleClick3 = (slug,mission) => {

          window.location.href="/account/select/watcher/"+slug+"/"+mission; 
        
    }


    const handleClick2 = (idwatcher, refmission) => {
        window.location.href="/paiement/mission/"+idwatcher+"/"+refmission;
    }

        const returnReviews= (note) => {
          
         return(

        
            note==1 ?
             <div><i className="fas fa-star"></i></div>
             : note == 2 ?
             <div><i className="fas fa-star"></i><i className="fas fa-star"></i></div>
             : note == 3 ?
             <div><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></div>
             : note == 4 ?
             <div><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></div>
             : note == 5 ?
             <div><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></div>
             :
             <div></div>

          
       )
    }

    return (
        <div className={src == 'h' ? "assistant-item col-md-3 " :"assistant-item col-md-6 " }  >
            <article className="geodir-category-listing fl-wrap mr88">
                                            <div className="geodir-category-img">
                                                

                                                <a onClick={()=>src=='d' ? handleClick3(item.slug,item.id_mission) : handleClick(item.slug) } className="geodir-category-img-wrap fl-wrap">
                                                <img src="/images/team/2.png" alt="" /> 
                                                 <div className='center p33'>
                                                    <div className='ps889' style={{backgroundImage:`url(${item.photo })`}}>

                                                     </div>
                                                 </div>
                                                </a> 

                                                {
                                                    src=='d' ? 

                                                      

                                                         item.price > item.prixmission ?
                                                              <div className="geodir_status_date gsd_open pricebb"> <span className='libebaree'>€{item.prixmission}</span> €{item.price}</div>
                                                         :

                                                            <div className="geodir_status_date gsd_open price"> €{item.prixmission}</div>
                                                      
                                                   

                                                    :
                                                     <div className="geodir_status_date gsd_open price">€{item.tarif}/hr</div>
                                                }
                                               
                                               
                                                <div className="geodir-category-opt">
                                                    <div className="listing-rating-count-wrap">
                                                        
                                                        <div className="listing-rating card-popup-rainingvis" data-starrating2="5">

                                                             { item.evaluation &&  returnReviews(item.evaluation.note)}
                                                        <div className="card-popup-rainingvis_bg"><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><div></div></div></div>
                                                        <br/>
                                                        <div className="reviews-count">{item.evaluation && item.evaluation.avis} {t('avis')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="geodir-category-content fl-wrap title-sin_item">
                                                <div className="geodir-category-content-title fl-wrap">
                                                    <div className="geodir-category-content-title-item">
                                                        <h3 className="title-sin_map"><a className="upcase" onClick={()=>src=='d' ? handleClick3(item.slug,item.id_mission) : handleClick(item.slug) }>{item.prenom} <span>{item.lname}</span>. </a> {item.intern== 1 && <span className="verified-badge"><i className="fal fa-check"></i></span> }</h3>
                                                        <div className="geodir-category-location fl-wrap"><a  onClick={()=>src=='d' ? handleClick3(item.slug,item.id_mission) : handleClick(item.slug) }><i className="fas fa-map-marker-alt"></i> {item.ville}</a></div>
                                                    </div>
                                                </div>
                                                <div className="geodir-category-text fl-wrap">
                                                    <p className="small-text">{item.description}...</p>
                                                    
                                                </div>
                                                <div className="geodir-category-footer fl-wrap center">
                                                     {
                                                        src== 'd' ?
                                                        <button onClick={()=>handleClick2(item.id_watcher,item.id_mission)} className="chosir-profil-button red-bg">{t('Choisir ce profil')} </button>
                                                        :
                                                        <button onClick={()=>handleClick(item.slug)} className="view-profil-button green-bg">{t('Voir le profil')} </button>
                                                     }
                                                     
                                                    
                                                     
                                                </div>
                                            </div>
                                        </article>
        </div>
    );
}

export default Component;