import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button ,Modal,CloseButton } from "react-bootstrap"
import { AssistantItem } from "../../components";
import { AvisItem } from "../../components";
import { getAllCategories,getAllReviews,getWatchersHome } from "../../actions/pages";
import { getListWatcher } from "../../actions/user";
import { useHistory } from "react-router";
import { BASE_URL_PROD } from "../../components/config/keys";
import { surpls,epr } from "../../assets/images";
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import {getMetier, getServices, getMetierByName, getAllCities, getBlogs, getCommentaires, addNewsletter, getPartners } from "../../actions/pages";
 

const Page = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const { data,reviews,watchers } = useSelector(state => state.pages)
    const [dataCategories, setDataCategories] = useState(data)
    const [categorie, setCategorie] = useState(102)
    const [address,setAddress]=useState("");
    const [city,setCity]=useState("");
    const [value, setValue] = useState(null);
    const [lat,setLat]=useState("");
    const [lng,setLng]=useState("");

    const popuphowitwoarck = localStorage.getItem("popuphowitwoarck");
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [show, setShow] = useState(false);
    const [oneShow, setOneShow] = useState(false);
    const [langset, setLangset] = useState(false);
    const [errorEmail, setErrorEmailNews] = useState(false);
    const [serachinput, setSerachinput] = useState('');
    const [emailNewsletter, setEmailNewsletter] = useState('');
    const [typeService, setTypeService] = useState(1);
    const { metiers, services, allCities, blogs,commentaires, our_partners } = useSelector(state => state.pages)

    const [showmodalvideo1, setShowmodalVideo1] = useState(false);
    const [showmodalvideo2, setShowmodalVideo2] = useState(false);

    const lanshVideo1 = () => {
      setShowmodalVideo1(true)
    }
    const lanshVideo2 = () => {
      setShowmodalVideo2(true)
    }
    const handleClose1 = () => {
      setShowmodalVideo1(false)
    }
    const handleClose2 = () => {
      setShowmodalVideo2(false)
    }

    var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
    
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };

   var settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
    
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };
    const handleClose = () => setShow(false);
    const handleShow = () => {
       setOneShow(true);
       setShow(true);
       

     };

    const handleAddNewsletter =  () => {
      setErrorEmailNews(false)
      if(emailNewsletter == '')
        setErrorEmailNews(true)
      else
        dispatch(addNewsletter(emailNewsletter)); 
    }

    const handleClick = () => {
       
         let go=true;

         if(serachinput == ''){
            go=false;
            alert("Veuillez saisir un mot!")
            return false;
         }



        const params = {
                serachinput: serachinput,
        }

        dispatch(getMetierByName(params)); 
    }
    const handleChangeInputSearch = searchValue => {
      setSerachinput(searchValue.target.value);
    };
    const handleChangeNewsletter = emailNews => {
      setEmailNewsletter(emailNews.target.value);
    };

    const handleSetCategorie = (categ) => {
          setCategorie(categ);
    }

    const handleCheck = () => {
          
    }
    const GoToService = (slug, type) => {
      history.push("quel-est-votre-besoin-1/"+type+'-'+slug)
    }

    const proposerService = () => {
      history.push("login/")
    }

    const goToPage = (page) => {
      localStorage.setItem('autre_services_data', '')
      history.push(page)
    }

    const handleCreateProf = () => {
           
    }
    
    const handleService = (s) => {
      setTypeService(s)
   }

    useEffect(() => {
      
    dispatch(getAllCities());
    dispatch(getMetier());
    dispatch(getServices());
    dispatch(getBlogs());
    dispatch(getCommentaires());
    dispatch(getPartners());
        //dispatch(getAllCategories());
        //dispatch(getWatchersHome());
        //dispatch(getAllReviews());

    }, [])
 
      const handleChange = address => {
        setAddress(address);
      };
     const selectValuemap=(value)=>{
         
        

     }

 

    return (
        <div id="homwraped" className="home">
                <div className="content">
                    <section className="hero-section"   data-scrollax-parent="true" style={{paddingBottom:"0px"}}>
                        <div className="bg-tabs-wrap">
                            <div className="bg-parallax-wrap" data-scrollax="properties: { translateY: '200px' }">
                               
                                
                               
                               
                            </div>
                        </div>
                        <div className="container small-container center homflat">
                            <div className='row '>
                                 <div className='col-md-7'>

                                    <div className="intro-item fl-wrap">
                              
                                        <div >
                                            <h1>Nos pros à votre service !</h1>
                                        </div>
                                        <h3>Un gain de temps et des tarifs connus d'avance</h3>

                                        <div>
                                          
                                            <div className="tab tab667">
                                        <div id="tab-inpt1" className="tab-content first-tab">
                                            <div className="main-search-input-wrap fl-wrap">
                                                <div className="main-search-input fl-wrap">
                                                    
                                                    
                                                       <div className="main-search-input-item location autocomplete-container autocomplete-root">
                                                   
                                                                     
                                                                        <input className='serachinput' type='text' placeholder="ex: plombier, chauffagiste, électricien.." value={serachinput} name='serachinput'  onChange={handleChangeInputSearch}  />
                                                                             
                                                                        <a href="javascript:void(0)"><i className="fa fa-dot-circle-o"></i></a>

                                                            
                                                                </div>
                                                     
                                                    <button className="main-search-button_2 color2-bg" onClick={handleClick}> <i className="far fa-search"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                        </div>
                                         
                                    </div>

                                 </div>

                                  <div className='col-md-5'  >


                                        <div className='homebg' style={{backgroundImage:"url('/images/maçon.png')"}}>
                                        </div>

                                 </div>



                            </div>
                          

 
                    
                        </div>
                       
                    </section>


                    <section className='pd889' >
                        <div className="container small-container">
                            <div className="section-title">
                                <h2><span>Nos engagements </span></h2>
                           
                                <p className='center'>Nous nous engageons à vous proposer un service sans surprise !</p>
                            </div>


                              <div className="process-wrap fl-wrap commentcafonctionne "><div className="default-desc margright mrgtop"><p>&nbsp;</p>
                                    <ul className="no-list-style row">



                                    <li className="col-md-4">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/iconechrono.png' />
                                    </div>

                                    <p className='basecolor'>Traitement rapide des demandes</p>
                                    </div>
                                    </li>

                                    <li className="col-md-4">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/iconefacture.png' />
                                    </div>

                                    <p className='basecolor'>Une facturation claire et transparente</p>
                                    </div>
                                    </li>

                                    <li className="col-md-4">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/iconevisio-1.png' />
                                    </div>

                                    <p className='basecolor'>Dépannage en viso</p>
                                    </div>
                                    </li>

                                    </ul>
                                    </div>
                             </div>

                                         



                        </div>
                    </section>



<section className='pd889' id="section-services">
                        <div className="container small-container">
                            <div className="section-title">
                                <h2><span>De quel service avez-vous besoin ? </span></h2>
                           
                                <p className='center'>Pour chaque situation, trouvez le prestataire dont les compétences<br/> répondent à vos attentes et à votre niveau d'exigence.</p>
                            
                                       <div className="radionbtnhome">
                              <ul >

                                <li onClick={()=>handleService(1)} class={typeService == 1 ? 'active':''}>Artisans</li>
                                <li onClick={()=>handleService(2)} class={typeService == 2 ? 'active':''}>Autres services</li>

                              </ul>
                            </div>

                            </div>

                 


                            <div className="process-wrap fl-wrap sectionservices " >
                                <div className="default-desc margright mrgtop">
                                  {
                                    typeService == 1 ?
                                    <div className='row'>
                                         {
                                           metiers != undefined && metiers != "" ?
                                           metiers.data.map((item, index) => (
                                             <div className='col-6 col-md-3'>
                                               <div className='cardservice' >
                                               <a href='#' onClick={()=>GoToService(item.id,item.type)} >
      
 
                                                  <img src={item.image} />
                                                  <h4>{item.label}</h4>
      
                                               </a>
      
                                               </div>
                                             </div>
                                           ))
                                           :
                                           ""
                                         }
                                    </div>
                                    :
                                    <div className='row'>
                                          {/* {
                                            services != undefined && services != "" ?
                                            services.data.map((item, index) => (
                                              <div className='col-6 col-md-3'>
                                                <div className='cardservice' >
                                                <a href='#' onClick={()=>GoToService(item.id,item.type)}>
                                                  <h4>{item.label}</h4>
                                                </a>
      
                                                </div>
                                              </div>
                                            ))
                                            :
                                            ""
                                          } */}
                                      <div className='col-6 col-md-3'>
                                        <div className='cardservice' ><a onClick={() => goToPage('/aide-domicile')}><img src='/images/Aide domicile.png' /><h4>Aide Domicile</h4></a></div>
                                      </div>
                                      <div className='col-6 col-md-3'>
                                        <div className='cardservice' ><a onClick={() => goToPage('/coiffure')}><img src='/images/Coiffeur.png' /><h4>Coiffure</h4></a></div>
                                      </div>
                                      <div className='col-6 col-md-3'>
                                        <div className='cardservice' ><a onClick={() => goToPage('/cours-standard')}><img src='/images/Cours.png' /><h4>Cours Standard</h4></a></div>
                                      </div>
                                      <div className='col-6 col-md-3'>
                                        <div className='cardservice' ><a onClick={() => goToPage('/demenagement-meubles')}><img src='/images/Déménagement.png' /><h4>Déménagement Meubles Electro</h4></a></div>
                                      </div>
                                      <div className='col-6 col-md-3'>
                                        <div className='cardservice' ><a onClick={() => goToPage('/demenagement')}><img src='/images/Déménagement.png' /><h4>Déménagement</h4></a></div>
                                      </div>
                                      <div className='col-6 col-md-3'>
                                        <div className='cardservice' ><a onClick={() => goToPage('/garde-animaux')}><img src='/images/Animaux.png' /><h4>Garde Animaux</h4></a></div>
                                      </div>
                                      <div className='col-6 col-md-3'>
                                        <div className='cardservice' ><a onClick={() => goToPage('/garde-enfants')}><img src='/images/Enfant.png' /><h4>Garde Enfants</h4></a></div>
                                        </div>
                                      <div className='col-6 col-md-3'>
                                        <div className='cardservice' ><a onClick={() => goToPage('/informatique')}><img src='/images/Informatique.png' /><h4>Informatique</h4></a></div>
                                      </div>
                                      <div className='col-6 col-md-3'>
                                        <div className='cardservice' ><a onClick={() => goToPage('/jardinage')}><img src='/images/Jardinage.png' /><h4>Jardinage</h4></a></div>
                                      </div>
                                    </div>
                                  }

                                </div>
                             
                             </div>

                                         
                          
                          <div className='row '>

                             <div className="col-md-12 ">
                               <div className='cadre778'>
                                <h5>Nous sommes présents</h5>
                                <p>
                                  {
                                    allCities != undefined && allCities != "" && allCities.length > 0 ?
                                    allCities.map((item, index) => (
                                      <span>
                                        {item.ville}
                                        {
                                          index < (allCities.length-1) ?
                                          ' - '
                                          :
                                          ''
                                        }
                                      </span>
                                    ))
                                    :
                                    ''
                                  }
                                   {/* Tours - Paris - Bordeaux - Lille - Marseille */}
                                </p>
                                </div>
                             </div>

                          </div> 


                        </div>
                        <br/><br/>
                    </section>


                    <section className='pd889'>
                                            <div className="container small-container">
                                                <div className="section-title">
                                                    <h2><span> Comment ça marche ? </span></h2>
                                               
                                                
                                                </div>
                                                <br/>

                                                <div className='sectionvideos'>

                                                <div className='row'>

                                                  <div className='col-md-6'>

                                                      <div className='cms778'>

                                                        <img onClick={lanshVideo1} src='images/videobg1.jpg' />

                                                      </div>
                                                      <div className='cms778'>

                                                        <img onClick={lanshVideo2} src='images/videobg2.jpg' />

                                                      </div>

                                                  </div>

                                                   <div className='col-md-6'>

                                                     <div className='sectOK998'>
                                                      <img src="/images/menuicons.jpg" />
                                                      <ul>
                                                        <li style={{paddingTop:'0px'}}>Accédez aux disponibilités<br/> d'artisans qualifiés et labelisés</li>
                                                        <li style={{paddingTop:'9px'}}>Ajoutez des photos ou vidéos.</li>
                                                        <li style={{paddingTop:'26px'}}>Visio dépannage.</li>
                                                        <li style={{paddingTop:'21px'}}>Retrouvez votre historique<br/> d’intervention dans votre espace dédié.</li>
                                                        <li style={{paddingTop:'15px'}}>Suivez le déplacement du prestataire.</li>
                                                        <li style={{paddingTop:'11px'}}>Gérez vos projets en toute tranquillité<br/> sur votre espace dédié.</li>
                                                        <li style={{paddingTop:'9px'}}>Scannez un QR code pour sécuriser<br/> l'intervention.</li>
                                                        <li style={{paddingTop:'7px'}}>Restez informé à chaque étape de <br/>l'intervention et informez votre <br/>propriétaire, bailleur, syndic ou locataire.</li>
                                                        <li style={{paddingTop:'7px'}}>Laissez un avis.</li>
                                                        <li style={{paddingTop:'14px'}}>Payez vos interventions en 2x, 3X.</li>
                                                        <li style={{paddingTop:'15px'}}>Possibilité de s'abonner.</li>
                                                        <li style={{paddingTop:'20px'}}>Assurez votre logement.</li>
                                
                                                      </ul>


 
                                                     </div>

                                                  </div>

                                                </div>

                                            </div>

                                            </div>

                    </section>



                   <section className='pd889'>
   <div className="container small-container">
   <div className='row '>

   <div className="cadre779 cadre77990 " style={{backgroundImage:"url('/images/bloc-immo.png')"}}>

           <div className="col-md-4  center bg0098" >
                               
                             </div>

                              <div className="col-md-6 ">
                               <br/> <br/>
                                <h4 className='basecolor'  style={{    textAlign: 'left'}} ><strong>U<span style={{textTransform: 'lowercase'}}>n projet immobilier ?</span></strong> <br/> 
                                <span className='f1imo'><span style={{textTransform: 'uppercase'}}>V</span>ous souhaitez <span className='xx9'>louer</span>, <span className='xx9'>acheter</span> ou <span className='xx9'>estimer</span> un bien immobilier ? <span style={{textTransform: 'uppercase'}}>C</span>onsultez notre site d'annonces.</span></h4>
             
 
                                <p>
                                  
                                  <a className='btn float-btn color2-bg btn9877 ' onClick={(e)=>history.push('/biens')} href='javascript:void(0)' >Consulter</a>

                                </p>

                               
                             </div>

   </div>

                            
    </div>
    </div>
</section>




                    <section className='pd889'>
                        <div className="container small-container">
                            <div className="section-title">
                                <h2><span>Des services en toute confiance </span></h2>
                           <br/> <br/> <br/>
                                <p className='center p889'>Avec Proximiti, vous prenez le contrôle de votre demande. Votre espace personnel vous permettra de suivre en temps réel vos tickets de travaux et réparation, le montant de vos travaux, les détails de vos factures et devis, les rappels de vos services, la gestions de vos contrats.
<br/><strong style={{color:'#073460',marginTop:'5px'}}>En cas de sous soucis, nous sommes avec vous.</strong></p>
                            </div>


                              <div className=" fl-wrap commentcafonctionne "><div className="default-desc margright mrgtop"><p>&nbsp;</p>
                                    <ul className="no-list-style row" style={{padding:'0px'}}>



                                    <li className="col-md-3">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/iconeIA-1.png' />
                                    </div>

                                    <p className='basecolor'>Une IA pour vous  accompagner</p>
                                    </div>
                                    </li>

                                    <li className="col-md-3">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/icone24-7.png' />
                                    </div>

                                    <p className='basecolor'>Disponibilité 24/7</p>
                                    </div>
                                    </li>

                                    <li className="col-md-3">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/iconetransparencetarifaire.png' />
                                    </div>

                                    <p className='basecolor'>Transparence tarifaire</p>
                                    </div>
                                    </li>
                                     <li className="col-md-3">
                                    <div className="process-item">
                                    <div className="process-img">
                                     <img src='/images/iconeprofsvérifiés.png' />
                                    </div>

                                    <p className='basecolor'>Professionnels vérifiés</p>
                                    </div>
                                    </li>

                                    </ul>
                                    </div>
                             </div>

                                         



                        </div>
                    </section>




                 <section className='pd889'>
                        <div className="container small-container">

                             <div className='bgreviews'>
                                 <div className='row'>

                                    <div className='col-md-4'>
                                     <h5 className='basecolor'>Ce que<br/> pensent nos<br/> clients</h5>
                                     <div className='notereview'>{commentaires != undefined && commentaires != '' && commentaires.length > 0 ? commentaires[0].commentaires_moyenne : 0}/5</div>
                                     <div class="listing-rating-count-wrap"><div class="listing-rating card-popup-rainingvis" data-starrating2="5"><div><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></div><div class="card-popup-rainingvis_bg"><span class="card-popup-rainingvis_bg_item"></span><span class="card-popup-rainingvis_bg_item"></span><span class="card-popup-rainingvis_bg_item"></span><span class="card-popup-rainingvis_bg_item"></span><span class="card-popup-rainingvis_bg_item"></span><div></div></div></div><br/><div class="reviews-count"></div></div>
                                      <p>Sur la base de {commentaires != undefined && commentaires != '' && commentaires.length > 0 ? commentaires[0].commentaires_count : 0} avis</p>
                                      


                                    </div>

                                     <div className='col-md-8'>

                                          <Slider {...settings}>
                                          

                                                  {
                                                    commentaires != undefined && commentaires != '' && commentaires.length > 0 ?
                                                    commentaires.map((item, index) => (

                                                      <div className="avis-item" >


                                                      <div className="testi-item fl-wrap left">
                                                          
                                                          <div className="testimonilas-text fl-wrap">
                                                                <span className='ddavis'>il y a 2 jours</span>
                                                                <div className="listing-rating card-popup-rainingvis">
                                                                  <i className="fas fa-star"></i>
                                                                  <i className="fas fa-star"></i>
                                                                  <i className="fas fa-star"></i>
                                                                  <i className="fas fa-star"></i>
                                                                  <i className="fas fa-star"></i>
                                                                  <span>{item.point}/5</span>
    
                                                                </div>
                                                                <strong>{item.professionel_metier}</strong>
                                                                <p>{item.comment}</p>
                                                                
                                                                <div className="testimonilas-avatar fl-wrap">
                                                                    <h3>{item.client_name} ({item.ville}, {item.code_postal})</h3>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                      </div>
                                                    ))
                                                    :
                                                    ''
                                                  }
                                          </Slider>
 


                                    </div>

                                 </div>
                             </div>

                        </div>
                </section>



            <section className='pd889' id='section-formules'>
                        <div className="container small-container">
                            <div className="section-title">
                                <h2><span>Le forfait qui vous correspond </span></h2>
                           
                                <p className='center mxp99' >

                                  Aucune avance de frais, intervention prioritaire, rapidité d'intervention, suivi en temps réel, les meilleurs équipements conformes, intervention Reconnue Garant de l'Environnement (RGE), prestataires qualifiés et courtois… <strong className='basecolor'>Votre maison aura enfin le soins qu'elle mérite, votre maison se porte bien et votre budget aussi !</strong>

                                </p>
                            </div>


                              <div className="process-wrap fl-wrap commentcafonctionne "><div className="default-desc margright mrgtop"><p>&nbsp;</p>
                                    <ul className="no-list-style row offresection">

                                    {
                                        metiers != undefined && metiers != "" ?
                                        metiers.data.map((item, index) => (
                                          item.icone &&
                                          <li className="col-6 col-md-4">
                                          <div className="process-item">
                                          <div className="process-img">
                                          <img src={item.icone} />
                                          </div>
                                          <h4 className='basecolor'>{item.label}</h4>
                                          <h6 className='basecolor'>à partir de </h6>
                                          <div className="priceoffre"><span>{item.abonnement_price}€</span>/mois</div>
      
                                          <p className='basecolor'>aucune avance de frais</p>
                                          <a  className='offreaction' onClick={()=>GoToService(item.id,item.type)}>Voir l'offre</a>
                                          </div>
                                          </li>
                                        ))
                                        :
                                        ""
                                    }



                                    </ul>
                                    </div>
                             </div>

              <br/>                           
<h5 className='basecolor center'>Vous allez « adorer » faire sourire votre maison !</h5>


                        </div>
                    </section>

 <section className='pd889'>
   <div className="container small-container">
   <div className='row '>

                             <div className="col-md-12 ">
                               <div className='cadre779'>
                                <h5>Vous êtes un professionnel ?</h5>
                                <p>
                                     Inscrivez vos disponibilités

                                </p>

                                <a href='javascript:void(0)' onClick={proposerService} >Proposer un service</a>
                                </div>
                             </div>

    </div>
    </div>
</section>


 <section className='pd889'>
   <div className="container small-container">
   <div className='row '>

                             <div className="col-md-6 ">
                              
                                <h6 className='basecolor' style={{fontSize:'20px'}}><strong>La Newsletter</strong> aussi utile qu'une <br/>boîte à outils</h6>
                                <p className='basecolor sect98' >
                                    Pas de panne à l'horizon ? Vous avez de la chance ! Pour que ça dure, il suffit de prendre soin de vos appareils ! Astuces, conseils : on vous explique comment vous pouvez augmenter la durée de vie de vos équipements et être informé de nos bons plans.

                                </p>

                                <br/> 
 
                                <p>
                                  <div className="form-group--nest"><input  value={emailNewsletter} name='emailNewsletter'  onChange={handleChangeNewsletter} className="form-control" type="text" placeholder="Votre adresse e-mail" /><button className="ps-btn" onClick={handleAddNewsletter}>M'abonner</button></div>
                                  {
                                    errorEmail ?
                                    <div class="alert alert-danger col-md-12" style={{clear:'both', marginTop:'5px'}} role="alert">
                                      Veuillez saisir votre email
                                    </div>
                                    :
                                    ''
                                  }
                                </p>

                               
                             </div>

                             <div className="col-md-6  center">
                                <img style={{width: '60%'}} src='/images/Maletteoutilsnewsletter.png' />
                             </div>


    </div>
    </div>
</section>

 <section className='pd889'>
   <div className="container small-container">
   <div className='row '>

   <div className="cadre888">

           <div className="col-md-6  center bg0098" style={{backgroundImage:"url('/images/Groupe6702.png')"}}>
                               
                             </div>

                              <div className="col-md-6 ">
                               <br/> <br/>
                                <h5 className='basecolor'><strong>Nous vous conseillons sur</strong> <span className='xx9'>un projet immobilier</span>, <span className='xx19'>prêt immobilier</span>, <span className='xx119'>assurance habitation</span>, <span className='xx1119'>gestion de patrimoine</span>, <span className='xx11119'>fiscalité</span>, <span className='xx9'>crypto actifs</span>.</h5>
             

                                <br/> 
 
                                <p>
                                  
                                  <a className='btn float-btn color2-bg btn9877 ' href='javascript:void(0)' onClick={()=>history.push('/contact')} >Contactez-nous</a>

                                </p>

                               
                             </div>

   </div>

                            
    </div>
    </div>
</section>


 <section className='pd889 sectionbesoin'>
   <div className="container small-container">
   <div class="section-title left"><h5 className='basecolor' style={{textAlign:'left'}}><span>Besoin de conseils ? </span></h5></div>
   <div className='row '>
   <Slider {...settings1}>
              {
                blogs != undefined && blogs != '' && blogs.length > 0 ?
                blogs.map((item, index) => (
                  <div className='col-6 col-md-3'>
                            <div><img src={item.image} /></div>
                            <h6>{item.title}</h6>
                            <p>{item.min_descr}</p>
                  </div>
                ))
                :
                ''
              }
    </Slider>
                            
    </div>
    </div>
</section>

<Modal show={showmodalvideo1} onHide={handleClose1} centered>
  <Modal.Header closeButton></Modal.Header>
    <Modal.Body>
      <div style={{height:'280px', width:'100%'}}>
        <video style={{width:'100%',height:'100%'}} autoPlay={true} controls={true} ><source src="/video/Client 2.2.mp4" type="video/mp4"/></video>
      </div>
  </Modal.Body>
</Modal>
<Modal show={showmodalvideo2} onHide={handleClose2} centered>
  <Modal.Header closeButton></Modal.Header>
    <Modal.Body>
      <div style={{height:'280px', width:'100%'}}>
        <video style={{width:'100%',height:'100%'}} autoPlay={true} controls={true} ><source src="/video/Ivestisseur immo 1.1.mp4" type="video/mp4"/></video>
      </div>
  </Modal.Body>
</Modal>

  <section className='pd889 sectconfi90'>
                        <div className="container small-container">
                            <div className="section-title">
                                <h2><span>Ils nous font confiance </span></h2>
                           
                                 <br/>
                            </div>

                              

                            <div className='row '>
                              <Slider {...settings1}>
                                {
                                  our_partners != undefined && our_partners != '' && our_partners.length > 0 ?
                                  our_partners.map((item, index) => (
                                    <div className='col-6 col-md-3'>
                                      <div><img src={item.image} /></div>
                                    </div>
                                  ))
                                  :
                                  ""
                                }
                              </Slider>
                              </div>
                         </div>
 </section>

                </div>

 
        </div>
    );
}

export default Page;