import { AfterpayClearpayMessageElement } from "@stripe/react-stripe-js"
import { SET_CATEGORIES,SET_REVIEWS,GET_WATCHERS,GET_WATCHER,SAVE_MISSION,Edit_MISSION,SET_RATE,SET_MESSAGES,SET_FILES,SET_INVOICES,GET_RECLAMATIONS,MISSIONS_REALISE,MISSIONS_VALIDATE,MISSION_ENCOURS,GET_DETAIL_MISSION,WATCHER_MISSION,SET_CONTENT_PAGE,LIST_MISSIONS,GET_MISSION,GET_PATENAIRES,GET_FAQ,SET_CURRENT_RESTAURENT,SET_HORAIRE_RESTAURENT,
     SET_METIERS, SET_Services, SET_ELEMENT_PRICES,SET_CITIES,ALL_MISSIONS,ALL_MESSAGES,ALL_SENT_MESSAGES,COUNT_UNREAD_MSG, ALL_PROF_MISSIONS,SET_BLOGS,ALL_PROF_MISSIONS_CALENDAR, SET_PARTNERS, SET_COMMENTAIRES, SET_DATES, SET_INTERVENTIONS,SET_QUESTIONS,SET_RESPONSES, GET_USER_BIENS, GET_USER_ALL_BIENS,GET_SEARCHED_BIEN, GET_RESERVATION_BIEN,GET_LIST_RAPPORTS,GET_LIST_LOCATAIRES,GET_ASTREINTES, GET_LAST_BIEN } from "../constants/actions"
import { apiClient } from "./api"
 
export const getAllCategories = (params) => dispatch => {
    return apiClient().post(`/getMarque/`, params)
        .then((res) => {
           
            dispatch({type:SET_CATEGORIES,payload:res.data.data})
        })
}


export const getText = (id) => dispatch => {
    return apiClient().get(`/getTextContenu/${id}`)
        .then((res) => {
            return res.data          
        })
}
export const getAllReviews = (params) => dispatch => {
    return apiClient().post(`/getReviews/`, params)
        .then((res) => {
          
            dispatch({type:SET_REVIEWS,payload:res.data.data})
        })
}
export const getWatchersHome = (params) => dispatch => {
    return apiClient().post(`/getWatcherHome/`, params)
        .then((res) => {

            dispatch({type:GET_WATCHERS,payload:res.data.data})
        })
}

export const getReclamations = (params,id_user,type_user,action,token,lnmsg) => dispatch => {
    const data = {
        ...params,
        token:token,
        id_user:id_user,
        type_user:type_user,
        action:action,
        referer:'none'
        
    }
    return apiClient().post(`/getReclamations`, data)
        .then((res) => {

            if(action=='save' ||  action=='remove'){
                if(res.data.success==1){
                     alert(lnmsg)
                     window.location.reload()
                }
                else{
                    alert("An error occurred please try again later.")
                    window.location.reload()
                }
            }
             
             dispatch({type:GET_RECLAMATIONS,payload:res.data?.data})
             
        })
}
export const setInvoices = (params,id_user,type_user,action,token,lnmsg) => dispatch => {
    const data = {
        ...params,
        token:token,
        id_user:id_user,
        type:type_user,
        action:action,
        referer:'none'
        
    }
    return apiClient().post(`/setInvoices`, data)
        .then((res) => {
 
           
               dispatch({type:SET_INVOICES,payload:res.data?.data})
            
             
             
        })
}
export const setFiles = (params,id_user,to,type_user,action,token,lnmsg) => dispatch => {
    const data = {
        ...params,
        token:token,
        id_user:id_user,
        to:to,
        type_user:type_user,
        action:action,
        referer:'none'
        
    }
    console.log(data)
    return apiClient().post(`/setFiles`, data)
        .then((res) => {
 
           console.log(res)
               dispatch({type:SET_FILES,payload:res.data?.data})
            
             
             
        })
}

export const setMessages = (params,id_user,to,type_user,action,token,lnmsg) => dispatch => {
    const data = {
        ...params,
        token:token,
        id_user:id_user,
        to:to,
        type_user:type_user,
        action:action,
        referer:'none'
        
    }
    return apiClient().post(`/setMessages`, data)
        .then((res) => {
 
            if(action=='save'){
                if(res.data.success==1){
                     alert(lnmsg)
                     //window.location.reload()
                }
                else{
                    alert("An error occurred please try again later.")
                    window.location.reload()
                }
            }
            else if(action=='select'){
               dispatch({type:SET_MESSAGES,payload:res.data?.data})
            }
             
             
             
        })
}

export const setRate = (params,id_user,to,missionid,note,type_user,action,token,lnmsg) => dispatch => {
    const data = {
        ...params,
        token:token,
        id_user:id_user,
        to:to,
        missionid:missionid,
        type_user:type_user,
        action:action,
        note:note,
        referer:'none'
        
    }
    return apiClient().post(`/setRate`, data)
        .then((res) => {
 
            if(action=='save'){
                if(res.data.success==1){
                     alert(lnmsg)
                     //window.location.reload()
                }
                else{
                    alert("An error occurred please try again later.")
                    window.location.reload()
                }
            }
            else if(action=='select'){
               dispatch({type:SET_RATE,payload:res.data?.data})
            }
             
             
             
        })
}

export const getWatcher = (params) => dispatch => {
    return apiClient().post(`/getWatcher`, params)
        .then((res) => {
           
            dispatch({type:GET_WATCHER,payload:res.data.data})
        })
}

export const getListWatcherBymission = (params) => dispatch => {
    const data = {
        ...params,
        referer:'none'
    }

    return apiClient().post(`/getWatcherByMission`, data)
        .then((res) => {
             if(res.data.success==false){
               window.location.href='/my-account/';
              }
              else{
                 dispatch({type:WATCHER_MISSION,payload:res.data.data})
              }
            
        })
}


export const getDetailMission = (params) => dispatch => {
    const data = {
        ...params,
        referer:'none'
    }

    return apiClient().post(`/getDetailMission`, data)
        .then((res) => {
            
             if(res.data.success==false){
                // if(params.type=='user'){
                //      window.location.href='/my-account/';
                // }
                // else{
                //     window.location.href='/watchers/dashboard/';
                // }
               
              }
              else{
                 dispatch({type:GET_DETAIL_MISSION,payload:res.data.data})
              }
            
        })
}

export const getPartenaires = (params) => dispatch => {
    return apiClient().post(`/getPartenaires/`, params)
        .then((res) => {
          
            dispatch({type:GET_PATENAIRES,payload:res.data.data})
        })
}
export const getFaq = (params) => dispatch => {
    return apiClient().post(`/getFoireQuestions/`, params)
        .then((res) => {
          
            dispatch({type:GET_FAQ,payload:res.data.data})
        })
}

 
export const getContentPage = (params,page) => dispatch => {
    const data = {
        ...params,
        token:'A',
        referer:'none',
        page:page
    }

    return apiClient().post(`/getPageText`, data)
        .then((res) => {
            
            dispatch({type:SET_CONTENT_PAGE,payload:res.data.data})
        })
}




export const getListMissions = (params) => dispatch => {
    const data = {
        ...params,
        token:'A',
        referer:'none'
        
    }
    return apiClient().post(`/getMissions`, data)
        .then((res) => {
             
             dispatch({type:LIST_MISSIONS,payload:res.data?.data})
             
        })
}

export const getListEncoursMissions = (params) => dispatch => {
    const data = {
        ...params,
        token:'A',
        referer:'none'
        
    }
    return apiClient().post(`/getMissions`, data)
        .then((res) => {
             
             dispatch({type:MISSION_ENCOURS,payload:res.data?.data})
             
        })
}

export const getListValidateMissions = (params) => dispatch => {
    const data = {
        ...params,
        token:'A',
        referer:'none'
        
    }
    return apiClient().post(`/getMissions`, data)
        .then((res) => {

             dispatch({type:MISSIONS_VALIDATE,payload:res.data?.data})
             
        })
}

export const getListRealiseMissions = (params) => dispatch => {
    const data = {
        ...params,
        token:'A',
        referer:'none'
        
    }
    return apiClient().post(`/getMissions`, data)
        .then((res) => {
             
             dispatch({type:MISSIONS_REALISE,payload:res.data?.data})
             
        })
}


export const saveMission = (params,price,categorie,ville,user,token,currentlang) => dispatch => {
    const data = {
        ...params,
        token:token,
        referer:'none',
        origin_id:2,
        price:price,
        categorie:categorie,
        ville:ville,
        user:user,
        currentlang:currentlang
    }
    return apiClient().post(`/mission/save`, data)
        .then((res) => {
           
            if(res.data.success==false){
                alert(res.data?.message)
            }else{
                    // remove storage
                    window.location.href='/my-account/';
              
            }
        })
}

export const EditMission = (params,user,id_mission,token,type,profile) => dispatch => {

    const data = {
        ...params,
        token:token,
        referer:'none',
        type:type,
        user:user,
        profile:profile,
        id_mission:id_mission
    }
    return apiClient().post(`/mission/update`, data)
        .then((res) => {
                 
                 if(profile=='watcher'){
                     setTimeout(function(){
                         window.location.href='/watchers/dashboard/';
                     }, 2000);
                     
                 }

                 else{
                    window.location.href='/my-account/';
                 }
            
                   
              
            
        })
}

 
export const getMissionById = (id,user,token) => dispatch => {
    return apiClient().get(`/getmission/${id}/${user}/${token}`)
        .then((res) => {
              if(res.data.success==false){
               window.location.href='/my-account/';
              }
              else{
                 dispatch({type:GET_MISSION,payload:res.data?.data})
              }
            
        })
}

//====================================================================================
export const getRestaurentById = (id) => dispatch => {
    return apiClient().get(`/products/${id}`)
        .then((res) => {
            
            dispatch({type:SET_CURRENT_RESTAURENT,payload:res.data})
        })
}
export const getRestaurentHoraires = (id) => dispatch => {
    const data = {
        id:id
    };
    return apiClient().post(`/getspatterns/`,data)
        .then((res) => {
            console.log("getspatterns", res.data.data)
            dispatch({type:SET_HORAIRE_RESTAURENT,payload:res.data.data})
        })
}


export const getMetier = () => dispatch => {
    return apiClient().get(`/getMetier/`)
        .then((res) => {
            console.log("res", res.data)
            dispatch({type:SET_METIERS,payload:res.data})
        })
}
export const getServices = () => dispatch => {
    return apiClient().get(`/getServices/`)
        .then((res) => {
            console.log("res", res.data)
            dispatch({type:SET_Services,payload:res.data})
        })
}
export const getAllCities = () => dispatch => {
    return apiClient().get(`/getAllCities/`)
        .then((res) => {
            console.log("cities", res.data)
            console.log("res", res.data)
            dispatch({type:SET_CITIES,payload:res.data.data})
        })
}
export const getBlogs = () => dispatch => {
    return apiClient().get(`/getBlogs/`)
        .then((res) => {
            dispatch({type:SET_BLOGS,payload:res.data.data})
        })
}
export const getDates = () => dispatch => {
    return apiClient().get(`/getDates/`)
        .then((res) => {
            console.log("res", res.data.data)
            dispatch({type:SET_DATES,payload:res.data.data})
        })
}
export const getInterventions = (id) => dispatch => {
    return apiClient().get(`/getInterventions/${id}`)
        .then((res) => {
            console.log("res", res.data)
            dispatch({type:SET_INTERVENTIONS,payload:res.data})
        })
}
export const getQuestions = (id) => dispatch => {
    return apiClient().get(`/getQuestions/${id}`)
        .then((res) => {
            console.log("res", res.data)
            dispatch({type:SET_QUESTIONS,payload:res.data})
        })
}
export const getProfPrices = (id) => dispatch => {
    return apiClient().get(`/getProfPrices/${id}`)
        .then((res) => {
            console.log("resddddd", res.data)
            dispatch({type:SET_ELEMENT_PRICES,payload:res.data.data})
        })
}
export const getReponses = (id, currentlang) => dispatch => {
    return apiClient().get(`/getReponses/${currentlang}/${id}`)
        .then((res) => {
            console.log("res", res.data)
            dispatch({type:SET_RESPONSES,payload:res.data})
        })
}

export const getCommentaires = () => dispatch => {
    return apiClient().get(`/getCommentaires/`)
        .then((res) => {
            dispatch({type:SET_COMMENTAIRES,payload:res.data.data})
        })
}
export const getPartners = () => dispatch => {
    return apiClient().get(`/getPartners/`)
        .then((res) => {
            dispatch({type:SET_PARTNERS,payload:res.data.data})
        })
}

export const getMetierByName = (params) => dispatch => {
    const data = {
        ...params,
        
    }
    return apiClient().post(`/getMetierByName`, data)
        .then((res) => {
            //   dispatch({type:SET_LIST_WATCHERS,payload:res.data?.data})
            //   window.location.href="/find-a-watcher/"
            if(res.data?.succes){
                window.location.href = "quel-est-votre-besoin-1/"+res.data?.type+'-'+res.data?.data+'#'
            }else{
                alert("Désolé aucun métier correspond à votre recherche")
            }
        })
}


export const addNewsletter = (email) => dispatch => {

    const data = {
        email:email
    }
    return apiClient().post(`/newsletter`, data)
        .then((res) => {
            alert(res.data?.succes)
        })
}

export const checkCoupon = (coupon) => dispatch => {

    const data = {
        coupon:coupon
    }
    return apiClient().post(`/checkCoupon`, data)
        .then((res) => {
            return res.data
    })
}


export const acceptMission = (userId, missionId,type,price,comment,action='') => dispatch => {

    const data = {
        userId:userId,
        missionId:missionId,
        type:type,
        price:price,
        comment:comment,
        action:action,

    }
    return apiClient().post(`/acceptMission`, data)
        .then((res) => {
            return res.data
    })
}

export const valideMission = (userId, missionId) => dispatch => {

    const data = {
        userId:userId,
        missionId:missionId
    }
    return apiClient().post(`/valideMission`, data)
        .then((res) => {
            return res.data
    })
}

export const getElements = (type,id) => dispatch => {
    return apiClient().get(`/getElements/${type}/${id}`)
        .then((res) => {
            return res.data
        })
}


export const getDevisNumber = (id) => dispatch => {
    return apiClient().get(`/getDevisNumber/${id}`)
        .then((res) => {
            return res.data
        })
}

export const getProfessionelClients = (id) => dispatch => {
    return apiClient().get(`/getProfessionelClients/${id}`)
        .then((res) => {
            return res.data
        })
}

export const getPricesByElement = (element,lat,lng,codepostal) => dispatch => {
    return apiClient().get(`/getPricesByElement/${element}/${lat}/${lng}/${codepostal}`)
        .then((res) => {
            return res.data
        })
}

export const savePrice = (prof_id,element_id, min_price, max_price, isEnabled) => dispatch => {
    const data = {
        prof_id:prof_id,
        element_id:element_id,
        min_price:min_price,
        max_price:max_price,
        isEnabled:isEnabled,
    }
    return apiClient().post(`/saveElementPrice`, data)
        .then((res) => {

        })
}


export const getAllMissions = (id, status, type ='professionnal', ticket ='') => dispatch => {
    return apiClient().post(`/getAllMissions/${id}?status=${status}&type=${type}&ticket=${ticket}`)
        .then((res) => {
             dispatch({type:ALL_MISSIONS,payload:res.data?.missions})
        })
}

export const getAllMissionsv2 = (id, status, type ='professionnal', ticket ='') => dispatch => {
    return apiClient().post(`/getAllMissionsv2/${id}?status=${status}&type=${type}&ticket=${ticket}`)
        .then((res) => {
             dispatch({type:ALL_MISSIONS,payload:res.data?.missions})
        })
}
export const getAllProfessionalMissions = (id, status) => dispatch => {
    return apiClient().post(`/getAllProfessionalMissions/${id}?status=${status}`)
        .then((res) => {
             dispatch({type:ALL_PROF_MISSIONS,payload:res.data?.missions})
        })
}
export const getAllProfessionalMissionsCalendar = (id, status, type = 'professionnal') => dispatch => {
    return apiClient().post(`/getAllProfessionalMissionsCalendar/${id}?status=${status}&type=${type}`)
        .then((res) => {
             dispatch({type:ALL_PROF_MISSIONS_CALENDAR,payload:res.data?.missions})
        })
}
export const getMessages = (id, sent_from, type='professionnal') => dispatch => {
    return apiClient().post(`/getMessages/${id}?sent_from=${sent_from}&type=${type}`)
        .then((res) => {
            if(sent_from == 2)
                dispatch({type:ALL_MESSAGES,payload:res.data?.messages})
            else
                dispatch({type:ALL_SENT_MESSAGES,payload:res.data?.messages})
        })
}

export const getMessagesClient = (id, sent_from, type='professionnal') => dispatch => {
    return apiClient().post(`/getMessages/${id}?sent_from=${sent_from}&type=${type}`)
        .then((res) => {
            if(sent_from == 1)
                dispatch({type:ALL_MESSAGES,payload:res.data?.messages})
            else
                dispatch({type:ALL_SENT_MESSAGES,payload:res.data?.messages})
        })
}

export const saveMessage = (user_id, interventionId, message, sentfrom) => dispatch => {
    const data = {
        user_id:user_id,
        interventionId:interventionId,
        message:message,
        sentfrom:sentfrom
    }
    return apiClient().post(`/saveMessage`, data)
        .then((res) => {
           return res.data
        })
}

export const getCountUnreadMsg = (id, type = 'professional') => dispatch => {
    return apiClient().post(`/getCountUnreadMsg/${id}?type=${type}`)
        .then((res) => {
            dispatch({type:COUNT_UNREAD_MSG,payload:res.data?.count_msg})
        })
}


export const setAsReaded = (id) => dispatch => {
    return apiClient().post(`/setAsReaded/${id}`)
        .then((res) => {
            return res.data
        })
}


export const saveDevis = (params) => dispatch => {
    const data = {params}
    return apiClient().post(`/saveDevis`, params)
        .then((res) => {
            return res.succes
        })
}


export const getDevisList = (id, searched) => dispatch => {
    return apiClient().get(`/getDevisList/${id}?searched=${searched}`)
        .then((res) => {
            return res.data
        })
}




export const getBesoinAideDomicile = () => dispatch => {
    return apiClient().get(`/getBesoinAideDomicile`)
        .then((res) => {
            return res.data
    })
}
export const getBesoinCours = () => dispatch => {
    return apiClient().get(`/getBesoinCours`)
        .then((res) => {
            return res.data
    })
}
export const getBesoinDemenagement = () => dispatch => {
    return apiClient().get(`/getBesoinDemenagement`)
        .then((res) => {
            return res.data
    })
}
export const getBesoinInformatique = () => dispatch => {
    return apiClient().get(`/getBesoinInformatique`)
        .then((res) => {
            return res.data
    })
}
export const getBesoinJardinage = () => dispatch => {
    return apiClient().get(`/getBesoinJardinage`)
        .then((res) => {
            return res.data
    })
}

export const saveAutreService = (user_id, params) => dispatch => {
    const data = {
        user_id:user_id,
        params:params,
    }
    return apiClient().post(`/saveAutreService`, data)
        .then((res) => {
            return res.succes
        })
}

export const saveBien = (params) => dispatch => {
    const data = params
    return apiClient().post(`/saveBien`, data)
        .then((res) => {
            return res.succes
        })
}
export const updateBien = (params) => dispatch => {
    const data = params
    return apiClient().post(`/updateBien`, data)
        .then((res) => {
            return res.succes
        })
}
export const getUserBien = (params) => dispatch => {
    return apiClient().post(`/getUserBien`, params)
        .then((res) => {
            dispatch({type:GET_USER_BIENS,payload:res.data.data})
            //dispatch({type:GET_USER_ALL_BIENS,payload:res.data.data1})
            dispatch({type:GET_USER_ALL_BIENS,payload:res.data.data2})
        })
}
export const getBiens = (params) => dispatch => {
    return apiClient().post(`/getBiens`, params)
        .then((res) => {
            dispatch({type:GET_LAST_BIEN,payload:res.data.data})
        })
}
export const getSearchedBiens = (params) => dispatch => {
    return apiClient().post(`/getSearchedBiens`, params)
        .then((res) => {
            return res.data
            // dispatch({type:GET_SEARCHED_BIEN,payload:res.data.data})
        })
}
export const getBienById = (id) => dispatch => {
    return apiClient().get(`/getBienById/${id}`)
        .then((res) => {
            return res.data
        })
}

export const saveReservationBien = (params) => dispatch => {
    const data = params
    return apiClient().post(`/saveReservationBien`, data)
        .then((res) => {
            return res.succes
        })
}


export const getAllBienReservation = (id, status, type ='professionnal', ticket ='') => dispatch => {
    return apiClient().post(`/getAllBienReservation/${id}?status=${status}&type=${type}&ticket=${ticket}`)
        .then((res) => {
             dispatch({type:GET_RESERVATION_BIEN,payload:res.data?.data})
        })
}



export const saveAstreintes = (params) => dispatch => {
    const data = params
    return apiClient().post(`/saveAstreintes`, data)
        .then((res) => {
            return res.data?.data
            // dispatch({type:GET_ASTREINTES,payload:res.data?.data})
        })
}
export const getAstreintes = (id) => dispatch => {
    return apiClient().get(`/getAstreintes/${id}`)
        .then((res) => {
            return res.data?.data
            //  dispatch({type:GET_ASTREINTES,payload:res.data?.data})
        })
}
export const getDossierNumber = (id) => dispatch => {
    return apiClient().get(`/getDossierNumber/${id}`)
        .then((res) => {
            return res.data?.data
            //  dispatch({type:GET_ASTREINTES,payload:res.data?.data})
        })
}
export const saveLocataire = (params) => dispatch => {
    const data = params
    return apiClient().post(`/saveLocataire`, data)
        .then((res) => {
            dispatch({type:GET_LIST_LOCATAIRES,payload:res.data?.data})
            return true
        })
}
export const saveRapport = (params) => dispatch => {
    const data = params
    return apiClient().post(`/saveRapport`, data)
        .then((res) => {
            dispatch({type:GET_LIST_RAPPORTS,payload:res.data?.data})
            return true
        })
}
export const getLocataire = (id) => dispatch => {
    return apiClient().get(`/getLocataire/${id}`)
        .then((res) => {
            dispatch({type:GET_LIST_LOCATAIRES,payload:res.data?.data})
        })
}
export const getRapports = (id) => dispatch => {
    return apiClient().get(`/getRapports/${id}`)
        .then((res) => {
            dispatch({type:GET_LIST_RAPPORTS,payload:res.data?.data})
        })
}