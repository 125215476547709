import React,{ useState,useEffect ,useRef} from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card,Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenu,DashbordHeader } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"
import { BASE_URL } from "../../../components/config/keys";
import { AUTHENTICATE_USER, LOGOUT_USER } from "../../../constants/actions";
import { getAllCategories } from "../../../actions/pages";
import { updateInfoUser, suspendCompte,updateInfoUserImage } from "../../../actions/user";
import { BASE_URL_PROD } from "../../../components/config/keys";
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';


 

const Page = () => {

   
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user)
  const { data } = useSelector(state => state.pages)
  const { t, i18n } = useTranslation();
  const currentlangStorage = localStorage.getItem("currentlang");
  const currentlang=i18n.language;
  const [langset, setLangset] = useState(false);
  const [profilcomplete, setProfilcomplete] = useState(50);
  const [categories, setCategories] = useState([]);
  const [categ, setCateg] = useState('');
  const [rib, setRib] = useState('');
  const [loading, setLoading] = useState(false);
  const [rayon, setRayon] =  useState(user.rayon);
  const [show, setShow] = useState(false);
  const [showSuspend, setShowSuspend] = useState(false);
  const [disponibilte, setDisponibilite] = useState(user.disponibilite);
  const [geolocalisation, setGeolocalisation] = useState(user.geolocation);
  const [qrcode, setQrcode] = useState(user.qrcode);
  const [notif_email, set_notif_email] = useState(user.notif_email);
  const [notif_sms, set_notif_sms] = useState(user.notif_sms);
  const [notif_proximity, set_notif_proximity] = useState(user.notif_proximity);
  const fileInput = useRef(null)

  
  const [metier, setSecteurActivite] = useState(user.type_logement);
  const [type_entreprise, setTypeEntreprise] = useState(user.type_entreprise);
  const [societe, setSocieteName] = useState(user.societe_name);
  const [nb_employes, setNbEmploye] = useState(user.nb_employes);
  const [adresse, setAdresse] = useState(user.adresse);
  const [codepostal, setZipCode] = useState(user.zipcode);
  const [ville, setVille] = useState(user.ville);
  const [profession, setProfession] = useState(user.profession);
  const [prenom, setPrenom] = useState(user.prenom);
  const [nom, setNom] = useState(user.nom);
  const [telephone, setTelephone] = useState(user.telephone);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState('');
  const handleCloseDetail = () => setShowDetail(false);
  const [showDetail, setShowDetail] = useState(false);
  const [abonnement_price, setAbonnPrice] = useState(0);
  const [type_abonnement, setTypeabonnement] = useState(user.type_abonnement);

  

  const handleClose = () => setShow(false);
  const handleCloseSuspend = () => setShowSuspend(false);
  const handleShow = () => {


    setShow(true);
  }
  const handleShow1 = () => {
    setShowSuspend(true);
  }


  const onChangeData = (e) => {
    if(e.target.name == 'profession')
      setProfession(e.target.value)
    else if(e.target.name == 'nom')
      setNom(e.target.value)
    else if(e.target.name == 'prenom')
      setPrenom(e.target.value)
    else if(e.target.name == 'email')
      setEmail(e.target.value)
    else if(e.target.name == 'password')
      setPassword(e.target.value)
    else if(e.target.name == 'telephone')
      setTelephone(e.target.value)
  }

const changeShowDetail = (type, price) => {
  setTypeabonnement(type)
  if(type > 0){
    setShowDetail(true)
    setAbonnPrice(price)
  }
}

    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const label = { inputProps: { 'aria-label': '' } };
    const changeDisponibilite = async (valeur) => {
      setDisponibilite(valeur)
    }
    const changeGeolocalisation = async (valeur) => {
      setGeolocalisation(valeur)
    }
    const changeQrcode = async (valeur) => {
      setQrcode(valeur)
    }
    const changeRayon = async (valeur) => {
      setRayon(valeur)
    }
    const changeNotifEmail = async (valeur) => {
      set_notif_email(valeur)
    }
    const changeNotifSms = async (valeur) => {
      set_notif_sms(valeur)
    }
    const changeNotifProximity = async (valeur) => {
      set_notif_proximity(valeur)
    }
     const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
    
        
    
    }

    const handleSuspendreCompte = () => {
      dispatch(suspendCompte(user.id)).then(() => {
        dispatch({type:LOGOUT_USER});
        setTimeout(() => {
          window.location = '/'
        }, 500);
      })
      // 
    }

    const handleSubmit1 = async (e) => {
      e.preventDefault();

      const formData = new FormData(e.target)
      const formDataObj = Object.fromEntries(formData.entries())
      let go=true;

 


      if(go){
         dispatch(updateInfoUser(formDataObj,user.id,'professionnal'));
      }
   }



 const handleClickfile = () => {
      fileInput.current.click()
  }

  const handleFileChange = event => {
       
      const formData =  new FormData();
      formData.append('image',event.target.files[0]);
      formData.append('profile','professionnal');
      formData.append('id',user.id);
      formData.append('type','image');
     
     dispatch(updateInfoUserImage(formData));
     
  }



    useEffect(() => {
      let percent= 50
      if(user?.logo != ''){
          percent=percent+10
      }

       if(user?.kbis != ''){
          percent=percent+10
      }

       if(user?.assurance != ''){
          percent=percent+10
      }

       if(user?.zipcode != ''){
          percent=percent+5
      }

        if(user?.latitude != ''){
          percent=percent+5
      }

        if(user?.adresse != ''){
          percent=percent+5
      }

      if(user?.rayon != ''){
          percent=percent+5
      }

      setProfilcomplete(percent)
    
      

    }, [user])

   
    const saveAbonnement=(payement_inten_id)=>{
          const params = {
            payement_inten_id:payement_inten_id,
            user_id:user.id,
            type_abonnement:type_abonnement,
            abonnement_price:abonnement_price,
          };
          
          apiClient().post(`/professional/saveabonnement`,params)
          .then((res) => {
            if(res.data.success == 1){
                 setShowDetail(false)
                 dispatch({ type: AUTHENTICATE_USER, payload:res.data?.user})
                 setTimeout(function(){
                    history.push('/assistants/abonnement')
                 }, 2000);

            }
            else{
              alert('Une erreur est survenue veuillez réessayer ultérieurement.')
            }
            
             
          })
      
    } 
 
    // Changing State when volume increases/decreases
    const rangeSelector = (event, newValue) => {
      setRayon(newValue);
      
    };
   
    const changeTelephone = (event, newValue) => {
      setTelephone(newValue);
      
    };
    const changeNom = (event, newValue) => {
      setNom(newValue);
      
    };
 

 

    return (
        <div className="myccount editprofil abonnement " >
            
                       <section className="gray-bg main-dashboard-sec dashboard myccount" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenu src='abonnement'/>
                                 
                            </div>


                         <div className='col-md-9 dashbordassitantcontent' >

                             <div className='tab' id=''>


                              <DashbordHeader src={'w'} />

                             <div className='row' style={{marginTop:'30px'}}>

                               <div className='col-md-12'>

                                    <form  onSubmit={handleSubmit1}  >
                                    <div className='col-md-1'></div>
                                    <div className='col-md-4'>

                                      
                                    <input type="hidden" value={notif_email} name="notif_email"/>
                                    <input type="hidden" value={notif_sms} name="notif_sms"/>
                                    <input type="hidden" value={notif_proximity} name="notif_proximity"/>

                                  <div className='imgprofil' onClick={() => handleClickfile()} style={{cursor:'pointer'}} >
                                   <input
                                             style={{display:'none'}}
                                               type="file"
                                               accept='image/*'

                                              onChange={(e) => handleFileChange(e)}
                                              ref={fileInput} 
                                          />
                                    
                                  {
                                      user.logo != '' && user.logo != null ?
                                      <img className="profilImg" src={BASE_URL_PROD+'/admin/public/img/professionnal/'+user.id+'/'+user.logo} />
                                      :
                                      <img src='/images/icones/iconeprofilproblocabonnementmenu.png' />
                                  }
                                    <strong style={{    display: 'block',marginTop:'5px'}} >{user.nom != null ? user.nom : ''}  {user.prenom != null ? user.prenom : ''}</strong>
                                    <p style={{marginBottom:'4px'}}>Plombier</p>

                                 </div>
                                 <div className='row'>
                                  <div className='col-md-2'></div>
                                  <div className='col-md-8'>
                                    <div className='flx889'>
                                     <div className='clrbl9'>Profil complété</div>
                                     <div className='clrbl9'>{profilcomplete}%</div>
                                    </div>
                                       <div className='progress'>
                                           <div className='progress-bar' style={{width:profilcomplete+'%'}}>
                                             <span className='sr-only'></span>
                                           </div>
                                       </div>
                                  </div>
                                  <div className='col-md-2'></div>

                                 </div>

                                <div className="profile-edit-container fl-wrap block_box" style={{marginTop:'10px',paddingBottom: '3px',marginBottom:0}}>
                                          <div className="custom-form">

                                            <div className='flx889 '>
                                               <div  className='item1'>
                                                <span className='clrbl9' >Disponibilité </span>
                                               </div>
                                              <div  className='item2 flx889bg'>
                                                <span className={disponibilte == 0 ? 'it1':'it2'} onClick={() => changeDisponibilite(0)}>Non </span>
                                                <span className={disponibilte == 1 ? 'it1':'it2'} onClick={() => changeDisponibilite(1)} >Oui </span>
                                                <input type="hidden" value={disponibilte} name="disponibilte"/>
                                                
                                               </div>
                                            </div>

                                            <div className='flx889 '>
                                               <div  className='item1'>
                                                <span className='clrbl9' >Géolocalisation </span>
                                               </div>
                                              <div  className='item2 flx889bg'>
                                                <span className={geolocalisation == 0 ? 'it1':'it2'} onClick={() => changeGeolocalisation(0)}>Non </span>
                                                <span className={geolocalisation == 1 ? 'it1':'it2'} onClick={() => changeGeolocalisation(1)} >Oui </span>
                                                <input type="hidden" value={geolocalisation} name="geolocalisation"/>
                                                
                                               </div>
                                            </div>

                                            <div className='flx889 '>
                                               <div  className='item1'>
                                                <span className='clrbl9' >QR code intervention activé </span>
                                               </div>
                                              <div  className='item2 flx889bg'>
                                                <span className={qrcode == 0 ? 'it1':'it2'} onClick={() => changeQrcode(0)}>Non </span>
                                                <span className={qrcode == 1 ? 'it1':'it2'} onClick={() => changeQrcode(1)} >Oui </span>
                                                <input type="hidden" value={qrcode} name="qrcode"/>
                                                
                                               </div>
                                            </div>

                                          <div className='flx889 '>
                                               <div  className='item1'>
                                                <span className='clrbl9' >Rayon autour de </span>
                                               </div>
                                              <div  className='item2 flx889bg'>
                                                
                                                <span className='it2' style={{padding: '3px 24px',fontWeight: 'bold'}} >{rayon}km </span>
                                                
                                               </div>
                                            </div>

                                          <div className='flx889 ' style={{marginBottom:'0px'}}>
                                                <Slider value={rayon} onChange={rangeSelector} valueLabelDisplay="auto" min={1} step={1} max={100} color={'primary'} />
                                                <input type="hidden" value={rayon} name="rayon"/>
                                            </div>

                                            <div className='flx889 ' style={{marginBottom:'10px'}}>
                                               <div  className='item1'>
                                                <span className='clrbl9' >0km </span>
                                               </div>
                                                <div  className='item1'>
                                                <span className='clrbl9' >+100km </span>
                                               </div>
                                            </div>
                                          </div>

                                       

                                         
                                  </div>
                                      <div className='profile-edit-container fl-wrap block_box ' style={{marginBottom:'0px'}}>
                                                   
                                                    <a onClick={()=>history.push("/assistants/modifier-profile/")}  href='#' className='btn float-btn color2-bg btn9876 w100 pprmmp0'>Mon compte entreprise</a>
                                            </div>
                                  </div>
                                  <div className='col-md-1'></div>
                                  <div className='col-md-1'></div>

                                  <div className='col-md-4'>

                                   <div className="custom-form">

                                    <div className='flx881 '>
                                                <label className='clrbl9'>Votre profession </label>
                                                <input placeholder="" name="profession" type="text" className="form-control"  onChange={e => onChangeData(e)} value={profession} required/>    
                                            </div>
                                            <div className='flx881 '>
                                                <label className='clrbl9'>Votre nom </label>
                                                <input placeholder="" name="nom" type="text" className="form-control"  onChange={e => onChangeData(e)} value={nom} disabled/>    
                                            </div>
                                            <div className='flx881 '>
                                                <label className='clrbl9'>Votre prénom </label>
                                                <input placeholder="" name="prenom" type="text" className="form-control"  onChange={e => onChangeData(e)} value={prenom} disabled/>    
                                            </div>
                                          <div className='flx881 '>
                                                <label className='clrbl9'>Votre n° de mobile </label>
                                                <input placeholder="" name="telephone" type="text" className="form-control" onChange={e => onChangeData(e)} value={telephone} required/>    
                                            </div>
                                          <div className='flx881 '>
                                                <label className='clrbl9'>Votre e-mail </label>
                                                <input placeholder="" name="email" type="text" className="form-control"  onChange={e => onChangeData(e)} value={email} disabled/>    
                                            </div>
                                          <div className='flx881 '>
                                                <label className='clrbl9'>Votre mot de passe </label>
                                                <input placeholder="" name="password" type="password"  onChange={e => onChangeData(e)} className="form-control" />    
                                            </div>
                                            <div className='flx881 ' style={{marginBottom:'0px'}}>
                                                   <button type="submit" className="btn float-btn color2-bg btn9876 w100"> Mettre à jour</button>
                                            </div>

                                 </div>

                                  </div>
                                  <div className='col-md-1'></div>
                                </form>

                               </div>
                          <div className='row'>

                                 
                       
                    <div className='col-md-6'>

                        <form  onSubmit={handleSubmit} >

                                <div className='rowtitel'>
                                  
                                  <img src='/images/icones/iconenotification.png' />
                                  <h3 className='htri1'>Notifications </h3>

                               </div>

                          <div className="profile-edit-container fl-wrap block_box">
                                    <div className="custom-form">

                                      <div className="row mrgbtm">
                                           <div className="col-sm-1"></div>
                                            <div className="col-sm-12">
                                             <div className='rowflex '>

                                                 <div className='item labelswt'>
                                                    <label > <span className='inlineblk'> Par e-mail </span> 
                                                    {
                                                      notif_email == 1 ?
                                                      <Switch {...label} defaultChecked size="small" onChange={() => changeNotifEmail(0)} color="secondary"  />
                                                      :
                                                      <Switch {...label} size="small" color="secondary" onChange={() => changeNotifEmail(1)}  />
                                                    }
                                                    </label>
                                                 </div>
                                               <div className='item labelswt'>
                                                    <label > <span className='inlineblk'> Par sms </span> 
                                                    {
                                                      notif_sms == 1 ?
                                                      <Switch {...label} defaultChecked size="small" onChange={() => changeNotifSms(0)} color="secondary"  />
                                                      :
                                                      <Switch {...label} size="small" color="secondary" onChange={() => changeNotifSms(1)}   />
                                                    }
                                                    </label>
                                                 </div>
                                                 <div className='item labelswt'>
                                                    <label > <span className='inlineblk'> Equipe proximiti  </span> 
                                                    {
                                                      notif_proximity == 1 ?
                                                      <Switch {...label} defaultChecked size="small" onChange={() => changeNotifProximity(0)} color="secondary"  />
                                                      :
                                                      <Switch {...label} size="small" color="secondary" onChange={() => changeNotifProximity(1)} />
                                                    }
                                                    </label>
                                                 </div>  

                                             </div>
                                              
      
                                            </div>
                                         </div>




                                      
                                    </div>
                                </div>



                                   </form>

                            </div>

                             <div className='col-md-6'>

                                   
                          <form   >

                                <div className='rowtitel'>
                                  
                                  <img src='/images/icones/iconeprofil-1.png' />
                                  <h3 className='htri1'>Mon compte </h3>

                               </div>

                          <div className="profile-edit-container fl-wrap block_box">
                                    <div className="custom-form">

                                      <div className="row mrgbtm">
                                       <div className="col-sm-1"></div>
                                            <div className="col-sm-12">
                                             <div className='rowflex row cstm09'>
                                                 <div className='col-md-6' >
                                                     <a href='javascript:void(0)' onClick={handleShow} style={{width: '100%',display:'block',fontSize: 13}}>Supprimer mon compte</a>
                                                 </div>
                                                 <div className='col-md-6'>
                                                     <a href='javascript:void(0)' onClick={handleShow1} style={{width: '100%',display:'block','fontSize': 13}} >Suspendre mon compte</a>
                                                 </div>
                                             </div>
                                              
      
                                            </div>
                                         </div>




                                      
                                    </div>
                                </div>



                                   </form>
                                  </div>

                               </div>
                  

                             </div>


                                 
                          </div>
 

                              

                         </div>
                    </div>
            </section>
     <Modal show={show}   
      size="lg"
 
     onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='clrbl90'>Supprimer mon compte</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <p className='clrbl10'>En supprimant votre compte vous supprimez également ses données (services, base clients, factures/devis, chiffre d'affaires) sans remboursement possible.<br/>
          <strong>Vous ne pourrez plus vous connecter à votre espace de travail.</strong></p>

          <div className='mdflx99'>

         
            <input type='checkbox'  />
               <label>
                  Je confirme que mes transactions sont bien finalisées.
            </label>

          </div>

          <div>
           <textarea className='form-control clrbl10' placeholder='Aidez-nous à nous améliorer, pourquoi souhaitez-vous supprimer votre compte ?'></textarea>
          </div>


        </Modal.Body>
        <Modal.Footer style={{justifyContent: 'flex-start'}}>
          <Button className='btnmodal btnmodalbrd' variant="secondary" onClick={handleClose} >
            Annuler
          </Button>
          <Button className='btnmodal' variant="primary" onClick={handleClose}>
            Supprimer mon compte
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSuspend}   
      size="lg"
 
     onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='clrbl90'>Suspendre mon compte</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>Voullez vous vraiment suspendre votre compte ?</strong>
        </Modal.Body>
        <Modal.Footer style={{justifyContent: 'flex-start'}}>
          <Button className='btnmodal btnmodalbrd' variant="secondary" onClick={handleCloseSuspend} >
            Annuler
          </Button>
          <Button className='btnmodal' variant="primary" onClick={handleSuspendreCompte}>
            Suspendre mon compte
          </Button>
        </Modal.Footer>
      </Modal>

 
        </div>
    );
}

export default Page;

 