import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button ,Modal,CloseButton } from "react-bootstrap"
import { AssistantItem } from "../../../../components";
import { AvisItem } from "../../../../components";
import { getListWatcher } from "../../../../actions/user";
import { useHistory } from "react-router";
import { BASE_URL_PROD } from "../../../../components/config/keys";
import { surpls,epr } from "../../../../assets/images";
import { useTranslation } from 'react-i18next';
 
import { getBesoinDemenagement } from "../../../../actions/pages";

const Page = () => {
    
    const autre_services_data = JSON.parse(localStorage.getItem('autre_services_data'));
    const dispatch = useDispatch();
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const [besoin, setBesoin] = useState(autre_services_data.besoin);
    const [desc_besoin, setDescBesoin] = useState(autre_services_data.desc_besoin);
    const [besoinText, setBesoinText] = useState(autre_services_data.besoinText);
    const [avecCamion, setAvecCamion] = useState(autre_services_data.avecCamion);
    const [qte, setQte] = useState(autre_services_data.qte);
    const [besoinPrice, setBesoinPrice] = useState(autre_services_data.besoinPrice);
    const [besoinList, setBesoinList] = useState([]);
    
    const [besoinError, setShowBesoinError] = useState(0);
    const [camionError, setShowCamionError] = useState(0);
    // const [autre_services_data, setItems] = useState([]);
    useEffect(() => {
      dispatch(getBesoinDemenagement()).then((res) => {
          setBesoinList(res.data);
      })
      if(autre_services_data.qte == '' || autre_services_data.qte == undefined)
        setQte(1)
    }, [])
  
    const handleGoBack = () => {
      history.push('/demenagement');
    };

    const handleGoTo = () => {
      setShowBesoinError(0)
      setShowCamionError(0)

      if(besoin == '' || besoin == undefined){
        setShowBesoinError(1)
      }else if(avecCamion == '' || avecCamion == undefined){
        setShowCamionError(1)
      }else{
        autre_services_data.besoin = besoin
        autre_services_data.desc_besoin = desc_besoin
        autre_services_data.besoinText = besoinText
        autre_services_data.avecCamion = avecCamion
        autre_services_data.besoinPrice = besoinPrice
        autre_services_data.qte = qte
        localStorage.setItem('autre_services_data', JSON.stringify(autre_services_data))
        history.push('/demenagement/date');
      }
    };
    
    const handleBesoin = element => {
      setShowBesoinError(0)
      var index = element.nativeEvent.target.selectedIndex;
      setBesoinPrice(element.nativeEvent.target[index].dataset.price);
      setBesoin(element.target.value);
      setBesoinText(element.nativeEvent.target[index].text);
    };
    const handleaAvecCamion = element => {
      setShowCamionError(0)
      setAvecCamion(element);
    };
    const handelePlus = () => {
      const qte_new = qte+1
      setQte(qte_new)
    };
    const handeleMoins = () => {
      if(qte > 1){
        const qte_new = qte-1
        setQte(qte_new)
      }
    };
    return (
      <div className="demenagement12" >
        <section className="gray-bg big-padding " id="besoin1">
          <div className="container small-container ">
            <div className='registerform'>
              <div className='row '>
                <div className='col-md-8'><p onClick={()=>handleGoBack()} style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>&lt;	&lt;	Retour</p></div>
                <div className='col-md-8'>
                  <div className="section-title"><h2><span>Quelle est votre besoin ? </span></h2>
                  </div>
                  <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                  <select required id='besoin' name='besoin' value={besoin}  onChange={handleBesoin} >
                      <option value=""></option>
                      {
                        besoinList.length > 0 ?
                        besoinList.map(item => (
                          <option value={item.id} data-price={item.price}>{item.besoin}</option>
                        ))
                        :
                        ''
                      }
                    </select>
                  </div>

                  {
                    besoinError == 1 ?
                    <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                      <div class="alert alert-danger" role="alert">
                        Veuillez choisir votre besoin !
                      </div>
                    </div>
                    :
                    ""
                  }
                  {
                    besoinText != '' ?
                    <div className='custom-form col-md-10' style={{ paddingLeft:"0", marginBottom:'20px' }}>
                      <p style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>Nombre de meubles à déplacer ?</p>
                      <span onClick={() => handeleMoins()} className="span span_cercle"><i style={{ marginTop:'6px', fontSize:'16px' }} className="fa fa-minus"/></span>
                      <span className="span s_qte" style={{ paddingTop:'4px' }}>{qte}</span>
                      <span onClick={() => handelePlus()} className="span span_cercle s_plus"><i style={{ marginTop:'6px', fontSize:'16px' }} className="fa fa-plus"/></span>
                    </div>
                    :
                    ''
                  }
                  {
                    besoinText != '' ?
                    <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                      <p style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>Intervention réaliser avec le camion du prestataire ?</p>
                      <div onClick={() => handleaAvecCamion(1)} className={avecCamion === 1 ? 'custom-form col-md-3 boxShadow blocNiveau blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocNiveau'}><p className="p1">Oui</p></div>
                      <div onClick={() => handleaAvecCamion(2)} className={avecCamion === 2 ? 'custom-form col-md-3 boxShadow blocNiveau blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocNiveau'}><p className="p1">Non</p></div>
                    </div>
                    :
                    ''
                  }

                {
                    camionError == 1 ?
                    <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                      <div class="alert alert-danger" role="alert">
                        Veuillez indiquer votre choix !
                      </div>
                    </div>
                    :
                    ""
                  }
                  <div className='col-md-9' style={{ paddingLeft:"0", paddingTop:'30px', clear:'both' }}>
                    <div style={{textAlign:'left',marginBottom:'30px'}}>
                      <a onClick={()=>handleGoTo()} class="btn btnform w200 "> Continuer </a>
                    </div>
                  </div>
                </div>    
                <div className='col-md-4'>
                    <div className="header1 boxShadow">Recapitulatif</div>
                    <div className="body1 boxShadow">
                      <p style={{ margin:'0px', paddingLeft:'10px' }}>1 intervention déménagement</p>
                      <hr style={{ margin:'10px' }}></hr>
                      {
                        besoinText != '' ?
                        <ul>
                          <li>{besoinText}</li>
                        </ul>
                        :
                        ''
                      }
                    </div>
                </div>  
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Page;