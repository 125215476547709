import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button ,Modal,CloseButton } from "react-bootstrap"
import { AssistantItem } from "../../../../components";
import { AvisItem } from "../../../../components";
import { getListWatcher } from "../../../../actions/user";
import { useHistory } from "react-router";
import { BASE_URL_PROD } from "../../../../components/config/keys";
import { surpls,epr } from "../../../../assets/images";
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import {getMetier } from "../../../../actions/pages";
 

const Page = () => {
    const autre_services_data = localStorage.getItem('autre_services_data') !== '' ? JSON.parse(localStorage.getItem('autre_services_data')) : {};
    const dispatch = useDispatch();
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const [showMore, setShowMore] = useState(0);
    const [adresse, setAdresse] = useState(autre_services_data.adresse);
    const [adresseError, setShowAdresseError] = useState(0);

    useEffect(() => {

    }, [])
  
    const handleGoTo = valeur => {
      setShowAdresseError(0)
      if(adresse == '' || adresse == undefined){
        setShowAdresseError(1)
      }else{
        autre_services_data.type = 7
        autre_services_data.adresse = adresse
        autre_services_data.type_title = "Intervention Garde Enfants"
        localStorage.setItem('autre_services_data', JSON.stringify(autre_services_data))
        history.push('/garde-enfants/pour-qui');
      }
    };
    
    const handleAdresse = element => {
      setShowAdresseError(0)
      setAdresse(element.target.value);
    };
    return (
      <div className="gardeenfants1" >
        <section className="gray-bg big-padding " id="besoin1">
          <div className="container small-container ">
            <div className='registerform'>
              <div className='row '>
                <div className='col-md-8'>
                  <div className="section-title"><h2><span>A quelle adresse aura lieu la garde ? </span></h2>
                  </div>
                  <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                    <input type='text' placeholder="Indiquer votre adresse complete" required id='adresse' name='adresse' value={adresse}  onChange={handleAdresse} />
                  </div>

                  {
                    adresseError == 1 ?
                    <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                      <div class="alert alert-danger" role="alert">
                        Veuillez saisir l'adresse !
                      </div>
                    </div>
                    :
                    ""
                  }
                  <div className='col-md-9' style={{ paddingLeft:"0" , clear:'both'}}>
                    <div style={{textAlign:'left',marginBottom:'30px'}}>
                      <a onClick={()=>handleGoTo()} class="btn btnform w200 "> Continuer </a>
                    </div>
                  </div>
                </div>    
                <div className='col-md-4'>
                    <div className="header1 boxShadow">Recapitulatif</div>
                    <div className="body1 boxShadow">
                      <p>1 intervention garde enfants</p>
                    </div>
                </div>  
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Page;