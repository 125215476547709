import React, { useState, useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button, Nav, Card, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap, BiShoppingBag, BiUserCircle, BiHistory } from 'react-icons/bi';
import { DashbordMenuClient, DashbordHeaderClient } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"

import { AUTHENTICATE_USER, LOGOUT_USER } from "../../../constants/actions";
import { getAllCategories } from "../../../actions/pages";
import { updateInfoUser, suspendCompte } from "../../../actions/user";
import { BASE_URL_PROD } from "../../../components/config/keys";
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';
import { saveAstreintes, getAstreintes, getRapports } from "../../../actions/pages";

const Page = () => {

const dispatch = useDispatch();
const { user } = useSelector(state => state.user)
const { data } = useSelector(state => state.pages)
const { list_rapports } = useSelector(state => state.pages)
const { t, i18n } = useTranslation();
const currentlangStorage = localStorage.getItem("currentlang");
const currentlang = i18n.language;
const [langset, setLangset] = useState(false);

const history = useHistory()
const [startDate, setStartDate] = useState(new Date());
const label = { inputProps: { 'aria-label': '' } };
const [show, setShow] = useState(false);
const [astreinte_info, setAstreinteInfo] = useState({});
const [nom_bailleur, setnom_bailleur] = useState();
const [forme_juridique, setforme_juridique] = useState();
const [adresse, setadresse] = useState();
const [cp, setcp] = useState();
const [ville, setville] = useState();
const [email, setemail] = useState();
const [phone, setphone] = useState();

const [nom_astreinte, setnom_astreinte] = useState();
const [adresse_astreinte, setadresse_astreinte] = useState();
const [cp_astreinte, setcp_astreinte] = useState();
const [ville_astreinte, setville_astreinte] = useState();
const [email_astreinte, setemail_astreinte] = useState();
const [phone_astreinte, setphone_astreinte] = useState();
const [secteur_astreinte, setsecteur_astreinte] = useState();

const [nom_technicien, setnom_technicien] = useState();
const [adresse_technicien, setadresse_technicien] = useState();
const [cp_technicien, setcp_technicien] = useState();
const [ville_technicien, setville_technicien] = useState();
const [email_technicien, setemail_technicien] = useState();
const [secteur_technicien, setsecteur_technicien] = useState();
const [phone_technicien, setphone_technicien] = useState();
const [rapport_info, setRapport] = useState({});
const handleClose = () => setShow(false);
const { list_astreintes } = useSelector(state => state.pages)
const showrapport = (id) => {
    setRapport(list_rapports[id])
    setShow(true)
}

useEffect(() => {
  dispatch(getRapports(user.id))
  dispatch(getAstreintes(user.id)).then((res) => {
    setAstreinteInfo(res);
    setnom_bailleur(res.nom_bailleur)
    setforme_juridique(res.forme_juridique)
    setadresse(res.adresse)
    setcp(res.cp)
    setville(res.ville)
    setemail(res.email)
    setphone(res.phone)

    setnom_astreinte(res.nom_astreinte)
    setadresse_astreinte(res.adresse_astreinte)
    setville_astreinte(res.cp_astreinte)
    setville_astreinte(res.ville_astreinte)
    setemail_astreinte(res.email_astreinte)
    setphone_astreinte(res.phone_astreinte)
    setsecteur_astreinte(res.secteur_astreinte)
    setcp_astreinte(res.cp_astreinte)

    setnom_technicien(res.nom_technicien)
    setadresse_technicien(res.adresse_technicien)
    setville_technicien(res.ville_technicien)
    setemail_technicien(res.email_technicien)
    setsecteur_technicien(res.secteur_technicien)
    setphone_technicien(res.phone_technicien)
    setsecteur_astreinte(res.secteur_astreinte)
    setcp_technicien(res.cp_technicien)
  });
}, [])

const handlePrint = () => {
  window.print();
};

const handelChange = (e) => {
  if(e.target.name == 'nom_bailleur')
    setnom_bailleur(e.target.value)
  else if(e.target.name == 'forme_juridique')
  setforme_juridique(e.target.value)
  else if(e.target.name == 'adresse')
    setadresse(e.target.value)
  else if(e.target.name == 'cp')
    setcp(e.target.value)
  else if(e.target.name == 'ville')
    setville(e.target.value)
  else if(e.target.name == 'email')
    setemail(e.target.value)
  else if(e.target.name == 'phone')
    setphone(e.target.value)

  else if(e.target.name == 'nom_astreinte')
    setnom_astreinte(e.target.value)
  else if(e.target.name == 'adresse_astreinte')
    setadresse_astreinte(e.target.value)
  else if(e.target.name == 'ville_astreinte')
    setville_astreinte(e.target.value)
  else if(e.target.name == 'ville_astreinte')
    setville_astreinte(e.target.value)
  else if(e.target.name == 'email_astreinte')
    setemail_astreinte(e.target.value)
  else if(e.target.name == 'phone_astreinte')
    setphone_astreinte(e.target.value)
  else if(e.target.name == 'secteur_astreinte')
    setcp_astreinte(e.target.value)
  else if(e.target.name == 'cp_astreinte')
    setcp_astreinte(e.target.value)


  else if(e.target.name == 'nom_technicien')
    setnom_technicien(e.target.value)
  else if(e.target.name == 'adresse_technicien')
    setadresse_technicien(e.target.value)
  else if(e.target.name == 'ville_technicien')
    setville_technicien(e.target.value)
  else if(e.target.name == 'email_technicien')
    setemail_technicien(e.target.value)
  else if(e.target.name == 'secteur_technicien')
    setsecteur_technicien(e.target.value)
  else if(e.target.name == 'phone_technicien')
    setphone_technicien(e.target.value)
  else if(e.target.name == 'secteur_astreinte')
    setsecteur_astreinte(e.target.value)
  else if(e.target.name == 'cp_technicien')
    setcp_technicien(e.target.value)
}

const handleSubmit = (e) => {
       
  e.preventDefault();
  const formData = new FormData(e.target)
  const formDataObj = Object.fromEntries(formData.entries())
  formDataObj.user_id = user.id
  console.log('formDataObj');
  console.log(formDataObj);

  dispatch(saveAstreintes(formDataObj)).then((res) => {
    setAstreinteInfo(res);
  });
  console.log(list_astreintes);
}

const returnitems = () => {
  return (
    <div className='col-md-3  position_relative'>
      <div className="process-item position_relative" style={{ cursor: 'pointer' }} onClick={() => history.push('/detail-bien/1869')}>
        <div className="process-img" style={{ backgroundImage: "url('/images/imo.png')", minHeight: '95px' }}>
        </div>
        <h4 className='basecolor title' ><strong style={{ color: '#0E0E0E' }}>Appartement </strong>3 pièces 65 m²</h4>
        <h6 className='' style={{ textAlign: "left", fontSize: "11px", color: "gray", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div><img src="/images/immo/icone-map.png" className="" style={{ width: "12px", height: '16px', marginTop: '0px' }} /> Paris 18ème</div>
          <div className="priceoffre" style={{ textAlign: "left", fontSize: "18px", padding: '4px', background: '#073460' }}><span style={{ fontSize: '12px', color: '#FFF' }}>85 0000 € </span></div>
        </h6>
      </div>
    </div>
  )
}

  return (
    <div className="myccount editprofil abonnement restreintes " >
      <section className="gray-bg main-dashboard-sec dashboard myccount" id="sec1">
        <div className="container">
          <div className="col-md-3">
            <DashbordMenuClient src='streintes' />
          </div>
          <div className='col-md-9 dashbordassitantcontent' >
            <div className='tab' id=''>
              <DashbordHeaderClient src={'w'} />
              <div className='row' style={{ marginTop: '30px' }}>
                <div className='col-md-12'>
                  <div className='flx998'>
                    <div class="rowtitel">
                      <img src="/images/streintes.png" style={{ width: '59px' }} />
                      <h3 class="htri1">Gestion des astreintes </h3>
                    </div>
                    <div>
                      <a className="btngreen" href="javascript:void(0)" onClick={() => history.push('/client/creer-rapport')} >Créer un rapport</a>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit} className='bailleurpermoral'>
                    <div className='flx889 '>
                      <div className='item2 flx889bg'>
                        <span className='it1' >Bailleur </span>
                      </div>
                    </div>
                    <div className='section298 '>
                      <div className='section298frm row' style={{ marginBottom: '0px' }}>
                        <div className='col-md-6'>
                          <h4>Bailleur personne morale</h4>
                        </div>
                        <div className='col-md-1'></div>
                      </div>
                      <div className='row'>
                        <div className="col-md-6">
                          <input placeholder="Nom du bailleur" onChange={handelChange}  name="nom_bailleur" value={nom_bailleur} type="text" class="form-control inptxt" required="" />
                        </div>
                        <div className="col-md-6">
                          <input placeholder="Forme juridique" onChange={handelChange} name="forme_juridique" value={forme_juridique} type="text" class="form-control inptxt" required="" />
                        </div>
                      </div>
                      <div className='row' style={{ marginTop: '20px' }}>
                        <div className="col-md-6">
                          <input  onChange={handelChange}  placeholder="Adresse" value={adresse} name="adresse" type="text" class="form-control inptxt" required="" />
                        </div>
                        <div className="col-md-2">
                          <input  onChange={handelChange}  placeholder="CP" name="cp" value={cp} type="text" class="form-control inptxt" required="" />
                        </div>
                        <div className="col-md-4">
                          <input  onChange={handelChange}  placeholder="Ville" name="ville" value={ville} type="text" class="form-control inptxt" required="" />
                        </div>
                      </div>
                      <div className='row ' style={{ marginTop: '20px' }}>
                        <div className="col-md-6">
                          <input  onChange={handelChange}  placeholder="Email" name="email" value={email} type="text" class="form-control inptxt" required="" />
                        </div>
                        <div className="col-md-6">
                          <input  onChange={handelChange}  placeholder="Téléphone" name="phone" value={phone} type="text" class="form-control inptxt" required="" />
                        </div>
                      </div>
                      <div className='row ' style={{ marginTop: '60px' }}>
                        <div className='col-md-6'>
                          <h4>Cadre d'astreinte</h4>
                        </div>
                      </div>
                      <div className='row' style={{ marginTop: '20px' }}>
                        <div className="col-md-6" style={{ marginBottom: '20px' }}>
                          <input  onChange={handelChange}  placeholder="Nom et prénom" value={nom_astreinte} name="nom_astreinte" type="text" class="form-control inptxt" required="" />
                        </div>
                        <div className="col-md-6"> </div>
                        <div className="col-md-6">
                          <input  onChange={handelChange}  placeholder="Adresse" value={adresse_astreinte} name="adresse_astreinte" type="text" class="form-control inptxt" required="" />
                        </div>
                        <div className="col-md-2">
                          <input  onChange={handelChange}  placeholder="CP" value={cp_astreinte} name="cp_astreinte" type="text" class="form-control inptxt" required="" />
                        </div>
                        <div className="col-md-4">
                          <input  onChange={handelChange}  placeholder="Ville" value={ville_astreinte} name="ville_astreinte" type="text" class="form-control inptxt" required="" />
                        </div>
                      </div>
                      <div className='row ' style={{ marginTop: '20px' }}>
                        <div className="col-md-6">
                          <input  onChange={handelChange}  placeholder="Email" value={email_astreinte} name="email_astreinte" type="text" class="form-control inptxt" required="" />
                        </div>
                        <div className="col-md-6">
                          <input  onChange={handelChange}  placeholder="Téléphone" value={phone_astreinte} name="phone_astreinte" type="text" class="form-control inptxt" required="" />
                        </div>
                        <div className="col-md-6" style={{ marginTop: '20px' }} >
                          <input  onChange={handelChange}  placeholder="Secteur couvert (adresse)" value={secteur_astreinte} name="secteur_astreinte" type="text" class="form-control inptxt" required="" />
                        </div>
                      </div>
                      <div className='row ' style={{ marginTop: '60px' }}>
                        <div className='col-md-6'>
                          <h4>Technicien d'astreinte</h4>
                        </div>
                      </div>
                      <div className='row' style={{ marginTop: '20px' }}>
                        <div className="col-md-6" style={{ marginBottom: '20px' }}>
                          <input  onChange={handelChange}  placeholder="Nom et prénom" value={nom_technicien} name="nom_technicien" type="text" class="form-control inptxt" required="" />
                        </div>
                        <div className="col-md-6"> </div>
                        <div className="col-md-6">
                          <input  onChange={handelChange}  placeholder="Adresse" value={adresse_technicien} name="adresse_technicien" type="text" class="form-control inptxt" required="" />
                        </div>
                        <div className="col-md-2">
                          <input  onChange={handelChange}  placeholder="CP" name="cp_technicien" value={cp_technicien} type="text" class="form-control inptxt" required="" />
                        </div>
                        <div className="col-md-4">
                          <input  onChange={handelChange}  placeholder="Ville" name="ville_technicien" value={ville_technicien} type="text" class="form-control inptxt" required="" />
                        </div>
                      </div>
                      <div className='row ' style={{ marginTop: '20px' }}>
                        <div className="col-md-6">
                          <input  onChange={handelChange}  placeholder="Email" name="email_technicien" value={email_technicien} type="text" class="form-control inptxt" required="" />
                        </div>
                        <div className="col-md-6">
                          <input  onChange={handelChange}  placeholder="Téléphone" name="phone_technicien" value={phone_technicien} type="text" class="form-control inptxt" required="" />
                        </div>
                        <div className="col-md-6" style={{ marginTop: '20px' }} >
                          <input  onChange={handelChange}  placeholder="Secteur couvert (adresse)" name="secteur_technicien" value={secteur_technicien} type="text" class="form-control inptxt" required="" />
                        </div>
                      </div>
                      <button type="submit" className="btn float-btn color2-bg btn9876 btcustom " style={{ borderRadius: 27, marginTop: '5px' }}>Enregistrer</button>
                    </div>
                  </form>
                  <form>
                    <div className='col-md-12'>
                      <h4 style={{ color: '#073460', fontSize: '17px' }}> Historique des astreintes</h4>
                    </div>
                    <div className="custom-form">
                      <table id="datatable_valides" className="rowtabcustomer table table-separate  table-head-custom table-checkable dataTable no-footer dtr-inline mb-0 animated ">
                        <thead>
                          <tr>
                            <th>N° dossier</th>
                            <th>Date début</th>
                            <th>Date fin</th>
                            <th>Etat</th>
                            <th>Intervention</th>
                            <th>Statut</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            list_rapports != undefined ?
                            list_rapports.map((elem,index) => (
                              <tr>
                                <td>{elem.code}</td>
                                <td>{elem.debut_astreinte}</td>
                                <td>{elem.fin_astreinte}</td>
                                <td>{elem.type_intervention}</td>
                                <td>{elem.status}</td>
                                <td><a href="javascript:void(0)" onClick={() => showrapport(index)} className='btnrounded'>Rapport</a></td>
                              </tr>
                            ))
                            :
                            ''
                          }
                        </tbody>
                      </table>
                    </div>
                    <br /><br />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose} size={'xl'}>
        <Modal.Header closeButton style={{ border: 0 }}>
        </Modal.Header>
        <Modal.Body>
          <div className='showrapportrestreinte'>
            <div className='row'>
              <div className='col-md-4'>
                <h2>Rapport d'astreinte</h2>
                <h3>N° dossier <span>{rapport_info.code}</span></h3>
              </div>
              <div className='col-md-6'>
                <div>
                  <a href='javascript:void(0)' onClick={handlePrint}><img src='/images/download.png' style={{ width: 50 }} /></a>
                  <a href='javascript:void(0)' onClick={handlePrint}><img src='/images/print.png' style={{ width: 50 }} /></a>
                </div>
              </div>
            </div>
            <br /> <br /> <br />
            <div className='row'>
              <div className='col-md-3'>
                <h4>Locataire concerné</h4>
                <label>{rapport_info.last_name} {rapport_info.first_name}</label>
                <label>{rapport_info.locataire_adresse}</label>
                <label>{rapport_info.cp_adresse} {rapport_info.locataire_ville}</label>
                <label>Etage : {rapport_info.etage}</label>
                <label>Porte : {rapport_info.porte}</label>
              </div>
              <div className='col-md-3'>
                <h4>Lieu de l'intervention</h4>
                <label>{rapport_info.adresse}</label>
                <label>{rapport_info.cp+' '+rapport_info.ville}</label>
              </div>
              <div className='col-md-6'>
                <div className='co009'>
                  <div className='row'>
                    <div className='col-md-3'>
                      <label style={{ paddingTop: '4px' }}>Début d'astreinte</label>
                    </div>
                    <div className='col-md-5 flxcol9'>
                      <label className='t9987'>{rapport_info.debut_astreinte}</label>
                      <label className='t9987'>{rapport_info.debut_astreinte_heure}</label>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-3'>
                      <label style={{ paddingTop: '4px' }}>Fin d'astreinte</label>
                    </div>
                    <div className='col-md-5 flxcol9'>
                      <label className='t9987'>{rapport_info.fin_astreinte}</label>
                      <label className='t9987'>{rapport_info.fin_astreinte_heure}</label>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-3'>
                      <label style={{ paddingTop: '4px' }}>Technicien</label>
                    </div>
                    <div className='col-md-6 '>
                      <label className='t9987' style={{ width: '100%' }} >{rapport_info.technicien}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 '> <div className='co009' style={{ padding: 9, marginTop: 30 }}><h4 style={{ margin: 0 }}>Détails de l'intervention</h4></div></div>
            </div>
            <br />   <br />
            <div className='row'>
              <div className='col-md-4'>
                <div className='flx9987'>
                  <label>Technicien</label>
                  <label><strong>{rapport_info.technicien}</strong></label>
                </div>
                <div className='flx9987'>
                  <label>Type d'intervention</label>
                  <label><strong>{rapport_info.type_intervention}</strong></label>
                </div>
                <div className='flx9987'>
                  <label>Statut</label>
                  <label><strong>{rapport_info.status}</strong></label>
                </div>
              </div>
            </div>
            <br /><br />
            <div className='row'>
              <div className='col-md-12'>
                <label>Commentaires</label>
                <p className='pa009'>
                {rapport_info.commentaire}
                </p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                {
                  rapport_info.demande_rappel == 1 ?
                  <div class="form-check">
                    <input name="" type="checkbox" checked class="form-check-input ch667" value="1" readOnly/>
                    <label title="" class="form-check-label">Demande de rappel du locataire</label>
                  </div>
                  :
                  ""
                }
                {
                  rapport_info.confirmation_locataire == 1 ?
                  <div class="form-check">
                    <input name="" type="checkbox" checked class="form-check-input ch667" value="1" readOnly/>
                    <label title="" class="form-check-label">Confirmation avec le locataire</label>
                  </div>
                  :
                  ""
                }
                {
                  rapport_info.locataire_en_attente == 1 ?
                  <div class="form-check">
                    <input name="" type="checkbox" checked class="form-check-input ch667" value="1" readOnly/>
                    <label title="" class="form-check-label">Locataire en attente</label>
                  </div>
                  :
                  ""
                }
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 '> <div className='co009' style={{ padding: 9, marginTop: 30 }}><h4 style={{ margin: 0 }}>Partager le rapport</h4></div></div>
            </div>
            <br />   <br />
            <div className='row'>
              <div className='col-md-12'>
                <div class="form-check">
                  <input name="" type="checkbox" class="form-check-input ch667" value="1" />
                  <label title="" class="form-check-label">Directeur technique</label>
                </div>
                <div class="form-check">
                  <input name="" type="checkbox" class="form-check-input ch667" value="1" />
                  <label title="" class="form-check-label">Responsable patrimoine</label>
                </div>
                <div class="form-check">
                  <input name="" type="checkbox" class="form-check-input ch667" value="1" />
                  <label title="" class="form-check-label">Agence</label>
                </div>
                <div class="form-check">
                  <input name="" type="checkbox" class="form-check-input ch667" value="1" />
                  <label title="" class="form-check-label">Locataire</label>
                </div>
              </div>
            </div>
            <button className="btn float-btn color2-bg btn9876 btcustom " style={{ borderRadius: 27, marginTop: '5px' }}>Envoyer</button>
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default Page;


