import React,{ useState,useEffect } from "react";
import './styles.scss';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { IoMdTime } from 'react-icons/io';
import { BiCategoryAlt } from 'react-icons/bi';
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../actions/api"
import { Container, Row, Col, Form, Button,Modal } from "react-bootstrap"
import { updateInfoUser } from "../../actions/user";
import { getCountUnreadMsg  } from "../../actions/pages";
const Component = ({ item }) => {

    const history = useHistory();
    const { user } = useSelector(state => state.user)
    const { count_unread_msg } = useSelector(state => state.pages)
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);

    useEffect(() => {
      //dispatch(getCountUnreadMsg(user.id, user.type))
      setInterval(() => dispatch(getCountUnreadMsg(user.id, user.type)), 50000);
      // const timer = setTimeout(
      //    () => dispatch(getCountUnreadMsg(user.id)),
      //    500
      // );
      // return () => clearTimeout(timer);
         

        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 


    }, [])
     

  const createImage = (file) => {
   
      const data = new FormData();
      data.append('membreId', user.id);
      data.append('file', file);

      if(user.type==1){
         data.append('type', 'pieces_membre');
      }
      else{
        data.append('type', 'pieces_watcher');
      }
      
      apiClient().post(`/insertimage`,data)
            .then((res) => {
               console.log(res)
                 
                const params = {
                photo: res.data.name,
                 
                }
                dispatch(updateInfoUser(params,user.id,user.type==1 ? 'user' : 'watcher' ))
               
            })

  }

    const changeProfilPicture = (e) => {
        

    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
    return;

      if(files[0].type=='image/png' || files[0].type=='image/jpeg' || files[0].type=='image/jpg' || files[0].type=='image/gif' ){
              if(files[0].size <= (2*1000000)){
                 createImage(files[0]);
              }
              else{

                 alert(t('Votre pièce-jointe ne doit pas dépasser cette taille: 2 MO.'));
              }
      }
      else{
         alert(t("Ce format n'est pas supporté ! Les formats acceptés sont (JPG,JPEG,PNG,GIF)"));
      }

        const param = {
                id:user.id,
              //files_img:this.state.files,
            }
    }
 const handleClick = (slug) => {
         history.push(slug)
  }
 
    return (

   
              <div className='row'>
                               <div className='col-md-8 ' > 
                                 <div className='serachdash'>
                                  <i className="far fa-search"></i>
                                 <input className="form-control" type="text" placeholder="Rechercher.." />
                                 </div>
                               </div>
                               <div className='col-md-4'> 


                                  <div className="dashboard-header_conatiner fl-wrap">
                                     
                                          <a href='#'><img src="/images/icones/iconenotifications.png" /><span className='spannotf'>0</span></a>
                                          <a href='#' onClick={()=>handleClick("/assistants/messages/")} ><img src="/images/icones/iconemessage.png" /><span>{count_unread_msg != undefined ? count_unread_msg : 0}</span></a>
                                          <a onClick={()=>handleClick("/assistants/profile/")}  href='#'><img src="/images/icones/iconeprofil.png" /></a>
                                  </div>


                               </div>

              </div>
          
     
    );
}

export default Component;