import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateInfoUser,getCommandeById,UpdateUserAddresse,UpdateUserAddresseInf} from "../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { commandeslist } = useSelector(state => state.restaurent)

    useEffect(() => {
        dispatch(getCommandeById(user.id));
    }, []);

    const history = useHistory();
    function gotoCmd(item) {
       history.push(`/commande/${item.id}`);
     }
    
    return (
        <div className="register" >
            <Container>
                <Row>
            <Col  className="text-center mx-auto my-5"  lg="4">

               <Card>
               <Card.Img variant="top" src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg" />

                  <Card.Body>
                    <Card.Title>{user.firstname} {user.lastname}</Card.Title>
                    <Card.Text>
                    {user.adresse}
                    </Card.Text>
                    <Nav defaultActiveKey="/home" className="flex-column nav-style">
                  <Nav.Link href="/profile"><BiUserCircle   size={20} /> Mes coordonnées</Nav.Link>
                  <Nav.Link href="/adresses" ><BiMap  size={20} /> Mes adresses</Nav.Link>
                  <Nav.Link eventKey="link-1" href="/commandes" ><BiShoppingBag  size={20} /> Mes commandes</Nav.Link>
                  <Nav.Link eventKey="link-1" href="/historique" className="active"><BiHistory   size={20} /> Historique</Nav.Link>
                </Nav>
                  </Card.Body>
                </Card>
               
                </Col>
              
                    <Col  className="text-center mx-auto my-5"  >
                        
                         
                            <h3 className="mb-4" ><BiHistory color={"#FF914D"} size={20} />Historiques</h3>
                     
                          <Row className="mb-3"  >
                           
                          <Table responsive>
                          <thead>
                            <tr>
                                <th>#</th>
                                <th key="0">Statuts</th>
                                <th key="1">Détail</th>
                             
                            </tr>
                          </thead>
              <tbody>
    {commandeslist.orders?.filter(person => person.state_id == 30).map((item) => (

    <tr>
      <td>Commande#{item.id}</td>
      <td>
        {item.state_id == 10    ? "En cours":""}
        {item.state_id == 20    ? "Validée":""}
        {item.state_id != 20 && item.state_id != 10    ? "Annulée":""}

      </td>
      <td>
<Button className="w-100" onClick={() => {gotoCmd(item)}} variant="primary" type="submit">
                                Détail
                            </Button>
      </td>
    </tr>
    ))}
  

  </tbody>
</Table>
                           

                          </Row>





   

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;