import i18n from "i18next";
import { initReactI18next } from "react-i18next";
 
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Choisir un assistant": "Get a watcher",
      "Accueil": "Home",
      "Comment ça marche": "How it works",
      "Devenir assistant": "Become a watcher",
      "Contact": "Contact",
      "Connexion": "Login",
      "Mon compte": "My account",
      "MYWATCHER": "MY WATCHER",
      "Vérifier en live n’importe quel lieu dans le monde": "Explore and Check any place in the world",
      "Que souhaitez vous Vérifier ?": "What do you want to see and check  in real time?",
      "Dans quelle ville souhaiteriez être assisté ?": "Wich town?",
      "Vérifier": "Check",
      "home_small_desc_for_watcher": "Want to help travellers to check places in your town ? ",
      "Me proposer comme assistant": "Create a watcher account",
      "Meilleurs assistants": "Featured Watchers",
      "Best_watchers_desc": "See some of our talented watchers, learn about their background",
      "avis": "reviews",
      "Voir le profil": "See profile",
      "Choisir ce profil": "Choose this profile",
      "Les bons plans du moment": "Good ideas",
      "good_plans_desc": "We have experperimented, and guess what ? we recommend !",
      "Expériences": "Experiences",
      "Expériences_desc": "Constant care and attention to the patients makes good record",
      "Coworking": "Coworking",
      "Coworking_desc": "Constant care and attention to the patients makes good record",
      "Hôtels": "Hotels",
      "hotels_desc": "Constant care and attention to the patients makes good record",
      "Restaurants & Bars": "Restaurants & Bars",
      "restaurants_bare_desc": "Constant care and attention to the patients makes good record",
      "Appartements & villas": "Apartments & villas",
      "Apartments_desc": "Constant care and attention to the patients makes good record",
      "Témoignages": "Testimonials",
      "Témoignages_desc": "Our clients talk about us",
      "Entreprise": "Company",
      "Aide": "Help",
      "Qui sommes nous": "About",
      "Qui sommes-nous?": "About Us",
      "Nous contacter": "Contact us",
      "Espace assistant": "Watcher account",
      "Conditions générales": "Terms and conditions",
      "Mentions légales": "Legal Notice",
      "FAQ": "FAQ",
      "Rejoindre la team": "Join the team",
       "mywatcher_desc_footer": "There is no telling what technology and humans can do. Its opportunities are infinite. At octobre31 group, our mission is to guide our customers, partners and employees towards bold choices by leveraging digital technology to build a positive future for all. We believe in technology, but also in our ability to use our collective intelligence to help the world move forward.",
      "Trouvez-nous sur :": "Find us on:",
      "Décrivez le service recherché": "Describe the service you are looking for",
      "service_recherche_desc": "Proin dapibus nisl ornare diam varius tempus. Aenean a quam luctus.",
      "Accompli la mission": "perform the mission",
      "accompli_mission_desc": "Proin dapibus nisl ornare diam varius tempus. Aenean a quam luctus.",
      "Recevez la liste des assistants": "Receive our watchers list",
      "recevez_list_desc": "Proin dapibus nisl ornare diam varius tempus. Aenean a quam luctus.",
      "Voir la vidéo": "See video",
      "Inscription": "Registration",
      "Email": "E-mail",
      "Mot de passe": "Password",
      "Se souvenir de moi": "Remember me",
      "Mot de passe oublié ?": "Forgot your password ?",
      "Inscription": "Registration",
      "Nom": "Last name",
      "Prénom": "First name",
      "Ville": "City",
      "Téléphone": "Phone",
      "En créant un compte, j’accepte les": "By creating an account, I accept the",
      "Je créée mon compte": "Create my account",
      "Pour une connexion ou un enregistrement plus rapide, utilisez votre Facebook.": "For faster login or registration, use your Facebook.",     
      "OU": "OR",
      "Mes demandes": "Requests",
      "Mon profil": "Profile",
      "Mes messages": "Messages",
      "Mes factures": "Invoices",
      "Laisser une réclamation": "Add claim",
      "Déconnexion": "Log Out",
      "Monsieur": "M",
      "Madame": "W",
      "Décris toi brièvement (ce que tu aimes, tes passions, etc.) max 150 mots pour tes futurs contacts": "Briefly describe yourself (what you like, your passions, etc.) max 150 words for your future missions",
      "Votre tarification minimum": "Your minimum pricing",
      "Vous pouvez assister": "You can verify",
      "Confirmer mot de passe": "Confirm password",
      "Si vous avez déjà un compte": "If you already have an account",
      "S'inscrir": "Register",
      "Veuillez consulter vos emails et cliquer sur le lien afin de valider votre inscription sur mywatcher.fr": "Please check your emails and click on the link to validate your registration on mywatcher.fr",
      "Nous avons envoyé un e-mail à votre adresse": "We have sent an email to your address",
      "Vous n'avez pas encore de compte assistant !": "You don't have a watcher account yet!",
      "Créer un compte": "Create an account",
      "Finalisez votre recherche ci-dessous": "Complete your search below.",
      "Adresse ou Nom du lieu": "Address or Name of the place",
      "Prix minimum": "Minimum price",
      "Date souhaitée": "Date",
      "Choisissez une durée approximative": "Choose an approximate time",
      "Durée":"Time",
      "Que souhaitez vous faire ? Décrivez ce que dois réaliser l’assistant...": "What do you want to see? Describe what the watcher must show you (remember hotels rooms are not always accessible).",
      "Envoyer ma demande": "Send my request",
      "Watchers sont inscrits dans cette ville": "Watchers are listed in this town",
      "Watcher est inscrit dans cette ville": "Watcher is listed in this town",
      "Appliquer":"Apply",
      "Bonne nouvelle":"Good news",
      "Félicitations":"Congratulation",
      "Nous avons envoyé votre demande aux assistants selectionés, nous vous informerons de leur disponibilité pour votre mission.":"We have sent your request to the selected watchers, we will inform you of their availability for your mission.",
      "Nos partenaires":"Our partners",
      "Nos_partenaires_desc":"Explore some of the best tips from around the city from our partners and friends.",
      "FOIRE AUX QUESTIONS":"FREQUENTLY ASKED QUESTIONS",
      "Encore besoin d'aide ?":"Still need help?",
      "Entrer en contact":"Get in touch",
      "Adresse":"Address",
      "Écrivez-nous":"Write to us",
      "Écrivez-nous-desc":"Ut euismod ultricies sollicitudin. Curabitur sed dapibus nulla. Nulla eget iaculis lectus. Mauris ac maximus neque. Nam in mauris quis libero sodales eleifend.",
      "Votre nom complet":"Your full name",
      "Votre Message":"Your message",
      "Envoyer":"Send",
      "Conditions générales de vente":"Terms of Sales",
      "Mentions légales":"Legal Notice",
      "RGPD":"GDPR",
      "Témoignage Loic":"Testimonial Loic",
      "Témoignage Loic desc":"I find it super interesting to be useful while doing something fun, plus no surprise on the price I get paid quickly after each mission",
      "Bienvenue":"Welcome",
      "Votre pièce-jointe ne doit pas dépasser cette taille: 2 MO.":"Your attachment should not exceed this size: 2 MO.",
      "Ce format n'est pas supporté ! Les formats acceptés sont (JPG,JPEG,PNG,GIF)":"This format is not supported! Accepted formats are (JPG,JPEG,PNG,GIF)",
      "Mes demandes en cours":"My current requests",
      "Mes demandes validées":"My validated requests",
      "Mes missions realisées":"My completed missions",
      "Ref":"Ref",
      "Date":"Date",
      "Action":"Action",
      "Modifier":"Edit",
      "Supprimer":"Delete",
      "Réponses":"Responses",
      "Assistant":"Watcher",     
      "Détail":"Detail",
      "Marquer comme realisée":"Mark as done",
      "Noter la mission":"Rate the mission",
      "Modifier mon profile":"Edit my profile",
      "Modifier Mission":"Edit Mission",
      "Catégorie":"Category",
      "Watchers disponibles":"Watchers available",
      "Watcher disponible":"Watcher available",
      "Paiement par carte bancaire":"Payment by credit card",
      "La somme ne vous sera débitée qu’après réalisation de la tâche, une facture vous sera alors envoyée dans votre boite.":"The sum will only be debited to you after completion of the task, an invoice will then be sent to your mailbox.",
      "Votre paiement a bien été pris en compte.":"Your payment has been processed successfully.",
      "Une erreur est survenue veuillez réessayer ultérieurement.":"An error occurred please try again later.",
      "Détail Mission":"Detail Mission",
      "Missions reçues":"Received Missions",
      "Missions en attente de paiement":"Missions awaiting payment",
      "Missions en cours":"Missions in progress",
      "Missions réalisées":"Missions completed",
      "Client":"Customer",
      "Localisation":"Location",
      "Voir détail":"See details",
      "Envoyer message":"Send Message",
      "Accepter la mission":"Accept the mission",
      "Contacter le client":"Contact the customer",
      "Je veux modifier le prix proposé !":"I want to modify the proposed price!",
      "Votre prix..": "Your price..",
      "Votre réponse a bien été enregistrée..":"Your offer has been saved..",
      "Veuillez enregistrer vos informations de paiement.":"Please register your payment details.",
      "Télécharger votre RIB..":"Upload your RIB",
      "Complétez l’insertion et la saisie de toutes vos données pour activer votre compte.":"Complete the insertion and entry of all your data to activate your account.",
      "Compléter mon profil":"Complete my profile",
      "Déposer une réclamation":"Submit a claim",
      "Veuillez utiliser le formulaire ci-dessous afin de décrire votre problème..":"Please use the form below to describe your problem.",
      "Objet":"Object",
      "Mes réclamations":"My claims",
      "Nous avons bien reçu votre demande, Notre conseiller va vous contacter rapidement..":"We have received your request, our advisor will contact you shortly.",
      "Traitée":"Processed",
      "En cours":"In progress",
      "Votre réclamation est bien été annulée":"Your claim has been canceled",
      "Votre message est bien été envoyé..":"Your message has been successfully sent..",
      "Aucun message trouvé":"No messages found",
      "Uploader un ou plusieurs fichiers pour faire valider votre mission":"Upload one or several files to validate your mission",
      "Uploader un fichier (photo, vidéo..)":"Upload a file (photo, video, etc.)",
      "Cette operation peut durer plusieurs minutes, merci de ne pas fermer cette fenêtre..":"This operation may take several minutes, please do not close this window.",
      "Ref facture":"Invoice Ref",
      "Ref mission":"Mission Ref",
      "Validé":"Approved",
      "Télécharger la facture":"Download invoice",
      "Votre commentaire":"Your comment",
      "Noter le watcher":"Rate the watcher",
      "Votre évaluation est bien été enregistrée..":"Your rating has been saved.",
      "Envoyer un e-mail de réinitialisation":"Send reset email",
      "Un e-mail vous a été envoyé..":"An email has been sent to you..",
      "Votre adresse email n'existe pas !":"Your email address does not exist!",
      "Token invalide ou expirée !":"Token invalid or expired!",
      "Félicitations, votre inscription sur mywatcher.fr est validée !":"Congratulations, your registration on mywatcher.fr is validated!",
      "Veuillez vous connecter à votre espace client !":"Please login to your account !",
      "Le prix minimum imposé est : ":"The imposed minimum price is:",
      "Ce format n'est pas supporté ! Le format accepté est (PNG,JPEG,JPEG,GIF,MOV,MP4)":"This format is not supported! Accepted formats are (PNG,JPEG,JPG,GIF,MOV,MP4)",
      "Votre pièce-jointe ne doit pas dépasser cette taille: 10 MO":"Your attachment should not exceed this size: 10 MO",
      "Prix accepté":"Price accepted",
      "Voir Détail":"See Details",
      "Votre message a bien été envoyé.":"Your message has been sent successfully.",
      "Connexion avec Facebook":"Login with Facebook",
      "Ils parlent de nous":"They talk about us",
      "Proposer une mission":"Propose a mission",
      "Retour":"Return",
      "Nous sommes en cours d’installation dans cette ville renseignez votre email nous vous préviendrons.":"We are in the process of setting up in this city, enter your email we will notify you.",
      "Créez ou accédez à votre compte pour valider votre mission":"Create or access your account to validate your mission"

    } 
  },
  fr: {
    translation: {
      "Choisir un assistant": "Choisir un watcher",
      "Accueil": "Accueil",
      "Comment ça marche": "Comment ça marche",
      "Devenir assistant": "Devenir Watcher",
      "Contact": "Contact",
      "Connexion": "Connexion",
      "Mon compte": "Mon compte",
      "MYWATCHER": "MYWATCHER",
      "Vérifier en live n’importe quel lieu dans le monde": "Vérifier en live n'importe quel lieu dans le monde",
      "Que souhaitez vous Vérifier ?": "Que souhaitez vous Vérifier ?",
      "Dans quelle ville souhaiteriez être assisté ?": "Dans quelle ville ?",
      "Vérifier": "Vérifier",
      "home_small_desc_for_watcher": "Tu souhaite aider des voyageurs à vérifier des lieux dans ta ville ?",
      "Me proposer comme assistant": "Me proposer comme watcher",
      "Meilleurs assistants": "Meilleurs watchers",
      "Best_watchers_desc": "Nos watchers à l’affiche",
      "avis": "avis",
      "Voir le profil": "Voir le profil",
      "Choisir ce profil": "Choisir ce profil",
      "Les bons plans du moment": "Les bons plans du moment",
      "good_plans_desc": "On a testé et on recommande",
      "Expériences": "Expériences",
      "Expériences_desc": "Constant care and attention to the patients makes good record",
      "Coworking": "Coworking",
      "Coworking_desc": "Constant care and attention to the patients makes good record",
      "Hôtels": "Hôtels",
      "hotels_desc": "Constant care and attention to the patients makes good record",
      "Restaurants & Bars": "Restaurants & Bars",
      "restaurants_bare_desc": "Constant care and attention to the patients makes good record",
      "Appartements & villas": "Appartements & villas",
      "Apartments_desc": "Constant care and attention to the patients makes good record",
      "Témoignages": "Témoignages",
      "Témoignages_desc": "Nos clients parlent de nous",
      "Entreprise": "Entreprise",
      "Aide": "Aide",
      "Qui sommes nous": "Qui sommes nous",
      "Qui sommes-nous?": "Qui sommes-nous ?",
      "Nous contacter": "Nous contacter",
      "Espace assistant": "Espace Watcher",
      "Conditions générales": "Conditions générales",
      "Mentions légales": "Mentions légales",
      "FAQ": "FAQ",
      "Rejoindre la team": "Rejoindre la team",
      "mywatcher_desc_footer":"Chez octobre31 groupe, notre mission est d'accompagner nos clients, partenaires et collaborateurs vers des choix audacieux en s'appuyant sur le numérique pour construire un avenir positif pour tous. Nous croyons en la technologie, mais aussi en notre capacité à utiliser notre intelligence collective pour faire avancer le monde.",
      "Trouvez-nous sur :": "Trouvez-nous sur :",
      "Décrivez le service recherché": "Décrivez ce que doit vous montrer le watcher extérieur, parties communes.,etc..N’oubliez pas que certains hôtels ne donnent pas accès aux chambres",
      "service_recherche_desc": "Proin dapibus nisl ornare diam varius tempus. Aenean a quam luctus.",
      "Accompli la mission": "Accompli la mission",
      "accompli_mission_desc": "Proin dapibus nisl ornare diam varius tempus. Aenean a quam luctus.",
      "Recevez la liste des assistants": "Recevez la liste des watchers",
      "recevez_list_desc": "Proin dapibus nisl ornare diam varius tempus. Aenean a quam luctus.",
      "Voir la vidéo": "Voir la vidéo",
      "Mes demandes": "Mes demandes",
      "Mon profil": "Mon profil",
      "Mes messages": "Mes messages",
      "Mes factures": "Mes factures",
      "Laisser une réclamation": "Laisser une réclamation",
      "Déconnexion": "Déconnexion",
      "Monsieur": "Monsieur",
      "Madame": "Madame",
      "Décris toi brièvement (ce que tu aimes, tes passions, etc.) max 150 mots pour tes futurs contacts": "Décris toi brièvement (ce que tu aimes, tes passions, etc.) max 150 mots pour tes futures missions",
      "Votre tarification minimum": "Votre tarification minimum",
      "Vous pouvez assister": "Vous pouvez assister",
      "Confirmer mot de passe": "Confirmer mot de passe",
      "Si vous avez déjà un compte": "Si vous avez déjà un compte",
      "S'inscrir": "S'inscrir",
      "Veuillez consulter vos emails et cliquer sur le lien afin de valider votre inscription sur mywatcher.fr": "Veuillez consulter vos emails et cliquer sur le lien afin de valider votre inscription sur mywatcher.fr",
      "Nous avons envoyé un e-mail à votre adresse": "Nous avons envoyé un e-mail à votre adresse",
      "Vous n'avez pas encore de compte assistant !": "Vous n'avez pas encore de compte assistant !",
      "Créer un compte": "Créer un compte",
      "Finalisez votre recherche ci-dessous": "Finalisez votre recherche ci-dessous",
      "Adresse ou Nom du lieu": "Adresse ou Nom du lieu",
      "Prix minimum": "Prix minimum",
      "Date souhaitée": "Date souhaitée",
      "Choisissez une durée approximative": "Choisissez une durée approximative",
      "Durée":"Durée",
      "Que souhaitez vous faire ? Décrivez ce que dois réaliser l’assistant...": "Que souhaitez vous vérifier et voir ? Décrivez ce que dois vous montrer le watcher..extérieur, parties communes,etc..rappelez-vous certains hôtels ne donnent pas accès aux chambres.",
      "Envoyer ma demande": "Envoyer ma demande",
      "Watchers sont inscrits dans cette ville": "Watchers sont inscrits dans cette ville",
      "Watcher est inscrit dans cette ville": "Watcher est inscrit dans cette ville",
      "Appliquer":"Appliquer",
      "Bonne nouvelle":"Bonne nouvelle",
      "Félicitations":"Félicitations",
      "Nous avons envoyé votre demande aux assistants selectionés, nous vous informerons de leur disponibilité pour votre mission.":"Nous avons envoyé votre demande aux watchers selectionés, nous vous informerons de leur disponibilité pour votre mission.",
      "Nos partenaires":"Nos partenaires",
      "Nos_partenaires_desc":"Découvrez quelques-uns des bons plans de nos partenaires.",
      "FOIRE AUX QUESTIONS":"FOIRE AUX QUESTIONS",
      "Encore besoin d'aide ?":" besoin d'aide complémentaire ?",
      "Entrer en contact":"Entrer en contact",
      "Adresse":"Adresse",
      "Écrivez-nous":"Écrivez-nous",
      "Écrivez-nous-desc":"Ut euismod ultricies sollicitudin. Curabitur sed dapibus nulla. Nulla eget iaculis lectus. Mauris ac maximus neque. Nam in mauris quis libero sodales eleifend.",
      "Votre nom complet":"Votre nom complet",
      "Votre Message":"Votre Message",
      "Envoyer":"Envoyer",
      "Mentions légales":"Mentions légales",
      "RGPD":"RGPD",
      "Témoignage Loic":"Témoignage Loic",
      "Témoignage Loic desc":"Je trouve hyper intéressant d’être utile tout en faisant quelque chose de fun, en plus pas de surprise sur le prix je suis payé rapidement après chaque mission",
      "Bienvenue":"Bienvenue",
      "Votre pièce-jointe ne doit pas dépasser cette taille: 2 MO.":"Votre pièce-jointe ne doit pas dépasser cette taille: 2 MO.",
      "Ce format n'est pas supporté ! Les formats acceptés sont (JPG,JPEG,PNG,GIF)":"Ce format n'est pas supporté ! Les formats acceptés sont (JPG,JPEG,PNG,GIF)",
      "Mes demandes en cours":"Mes demandes en cours",
      "Mes demandes validées":"Mes demandes validées",
      "Mes missions realisées":"Mes missions realisées",
      "Ref":"Ref",
      "Date":"Date",
      "Action":"Action",
      "Modifier":"Modifier",
      "Supprimer":"Supprimer",
      "Réponses":"Réponses",
      "Assistant":"Watcher",     
      "Détail":"Détail",
      "Marquer comme realisée":"Marquer comme realisée",
      "Noter la mission":"Noter la mission",
      "Modifier mon profile":"Modifier mon profile",
      "Modifier Mission":"Modifier Mission",
      "Catégorie":"Catégorie",
      "Watchers disponibles":"Watchers disponibles",
      "Watcher disponible":"Watcher disponible",
      "Paiement par carte bancaire":"Paiement par carte bancaire",
      "La somme ne vous sera débitée qu’après réalisation de la tâche, une facture vous sera alors envoyée dans votre boite.":"La somme ne vous sera débitée qu’après réalisation de la mission, une facture vous sera alors envoyée dans votre boite.",
      "Votre paiement a bien été pris en compte.":"Votre paiement a bien été pris en compte.",
      "Une erreur est survenue veuillez réessayer ultérieurement.":"Une erreur est survenue veuillez réessayer ultérieurement.",
      "Détail Mission":"Détail Mission",
      "Missions reçues":"Missions reçues",
      "Missions en attente de paiement":"Missions en attente de paiement",
      "Missions en cours":"Missions en cours",
      "Missions réalisées":"Missions réalisées",
      "Client":"Client",
      "Localisation":"Localisation",
      "Voir détail":"Voir détail",
      "Envoyer message":"Envoyer message",
      "Accepter la mission":"Accepter la mission",
      "Contacter le client":"Contacter le client",
      "Je veux modifier le prix proposé !":"Je veux modifier le prix proposé !",
      "Votre prix..": "Votre prix..",
      "Votre réponse a bien été enregistrée..":"Votre réponse a bien été enregistrée..",
      "Veuillez enregistrer vos informations de paiement.":"Veuillez enregistrer vos informations de paiement.",
      "Télécharger votre RIB..":"Télécharger votre RIB..",
      "Complétez l’insertion et la saisie de toutes vos données pour activer votre compte.":"Complétez l’insertion et la saisie de toutes vos données pour activer votre compte.",
      "Compléter mon profil":"Compléter mon profil",
      "Déposer une réclamation":"Déposer une réclamation",
      "Veuillez utiliser le formulaire ci-dessous afin de décrire votre problème..":"Veuillez utiliser le formulaire ci-dessous afin de décrire votre problème..",
      "Objet":"Objet",
      "Mes réclamations":"Mes réclamations",
      "Nous avons bien reçu votre demande, Notre conseiller va vous contacter rapidement..":"Nous avons bien reçu votre demande, Notre conseiller va vous contacter rapidement..",
      "Traitée":"Traitée",
      "En cours":"En cours",
      "Votre réclamation est bien été annulée":"Votre réclamation est bien été annulée",
      "Votre message est bien été envoyé..":"Votre message est bien été envoyé..",
      "Aucun message trouvé":"Aucun message trouvé",
      "Uploader un ou plusieurs fichiers pour faire valider votre mission":"Uploader un ou plusieurs fichiers pour faire valider votre mission",
      "Uploader un fichier (photo, vidéo..)":"Uploader un fichier (photo, vidéo..)",
      "Cette operation peut durer plusieurs minutes, merci de ne pas fermer cette fenêtre..":"Cette operation peut durer plusieurs minutes, merci de ne pas fermer cette fenêtre..",
      "Ref facture":"Ref facture",
      "Ref mission":"Ref mission",
      "Validé":"Validé",
      "Télécharger la facture":"Télécharger la facture",
      "Votre commentaire":"Votre commentaire",
      "Noter le watcher":"Noter le watcher",
      "Votre évaluation est bien été enregistrée..":"Votre évaluation est bien été enregistrée..",
      "Envoyer un e-mail de réinitialisation":"Envoyer un e-mail de réinitialisation",
      "Un e-mail vous a été envoyé..":"Un e-mail vous a été envoyé..",
      "Votre adresse email n'existe pas !":"Votre adresse email n'existe pas !",
      "Token invalide ou expirée !":"Token invalide ou expirée !",
      "Félicitations, votre inscription sur mywatcher.fr est validée !":"Félicitations, votre inscription sur mywatcher.fr est validée !",
      "Veuillez vous connecter à votre espace client !":"Veuillez vous connecter à votre espace client !",
      "Le prix minimum imposé est : ":"Le prix minimum imposé est : ",
      "Ce format n'est pas supporté ! Le format accepté est (PNG,JPEG,JPEG,GIF,MOV,MP4)":"Ce format n'est pas supporté ! Le format accepté est (PNG,JPEG,JPG,GIF,MOV,MP4)",
      "Votre pièce-jointe ne doit pas dépasser cette taille: 10 MO":"Votre pièce-jointe ne doit pas dépasser cette taille: 10 MO",
      "Prix accepté":"Prix accepté",
      "Voir Détail":"Voir Détail",
      "Votre message a bien été envoyé.":"Votre message a bien été envoyé.",
      "Connexion avec Facebook":"Connexion avec Facebook",
      "Ils parlent de nous":"Ils parlent de nous",
      "Proposer une mission":"Proposer une mission",
      "Retour":"Retour",
      "Nous sommes en cours d’installation dans cette ville renseignez votre email nous vous préviendrons.":"Nous sommes en cours d’installation dans cette ville renseignez votre email nous vous préviendrons.",
      "Créez ou accédez à votre compte pour valider votre mission":"Créez ou accédez à votre compte pour valider votre mission"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;