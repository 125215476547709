import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button ,Modal,CloseButton } from "react-bootstrap"
import { AssistantItem } from "../../../../components";
import { AvisItem } from "../../../../components";
import { getListWatcher } from "../../../../actions/user";
import { useHistory } from "react-router";
import { BASE_URL_PROD } from "../../../../components/config/keys";
import { surpls,epr } from "../../../../assets/images";
import { useTranslation } from 'react-i18next';
 
import { saveAutreService } from "../../../../actions/pages";

const Page = () => {
    const autre_services_data = JSON.parse(localStorage.getItem('autre_services_data'));
    const dispatch = useDispatch();
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const [frequence, setFrequence] = useState(autre_services_data.frequence);
    const [besoinText, setBesoinText] = useState(autre_services_data.besoinText);
    const [date, setDate] = useState();
    const [dateName, setDateName] = useState('');
    const [heure, setHeure] = useState('');
    const [errorHeure, setErrorHeure] = useState(0);
    const { user } = useSelector(state => state.user)

    useEffect(() => {
      const dateObj = new Date(autre_services_data.date);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const formattedDate = dateObj.toLocaleDateString('fr-FR', options);
      setDateName(formattedDate)
    }, [])
  
    const handleGoBack = () => {
      history.push('/informatique/frequence');
    };

    const handleGoTo = () => {
      setErrorHeure(0)
      if(heure == '' || heure == undefined){
        setErrorHeure(1)
      }else{
        autre_services_data.heure = heure
        localStorage.setItem('autre_services_data', JSON.stringify(autre_services_data))
  
        if(user.id != undefined && user.id > 0 && user.type == 2){
          history.push("/autre-service-paiement")
          // dispatch(saveAutreService(user.id, autre_services_data)).then((res) => {
          //   history.push("/autre-service-succes")
          // })
        }else{
          history.push('/connexion');
        }
      }
    };
    
    const handleHeure = element => {
      setErrorHeure(0)
      setHeure(element);
    };
    return (
      <div className="informatique4" >
        <section className="gray-bg big-padding " id="besoin1">
          <div className="container small-container ">
            <div className='registerform'>
              <div className='row '>
                <div className='col-md-8'><p onClick={()=>handleGoBack()} style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>&lt;	&lt;	Retour</p></div>
                <div className='col-md-8'>
                  <div className="section-title">
                    <h2><span>Quelle heure vous convient le mieux  ? </span></h2>
                  </div>
                  <div className="row boxShadow" style={{ background:"#fff", borderRadius:'5px', paddingTop:'10px' }}>
                      <div className='col-md-2'><div onClick={() => handleHeure('7:00')} className={heure == '7:00' ? 'herueSelected heure' : 'heure'}>7:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('7:30')} className={heure == '7:30' ? 'herueSelected heure' : 'heure'}>7:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('8:00')} className={heure == '8:00' ? 'herueSelected heure' : 'heure'}>8:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('8:30')} className={heure == '8:30' ? 'herueSelected heure' : 'heure'}>8:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('9:00')} className={heure == '9:00' ? 'herueSelected heure' : 'heure'}>9:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('9:30')} className={heure == '9:30' ? 'herueSelected heure' : 'heure'}>9:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('10:00')} className={heure == '10:00' ? 'herueSelected heure' : 'heure'}>10:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('10:30')} className={heure == '10:30' ? 'herueSelected heure' : 'heure'}>10:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('11:00')} className={heure == '11:00' ? 'herueSelected heure' : 'heure'}>11:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('11:30')} className={heure == '11:30' ? 'herueSelected heure' : 'heure'}>11:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('12:00')} className={heure == '12:00' ? 'herueSelected heure' : 'heure'}>12:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('12:30')} className={heure == '12:30' ? 'herueSelected heure' : 'heure'}>12:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('13:00')} className={heure == '13:00' ? 'herueSelected heure' : 'heure'}>13:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('13:30')} className={heure == '13:30' ? 'herueSelected heure' : 'heure'}>13:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('14:00')} className={heure == '14:00' ? 'herueSelected heure' : 'heure'}>14:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('14:30')} className={heure == '14:30' ? 'herueSelected heure' : 'heure'}>14:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('15:00')} className={heure == '15:00' ? 'herueSelected heure' : 'heure'}>15:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('15:30')} className={heure == '15:30' ? 'herueSelected heure' : 'heure'}>15:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('16:00')} className={heure == '16:00' ? 'herueSelected heure' : 'heure'}>16:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('16:30')} className={heure == '16:30' ? 'herueSelected heure' : 'heure'}>16:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('17:00')} className={heure == '17:00' ? 'herueSelected heure' : 'heure'}>17:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('17:30')} className={heure == '17:30' ? 'herueSelected heure' : 'heure'}>17:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('18:00')} className={heure == '18:00' ? 'herueSelected heure' : 'heure'}>18:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('18:30')} className={heure == '18:30' ? 'herueSelected heure' : 'heure'}>18:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('19:00')} className={heure == '19:00' ? 'herueSelected heure' : 'heure'}>19:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('19:30')} className={heure == '19:30' ? 'herueSelected heure' : 'heure'}>19:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('20:00')} className={heure == '20:00' ? 'herueSelected heure' : 'heure'}>20:00</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('20:30')} className={heure == '20:30' ? 'herueSelected heure' : 'heure'}>20:30</div></div>
                      <div className='col-md-2'><div onClick={() => handleHeure('21:00')} className={heure == '21:00' ? 'herueSelected heure' : 'heure'}>21:00</div></div>
                  </div>
                  {
                      errorHeure == 1 ?
                      <div className='col-md-9' style={{ paddingLeft:"0", marginTop:'30px', clear:'both' }}>
                        <div class="alert alert-danger" role="alert">
                          Veuillez choisir une heure !
                        </div>
                      </div>
                      :
                      ""
                  }
                  <div className='col-md-9' style={{ paddingLeft:"0", marginTop:'30px', clear:'both' }}>
                    <div style={{textAlign:'left',marginBottom:'30px'}}>
                      <a onClick={()=>handleGoTo()} class="btn btnform w200 "> Continuer </a>
                    </div>
                  </div>
                </div>    
                <div className='col-md-4'>
                    <div className="header1 boxShadow">Recapitulatif</div>
                    <div className="body1 boxShadow">
                      <p style={{ margin:'0px', paddingLeft:'10px', fontWeight:'bold' }}>1 intervention informatique</p>
                      <hr style={{ margin:'10px' }}></hr>
                      <p style={{ margin:'0px', paddingLeft:'10px' }}><img style={{ width:'16px' }} src="/images/services/icone_detail.png"/> Détails de l'intervention</p>
                      {
                        besoinText != '' ?
                        <ul>
                          <li>{besoinText}</li>
                        </ul>
                        :
                        ''
                      }
                      <hr style={{ margin:'10px' }}></hr>
                      <p style={{ margin:'0px', paddingLeft:'10px' }}><img style={{ width:'16px' }} src="/images/services/icone_frequence.png"/> Rendez-vous</p>
                      <ul>
                          {
                            dateName != '' ?
                            <li>{dateName}</li>
                            :
                            ''
                          }
                          {
                            heure != '' ?
                            <li>{heure}</li>
                            :
                            ''
                          }
                      </ul>
                      <hr style={{ margin:'10px' }}></hr>
                      <p style={{ margin:'0px', paddingLeft:'10px', marginBottom:'20px', fontWeight:'bold' }}>Estimation de l'intervention</p>
                      <p style={{ margin:'0px', paddingLeft:'10px' }}><span>1 service(1 heure)</span><span style={{ float:'right' }}>{autre_services_data.besoinPrice} €</span></p>
                                        
                    </div>
                      <div className="footer1 boxShadow">
                        <p style={{ margin:'0px', fontWeight:'bold', width:'49%', textAlign:'left', float:'left' }}>TOTAL</p>
                        <p style={{ margin:'0px', fontWeight:'bold', width:'49%', textAlign:'right', float:'right' }}>{autre_services_data.besoinPrice} €</p>
                      </div>
                </div>  
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Page;