import React, { useState, useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button, Nav, Card, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap, BiShoppingBag, BiUserCircle, BiHistory } from 'react-icons/bi';
import { DashbordMenuClient, DashbordHeaderClient } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"

import { AUTHENTICATE_USER, LOGOUT_USER } from "../../../constants/actions";
import { updateBien } from "../../../actions/pages";
import { BASE_URL, BASE_URL_PROD } from "../../../components/config/keys";
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';
import GoogleMapReact from 'google-map-react';
import axios from "axios";

import { geocodeByAddress,getLatLng }  from 'react-google-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useParams } from "react-router";

import { getBienById } from "../../../actions/pages";

const Page = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { user } = useSelector(state => state.user)
  const { data } = useSelector(state => state.pages)
  const { t, i18n } = useTranslation();
  const currentlangStorage = localStorage.getItem("currentlang");
  const currentlang = i18n.language;
  const [langset, setLangset] = useState(false);
  const history = useHistory()
  const [startDate, setStartDate] = useState(new Date());
  
  const [nb_chambres, setNbChambre] = useState(0);
  const [nb_salle_bain, setNbBain] = useState(0);
  const [bien_detail, setDetail] = useState({});
  const [nb_wc, setNbWc] = useState(0);
  const [aproximite, setAproximite] = useState([]);
  const [animaux, setAnimaux] = useState(0);
  const [fumeur, setFumeur] = useState(0);
  const [image1, setImageName1] = useState("");
  const [image2, setImageName2] = useState("");
  const [image3, setImageName3] = useState("");
  const [image4, setImageName4] = useState("");
  const [image5, setImageName5] = useState("");
  const [image6, setImageName6] = useState("");
  const [equipements, setEquipement] = useState([]);
                const [exterieurs, setExterieurs] = useState([]);
  
  const [address,setAddress]=useState("");
  const [city,setCity]=useState("");
  const [lat,setLat]=useState("");
  const [lng,setLng]=useState("");
  const [title,setTitle]=useState("");
  const [duree,setDuree]=useState("");
  const [type_logement,settype_logement]=useState("");
  const [nb_pieces,setnb_pieces]=useState("");
  const [surface,setsurface]=useState("");
  const [prix_nuit,setprix_nuit]=useState("");
  const [a_partir,seta_partir]=useState("");
  const [min_sejour,setmin_sejour]=useState("");
  const [capacite,setcapacite]=useState("");
  const [frais_menage,setfrais_menage]=useState("");
  const [frais_service,setfrais_service]=useState("");
  const [taxe_sejour,settaxe_sejour]=useState("");
  const [description,setdescription]=useState("");
  const [heurearrive,setheurearrive]=useState("");
  const [heuredepart,setheuredepart]=useState("");
  

  const [value, setValue] = useState(null);

  useEffect(() => {
    dispatch(getBienById(id)).then((res) => {
      console.log("res");
      console.log(res.data.exterieurs);
      console.log(res);
      setDetail(res.data);
      setTitle(res.data.title);
      setDuree(res.data.duree);
      settype_logement(res.data.type_logement);
      setnb_pieces(res.data.nb_pieces);
      setsurface(res.data.surface);
      setprix_nuit(res.data.prix_nuit);
      seta_partir(res.data.a_partir);
      setmin_sejour(res.data.min_sejour);
      setcapacite(res.data.capacite);
      setfrais_menage(res.data.frais_menage);
      setfrais_service(res.data.frais_service);
      settaxe_sejour(res.data.taxe_sejour);
      setdescription(res.data.description);
      setNbChambre(res.data.nb_chambres);
      setNbBain(res.data.nb_salle_bain);
      setNbWc(res.data.nb_wc);
      setEquipement(res.data.equipements);
      console.log('res.data.exterieurs');
      console.log(res.data.aproximite);
      setExterieurs(res.data.exterieurs);
      setAproximite(res.data.aproximite);
      setAddress(res.data.address);
      setCity(res.data.city);
      setLat(res.data.lat);
      setLng(res.data.lng);
      setheurearrive(res.data.heure_arrivee);
      setheuredepart(res.data.heure_depart);
      setAnimaux(res.data.animaux)
      setFumeur(res.data.fumeur)

      if(res.data.images_init[0] != '' && res.data.images_init[0] != null)
      setImageName1(res.data.images_init[0])
      if(res.data.images_init[1] != '' && res.data.images_init[1] != null)
      setImageName2(res.data.images_init[1])
      if(res.data.images_init[2] != '' && res.data.images_init[2] != null)
      setImageName3(res.data.images_init[2])
      if(res.data.images_init[3] != '' && res.data.images_init[3] != null)
      setImageName4(res.data.images_init[3])
      if(res.data.images_init[4] != '' && res.data.images_init[4] != null)
      setImageName5(res.data.images_init[4])
      if(res.data.images_init[5] != '' && res.data.images_init[5] != null)
      setImageName6(res.data.images_init[5])

    }).catch((e)=>{
    });
  }, [])

  const label = { inputProps: { 'aria-label': '' } };

  const defaultProps = {
    center: {
      lat: 48.8733089,
      lng: -0.4833178
    },
    zoom: 5
  };




  const handleChange = address => {
    setAddress(address);
  };
  
  const selectValuemap=(value)=>{
  
    setAddress(value);
    setValue(value);
     geocodeByAddress(value)
     .then(results => {
        const city = results[0].address_components.find(component => component.types.includes("locality")).long_name;
        setCity(city)
        getLatLng(results[0]).then(({ lat, lng }) =>{
            setLat(lat);
            setLng(lng);
         }
        )
     });
   }
 const onChangeaddress = (text) => {
    console.log("jjjjjjjjj",text)
 }

 const renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions,loading }) => (
  <div className="autocomplete-root">
    <input {...getInputProps({
        placeholder: 'Adresse ...',
        className: 'form-control',
    })} />
    <div className="autocomplete-dropdown-container my-2">
      {loading && <div>Loading...</div>}
      {suggestions.map(suggestion => (
      
        <div className=" mb-2  listitem" {...getSuggestionItemProps(suggestion)}>
          <span >{suggestion.description}</span>
        </div>
      ))}
    </div>
  </div>
);

  const returnFormAddLocation = () => {
    return (
      <div className='col-md-3  position_relative'></div>
    )
  }

  const handleChangeAproximite = (element) => {
    const data = aproximite
    if(data[element].selected == 0)
      data[element].selected = 1
    else
      data[element].selected = 0
    setAproximite(data)
    console.log("aproximite");
    console.log(aproximite);
  }

  const handleChangeEquipement = (element) => {
    const data = equipements
    if(data[element].selected == 0)
      data[element].selected = 1
    else
      data[element].selected = 0
    setEquipement(data)
    console.log("equipements");
    console.log(equipements);
  }

  const handleChangeExterieur = (element) => {
    const data = exterieurs
    if(data[element].selected == 0)
      data[element].selected = 1
    else
      data[element].selected = 0
    setExterieurs(data)
    console.log("exterieurs");
    console.log(exterieurs);
  }
  const handleChangeElement = (e) => {
    if(e.target.name == 'title')
      setTitle(e.target.value)
    if(e.target.name == 'description')
      setdescription(e.target.value)
    if(e.target.name == 'a_partir')
      seta_partir(e.target.value)
    if(e.target.name == 'type_logement')
      settype_logement(e.target.value)
    if(e.target.name == 'nb_pieces')
      setnb_pieces(e.target.value)
    if(e.target.name == 'surface')
      setsurface(e.target.value)
    if(e.target.name == 'prix_nuit')
      setprix_nuit(e.target.value)
    if(e.target.name == 'min_sejour')
      setmin_sejour(e.target.value)
    if(e.target.name == 'capacite')
      setcapacite(e.target.value)
    if(e.target.name == 'frais_menage')
      setfrais_menage(e.target.value)
    if(e.target.name == 'frais_service')
      setfrais_service(e.target.value)
    if(e.target.name == 'taxe_sejour')
      settaxe_sejour(e.target.value)
    if(e.target.name == 'description')
      setdescription(e.target.value)
    if(e.target.name == 'heurearrive')
      setheurearrive(e.target.value)
    if(e.target.name == 'heuredepart')
      setheuredepart(e.target.value)
      if(e.target.name == 'duree')
          setDuree(e.target.value)
  }

  const handleChangeAnimaux = () => {
    if(animaux == 0)
      setAnimaux(1)
    else
      setAnimaux(0)
  }
  const handleChangeFumeur = () => {
    if(fumeur == 0)
      setFumeur(1)
    else
    setFumeur(0)
  }

  const handelePlus = (element) => {
    if(element == 1){
      const nb = nb_chambres+1
      setNbChambre(nb)
    }
    else if(element == 2){
      const nb = nb_salle_bain+1
      setNbBain(nb)
    }
    else if(element == 3){
      const nb = nb_wc+1
      setNbWc(nb)
    }
  };
  const handeleMoins = (element) => {
    if(element == 1){
      if(nb_chambres > 0){
        const nb = nb_chambres-1
        setNbChambre(nb)
      }
    }
    else if(element == 2){
      if(nb_salle_bain > 0){
        const nb = nb_salle_bain-1
        setNbBain(nb)
      }
    }
    else if(element == 3){
      if(nb_wc > 0){
        const nb = nb_wc-1
        setNbWc(nb)
      }
    }
  };

  const handleSubmit = (e) => {
       
      e.preventDefault();
      const formData = new FormData(e.target)
      const formDataObj = Object.fromEntries(formData.entries())
      formDataObj.equipements = equipements
      formDataObj.exterieurs = exterieurs
      formDataObj.aproximite = aproximite
      formDataObj.animaux = animaux
      formDataObj.fumeur = fumeur
      formDataObj.user_id = user.id
      formDataObj.image1 = image1
      formDataObj.image2 = image2
      formDataObj.image3 = image3
      formDataObj.image4 = image4
      formDataObj.image5 = image5
      formDataObj.image6 = image6
      console.log('formDataObj');
      console.log(formDataObj);

      dispatch(updateBien(formDataObj)).then((res) => {
        // history.push('/client/patrimoine/')
      })
  }

  const onChangeFile = (e) => {
		let files = e.target.files || e.dataTransfer.files;

		if (!files.length)
		return;

    if(files[0].type=='image/png' || files[0].type=='image/jpeg' || files[0].type=='image/jpg' || files[0].type=='image/gif' ){
      if(files[0].size <= (2*1000000)){
          createImage(files[0], e.target.name);
      }
      else{
          alert('Votre pièce-jointe ne doit  pas dépasser cette taille: 2 MO.');
      }
    }
    else{
        alert("Ce Format document n'est pas supporté ! Les formats acceptés sont (JPG,JPEG,PNG,GIF)");
    }
	}
  
	function createImage(file, tag_name) {
		// $('#divLoad').show()
		let reader = new FileReader();
		reader.onload = (e) => {
		  fileUpload(e.target.result, tag_name);
		};
		reader.readAsDataURL(file);
	}
	function fileUpload (values, tag_name){
			var self = this;
		    axios.post(BASE_URL+'/insertimage', {'file':values, 'type':'images_bien'}, { headers: {"Accept": "application/json",
	                "Content-Type": "application/json"}}).then((res) => {
                    if(tag_name == 'image1')
                      setImageName1(res.data.name)
                    else if(tag_name == 'image2')
                      setImageName2(res.data.name)
                    else if(tag_name == 'image3')
                      setImageName3(res.data.name)
                    else if(tag_name == 'image4')
                      setImageName4(res.data.name)
                    else if(tag_name == 'image5')
                      setImageName5(res.data.name)
                    else if(tag_name == 'image6')
                      setImageName6(res.data.name)
                // this.setState({
                // 	[tag_name]: res.data.name
                // })

                // $('#divLoad').hide()
			}).catch((e)=>{
		});
	}

  return (
    <div className="myccount editprofil abonnement addnewpatremoine " >
      <section className="gray-bg main-dashboard-sec dashboard myccount" id="sec1">
        <div className="container">
          <div className="col-md-3">
            <DashbordMenuClient src='patrimoine' />
          </div>
          <div className='col-md-9 dashbordassitantcontent' >
            <div className='tab' id=''>
              <DashbordHeaderClient src={'w'} />
              <div className='row' style={{ marginTop: '30px' }}>
                <div className='col-md-12'>
                  <form style={{ background: '#f6f6f8', boxShadow: 'none' }} className='custom-form' onSubmit={handleSubmit}>
                        <input type="hidden" name="city" value={city} />
                        <input type="hidden" name="id" value={id} />
                    <div class="rowtitel">
                      <img src="/images/addpatrimoine.png" style={{ width: '55px' }} />
                      {/* <h3 class="htri1">Patrimoine -  <span style={{ color: '#4EC5CA' }}>Ajouter un bien en location</span> </h3> */}
                      <h3 class="htri1">Patrimoine -  <span style={{ color: '#4EC5CA' }}>Modifier un bien en location</span> </h3>
                    </div>
                    <div className='section298'>
                      <div className="form-check">
                        <input name="option" type="radio" className="form-check-input ch667" value="1" style={{ paddingLeft: '0px !important' }} />
                        <label title="" className="form-check-label">Avec option conciergerie</label>
                      </div>
                      <div className="form-check">
                        <input name="option" type="radio" className="form-check-input ch667" value="2" style={{ paddingLeft: '0px !important' }} />
                        <label title="" className="form-check-label">Avec option gestion</label>
                      </div>
                    </div>
                    <div className='section298 '>
                      <div className='section298frm row'>
                        <div className='col-md-7'>
                          <h4>Titre de l'annonce</h4>
                          <input placeholder="Ex. : Appartement T3 vue dégagée" name="title" onChange={handleChangeElement} type="text" class="form-control inptxt" value={title} required="" />
                        </div>
                        <input type="hidden" className='form-control' name='type_bien' value="1"/>
                        <div className='col-md-3'>
                          <h4>Durée de l'annonce</h4>
                          <select className='form-control' name='duree' onChange={handleChangeElement} value={duree}>
                            <option value="">Sélectionnez</option>
                            <option value="1">1 mois</option>
                            <option value="2">2 mois</option>
                            <option value="3">3 mois</option>
                            <option value="4">Pas de limite de temps</option>
                          </select>
                        </div>
                      </div>
                      <div className='section298frm row'>
                        <div className='col-md-12'> <h4>Type de logement</h4></div>
                        <div className='col-md-4'>
                          <select className='form-control' name='type_logement' onChange={handleChangeElement} value={type_logement}>
                            <option value="">Sélectionnez</option>
                            <option value="1">Maison</option>
                            <option value="2">Pavillon</option>
                            <option value="3">Gîte</option>
                            <option value="4">Appartement</option>
                            <option value="5">Appartement en résidence</option>
                            <option value="6">Loft</option>
                            <option value="7">Résidence de tourisme</option>
                            <option value="8">Chambre privée</option>
                            <option value="9">Logement de vacances</option>
                          </select>
                        </div>
                        <div className='col-md-4'>
                          <select className='form-control' name='nb_pieces' onChange={handleChangeElement} value={nb_pieces}>
                            <option value="">Nb de pièces</option>
                            <option value="1">1 pièce</option>
                            <option value="2">2 pièces</option>
                            <option value="3">3 pièces</option>
                            <option value="4">4 pièces</option>
                            <option value="5">5 pièces et +</option>
                          </select>
                        </div>
                        <div className='col-md-4'>
                          <input placeholder="surface m²" name="surface" type="text" class="form-control inptxt" onChange={handleChangeElement} value={surface} required="" />
                        </div>
                      </div>
                      <div className='section298frm row'>
                        <div className='col-md-3'>
                          <h4>Prix par nuit</h4>
                          <div className='posrela'><input placeholder="0" name="prix_nuit" onChange={handleChangeElement} value={prix_nuit} type="number" min="0" class="form-control inptxt" required="" /><span>€</span></div>
                        </div>
                        <div className='col-md-1'></div>
                      </div>
                      <div className='section298frm row'>
                        <div className='col-md-12'>
                          <h4>Location</h4>
                        </div>
                        <div className='col-md-2'>
                          <h6>A partir du</h6>
                          <div className='posrela'><input style={{ paddingRight: 3 }} placeholder="Date" onChange={handleChangeElement} value={a_partir} name="a_partir" type="date" class="form-control inptxt" required="" /></div>
                        </div>
                        <div className='col-md-2'>
                          <h6 style={{ fontSize: '11px' }}>Durée min. de séjour</h6>
                          <select className='form-control' name='min_sejour' onChange={handleChangeElement} value={min_sejour}>
                            <option value="">Sélectionnez</option>
                            <option value="1">1 nuit</option>
                            <option value="2">2 nuits</option>
                            <option value="3">3 nuits</option>
                            <option value="4">4 nuits</option>
                            <option value="5">5 nuits</option>
                            <option value="6">6 nuits</option>
                            <option value="7">7 nuits</option>
                            <option value="8">8 nuits et +</option>
                          </select>
                        </div>
                        <div className='col-md-2'>
                          <h6>Capacité</h6>
                          <select className='form-control' name='capacite' onChange={handleChangeElement} value={capacite}>
                            <option value="">Sélectionnez</option>
                            <option value="1">1 personne</option>
                            <option value="2">2 personnes</option>
                            <option value="3">3 personnes</option>
                            <option value="4">4 personnes</option>
                            <option value="5">5 personnes</option>
                            <option value="6">6 personnes</option>
                            <option value="7">7 personnes</option>
                            <option value="8">8 personnes et +</option>
                          </select>
                        </div>
                        <div className='col-md-2'>
                          <h6>Frais de ménage</h6>
                          <div className='posrela'><input placeholder="0" name="frais_menage" onChange={handleChangeElement} value={frais_menage} type="number" class="form-control inptxt" required="" /><span>€</span></div>
                        </div>
                        <div className='col-md-2'>
                          <h6>Frais de service</h6>
                          <div className='posrela'><input placeholder="0" name="frais_service" onChange={handleChangeElement} value={frais_service} type="number" class="form-control inptxt" required="" /><span>€</span></div>
                        </div>
                        <div className='col-md-2'>
                          <h6>Taxes de séjour</h6>
                          <div className='posrela'><input placeholder="0" name="taxe_sejour" onChange={handleChangeElement} value={taxe_sejour} type="number" class="form-control inptxt" required="" /><span>€</span></div>
                        </div>
                      </div>
                      <div className='section298frm row'>
                        <div className='col-md-12'>
                          <h4>Présentation du bien</h4>
                          <textarea name="description" onChange={handleChangeElement} value={description} style={{ border: '1px solid #e5e7f2', height: '135px' }} class="form-control" row='3' placeholder='Ex. : Situé à 400 mètres de la plage, magnifique gîte avec piscine...'></textarea>
                          <div className='xtm00'>4000 caractères max.</div>
                        </div>
                      </div>
                      <div className='section298frm row'  >
                        <div className='col-md-12'>
                          <h4>Caractéristiques</h4>
                          <br />
                          <h7>GENERAL<span></span></h7>
                          <br />
                          <div className="col-md-2 ingrouped">
                            <h6>Nb de chambres</h6>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <button onClick={() => handeleMoins(1)} class="btn btn-outline-primary" type="button"   >-</button>
                              </div>
                              <input type="text" name="nb_chambres" class="form-control" value={nb_chambres} />
                              <div class="input-group-prepend">
                                <button onClick={() => handelePlus(1)} class="btn btn-outline-primary" type="button" >+</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 ingrouped">
                            <h6>Salle de bain</h6>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <button onClick={() => handeleMoins(2)} class="btn btn-outline-primary" type="button"  >-</button>
                              </div>
                              <input type="text" name="nb_salle_bain" class="form-control" value={nb_salle_bain} />
                              <div class="input-group-prepend">
                                <button onClick={() => handelePlus(2)} class="btn btn-outline-primary" type="button" >+</button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 ingrouped">
                            <h6>WC</h6>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <button onClick={() => handeleMoins(3)} class="btn btn-outline-primary" type="button"  >-</button>
                              </div>
                              <input type="text" name="nb_wc" class="form-control" value={nb_wc} />
                              <div class="input-group-prepend">
                                <button onClick={() => handelePlus(3)} class="btn btn-outline-primary" type="button" >+</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='section298frm row' >
                        <div className='col-md-12'>
                          <h7>EQUIPEMENTS<span></span></h7>
                          <br />
                        </div>
                        <div className='col-md-12'>
                          <ul className='row'>
                            {
                              equipements.map((eqp, index) => (
                                eqp.selected == 1 ?
                                <li className={'col-md-4 '+eqp.class}><span>{eqp.label}</span><Switch {...label} defaultChecked size="small" color="secondary" onChange={() => handleChangeEquipement(index)} /></li>
                                :
                                <li className={'col-md-4 '+eqp.class}><span>{eqp.label}</span><Switch {...label} size="small" color="secondary" onChange={() => handleChangeEquipement(index)} /></li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                      <div className='section298frm row' >
                        <div className='col-md-12'>
                          <h7>EXTERIEUR<span></span></h7>
                          <br />
                        </div>
                        <div className='col-md-4'>
                          <ul>
                            {
                              exterieurs.map((ext, index) => (
                                ext.selected == 1 ?
                                <li className={ext.class}><span>{ext.label}</span><Switch {...label} defaultChecked size="small" color="secondary" onChange={() => handleChangeExterieur(index)} /></li>
                                :
                                <li className={ext.class}><span>{ext.label}</span><Switch {...label} size="small" color="secondary" onChange={() => handleChangeExterieur(index)} /></li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                      <div class="section298frm row bgcap89">
                        <div class="col-md-12">
                          <h4>Photos</h4>
                        </div>
            {
              image1 != '' ? 
              <div className='col-6 col-md-2'><div className='img'  style={{background:"url("+BASE_URL_PROD+'/img/biens/'+image1+")", backgroundSize:"cover"}}></div></div>
              :
              <div className='col-6 col-md-2'><div className='img'><input style={{cursor:'pointer'}} className="inputFileInvis" name="image1" type="file" onChange={onChangeFile}/></div></div>
            }
            {
              image2 != '' ? 
              <div className='col-6 col-md-2'><div className='img'  style={{background:"url("+BASE_URL_PROD+'/img/biens/'+image2+")", backgroundSize:"cover"}}></div></div>
              : 
              <div className='col-6 col-md-2'><div className='img'><input style={{cursor:'pointer'}} className="inputFileInvis" name="image2" type="file" onChange={onChangeFile}/></div></div>
            }
            {
              image3 != '' ? 
              <div className='col-6 col-md-2'><div className='img'  style={{background:"url("+BASE_URL_PROD+'/img/biens/'+image3+")", backgroundSize:"cover"}}></div></div>
              : 
              <div className='col-6 col-md-2'><div className='img'><input style={{cursor:'pointer'}} className="inputFileInvis" name="image3" type="file" onChange={onChangeFile}/></div></div>
            }
            {
              image4 != '' ? 
              <div className='col-6 col-md-2'><div className='img'  style={{background:"url("+BASE_URL_PROD+'/img/biens/'+image4+")", backgroundSize:"cover"}}></div></div>
              : 
              <div className='col-6 col-md-2'><div className='img'><input style={{cursor:'pointer'}} className="inputFileInvis" name="image4" type="file" onChange={onChangeFile}/></div></div>
            }
            {
              image5 != '' ? 
              <div className='col-6 col-md-2'><div className='img'  style={{background:"url("+BASE_URL_PROD+'/img/biens/'+image5+")", backgroundSize:"cover"}}></div></div>
              : 
              <div className='col-6 col-md-2'><div className='img'><input style={{cursor:'pointer'}} className="inputFileInvis" name="image5" type="file" onChange={onChangeFile}/></div></div>
            }
            {
              image6 != '' ? 
              <div className='col-6 col-md-2'><div className='img'  style={{background:"url("+BASE_URL_PROD+'/img/biens/'+image6+")", backgroundSize:"cover"}}></div></div>
              : 
              <div className='col-6 col-md-2'><div className='img'><input style={{cursor:'pointer'}} className="inputFileInvis" name="image6" type="file" onChange={onChangeFile}/></div></div>
            }
                      </div>
                      <div class="section298frm row">
                        <div class="col-md-12">
                          <h4>Localisation du bien</h4><br />
                          <h6>Où se situe votre bien ?</h6>
                        </div>
                        <div className='col-12 col-md-12'>
                          <div style={{ height: 200, width: '100%', overflow: 'hidden', borderRadius: '14px', position: 'relative' }}>
                            <div className='google_search'>
                              <div className='flx998'>
                                {/* <input placeholder="Renseignez votre adresse ou votre ville" name="" type="text" class="form-control inptxt" required="" />
                                <button class="btn float-btn color2-bg btn9876 btcustom " ><i className='fa fa-search'></i></button> */}
                                <Form.Group as={Col} >
                                  <Form.Label>Adresse</Form.Label>
                                  <Form.Control defaultValue={address} type="hidden" placeholder="Votre adresse" name="address" />
                                  <Form.Control defaultValue={lat} type="hidden" placeholder="Votre adresse" name="lat" />
                                  <Form.Control defaultValue={lng} type="hidden" placeholder="Votre adresse" name="lng" />
                                  <PlacesAutocomplete value={address} onChange={handleChange}   onSelect={selectValuemap}>
                                    {renderFunc}
                                  </PlacesAutocomplete>
                                </Form.Group>
                              </div>
                            </div>
                            <GoogleMapReact
                              bootstrapURLKeys={{ key: "AIzaSyBTF7Lt3-kiG9UrWnl1R6SVsAev-1Y6aNc" }}
                              defaultCenter={defaultProps.center}
                              defaultZoom={defaultProps.zoom}
                            >
                              <AnyReactComponent
                                lat={lat}
                                lng={lng}
                                text=""
                              />
                            </GoogleMapReact>
                          </div>
                        </div>
                      </div>
                      <div class="section298frm row">
                        <div class="col-md-12">
                          <h4>A proximité</h4><br />
                        </div>
                        <div className='col-12 col-md-12'>
                          <ul className='row'>
                            {
                              aproximite.map((p, index) =>(
                                p.selected == 1 ?
                                <li className='col-md-4 isnonbg'><Switch {...label} defaultChecked size="small" color="secondary" onChange={() => handleChangeAproximite(index)} /> <span>{p.label}</span></li>
                                :
                                <li className='col-md-4 isnonbg'><Switch {...label} size="small" color="secondary" onChange={() => handleChangeAproximite(index)} /> <span>{p.label}</span></li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                      <div class="section298frm row">
                        <div class="col-md-12">
                          <h4>Règlement intérieur</h4><br />
                        </div>
                        <div className='col-6 col-md-3'>
                          <h6>Horaire d'arrivée</h6>
                          <select className='form-control' name='heurearrive' onChange={handleChangeElement} value={heurearrive}>
                            <option value="">Sélectionnez</option>
                            <option value="08:00">08:00</option>
                            <option value="09:00">09:00</option>
                            <option value="10:00">10:00</option>
                            <option value="11:00">11:00</option>
                            <option value="12:00">12:00</option>
                            <option value="13:00">13:00</option>
                            <option value="14:00">14:00</option>
                            <option value="15:00">15:00</option>
                          </select>
                        </div>
                        <div className='col-6 col-md-3'>
                          <h6>Horaire de départ</h6>
                          <select className='form-control' name='heuredepart' onChange={handleChangeElement} value={heuredepart}>
                            <option value="">Sélectionnez</option>
                            <option value="08:00">08:00</option>
                            <option value="09:00">09:00</option>
                            <option value="10:00">10:00</option>
                            <option value="11:00">11:00</option>
                            <option value="12:00">12:00</option>
                            <option value="13:00">13:00</option>
                            <option value="14:00">14:00</option>
                            <option value="15:00">15:00</option>
                          </select>
                        </div>
                        <div className='col-12 col-md-6'></div>
                        <div className='col-12 col-md-3'>
                          <ul>
                            {
                              animaux == 1 ?
                              <li className='isnonbg'><Switch {...label} defaultChecked onChange={() => handleChangeAnimaux()} size="small" color="secondary" /> <span>Animaux acceptés</span></li>
                              :
                              <li className='isnonbg'><Switch {...label}  onChange={() => handleChangeAnimaux()} size="small" color="secondary" /> <span>Animaux acceptés</span></li>
                            }
                            
                            {
                              fumeur == 1 ?
                              <li className='isnonbg'><Switch {...label} defaultChecked onChange={() => handleChangeFumeur()} size="small" color="secondary" /> <span>Fumeurs acceptés</span></li>
                              :
                              <li className='isnonbg'><Switch {...label}  onChange={() => handleChangeFumeur()} size="small" color="secondary" /> <span>Fumeurs acceptés</span></li>
                            }
                          </ul>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div>
                            <button type="submit" class="btn float-btn color2-bg btn9876 btcustom " style={{ borderRadius: '13px' }}>Publier l'annonce</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
const AnyReactComponent = ({ text }) => <div></div>;
export default Page;