import React,{useEffect,useState} from "react";
import './styles.scss';
import { logo } from "../../assets/images";
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../actions/api";
import { useParams } from "react-router";

const Page = () => {

    const dispatch = useDispatch();
  
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const { slug } = useParams()

  const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        apiClient().post(`/forgotPass`,formDataObj)
           .then((res) => {
            
              if(res.data.success==false){
                 alert(t("Votre adresse email n'existe pas !"))
              }else{
                alert(t('Un e-mail vous a été envoyé..'))
                window.location.href="/"
              }
               
           })
    }

      useEffect(() => {
        
        if(langset == false){
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }
 
        
 

    }, [])


    return (
        <div className="forget login custom-form" >
            <Container>
                <Row>
                    <Col lg={{ span: 5 }} className="text-center mx-auto my-5" >
                     
                        <Form className="mb-5" style={{marginTop:'50px',overflow: 'hidden'}} onSubmit={handleSubmit} >
                            <h4 style={{    color: '#073460'}}>Mot de passe oublié ?</h4>
                            <br/><br/>
                            <Form.Group  >
                                <Form.Label>Email*</Form.Label>
                                <input type="email" name='email' placeholder="mail@website.com" required className='form-control' />
                                <input type='hidden' name='type' value={slug} />
                            </Form.Group>
                            <button className="w-100 btn float-btn color2-bg pdpad"  type="submit" style={{fontSize:'17px'}}>
                                Envoyer un e-mail de réinitialisation
                            </button>
                        </Form>
                      
                         
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;