import React from "react";
import './styles.scss';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { IoMdTime } from 'react-icons/io';
import { BiCategoryAlt } from 'react-icons/bi';
import { useHistory } from "react-router";


const Component = ({ item }) => {
    const history = useHistory();

   

    return (
            <div className="avis-item" >


                                            <div className="testi-item fl-wrap left">
                                                 
                                                <div className="testimonilas-text fl-wrap">
                                                    <span className='ddavis'>il y a 2 jours</span>
                                                    <div className="listing-rating card-popup-rainingvis">
                                                      <i className="fas fa-star"></i>
                                                      <i className="fas fa-star"></i>
                                                      <i className="fas fa-star"></i>
                                                      <i className="fas fa-star"></i>
                                                      <i className="fas fa-star"></i>
                                                      <span>5/5</span>

                                                    </div>
                                                    <strong>Peinture</strong>
                                                    <p>Merci Jean, prestation professionnelle, c'est parfait !</p>
                                                     
                                                    <div className="testimonilas-avatar fl-wrap">
                                                        <h3>Nathalie (Paris, 75015)</h3>
                                                        
                                                    </div>
                                                </div>
                                            </div>
            </div>
    );
}

export default Component;