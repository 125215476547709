import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import { BASE_URL_PROD } from "../../../components/config/keys";
import { surpls,epr } from "../../../assets/images";
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import { HiLocationMarker } from 'react-icons/hi';
import GoogleMapReact from 'google-map-react';

import { getSearchedBiens,getBiens } from "../../../actions/pages";

const Page = () => {
    const search_data = JSON.parse(localStorage.getItem('search_data'));
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [searched_biens, setSearchedBiens] = useState([]);
    const [currentData, setcurrentData] = useState([]);
    const [pageNumbers, setPageNumber] = useState([]);
     const { last_biens } = useSelector(state => state.pages)

    const [type_active, setTypeActive] = useState(search_data.active);
    const [type_logement, setTypeLogement] = useState(search_data.type_bien);
    const [adress, setAdress] = useState(search_data.adress);
    const [valueDates, setvalueDates] = useState(search_data.valueDates);
    const [voyageurs, setVoyageur] = useState(search_data.adultes +  search_data.enfants + search_data.bebes + search_data.animeaux);
   

    const [currentPage, setCurrentPage] = useState(1); // current page number
    const [itemsPerPage, setItemsPerPage] = useState(6); // number of items to display per page

    // const { searched_biens } = useSelector(state => state.pages)

  // Calculate the index of the first and last items to display

    const history = useHistory()
    const defaultProps = {
    center: {
      lat: 48.8588548,
      lng: 2.347035
    },
    zoom: 11
  };


  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);

    const lastIndex = pageNumber * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    
    const currentDataTmp = searched_biens.slice(firstIndex, lastIndex);
    
    setcurrentData(currentDataTmp)
  }

    const handleSubmit = async (e) => {
        e.preventDefault();
    }

    const getDataElement = (element) => {
      search_data.page = element
      dispatch(getSearchedBiens(search_data)).then((res) => {
        setSearchedBiens(res.data.data)
        setTotal(res.data.total)
      })
    }

 

    useEffect(() => {
      search_data.page = page
      dispatch(getBiens({limit:2}));
      dispatch(getSearchedBiens(search_data)).then((res) => {
        console.log(res);
        setSearchedBiens(res.data)
        setTotal(res.data.length)
        const totalPages = Math.ceil(res.data.length / itemsPerPage);
  
        const pageNumbersTmp = [];
        for (let i = 1; i <= totalPages; i++) {
          pageNumbersTmp.push(i);
        }
        setPageNumber(pageNumbersTmp)
        
        const lastIndex = currentPage * itemsPerPage;
        const firstIndex = lastIndex - itemsPerPage;
        
        const currentDataTmp = res.data.slice(firstIndex, lastIndex);
        
        setcurrentData(currentDataTmp)
        console.log(res.data);
        console.log(searched_biens);
        console.log(currentData);
      })
    }, [])



    const filtreBySearch = async (e) => {
        e.preventDefault();

        let newdata= search_data

     
         const formData = new FormData(e.target)
         let formDataObj = Object.fromEntries(formData.entries())
 

        if(formDataObj?.type_active){
           newdata.active=formDataObj?.type_active
        }

       if(formDataObj?.type_bien){
           newdata.type_bien=formDataObj?.type_bien
        }

        if(formDataObj?.adress){
           newdata.adress=formDataObj?.adress
        }

        localStorage.setItem('search_data', JSON.stringify(newdata))
         history.push('/liste-biens')
        
 

  
        /*



    let search={}
      if(active==1){
        //louer
         search = {'active':active, 'type_bien':type_bien, 'surface':surface, 'prix_max':prix_max, 'adress':adress,'valueDates':valueDates,'adultes':adultes,'enfants':enfants,'bebes':bebes,'animeaux':animeaux,'datedepart':datedepart,'datearrive':datearrive}
      }
      else{

        //achat

          search = {'active':active, 'type_bien':type_bien, 'surface':surface, 'prix_max':prix_max, 'adress':adress}

      }

  

      localStorage.setItem('search_data', JSON.stringify(search))
 

      history.push('/liste-biens')



        */



    }

    const returnitemsvente =  (item) => {
      return(
        <div className='col-6  position_relative'>
          <div className="process-item position_relative" style={{cursor:'pointer'}} onClick={()=>history.push('/detail-bien/'+item.id)}>
            <div className="process-img" style={{backgroundImage: (item.img != '' ? "url('"+item.img+"')" : "url('/images/imo.png')")}}></div>
            <h4 className='basecolor type-bien tag'>Vente</h4>
            <h4 className='basecolor title' >{item.logement}</h4>
            <img src="/images/immo/favori.png" className="img-favoris"/>
            <h6 className='' style={{ textAlign:"left", fontSize:"11px", color:"gray" }}>
              <img src="/images/immo/icone-map.png" className="" style={{ width:"12px" }} /> {item.city}
            </h6>
            <div className="priceoffre" style={{ textAlign:"left", fontSize:"18px" }}><span>{item.price} € </span></div>
          </div>
        </div>
      )
    }

    const returnitems =  (item) => {
      return(
        <div className='col-6  position_relative'>
          <div className="process-item position_relative" style={{cursor:'pointer'}} onClick={()=>history.push('/detail-bien/'+item.id)}>
            <div className="process-img" style={{backgroundImage: (item.img != '' ? "url('"+item.img+"')" : "url('/images/imo.png')")}}></div>
            <h4 className='basecolor type-bien tag'>Location</h4>
            <h4 className='basecolor title' >{item.logement}</h4>
            <img src="/images/immo/favori.png" className="img-favoris"/>
            <h6 className='' style={{ textAlign:"left", fontSize:"11px", color:"gray" }}>
              <img src="/images/immo/icone-map.png" className="" style={{ width:"12px" }} /> {item.city}
            </h6>
            <div className="priceoffre" style={{ textAlign:"left", fontSize:"18px" }}><span>{item.price} € </span>par nuit</div>
          </div>
        </div>
      )
    }
    
 
    return (
      <div id="homwraped" className="biens">
        <div className="content">
        <section id='section-filtre-bien'>
        <form onSubmit={filtreBySearch}>
         <div className="container small-container">

          <div className='row section-filtre-row'>

           <div className='col-md-2 lfp333'>
              <select className='' name='type_active' defaultValue={type_active}>
                            <option value='1'>Location Saisonnière</option>
                            <option value="2">Vente</option>
                            <option value="3">Colocation</option>
                           
              </select>
               
           </div>

            <div className='col-md-2'>
                        <div style={{position:'relative'}}>
                        <span className='san88'><HiLocationMarker size={25} /></span>
                          <input name='adress' className='adress markerinput' type="text" placeholder="Paris" defaultValue={adress}/>
                        </div>
            </div>
            <div className='col-md-2'>
                          <select className='type_bien' name='type_bien' defaultValue={search_data.type_bien}>
                            <option>Type de bien</option>
                            <option value="1">Maison</option>
                            <option value="2">Pavillon</option>
                            <option value="3">Gîte</option>
                            <option value="4">Appartement</option>
                            <option value="5">Appartement en résidence</option>
                            <option value="6">Loft</option>
                            <option value="7">Résidence de tourisme</option>
                            <option value="8">Chambre privée</option>
                            <option value="9">Logement de vacances</option>
                          </select>
             </div>
            {
              search_data.active == 1 ? 
              <>

              <div className='col-md-2'>
              <select className=''>
                <option>{valueDates}</option>
                  
              </select>
            </div>

            <div className='col-md-2'>
              <select className=''>
                <option>{voyageurs} voyageurs</option>
              </select>
            </div>
              </>
              :
              ""
            }
            <div className='col-md-2 lrp333'>
                <button type='submit' className="btn btnform w200 button_search" href='javascript:void(0)' > Rechercher </button>
            </div>

          </div>


          <div className='row mtf09' >
           <div className='col-md-1' style={{    paddingRight: '0px'}}>
            <span className='basecolor' style={{fontSize:'11px',fontWeight: 'bold',lineHeight: '30px'}}>{total} résultats </span>
          </div>
          <div className='col-md-3'>
              <div classNam='flex'>
               
                  <select className='selectfiltre6654' style={{    height: '32px'}}>
                            <option>Date la plus récente</option>
                            <option>Date la plus ancienne</option>
                            <option>Surface la plus petite</option>
                            <option>Surface la plus grande</option>
                            <option>Prix le plus bas</option>
                            <option>Prix le plus élevé</option>

                             
                  </select>

              </div>
           </div>


          </div>



        </div>
        </form>
        </section>

        <section id='biens-results'>
          <div className="container small-container">

          <div className='row '>
              <div className='col-md-6'>
                <div className='row offresection'>
                {
                  currentData != undefined ?
                  currentData.map((item, index)=>(
                    item.type_bien == 1 ?
                    returnitems(item)
                    :
                    returnitemsvente(item)
                  ))
                  :
                  ""
                }

                 </div>
                 <div className='row'>
                 {/* <Pagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={searched_biens.length}
                  onPageChange={handlePageChange}
                /> */}

                <ul class="pagination uk-flex-center">
                  {pageNumbers.map((pageNumber) => (
                    <li key={pageNumber} className={`page-item${pageNumber === currentPage ? " active" : ""}`}>
                      <button className="page-link" onClick={() => handlePageChange(pageNumber)}>
                        {pageNumber}
                      </button>
                    </li>
                  ))}
                </ul>

                   {/* <ul class="pagination uk-flex-center">
                      <li class="previous disabled">
                        <a class=" " tabindex="-1" role="button" aria-disabled="true" aria-label="Previous page" rel="prev">❮</a>
                      </li>
                      <li class="active">
                        <a rel="canonical" role="button" tabindex="-1" aria-label="Page 1 is your current page" aria-current="page">1</a>
                      </li><li><a rel="next" role="button" tabindex="0" aria-label="Page 2">2</a></li><li class="break"><a role="button" tabindex="0">...</a></li><li><a role="button" tabindex="0" aria-label="Page 32">32</a></li><li><a role="button" tabindex="0" aria-label="Page 33">33</a></li><li class="next"><a class="" tabindex="0" role="button" aria-disabled="false" aria-label="Next page" rel="next">❯</a></li>
                    </ul> */}

                 </div>

              </div>
               <div className='col-md-6'>

               <div className='mapsview' style={{ height: '100vh', width: '100%' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyBTF7Lt3-kiG9UrWnl1R6SVsAev-1Y6aNc" }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                  >
                {
                  currentData != undefined ?
                  currentData.map((item, index)=>(
                    item.lat != '' &&  item.lng != '' ?
                    <AnyReactComponent
                      lat={item.lat}
                      lng={item.lng}
                      text=""
                    />
                    :
                    ""
                  ))
                  :
                  ""
                }

                  </GoogleMapReact>
                </div>
               
              </div>
          </div>

          </div>
        </section>

       <section className='pd889' id='section-formules'>
            <div className="container small-container">
              <div className="">
                <br/>
                <h5 style={{ color:'gray' }}><span>Les derniers biens publiés</span></h5>
              </div>
              <div className="process-wrap fl-wrap commentcafonctionne ">
                <div className="default-desc margright mrgtop"><p>&nbsp;</p>
                  <ul className="no-list-style row offresection">
                  {
                    last_biens != undefined ?
                    last_biens.map((element, index) => (
                      <li className="col-6 col-md-4 position_relative" style={{cursor:'pointer'}} onClick={()=>history.push('/detail-bien/'+element.id)}>
                      <div className="process-item position_relative">
                        <div className="process-img" style={{backgroundImage: element.img != '' ? "url("+BASE_URL_PROD+'/admin/public//img/biens/'+element.img+")" : "url('/images/imo.png')"}}></div>
                        <h4 className='basecolor type-bien tag'>{element?.type_bien== 1 ? 'Location Saisonnière' : 'Vente'}</h4>
                        <h4 className='basecolor title' >{element.logement}</h4>
                        <img src="/images/immo/favori.png" className="img-favoris"/>
                        <h6 className='' style={{ textAlign:"left", fontSize:"11px", color:"gray" }}>
                          <img src="/images/immo/icone-map.png" className="" style={{ width:"12px" }} /> {element?.address}
                        </h6>
                        <div className="priceoffre" style={{ textAlign:"left", fontSize:"18px" }}><span>{element.price} € </span>par nuit</div>
                      </div>
                    </li>
                    ))
                    :
                    ""
                  }
                  </ul>
                </div>
              </div>
              <br/>                           
            </div>
          </section>


        </div>
      </div>
    );
}
const AnyReactComponent = ({ text }) => <div><img style={{width:25}} src='/images/marker2.png' /></div>;
export default Page;