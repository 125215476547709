import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { Dashbord,AssistantItem, DashbordHeader} from "../../../components";
import { EditMission,getMissionById } from "../../../actions/pages";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router";
const Page = () => {

    const dispatch = useDispatch();
    const { slug } = useParams()
    const { user } = useSelector(state => state.user)
    const { mission } = useSelector(state => state.pages)
    const { t, i18n } = useTranslation();
    const currentlangStorage = localStorage.getItem("currentlang");
    const currentlang=i18n.language;
    const [langset, setLangset] = useState(false);
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        dispatch(EditMission(formDataObj,user.id,slug,user.emailToken,'update','user'));
    
    }
     useEffect(() => {
        
      
        if(langset == false){
            dispatch(getMissionById(slug,user.id,user.emailToken));
            if(!currentlangStorage || currentlangStorage=='null'){
             localStorage.setItem("currentlang",currentlang)
            }
            else{
                 i18n.changeLanguage(localStorage.getItem("currentlang"))
            }
            setLangset(true)
        }

    }, [])

    
 
    return (
        <div className="myccount editprofile " >
           <DashbordHeader src={'u'} />
     

            <section className="gray-bg main-dashboard-sec" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <Dashbord />
                                 
                            </div>

                    
                         <div className='col-md-9'>

                             <div className='tab' id=''>
                                 <div className="dashboard-title   fl-wrap">
                                    <h3 className='htri1'>{t('Modifier Mission')} </h3>
                                    <br/><br/>
                                   <form  onSubmit={handleSubmit} >
                                    <div className="profile-edit-container fl-wrap block_box">
                                    <div className="custom-form">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <label>{t('Catégorie')}</label>
                                                <Form.Control readOnly    type="text" className="form-control" defaultValue={mission.label} />                                              
                                            </div>
                                            <div className="col-sm-6">
                                                <label>{t('Ville')} </label>
                                                 <Form.Control readOnly type="text" className="form-control" defaultValue={mission.ville} />                                              
                                            </div>
                                           
                                            <div className="col-sm-6">
                                                <label>{t('Adresse')}</label>
                                                <Form.Control readOnly type="text"   defaultValue={mission.adresse} />                                                
                                            </div>

                                            <div className="col-sm-6">
                                                <label>{t('Prix')} (€)</label>
                                                <Form.Control readOnly type="text"   defaultValue={mission.price} />                                                
                                            </div>

                                            <div className="col-sm-6">
                                                <label>{t('Durée')}</label>
                                                <Form.Control readOnly type="text"   defaultValue={mission.duree} />                                                
                                            </div>

                                            {
                                              /*
                                                  <div className="col-sm-12 listing " style={{paddingTop:"0px"}}>
                                                <label> {t('Choisissez une durée approximative')}   </label>
                                                <div className='blockbg55'>
                                                <div className="mb-12 row">
                                                 <div className="col-sm-6"><div className="col-md-12"><div className="form-check"><input defaultChecked={mission.duree=='15mn' ? true:false}  required name="duree" type="radio" className="form-check-input" value="15mn"/><label title="" className="form-check-label"> 15mn</label></div></div><div className="col-md-12"><div className="form-check"><input required name="duree" type="radio" defaultChecked={mission.duree=='30mn' ? true:false}  className="form-check-input" value="30mn"/><label title="" className="form-check-label">30mn</label></div></div></div>
                                                <div className="col-sm-6"><div className="col-md-12"><div className="form-check"><input defaultChecked={mission.duree=='45mn' ? true:false}   required name="duree" type="radio" className="form-check-input" value="45mn"/><label title="" className="form-check-label">45mn</label></div></div><div className="col-md-12"><div className="form-check"><input  required name="duree" type="radio" defaultChecked={mission.duree=='1h' ? true:false}  className="form-check-input" value="1h"/><label title="" className="form-check-label">1h</label></div></div></div> 
                                                </div>
                                                </div>
                                                                                               
                                            </div>

                                              */
                                            }

                                              <div className="col-sm-6">
                                                <label> {t('Date')} </label>
                                                <Form.Control  style={{backgroundColor: "#FFF"}} required type="text" placeholder={t('Date souhaitée')+"*"} name="datepicker-here-time" defaultValue={mission.date_mission}/>                                                
                                            </div>


                                           


                                            <div className="col-sm-12">
                                                <label> {t('Que souhaitez vous faire ? Décrivez ce que dois réaliser l’assistant...')} </label>
                                                <textarea   defaultValue={mission.description} style={{marginBottom:"20px",backgroundColor: "#FFF"}} required name='description' cols="40" rows="2" >{mission.description}</textarea>                                               
                                            </div>
                                          
                                        </div>
                                      
                                    </div>
                                </div>



                                  <div className="row">
                                            <div className="col-sm-12">
                                                 <button type="submit" className="btn float-btn color2-bg btn9876 "> Modifier <i className="fas fa-caret-right"></i></button>
                                            </div>
                                   </div>

                                    </form>
                                 </div>
                              </div>
 

                              

                         </div>
                    </div>
            </section>


        </div>
    );
}

export default Page;