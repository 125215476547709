import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button ,Modal,CloseButton } from "react-bootstrap"
import { AssistantItem } from "../../../../components";
import { AvisItem } from "../../../../components";
import { getListWatcher } from "../../../../actions/user";
import { useHistory } from "react-router";
import { BASE_URL_PROD } from "../../../../components/config/keys";
import { surpls,epr } from "../../../../assets/images";
import { useTranslation } from 'react-i18next';
 
import { getBesoinCours } from "../../../../actions/pages";

const Page = () => {
    
    const autre_services_data = JSON.parse(localStorage.getItem('autre_services_data'));
    const dispatch = useDispatch();
    const history = useHistory()
    const { t, i18n } = useTranslation();
    const [besoin, setBesoin] = useState(autre_services_data.besoin);
    const [desc_besoin, setDescBesoin] = useState(autre_services_data.desc_besoin);
    const [besoinText, setBesoinText] = useState(autre_services_data.besoinText);
    const [niveau, setNiveau] = useState(autre_services_data.niveau);
    const [format, setFormat] = useState(autre_services_data.format);
    const [besoinPrice, setBesoinPrice] = useState(autre_services_data.besoinPrice);
    const [besoinList, setBesoinList] = useState([]);

    const [besoinError, setShowBesoinError] = useState(0);
    const [niveauError, setShowNiveauError] = useState(0);
    const [formatError, setShowFormatError] = useState(0);
    // const [autre_services_data, setItems] = useState([]);
    useEffect(() => {
      dispatch(getBesoinCours()).then((res) => {
          setBesoinList(res.data);
      })
    }, [])
  
    const handleGoBack = () => {
      history.push('/cours-standard');
    };

    const handleGoTo = () => {
      setShowBesoinError(0)
      setShowNiveauError(0)
      setShowFormatError(0)

      if(besoin == '' || besoin == undefined){
        setShowBesoinError(1)
      }else if(niveau == '' || niveau == undefined){
        setShowNiveauError(1)
      }else if(format == '' || format == undefined){
        setShowFormatError(1)
      }else{
        autre_services_data.besoin = besoin
        autre_services_data.desc_besoin = desc_besoin
        autre_services_data.besoinText = besoinText
        autre_services_data.besoinPrice = besoinPrice
        autre_services_data.niveau = niveau
        autre_services_data.format = format
        localStorage.setItem('autre_services_data', JSON.stringify(autre_services_data))
        history.push('/cours-standard/frequence');
      }
    };
    
    const handleBesoin = element => {
      setShowBesoinError(0)
      var index = element.nativeEvent.target.selectedIndex;
      setBesoinPrice(element.nativeEvent.target[index].dataset.price);
      setBesoin(element.target.value);
      setBesoinText(element.nativeEvent.target[index].text);
    };
    const handleBesoinDesc = element => {
      setDescBesoin(element.target.value);
    };
    const handleNiveau = element => {
      setShowNiveauError(0)
      setNiveau(element);
    };
    const handleFormat = element => {
      setShowFormatError(0)
      setFormat(element);
    };
    return (
      <div className="coursStandard2" >
        <section className="gray-bg big-padding " id="besoin1">
          <div className="container small-container ">
            <div className='registerform'>
              <div className='row '>
                <div className='col-md-8'><p onClick={()=>handleGoBack()} style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>&lt;	&lt;	Retour</p></div>
                <div className='col-md-8'>
                  <div className="section-title"><h2><span>Quelle est votre besoin ? </span></h2>
                  </div>
                  <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                  <select required id='besoin' name='besoin' value={besoin}  onChange={handleBesoin} >
                      <option value=""></option>
                      {
                        besoinList.length > 0 ?
                        besoinList.map(item => (
                          <option value={item.id} data-price={item.price}>{item.besoin}</option>
                        ))
                        :
                        ''
                      }
                    </select>
                  </div>
                  {
                    besoinError == 1 ?
                    <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                      <div class="alert alert-danger" role="alert">
                        Veuillez choisir votre besoin !
                      </div>
                    </div>
                    :
                    ""
                  }
                  {
                    besoinText != '' ?
                    <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                      <p style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>Pour quel niveau ?</p>
                      <div onClick={() => handleNiveau(1)} className={niveau === 1 ? 'custom-form col-md-3 boxShadow blocNiveau blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocNiveau'}><p className="p1">Primaire</p></div>
                      <div onClick={() => handleNiveau(2)} className={niveau === 2 ? 'custom-form col-md-3 boxShadow blocNiveau blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocNiveau'}><p className="p1">Collège</p></div>
                      <div onClick={() => handleNiveau(3)} className={niveau === 3 ? 'custom-form col-md-3 boxShadow blocNiveau blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocNiveau'}><p className="p1">Lycée</p></div>
                      <div onClick={() => handleNiveau(4)} className={niveau === 4 ? 'custom-form col-md-3 boxShadow blocNiveau blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocNiveau'}><p className="p1">Ens. supérieur</p></div>
                    </div>
                    :
                    ''
                  }
                  {
                    niveauError == 1 ?
                    <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                      <div class="alert alert-danger" role="alert">
                        Veuillez choisir un niveau !
                      </div>
                    </div>
                    :
                    ""
                  }
                  {
                    besoinText != '' ?
                    <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                      <p style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>Quel est le format qui vous convient le mieux ?</p>
                      <div onClick={() => handleFormat(1)} className={format === 1 ? 'custom-form col-md-3 boxShadow blocNiveau blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocNiveau'}><p className="p1">En présentiel</p></div>
                      <div onClick={() => handleFormat(2)} className={format === 2 ? 'custom-form col-md-3 boxShadow blocNiveau blocfrequenceActive' : 'custom-form col-md-3 boxShadow blocNiveau'}><p className="p1">En visio</p></div>
                    </div>
                    :
                    ''
                  }
                  {
                    formatError == 1 ?
                    <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                      <div class="alert alert-danger" role="alert">
                        Veuillez choisir le format !
                      </div>
                    </div>
                    :
                    ""
                  }
                  {
                    besoinText != '' ?
                    <div className='custom-form col-md-10' style={{ paddingLeft:"0" }}>
                      <p style={{ color: "rgb(7, 52, 96)", cursor:'pointer' }}>Décrivez votre besoin (facultatif)</p>
                      <textarea required id='desc_besoin' name='desc_besoin' value={desc_besoin}  onChange={handleBesoinDesc} ></textarea>
                    </div>
                    :
                    ''
                  }
                  <div className='col-md-9' style={{ paddingLeft:"0", paddingTop:'30px', clear:'both' }}>
                    <div style={{textAlign:'left',marginBottom:'30px'}}>
                      <a onClick={()=>handleGoTo()} class="btn btnform w200 "> Continuer </a>
                    </div>
                  </div>
                </div>    
                <div className='col-md-4'>
                    <div className="header1 boxShadow">Recapitulatif</div>
                    <div className="body1 boxShadow">
                      <p style={{ margin:'0px', paddingLeft:'10px' }}>1 intervention cours</p>
                      <hr style={{ margin:'10px' }}></hr>
                      {
                        besoinText != '' ?
                        <ul>
                          <li>{besoinText}</li>
                        </ul>
                        :
                        ''
                      }
                    </div>
                </div>  
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Page;