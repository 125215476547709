import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card,Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { DashbordMenuClient,DashbordHeaderClient } from "../../../components";
import { useTranslation } from 'react-i18next';
import { apiClient } from "../../../actions/api"
import { BASE_URL } from "../../../components/config/keys";
import { AUTHENTICATE_USER, LOGOUT_USER } from "../../../constants/actions";
import { getAllCategories } from "../../../actions/pages";
import { updateInfoUser, suspendCompte } from "../../../actions/user";
import { BASE_URL_PROD } from "../../../components/config/keys";
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';


import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const Page = () => {

  const stripe = loadStripe("pk_test_51LSGF1G7VfW1y640IKD9C5oLmKjNJD3WdB2jwYNOh0vqBOCdFtTCL58QrWONgbrOPA6ImuX0E2uYpk1aQJrWgQib00Ok5knJ1L");
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user)
  const { data } = useSelector(state => state.pages)
  const { t, i18n } = useTranslation();
  const currentlangStorage = localStorage.getItem("currentlang");
  const currentlang=i18n.language;
  const [langset, setLangset] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categ, setCateg] = useState('');
  const [rib, setRib] = useState('');
  const [loading, setLoading] = useState(false);
  const [rayon, setRayon] =  useState(user.rayon);
  const [show, setShow] = useState(false);
  const [showSuspend, setShowSuspend] = useState(false);
  const [disponibilte, setDisponibilite] = useState(user.disponibilite);
  const [geolocalisation, setGeolocalisation] = useState(user.geolocation);
  const [qrcode, setQrcode] = useState(user.qrcode);
  const [notif_email, set_notif_email] = useState(user.notif_email);
  const [notif_sms, set_notif_sms] = useState(user.notif_sms);
  const [notif_proximity, set_notif_proximity] = useState(user.notif_proximity);

  
  const [metier, setSecteurActivite] = useState(user.type_logement);
  const [type_entreprise, setTypeEntreprise] = useState(user.type_entreprise);
  const [societe, setSocieteName] = useState(user.societe_name);
  const [nb_employes, setNbEmploye] = useState(user.nb_employes);
  const [adresse, setAdresse] = useState(user.adresse);
  const [codepostal, setZipCode] = useState(user.zipcode);
  const [ville, setVille] = useState(user.ville);
  const [profession, setProfession] = useState(user.profession);
  const [prenom, setPrenom] = useState(user.prenom);
  const [nom, setNom] = useState(user.nom);
  const [telephone, setTelephone] = useState(user.telephone);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState('');
  const handleCloseDetail = () => setShowDetail(false);
  const [showDetail, setShowDetail] = useState(false);
  const [abonnement_price, setAbonnPrice] = useState(0);
  const [type_abonnement, setTypeabonnement] = useState(user.type_abonnement);

  

  const handleClose = () => setShow(false);
  const handleCloseSuspend = () => setShowSuspend(false);
  const handleShow = () => {


    setShow(true);
  }
  const handleShow1 = () => {
    setShowSuspend(true);
  }


  const onChangeData = (e) => {
    if(e.target.name == 'profession')
      setProfession(e.target.value)
    else if(e.target.name == 'nom')
      setNom(e.target.value)
    else if(e.target.name == 'prenom')
      setPrenom(e.target.value)
    else if(e.target.name == 'email')
      setEmail(e.target.value)
    else if(e.target.name == 'password')
      setPassword(e.target.value)
    else if(e.target.name == 'telephone')
      setTelephone(e.target.value)
  }

const changeShowDetail = (type, price) => {
  setTypeabonnement(type)
  if(type > 0){
    setShowDetail(true)
    setAbonnPrice(price)
  }
}

    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const label = { inputProps: { 'aria-label': '' } };
    const changeDisponibilite = async (valeur) => {
      setDisponibilite(valeur)
    }
    const changeGeolocalisation = async (valeur) => {
      setGeolocalisation(valeur)
    }
    const changeQrcode = async (valeur) => {
      setQrcode(valeur)
    }
    const changeRayon = async (valeur) => {
      setRayon(valeur)
    }
    const changeNotifEmail = async (valeur) => {
      set_notif_email(valeur)
    }
    const changeNotifSms = async (valeur) => {
      set_notif_sms(valeur)
    }
    const changeNotifProximity = async (valeur) => {
      set_notif_proximity(valeur)
    }
     const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
    
        
    
    }

    const handleSuspendreCompte = () => {
      dispatch(suspendCompte(user.id, 'customer')).then(() => {
        dispatch({type:LOGOUT_USER});
        setTimeout(() => {
          window.location = '/'
        }, 500);
      })
      // 
    }

    const handleSubmit1 = async (e) => {
      e.preventDefault();

      const formData = new FormData(e.target)
      const formDataObj = Object.fromEntries(formData.entries())
      let go=true;

 


      if(go){
         dispatch(updateInfoUser(formDataObj,user.id,'customer'));
      }
   }


    useEffect(() => {
    
      

    }, [])

   
    const saveAbonnement=(payement_inten_id)=>{
          const params = {
            payement_inten_id:payement_inten_id,
            user_id:user.id,
            type_abonnement:type_abonnement,
            abonnement_price:abonnement_price,
          };
          
          apiClient().post(`/professional/saveabonnement`,params)
          .then((res) => {
            if(res.data.success == 1){
                 setShowDetail(false)
                 dispatch({ type: AUTHENTICATE_USER, payload:res.data?.user})
                 setTimeout(function(){
                    history.push('/assistants/abonnement')
                 }, 2000);

            }
            else{
              alert('Une erreur est survenue veuillez réessayer ultérieurement.')
            }
            
             
          })
      
    } 
 
    // Changing State when volume increases/decreases
    const rangeSelector = (event, newValue) => {
      setRayon(newValue);
      
    };
   
    const changeTelephone = (event, newValue) => {
      setTelephone(newValue);
      
    };
    const changeNom = (event, newValue) => {
      setNom(newValue);
      
    };
 

 

    return (
        <div className="myccount editprofil abonnement " >
            
                       <section className="gray-bg main-dashboard-sec dashboard myccount" id="sec1">
                    <div className="container">
                            
                            <div className="col-md-3">
                                 <DashbordMenuClient src='profil'/>
                                 
                            </div>


                         <div className='col-md-9 dashbordassitantcontent' >

                             <div className='tab' id=''>


                              <DashbordHeaderClient src={'w'} />

                             <div className='row' style={{marginTop:'30px'}}>

                               <div className='col-md-12'>

                  <img src="/images/liset_abonnement.png" style={{width:"100%"}}></img>
                               </div>
                  

                             </div>


                                 
                          </div>
 

                              

                         </div>
                    </div>
            </section>
     <Modal show={show}   
      size="lg"
 
     onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='clrbl90'>Supprimer mon compte</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <p className='clrbl10'>En supprimant votre compte vous supprimez également ses données (services, base clients, factures/devis, chiffre d'affaires) sans remboursement possible.<br/>
          <strong>Vous ne pourrez plus vous connecter à votre espace de travail.</strong></p>

          <div className='mdflx99'>

         
            <input type='checkbox'  />
               <label>
                  Je confirme que mes transactions sont bien finalisées.
            </label>

          </div>

          <div>
           <textarea className='form-control clrbl10' placeholder='Aidez-nous à nous améliorer, pourquoi souhaitez-vous supprimer votre compte ?'></textarea>
          </div>


        </Modal.Body>
        <Modal.Footer style={{justifyContent: 'flex-start'}}>
          <Button className='btnmodal btnmodalbrd' variant="secondary" onClick={handleClose} >
            Annuler
          </Button>
          <Button className='btnmodal' variant="primary" onClick={handleClose}>
            Supprimer mon compte
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSuspend}   
      size="lg"
 
     onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='clrbl90'>Suspendre mon compte</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>Voullez vous vraiment suspendre votre compte ?</strong>
        </Modal.Body>
        <Modal.Footer style={{justifyContent: 'flex-start'}}>
          <Button className='btnmodal btnmodalbrd' variant="secondary" onClick={handleCloseSuspend} >
            Annuler
          </Button>
          <Button className='btnmodal' variant="primary" onClick={handleSuspendreCompte}>
            Suspendre mon compte
          </Button>
        </Modal.Footer>
      </Modal>


          <Modal show={showDetail} onHide={handleCloseDetail}>
                  <Modal.Header closeButton>
                        Abonnement
                  </Modal.Header>
                  <Modal.Body>

                  <div className="cadrerecap">
                            
                            <div className='bodyrecap '>
                              <div className="ligne">
                                <div className="col-md-12">
                                    <img src='/images/paiemenetsecurise.jpg' />
                                  </div>
                              
                              </div>
                                <div className="ligne paiments">
                                <div className="col-md-12">
                        <Elements stripe={stripe}>
                            <CheckoutForm saveAbonnement={saveAbonnement} user={user} total={abonnement_price} history={history} BASE_URL={BASE_URL}  />
                        </Elements>
                                  </div>
                              
                              </div>


                            </div>

                        </div>
                  </Modal.Body>
          </Modal>
        </div>
    );
}

export default Page;


const CheckoutForm = ({saveAbonnement,total,user,history,BASE_URL}) => {
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  
  const stripe = useStripe();
  const elements = useElements();


   const getSetupClientSecret=()=>{

      const params = {
        total: total ,
        customer_id: user.id
      }
      return fetch(BASE_URL+'/payment/stripe', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(params)
      }).then(response => response.json())
        .catch(error => {
          console.log("stripe_get_secret_setup_intents error", error)
        });
    } 


  const payMoney = async (e) => {
    e.preventDefault();
      
      // history.push('/votre-paiement-accepte/')
    // return false;
    
    if (!stripe || !elements) {
      return;
    }


    setPaymentLoading(true);
    
    const info = await getSetupClientSecret();
    const clientSecret = info.stripe.client_secret;
    const paymentResult = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: user.prenom+ +user.nom,
        },
      },
    });
    setPaymentLoading(false);
    console.log(paymentResult);
    if (paymentResult.error) {
      alert(paymentResult.error.message);
    } else {
       if (paymentResult.paymentIntent.status == "requires_capture") {
           console.log(paymentResult.paymentIntent.id);
          saveAbonnement(paymentResult.paymentIntent.id);
      }
    }
  };




  return (
    <div>
      <div
        style={{
          maxWidth: "500px",
          margin: "0 auto",
        }}
      >
        <form
          style={{
            display: "block",
            width: "100%",
          }}

          onSubmit = {payMoney}
         
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardElement
              className="card"
              options={{
                style: {
                  base: {
                    backgroundColor: "white",
                    width: "100%",
                    
                  } 
                },
              }}
            />
            <button
              className="pay-button"
              disabled={isPaymentLoading}
            >
              {isPaymentLoading ? "Loading..." : "Payer "+total+" €"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}